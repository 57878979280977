import React, { useCallback, useMemo, useRef, useState } from "react";
import { Popup } from "react-mapbox-gl";
import moment from "moment";
import { Chip } from "@material-ui/core";

export const PopUpStop = ({ stopSelected, setStopSelected, mapProps }) => {
  return (
    <Popup key={stopSelected.id} coordinates={mapProps.center}>
      <button
        className="mapboxgl-popup-close-button"
        type="button"
        aria-label="Close popup"
        onClick={() => setStopSelected(undefined)}
      >
        ×
      </button>
      <div style={{ color: "green" }}>
        {"Fec. transmisión: " +
          moment(stopSelected.start.time).format("DD-MM-YYYY HH:mm")}
      </div>
      <div style={{ color: "green" }}>
        {"T. detenido: " +
          moment
            .duration(
              moment(stopSelected.end.time).diff(
                moment(stopSelected.start.time)
              )
            )
            .humanize()}
      </div>
    </Popup>
  );
};
