import React, {useState, useEffect, useRef} from "react";
import {useHistory} from "react-router-dom";
import {useSubheader} from "../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader, CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import * as requestFromServer from "../../helpers/axios";
import {Button} from "react-bootstrap"
import {FormCliente} from "./form/FormCliente";
import {InitialStateCliente} from "./form/InitialStateCliente";
import {addAlert} from "../../../redux/alerts/alertsRedux";
import {useDispatch} from "react-redux";

export const EditCliente = ({match}) => {
    const dispatch = useDispatch()
    const suhbeader = useSubheader();
    suhbeader.setTitle("Marcas");
    const history = useHistory();
    const {params} = match
    const {id} = params

    const btnRef = useRef()

    const [cliente, setCliente] = useState(InitialStateCliente)
    const [loading, setLoading] = useState(false)

    let cardTitle = "Ingresar Cliente "
    if (id) cardTitle = "Editar Cliente "

    const newEntity = !id

    const sendClienteToServer = async () => {
        setLoading(true)
        if (!await validateCliente()) {
            dispatch(addAlert({msg: "Ya existe un cliente con ese nombre, holding y código", type: "danger"}))
            setLoading(false)
            return
        }
        const sendRequest = () => {
            if (!newEntity)
                return requestFromServer.updateCliente(id, cliente)
            else return requestFromServer.insertCliente(cliente)
        }
        sendRequest()
            .then(response => {
                history.push('/clientes/list')
            })
            .catch(error => {
                console.log(error)
                setLoading(false)
            });
    }
    const validateCliente = async () => {
        const sendRequest = () => {
            if (!newEntity)
                return requestFromServer.validateClienteWithId({...cliente, id_clientes : id})
            else return requestFromServer.validateCliente(cliente)
        }
        const cantClientes = (await sendRequest()).data
        return cantClientes <= 0
    }
    const onHandleSubmit = () => btnRef.current.click()

    const fetchCliente = () => {
        setLoading(true)
        requestFromServer.getCliente(id)
            .then(response => {
                setCliente({...response.data})
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
            });
    }

    useEffect(() => {
        if (id) fetchCliente()
        // eslint-disable-next-line
    }, []);
    return (<>
        <Card>
            <CardHeader title={cardTitle}>

                <CardHeaderToolbar>


                    {!loading && <Button type="button" onClick={onHandleSubmit}>
                        {newEntity ? "Ingresar" : "Actualizar"}</Button>}


                    {loading && <span
                        className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
                    />}


                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>

                <FormCliente
                    cliente={cliente}
                    setCliente={setCliente}
                    newEntity={newEntity}
                    sendClienteToServer={sendClienteToServer}
                    setLoading={setLoading}
                    btnRef={btnRef}
                />

            </CardBody>
        </Card>
    </>);
};
