import React from "react";
import moment from "moment";
import { exportToExcelPlantillaVisJs } from "../../../../helpers/XLSHelpers";

const prepareXLSVisitas = async visitas => {
  const data = [];
  const dataCarga = [];
  for (const v of visitas)
    dataCarga.push({
      ID: v.id,
      "Fecha Programada": v.visitas_fecvisita,
      "Fecha Ejecutada": v.visitas_fecejecuta,
      "Cliente Nombre": v.clientes_nombre,
      "Cliente Rut": `${v.clientes_rut}`,
      "Tienda Nombre": `${v.direcciones_nombre}`,
      "Tienda CL": `${v.cl}`,
      "Tipo de Visita": v.mv_descripcion,
      "Estado visita": v.est_visita_nombre,
      "Cantidad Venta": v.visitas_reporte
        ? v.visitas_reporte.cantidad_venta
        : "",
      Asunto: v.visitas_asunto,
      Minuta: v.visitas_reporte ? v.visitas_reporte.motivo : "",
      Participantes: v.evento_participantes
    });

  const carga = { sheetName: "Data", data: dataCarga };

  data.push(carga);

  return data;
};

export const generateVisitasXls = async visitas => {
  const fileName = `Visitas ${moment().format("DD-MM-YYYY HH_ss")}`;
  const csvData = await prepareXLSVisitas(visitas);
  await exportToExcelPlantillaVisJs(csvData, fileName);
  return true;
};
