import React from "react";
import moment from "moment";
import { exportToExcelJs } from "../../../helpers/XLSHelpers";

const preparePlantillaXLS = async () => {
  const data = [];
  const dataCarga = [];

  dataCarga.push({
    Sku: "",
    Nombre: "",
    "Tipo Material": "",
    "Formato del envase": "",
    "Formato Material": "",
    "Edad Bodega": "",
    "Edad Primaria": "",
    "Edad Consumidor": "",
    "Edad Total": "",
    "Cajas por pallet": "",
    Cuenta: "",
    "Bot x Cjs": "",
    Litros: "",
    "U / M": "",
    "Conversión UC": "",
    "Tipo Envase": "",
    "UM Minima": ""
  });

  //cargamos tipos material
  /*const responseTiposMat =await requestFromServer.getTiposMat()
  const dataTiposMaterial=responseTiposMat.data.entities.map(x=>({
      'Tipos': `${x.id} - ${x.tipo_material_nombre}`
  }))

  const responseFormatos =await requestFromServer.getFormatos({})
  const dataFormatos=responseFormatos.data.entities.map(x=>({
      'Formatos': `${x.id} - ${x.formato_nombre}`
  }))

  const responseCuentas =await requestFromServer.getCuentasMat()
  const dataCuentas=responseCuentas.data.entities.map(x=>({
      'Cuentas': `${x.id} - ${x.cuenta_mat_nombre}`
  }))*/
  const carga = { sheetName: "Data", data: dataCarga };
  //const tmaterial = { sheetName: 'TiposMaterial', data: dataTiposMaterial }
  //const formatos = { sheetName: 'Formatos', data: dataFormatos }
  //const cuentas = { sheetName: 'Cuentas', data: dataCuentas }

  data.push(carga);
  //data.push(tmaterial)
  //data.push(formatos)
  //data.push(cuentas)
  return data;
};
const prepareXLSLicencias = async licencias => {
  const dataCarga = [];
  for (const v of licencias)
    dataCarga.push({
      "Login": `${v.usu_login}`,
      "Nombre": `${v.usu_nombre} ${v.usu_pat} ${v.usu_mat}`,
      "Cargo": `${v.usu_cargo}`,
      "Perfil": `${v.usu_perfil_nombre}`,
      "Correo": `${v.usu_mail}`,
    });
  const carga = { sheetName: "Data", data: dataCarga };
  const data = await preparePlantillaXLS();
  data[0] = carga;

  return data;
};

export const generateLicenciasXls = async licencias => {
  const fileName = `Licencias ${moment().format("DD-MM-YYYY HH_ss")}`;
  const csvData = await prepareXLSLicencias(licencias);
  await exportToExcelJs(csvData, fileName);
  return true;
};
