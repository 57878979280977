import { sortCaret } from "../../../../_metronic/_helpers";
import { ActionsColumnFormatterVisita } from "./ActionsColumnFormatterVisita";
export const getColumnsAuditorias = () => [
  {
    dataField: "id",
    text: "Visita",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "sap",
    text: "SAP",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "clientes_nombre",
    text: "Cliente",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "ccli_rut_cliente",
    text: "Rut Cliente",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "direcciones_nombre",
    text: "Dirección",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "fec_ejecuta",
    text: "Fecha Cierre",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "accions",
    text: "Acciones",
    formatter: ActionsColumnFormatterVisita,
    classes: "text-right pr-3 ",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "70px"
    }
  }
];
