import axios from "axios";

export const REQUEST_URL = "api/clientes";
export const getClientes = queryParams =>
  axios.post(`${REQUEST_URL}/getClientes`, { queryParams });
export const insertCliente = cliente =>
  axios.put(`${REQUEST_URL}/put`, { cli: cliente });
export const updateCliente = (id, cliente) =>
  axios.put(`${REQUEST_URL}/update/${id}`, { cli: cliente });
export const getCliente = id => axios.get(`${REQUEST_URL}/getcli/${id}`);
export const deleteCliente = id => axios.put(`${REQUEST_URL}/delete/${id}`);
export const UploadClienteXlxs = (dataClientes) => axios.post(`${REQUEST_URL}/UploadClienteXlxs`, {dataClientes: dataClientes});
export const getClientesXlxs = queryParams =>
    axios.post(`${REQUEST_URL}/getClientesXlxs`, { queryParams });
export const validateCliente = queryParams =>
    axios.post(`${REQUEST_URL}/validateCliente`, { queryParams })
export const validateClienteWithId = queryParams =>
    axios.post(`${REQUEST_URL}/validateClienteWithId`, { queryParams })