import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
const initialRdetState = {
  clientes: [],
  totalCount: 0,
  loading: false
};

export const clientesReducer = persistReducer(
  { storage, key: "v709-demo1-clientes", whitelist: ["clientes"] },
  (state = initialRdetState, action) => {
    switch (action.type) {
      case "@setClientes": {
        const { clientes, totalCount } = action.payload;
        return { ...state, clientes: clientes, totalCount: totalCount };
      }
      case "@setLoading": {
        const { loading } = action.payload;
        return { ...state, loading: loading };
      }
      default:
        return state;
    }
  }
);
