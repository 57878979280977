import axios from "axios";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
export const REQUEST_URL = "api/repositorios";
export const getRepositorios = queryParams =>
  axios.post(`${REQUEST_URL}/`, { queryParams });
export const insertRepositorio = (repositorio, extension) =>
  axios.post(`${REQUEST_URL}/put`, {
    repositorio: repositorio,
    extension: extension
  });
export const deleteRepositorio = id => axios.put(`${REQUEST_URL}/delete/${id}`);

export const uploadFileS3 = async (name, uri, folder) => {
  try {
    if (typeof uri === "string" || uri instanceof String) {
      if (uri.includes("amazonaws")) {
        return;
      }
    }
    const region = "sa-east-1";
    const client = new S3Client({
      region,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region }),
        identityPoolId: "sa-east-1:cd080295-f30a-4d28-a3bc-2e09b63cf27d"
      })
    });
    const params = {
      Bucket: "rodstreet-chile",
      Key: folder + name,
      Body: uri,
      ContentEncoding: "base64",
      ContentType: "*/*",
      ContentDisposition: 'inline;filename="' + name + '"'
    };
    return await client.send(new PutObjectCommand(params)).catch(err => {
      throw err;
    });
  } catch (err) {
    console.log("Error", err);
    throw err;
  }
};
