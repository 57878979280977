import {
  exportToExcelPlantillaJs,
  exportToExcelPlantillaMarketingJs,
  exportToExcelPlantillaVisJs
} from "./xls_templata_cm";
import moment from "moment";

const prepareXLS = async (proMetas, metas) => {
  const data = [];
  const dataCarga = [];

  for (const pm of proMetas) {
    const metasUsuario = metas
      .filter(x => parseInt(x.id_usu) === parseInt(pm.id_usu))
      .map(x => x.meta_cantidad);
    dataCarga.push({
      Representante: pm.fullname,
      Fecha: moment(pm.fecha).format("DD-MM-YYYY"),
      Ventas: parseInt(pm.ventas),
      Factor: parseFloat(pm.factor),
      Meta:
        metasUsuario.length > 0
          ? metasUsuario.reduce((partialSum, a) => partialSum + a, 0) *
            pm.factor
          : 0
    });
  }
  const carga = { sheetName: "Data", data: dataCarga };

  data.push(carga);

  return data;
};
const prepareRepCampanasMarketingXLS = async datas => {
  const data = [];
  const dataCarga = [];
  const headers = [];
  headers.push("CL");
  for (const d of datas.campanas) {
    headers.push(d.campana_venta_nombre + " - " + d.id);
  }

  for (const cl of datas.tiendas) {
    const cvc = datas.cvc.filter(x => x.cod_cl === cl.t_cod_cl);
    const row = {};
    row["CL"] = cl.t_cod_cl;
    for (const cv of datas.campanas) {
      const cvcRow = cvc.find(x => x.id === cv.id);
      if (cvcRow) row[cv.campana_venta_nombre + " - " + cv.id] = "1";
      else row[cv.campana_venta_nombre + " - " + cv.id] = "0";
    }

    dataCarga.push(row);
  }
  //console.log(dataCarga.filter(x => x["CL"] === "CL00000000"));
  const carga = { sheetName: "Data", data: dataCarga };

  data.push(carga);

  return { data: data, headers: headers };
};

export const generateProMetasXls = async (proMetas, metas) => {
  const fileName = `Pronostico Metas `;
  const csvData = await prepareXLS(proMetas, metas);
  await exportToExcelPlantillaVisJs(csvData, fileName);
  return true;
};

export const generateRepCampanasMarketingXls = async data => {
  const fileName = `Reporte Campanas Marketing `;
  const {
    data: csvData,
    headers: headers
  } = await prepareRepCampanasMarketingXLS(data);
  await exportToExcelPlantillaMarketingJs(csvData, fileName, headers);
  return true;
};
