import React, { useState, useCallback, useEffect } from "react";
import { isEqual, isFunction, cloneDeep } from "lodash";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import { getColumns } from "./table_helpers/Tablecols";
import * as requestFromServer from "../../helpers/axios";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from "../../../_metronic/_helpers";
import { Pagination } from "../../../_metronic/_partials/controls";
import { TableFilters } from "./table_helpers/TableFilters";
import {
  getPaginationOptions,
  initialTableFilter
} from "../../helpers/TableHelpers";
import { Button } from "react-bootstrap";
import { ModalRepositorio } from "./form/ModalRepositorio";
import { ModalRepositorioDelete } from "./form/ModalRepositorioDelete";
import { PaginationTotalCustom } from "../_GlobalComponents/table/pagination/PaginationTotalCustom";
import { getColumnsAuditorias } from "./table_helpers/TablecolsAuditorias";
import moment from "moment";

export const RutaDiariaRep = ({ match }) => {
  const { params } = match;
  const { id } = params;
  const suhbeader = useSubheader();
  suhbeader.setTitle("Ruta Diaria");

  const [repositorios, setRepositorios] = useState(null);
  const [auditorias, setAuditorias] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [nombreRep, setNombreRep] = useState("");

  const fecha = id.split("_")[1];

  const fetchRepositorios = () => {
    setListLoading(true);
    requestFromServer
      .getSugVisitasList(id)
      .then(response => {
        if (response.data) {
          if (response.data.entities) setRepositorios(response.data.entities);
          if (response.data.auditorias) setAuditorias(response.data.auditorias);
          if (response.data.entities && response.data.entities[0])
            setNombreRep(response.data.tec_fullname);
        } else {
          setRepositorios([]);
          setAuditorias([]);
        }

        setListLoading(false);
      })
      .catch(error => {
        console.log(error);
        setListLoading(false);
      });
  };
  useEffect(() => {
    fetchRepositorios();
  }, []);

  return (
    <>
      <Card>
        <CardHeader
          title={`Ruta Diaria ${nombreRep} ${moment(fecha).format(
            "DD/MM/yyyy"
          )}`}
        >
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <BootstrapTable
            wrapperClasses="table-responsive"
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            bordered={false}
            remote
            keyField={"id"}
            data={repositorios === null ? [] : repositorios}
            columns={getColumns()}
            filter={filterFactory()}
            striped
            hover
            condensed
          >
            <PleaseWaitMessage entities={repositorios} />
            <NoRecordsFoundMessage entities={repositorios} />
          </BootstrapTable>
        </CardBody>
      </Card>
      <Card>
        <CardHeader title="Visitas" />
        <CardBody>
          <BootstrapTable
            wrapperClasses="table-responsive"
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            bordered={false}
            remote
            keyField={"id"}
            data={auditorias === null ? [] : auditorias}
            columns={getColumnsAuditorias()}
            filter={filterFactory()}
            striped
            hover
            condensed
          >
            <PleaseWaitMessage entities={auditorias} />
            <NoRecordsFoundMessage entities={auditorias} />
          </BootstrapTable>
        </CardBody>
      </Card>
    </>
  );
};
