import React from "react";

export const CalendarioBadge = props => {
  const { label, quantity, color } = props;
  return (
    <>
      <div className={"form-group col-md-3 col-xs-12 m-0"}>
        <span
          className={"badge badge-square badge-dark"}
          style={{ backgroundColor: `${color}` }}
        >
          {quantity}
        </span>
        &nbsp;
        <label>{label}</label>
      </div>
    </>
  );
};
