import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import { Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";

const SelectAsyncPaginationDirMulti = props => {
  const [cliente, setCliente] = useState(null);
  const { tiendas } = useSelector(state => state.tiendas);

  useEffect(() => {
    setCliente(props.cliente);
  }, [props.cliente]);

  const loadOptions = async (searchQuery, loadedOptions, { pageNumber }) => {
    let dataFiltradas;
    if (searchQuery)
      dataFiltradas = tiendas.filter(
        x =>
          x.t_cod_cl?.toUpperCase().includes(searchQuery.toUpperCase()) ||
          x.t_direccion?.toUpperCase().includes(searchQuery.toUpperCase())
      );
    else dataFiltradas = tiendas;
    const dataPaginada = dataFiltradas
      .slice((pageNumber - 1) * 10, pageNumber * 10)
      .map(x => ({
        value: x.t_cod_cl,
        cl: x.t_cod_cl,
        t_nombre_cliente: x.t_nombre_cliente,
        t_rut_cliente: x.t_rut_cliente,
        t_direccion: x.t_direccion,
        label: `${x.t_direccion} - ${x.t_cod_cl}`
      }));
    let hasMore = false;
    if (dataPaginada) hasMore = dataFiltradas.length > pageNumber * 10;
    if (dataPaginada && props.isFilter && pageNumber === 1)
      dataPaginada.unshift({ value: undefined, label: "Todos" });
    return {
      options: dataPaginada ? dataPaginada : [],
      hasMore: hasMore,
      additional: {
        pageNumber: pageNumber + 1
      }
    };
  };
  const onChange = option => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  return (
    <Form.Group controlId="exampleForm.ControlInput1" as={Col} md={props.md}>
      {!props.isFilter && (
        <Form.Label>
          Tienda <span className={"text-danger"}>{props.required && "*"}</span>{" "}
          :
        </Form.Label>
      )}
      <AsyncPaginate
        key={JSON.stringify(cliente)}
        isMulti
        value={props.value || ""}
        loadOptions={loadOptions}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        onChange={onChange}
        isSearchable={true}
        placeholder="Seleccionar tienda"
        additional={{
          pageNumber: 1
        }}
      />
      {props.isFilter && (
        <small className="form-text text-muted">
          <b>Filtrar</b> por tienda
        </small>
      )}
    </Form.Group>
  );
};

SelectAsyncPaginationDirMulti.propTypes = {
  md: PropTypes.number.isRequired,
  cliente: PropTypes.number,
  noNullClients: PropTypes.bool,
  value: PropTypes.object,
  onChange: PropTypes.func,
  isFilter: PropTypes.bool.isRequired,
  required: PropTypes.bool
};
export default SelectAsyncPaginationDirMulti;
