import React, {useState} from 'react';
import {Modal, Button} from "react-bootstrap";
import * as requestFromServer from "../../../helpers/axios";
import { addAlert } from '../../../../redux/alerts/alertsRedux'
import { useDispatch } from 'react-redux'

export const ModalChangeVisita = (props) => {
    const {id_visita, show, setShow, handleChangeDate, OnClickBuscar} = props
    const dispatch = useDispatch()


    const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleDelete = () => {
        setLoading(true)
        requestFromServer.deleteVisita(id_visita).then(response => {
            dispatch(addAlert({ msg: 'Visita Eliminada' }))
            setShow(false)
        }).catch(err => console.log(err)).finally(() => setLoading(false))
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Reasignación de Visita</Modal.Title>
                </Modal.Header>
                <Modal.Body>¿Está seguro que desea reasignar la visita? </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={OnClickBuscar}>
                        No
                    </Button>
                    <Button variant="danger" onClick={handleChangeDate}>
                        Si
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
