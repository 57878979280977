import { getTiendasAction } from "./actions/getTiendasAction";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
const initialRdetState = {
  tiendas: [],
  totalCount: 0,
  loading: false
};

export const tiendasReducer = persistReducer(
  { storage, key: "v709-demo1-tiendas", whitelist: ["tiendas"] },
  (state = initialRdetState, action) => {
    switch (action.type) {
      case "@setTiendas": {
        const { tiendas, totalCount } = action.payload;
        return { ...state, tiendas: tiendas, totalCount: totalCount };
      }
      case "@setLoading": {
        const { loading } = action.payload;
        return { ...state, loading: loading };
      }
      default:
        return state;
    }
  }
);
export const actionsTiendas = {
  getTiendas: q => getTiendasAction(q)
};
