import React from "react";
import { NumericFormat } from "react-number-format";

export function RowTablaInformeRepresentanteTipo({
  prod,
  unitsThisYear,
  unitsLastYear,
  varVsUnits,
  lastYear,
  thisYear,
  varVsMTD,
  varPercentage,
  aspLastYear,
  aspThisYear,
  prefix
}) {
  const evaluateIfIntinity = value => {
    if (value === Infinity) return 100;
    else return value;
  };
  return (
    <div style={styles.row}>
      <div style={{ ...styles.col }}>
        <h3
          style={{
            ...styles.title2,
            textAlign: "center",
            fontWeight: "bold"
          }}
        >
          {prod}
        </h3>
      </div>
      <div style={{ ...styles.col }}>
        <NumericFormat
          value={isNaN(unitsThisYear) ? 0 : Math.round(unitsThisYear)}
          displayType={"text"}
          decimalSeparator={","}
          thousandSeparator={"."}
          prefix={prefix}
          renderText={value => <h3 style={styles.title2}>{value}</h3>}
        />
      </div>
      <div style={{ ...styles.col }}>
        <NumericFormat
          value={isNaN(unitsLastYear) ? 0 : Math.round(unitsLastYear)}
          displayType={"text"}
          decimalSeparator={","}
          thousandSeparator={"."}
          prefix={prefix}
          renderText={value => <h3 style={styles.title2}>{value}</h3>}
        />
      </div>
      <div style={{ ...styles.col }}>
        <h3
          style={{
            ...styles.title2,
            color: varVsUnits < 0 ? "red" : "blue"
          }}
        >
          {isNaN(varVsUnits)
            ? `0%`
            : `${evaluateIfIntinity(Math.round(varVsUnits))}%`}
        </h3>
      </div>

      <div style={{ ...styles.col }}>
        <NumericFormat
          value={isNaN(thisYear) ? 0 : Math.round(thisYear)}
          displayType={"text"}
          decimalSeparator={","}
          thousandSeparator={"."}
          prefix={prefix}
          renderText={value => <h3 style={styles.title2}>{value}</h3>}
        />
      </div>
      <div style={{ ...styles.col }}>
        <NumericFormat
          value={isNaN(lastYear) ? 0 : Math.round(lastYear)}
          displayType={"text"}
          decimalSeparator={","}
          thousandSeparator={"."}
          prefix={prefix}
          renderText={value => <h3 style={styles.title2}>{value}</h3>}
        />
      </div>
      <div style={{ ...styles.col }}>
        <h3
          style={{
            ...styles.title2,
            color: varVsMTD < 0 ? "red" : "blue"
          }}
        >
          {isNaN(varVsMTD)
            ? `0%`
            : `${evaluateIfIntinity(Math.round(varVsMTD))}%`}
        </h3>
      </div>

      <div style={{ ...styles.col }}>
        <NumericFormat
          value={isNaN(aspThisYear) ? 0 : Math.round(aspThisYear)}
          displayType={"text"}
          decimalSeparator={","}
          thousandSeparator={"."}
          prefix={prefix}
          renderText={value => <h3 style={styles.title2}>{value}</h3>}
        />
      </div>
      <div style={{ ...styles.col }}>
        <NumericFormat
          value={isNaN(aspLastYear) ? 0 : Math.round(aspLastYear)}
          displayType={"text"}
          decimalSeparator={","}
          thousandSeparator={"."}
          prefix={prefix}
          renderText={value => <h3 style={styles.title2}>{value}</h3>}
        />
      </div>
      <div style={{ ...styles.col }}>
        <h3
          style={{
            ...styles.title2,
            color: varPercentage < 0 ? "red" : "blue"
          }}
        >
          {isNaN(varPercentage)
            ? `0%`
            : `${evaluateIfIntinity(Math.round(varPercentage))}%`}
        </h3>
      </div>
    </div>
  );
}
const styles = {
  col: {
    width: 100
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignSelf: "stretch",
    paddingHorizontal: 10
  },
  title2: {
    color: "#000000",
    fontSize: 12,
    textAlign: "center",
    marginTop: 12,
    fontWeight: "bold"
  }
};
