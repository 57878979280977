import React, { useEffect, useState } from "react";
import * as requestFromServer from "../../../helpers/axios";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";

export const PerfilFilter = props => {
  const { onChange } = props;
  const [perfiles, setPerfiles] = useState([]);
  const animatedComponents = makeAnimated();

  useEffect(() => {
    const queryParams = { eliminado: false };

    requestFromServer
      .getPerfiles(queryParams)
      .then(response => {
        if (response.data) {
          const result = response.data.entities;
          perfiles.push({ value: undefined, label: "Todos" });
          setPerfiles([
            ...perfiles,
            ...result.map(x => ({
              value: x.id,
              label: `${x.perfil_nombre}`
            }))
          ]);
        }
      })
      .catch(error => console.log(error));
  }, []);

  return (
    <div className="col-sm-6 col-md">
      <Select
        options={perfiles}
        closeMenuOnSelect={true}
        components={animatedComponents}
        onChange={onChange}
        placeholder={" "}
      />

      <small className="form-text text-muted">
        <b>Filtrar</b> por perfil
      </small>
    </div>
  );
};
