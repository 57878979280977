import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Link } from "react-router-dom";

export const ActionsColumnFormatterVisita = (cellContent, row) => {
  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip id="botis-delete-tooltip">Ver Informe</Tooltip>}
      >
        <Link
          className="btn btn-icon btn-light btn-hover-primary btn-sm mr-1"
          target="_blank"
          to={"/visitas/ver/" + row.id}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
          </span>
        </Link>
      </OverlayTrigger>
    </>
  );
};
