import React from 'react'
import { Col, Form } from 'react-bootstrap'

export const InputTextArea = (props) => {
  const { value, onChange, label } = props
  const { required } = props
  return (
    <Form.Group controlId="exampleForm.ControlInput1" as={Col}>
      <Form.Label>{label} {required
        ? <span className={'text-danger'}>*  </span>
        : ''}:</Form.Label>
      <Form.Control as={'textarea'} required={required} onChange={onChange}
                    value={value}/>
    </Form.Group>
  )
}
