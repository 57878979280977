import React from 'react';
import {Form} from "react-bootstrap";

export const IdVisFilter = (props) => {
    const {onChange, label,value}=props
    return (
        <>
            <div className="col-sm-4 col-md-2">
                <Form.Control type={"text"} onChange={onChange}
                              value={value} />
                <small className="form-text text-muted">
                    {label}
                </small>
            </div>
        </>
    );
};
