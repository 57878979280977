import { ActionsColumnFormatter } from "./ActionsColumnFormatter";
import { sortCaret } from "../../../../_metronic/_helpers";
import {
  ColorColumnFormatter,
  LinkColumnFormatter
} from "./LinkColumnFormatter";
import { VigenciaColumnFormatter } from "./VigenciaColumnFormatter";

export const getColumns = (
  setShow,
  setIdCampanaVisita,
  setShowPause,
  setShowResumir
) => [
  {
    dataField: "campana_venta_nombre",
    text: "Nombre",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "campana_venta_fecha_inicio",
    text: "Fecha Inicio",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "campana_venta_fecha_termino",
    text: "Fecha Termino",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "campana_venta_archivo",
    text: "Archivo",
    sort: true,
    style: {
      width: "90px"
    },
    sortCaret: sortCaret,
    formatter: LinkColumnFormatter
  },
  {
    dataField: "estado_campanas_nombre",
    text: "Estado",
    sort: true,
    sortCaret: sortCaret,
    formatter: VigenciaColumnFormatter
  },
  {
    dataField: "accions",
    text: "Acciones",
    formatter: ActionsColumnFormatter,
    formatExtraData: {
      setShow,
      setIdCampanaVisita,
      setShowPause,
      setShowResumir
    },
    classes: "text-right pr-3 ",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "70px"
    }
  }
];
