import React, { useEffect, useState } from "react";
import { InitialFilter } from "./InitialFilters";
import { TextFilter } from "./TextFilter";
import { PerfilFilter } from "./PerfilFilter";
import { FecVisFilter } from "../../visitas/table_helpers/filter_comps/FecVisFilter";

export const TableFilters = props => {
  const { queryParams, setQueryParams } = props;

  const [filters, setFilters] = useState(InitialFilter);
  const prepareFilter = queryParams => {
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter["nombre"] = filters.nombre !== "" ? filters.nombre : undefined;
    filter["factura"] = filters.factura !== "" ? filters.factura : undefined;
    filter["denominacion"] = filters.denominacion
      ? filters.denominacion
      : undefined;
    filter["valor_neto"] = filters.valor_neto ? filters.valor_neto : undefined;
    filter["fechaDesde"] = filters.fechaDesde ? filters.fechaDesde : undefined;
    filter["fechaHasta"] = filters.fechaHasta ? filters.fechaHasta : undefined;

    newQueryParams.filter = filter;
    return newQueryParams;
  };
  const handleChangeFAsigD = date =>
    setFilters({ ...filters, fechaDesde: date });
  const handleChangeFAsigH = date =>
    setFilters({ ...filters, fechaHasta: date });

  useEffect(() => {
    const newQueryParams = prepareFilter(queryParams);
    setQueryParams({ ...newQueryParams, pageNumber: 1 });
  }, [filters]);

  return (
    <div className="form-group row">
      <FecVisFilter
        onChange={handleChangeFAsigD}
        label={"F. desde"}
        value={filters.fechaDesde}
      />
      <FecVisFilter
        onChange={handleChangeFAsigH}
        label={"F. hasta"}
        value={filters.fechaHasta}
      />
    </div>
  );
};
