import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import { Header } from '../../components/pdfvisita/Header'
import { Subtitle1 } from '../../components/pdfvisita/Subtitle1'
import { TableDatGen } from '../../components/pdfvisita/TableDatGen'
import { TableGTec } from '../../components/pdfvisita/TableGTec'
import { Resultado } from '../../components/pdfvisita/Resultado'
import React from 'react'

const styles = StyleSheet.create({
  page: { backgroundColor: 'white' },
  sectionTop: {
    color: 'white',
    textAlign: 'left',
    backgroundColor: 'black',
    paddingLeft: 30,
    paddingVertical: 5,
    fontSize: 10,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  sectionBottom: {
    color: 'white',
    textAlign: 'left',
    backgroundColor: 'black',
    paddingLeft: 30,
    paddingVertical: 5,
    fontSize: 10,
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  section: { color: 'black', textAlign: 'center', margin: 10 },
  textBody1: {
    fontFamily: 'Roboto',
    fontSize: 9,
  },
})


export const DocumentPdf = ({ visita, reporte }) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <Header visita={visita}/>
      <View style={styles.section}>
        <Subtitle1 label={'Datos Generales'}/>
        <TableDatGen visita={visita}/>
      </View>
      <View style={{ ...styles.section, textAlign: 'left' }}>
        <Text style={styles.textBody1}>Observaciones Requerimiento</Text>
        <Text
          style={{ ...styles.textBody1, marginLeft: 30, marginVertical: 10 }}
        >
          {visita.visitas_obs}
        </Text>
      </View>
      <View style={styles.section}>
        <Subtitle1 label={'Reporte'}/>
        <TableGTec reporte={reporte}/>
        <Resultado reporte={reporte} />
      </View>

      <View style={styles.sectionTop} fixed>
        <Text>
          SRES: {visita.clientes_nombre} - {visita.direcciones_texto}
        </Text>
      </View>

    </Page>
  </Document>
)
