import { getTiendas } from "../restFunctios/getTiendas";

export const getTiendasAction = queryParams => {
  return async dispatch => {
    dispatch({
      type: "@setLoading",
      payload: { loading: true }
    });

    const { data, error } = await getTiendas(queryParams);
    if (data)
      dispatch({
        type: "@setTiendas",
        payload: { tiendas: data, totalCount: data.length }
      });

    //if (error) dispatch(actionsLics.setIsError(true))

    dispatch({
      type: "@setLoading",
      payload: { loading: false }
    });
  };
};
