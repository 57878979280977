import React, { useCallback, useEffect, useState } from "react";
import { cloneDeep, isEqual, isFunction } from "lodash";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Pagination
} from "../../../_metronic/_partials/controls";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import { getColumns } from "./table_helpers/CliTablecols";
import * as requestFromServer from "../../helpers/axios";
import { Link } from "react-router-dom";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from "../../../_metronic/_helpers";
import { TableFilters } from "./table_helpers/TableFilters";
import { initialTableFilter } from "../../helpers/TableHelpers";
import { PaginationTotalCustom } from "../_GlobalComponents/table/pagination/PaginationTotalCustom";
import { ModalEliminarCliente } from "./components/ModalEliminarCliente";
import { ModalCargaClientes } from "./ModalCargaClientes";
import { generatePlantillaDireccionesXls } from "../direcciones/xls/xls_create";
import { addAlert } from "../../../redux/alerts/alertsRedux";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { generatePlantillaClientesXls } from "./xls/xls_create";

export const ListarClientes = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Marca");
  const dispatch = useDispatch();

  const [clientes, setClientes] = useState(null);
  const [listLoading, setListLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialTableFilter);
  const [show, setShow] = useState(false);
  const [idCliente, setIdCliente] = useState(null);
  const [showCarga, setShowCarga] = useState(false);

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 }
    ],
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
    paginationTotalRenderer: PaginationTotalCustom
  };
  useEffect(() => {
    fetchClientes();
  }, [queryParams]);

  const fetchClientes = () => {
    const queryParameters = cloneDeep(queryParams);
    setListLoading(true);
    requestFromServer
      .getClientes(queryParameters)
      .then(response => {
        if (response.data) {
          setClientes(response.data.entities);
          setTotalCount(response.data.totalCount);
        } else {
          setClientes([]);
          setTotalCount(0);
        }

        setListLoading(false);
      })
      .catch(error => {
        console.log(error);
        setListLoading(false);
      });
  };
  const handleXls = () => {
    setListLoading(true);
    const queryParameters = cloneDeep(queryParams);
    requestFromServer
      .getClientes(queryParameters)
      .then(response => {
        generatePlantillaClientesXls(response.data.entities).then(r =>
          setListLoading(false)
        );
      })
      .catch(err => {
        console.log("err", err);
        dispatch(
          addAlert({
            msg:
              "largo de información demasiado grande, debe achicar el rango de fechas"
          })
        );
        setListLoading(false);
      });
  };
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }
      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  return (
    <>
      <Card>
        <CardHeader title="Clientes">
          <CardHeaderToolbar>
            <ModalEliminarCliente
              show={show}
              setShow={setShow}
              idCliente={idCliente}
              fetchClientes={fetchClientes}
            />
            <ModalCargaClientes
              show={showCarga}
              setShow={setShowCarga}
              fetchClientes={fetchClientes}
            />
            {!listLoading && (
              <Button type={"button"} variant={"success"} onClick={handleXls}>
                XLS
              </Button>
            )}
            &nbsp;
            <Link className="btn btn-primary ml-1" to={"/clientes/nuevo"}>
              {" "}
              Agregar
            </Link>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <TableFilters
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  isLoading={listLoading}
                  paginationProps={paginationProps}
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    remote
                    keyField={"id"}
                    data={clientes === null ? [] : clientes}
                    columns={getColumns(setShow, setIdCliente)}
                    onTableChange={getHandlerTableChange(setQueryParams)}
                    filter={filterFactory()}
                    striped
                    hover
                    condensed
                    {...paginationTableProps}
                  >
                    <PleaseWaitMessage entities={clientes} />
                    <NoRecordsFoundMessage entities={clientes} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
    </>
  );
};
