export const getPaginationOptions = (totalCount, pageSize, pageNumber) => ({
  custom: true,
  totalSize: totalCount,
  sizePerPageList: [
    { text: "5", value: 5 },
    { text: "25", value: 25 },
    { text: "50", value: 50 }
  ],
  sizePerPage: pageSize,
  page: pageNumber
});
export const initialTableFilter = {
  filter: {
    id: undefined
  },
  sortOrder: "desc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 5,
  filters: {}
};
export const initialDias = {
  filter: {
    id: undefined
  },
  sortOrder: "desc", // asc||desc
  sortField: "fecha_no_visita",
  pageNumber: 1,
  pageSize: 5,
  filters: {}
};
export const initialSolTableFilter = {
  filter: {
    id: undefined
  },
  sortOrder: "desc", // asc||desc
  sortField: "fecha_sol",
  pageNumber: 1,
  pageSize: 5,
  filters: {}
};
