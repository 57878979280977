import React from 'react'
import DatePicker from 'react-datepicker'

export const FilterFec = ({ label,value,setFec,disabled }) => {

  return (
    <>
      <DatePicker dateFormat="dd/MM/yyyy"
                  className={'w-100 form-control'} selected={value}
                  onChange={date => setFec(date)}
                  disabled={disabled}/>
      <small className="form-text text-muted">
        {label}
      </small>
    </>
  )
}
