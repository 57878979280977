import { mbox_accesstoken } from "../../../components/MapBoxConstant";
import * as turf from "@turf/turf";

export const GetMatchMap = async coordinates => {
  const finalCoords = [];
  // numero de veces q debemos preguntar a la api, 100 porque es lo maximo q aguanta la api en coordenadas
  const numRequest = Math.ceil(coordinates.length / 100);
  for (let i = 0; i < numRequest; i++) {
    const newCoords = coordinates.splice(0, 100).map(x => [x[0], x[1]]);
    if (newCoords.length >= 2) {
      const radius = [];
      newCoords.forEach(element => {
        radius.push(50);
      });
      const profile = "driving";
      // Separate the radiuses with semicolons
      const radiuses = radius.join(";");
      // Create the query
      const query =
        "https://api.mapbox.com/matching/v5/mapbox/" +
        profile +
        "/" +
        newCoords.join(";") +
        "?geometries=geojson" +
        "&radiuses=" +
        radiuses +
        "&steps=false" +
        "&access_token=" +
        mbox_accesstoken;
      const response = await fetch(query).catch(function(error) {
        console.log("map match err", error);
      });

      const jsondata = await response.json();
      console.log("jsondata", jsondata);
      const c = jsondata.matchings[0].geometry.coordinates;
      finalCoords.push(...c);
    } else {
      finalCoords.push(...newCoords);
    }
  }

  return finalCoords;
};

export function FindStops(coordinates, threshold, timeThreshold = 2) {
  let stops = [];
  let prevCoord = null;
  let inArray = false;
  for (let i = 0; i < coordinates.length; i++) {
    let coord = coordinates[i];
    if (prevCoord) {
      let distance = CalculateDistance(prevCoord, coord);
      if (distance < threshold) {
        if (!inArray) {
          // Si no esta en el array, lo agregamos
          stops.push({
            start: {
              lat: prevCoord[0],
              lng: prevCoord[1],
              time: prevCoord[2]
            },
            end: {
              lat: coord[0],
              lng: coord[1],
              time: coord[2]
            }
          });
        } else {
          // Si ya esta en el array, solo actualizamos el end
          stops[stops.length - 1].end = {
            lat: coord[0],
            lng: coord[1],
            time: coord[2]
          };
        }
        inArray = true;
      } else {
        // Si la distancia es mayor al threshold, reiniciamos
        inArray = false;
      }
    }
    prevCoord = coord;
  }
  return stops;
}

function CalculateDistance(coord1, coord2) {
  const R = 6371e3; // Earth's radius in meters
  const lat1 = (coord1[0] * Math.PI) / 180;
  const lat2 = (coord2[0] * Math.PI) / 180;
  const deltaLat = ((coord2[0] - coord1[0]) * Math.PI) / 180;
  const deltaLng = ((coord2[1] - coord1[1]) * Math.PI) / 180;

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1) *
      Math.cos(lat2) *
      Math.sin(deltaLng / 2) *
      Math.sin(deltaLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}

//Calculate difference in minutes between two dates
export const GetMinutesDiff = (date1, date2) => {
  const diff = date1.getTime() - date2.getTime();
  return Math.floor(diff / 60000);
};

export const InitParamsMap = {
  center: [-70.669265, -33.44889],
  zoom: [10]
};

export const FlyToOptions = {
  speed: 0.6
};

export const paint_v = {
  "circle-stroke-width": 2,
  "circle-radius": 5,
  "circle-blur": 0.15,
  "circle-color": [
    "match",
    ["get", "status"],
    "pendiente",
    "#ef5e0c",
    "ejecutada",
    "#009d1a",
    /* other */ "#ccc"
  ],
  "circle-stroke-color": "white"
};

export const GetPointRoute = coords => {
  return turf.point(coords, {
    orderTime: Date.now(),
    key: Math.random()
  });
};
