import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export const ActionsColumnFormatter = (cellContent, row, rowIndex, props) => {
  const { setShowDelete, setIdRepositorio } = props;
  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip id="botis-delete-tooltip">Descargar</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-warning btn-sm mr-1"
          href={row.repositorio_url}
          target="_blank"
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")} />
          </span>
        </a>
      </OverlayTrigger>
    </>
  );
};
