import axios from "axios";

export const REQUEST_URL = "api/perfilesusu";

export const getPerfiles = queryParams =>
  axios.post(`${REQUEST_URL}/getAllPerfiles`, { queryParams });
export const insertPerfil = perfil =>
  axios.put(`${REQUEST_URL}/put`, { perfil: perfil });
export const updatePerfil = (id, perfil) =>
  axios.put(`${REQUEST_URL}/update/${id}`, { perfil: perfil });
export const getPerfil = id => axios.get(`${REQUEST_URL}/getPerfil/${id}`);
