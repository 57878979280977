import React, { useEffect, useState } from "react";
import { Card, CardBody } from "../../_metronic/_partials/controls";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import * as requestFromServer from "../helpers/axios";
import { StatusCard } from "./dashboard/StatusCard";
import { useSelector } from "react-redux";
import moment from "moment";

export const DashboardPage = () => {
  const meses = [
    { name: "Enero", id: 0 },
    { name: "Febrero", id: 1 },
    { name: "Marzo", id: 2 },
    { name: "Abril", id: 3 },
    { name: "Mayo", id: 4 },
    { name: "Junio", id: 5 },
    { name: "Julio", id: 6 },
    { name: "Agosto", id: 7 },
    { name: "Septiembre", id: 8 },
    { name: "Octubre", id: 9 },
    { name: "Noviembre", id: 10 },
    { name: "Diciembre", id: 11 }
  ];
  const years = [
    { id: 2021 },
    { id: 2022 },
    { id: 2023 },
    { id: 2024 },
    { id: 2025 },
    { id: 2026 },
    { id: 2027 }
  ];
  const [ventasCristales, setVentasCristales] = useState(0);
  const [ventasCristalesMTD, setVentasCristalesMTD] = useState(0);
  const [ventasEyewear, setVentasEyewear] = useState(0);
  const [ventasEyewearMTD, setVentasEyewearMTD] = useState(0);
  const [recargos, setRecargos] = useState(0);
  const [recargosMTD, setRecargosMTD] = useState(0);

  const [ventasCristalesMES, setVentasCristalesMES] = useState(0);
  const [ventasCristalesYTD, setVentasCristalesYTD] = useState(0);
  const [ventasEyewearMES, setVentasEyewearMES] = useState(0);
  const [ventasEyewearYTD, setVentasEyewearYTD] = useState(0);
  const [recargosMES, setRecargosMES] = useState(0);
  const [recargosYTD, setRecargosYTD] = useState(0);

  const [loadingReqMTD, setLoadingReqMTD] = useState(false);
  const [loadingReq, setLoadingReq] = useState(false);
  const [subStatus, setSubStatus] = useState([]);
  const [monthSelected, setMonthSelected] = useState(
    meses.find(mes => mes.id === new Date().getMonth())
  );
  const [yearSelected, setYearSelected] = useState(
    years.find(year => year.id === new Date().getFullYear())
  );
  const [monthSelectedMTD, setMonthSelectedMTD] = useState(
    meses.find(mes => mes.id === new Date().getMonth())
  );
  const [yearSelectedMTD, setYearSelectedMTD] = useState(
    years.find(year => year.id === new Date().getFullYear())
  );
  const { user } = useSelector(state => state.auth);

  useEffect(() => {}, []);

  useEffect(() => {
    getDashboard();
  }, [monthSelected, yearSelected]);

  useEffect(() => {
    getDashboardMTD();
  }, [monthSelectedMTD, yearSelectedMTD]);

  const getDashboard = () => {
    let userid = null;
    if (user.id_perfil === 3) userid = user.id;
    setLoadingReq(true);
    requestFromServer
      .getDashboard({ fecha_YTD: getFechaForQuery(), id_usu: userid })
      .then(response => {
        setVentasCristales(response.data.ventas_cristales);
        if (!response.data.ventas_cristales_mtd) setVentasCristalesYTD(0);
        else setVentasCristalesYTD(response.data.ventas_cristales_mtd);
        setVentasEyewear(response.data.ventas_eyewear);
        if (!response.data.ventas_eyewear_mtd) setVentasEyewearYTD(0);
        else setVentasEyewearYTD(response.data.ventas_eyewear_mtd);
        setRecargos(response.data.ventas_recargos);
        if (!response.data.ventas_recargos_mtd) setRecargosYTD(0);
        else setRecargosYTD(response.data.ventas_recargos_mtd);
      })
      .finally(() => setLoadingReq(false));
  };
  const getDashboardMTD = () => {
    let userid = null;
    if (user.id_perfil === 3) userid = user.id;
    setLoadingReqMTD(true);
    requestFromServer
      .getDashboardMonth({ fecha_MTD: getFechaForQueryMTD(), id_usu: userid })
      .then(response => {
        if (response.data.ventas_cristales)
          setVentasCristalesMES(response.data.ventas_cristales);
        else setVentasCristalesMES(0);
        if (!response.data.ventas_cristales_mtd) setVentasCristalesMTD(0);
        else setVentasCristalesMTD(response.data.ventas_cristales_mtd);
        if (response.data.ventas_eyewear)
          setVentasEyewearMES(response.data.ventas_eyewear);
        else setVentasEyewearMES(0);
        if (!response.data.ventas_eyewear_mtd) setVentasEyewearMTD(0);
        else setVentasEyewearMTD(response.data.ventas_eyewear_mtd);
        if (response.data.ventas_recargos)
          setRecargosMES(response.data.ventas_recargos);
        else setRecargosMES(0);
        if (!response.data.ventas_recargos_mtd) setRecargosMTD(0);
        else setRecargosMTD(response.data.ventas_recargos_mtd);
      })
      .finally(() => setLoadingReqMTD(false));
  };

  const getFechaForQuery = () => {
    let fecha = "";
    if (yearSelected.id === new Date().getFullYear()) {
      fecha = moment
        .utc(new Date())
        .endOf("day")
        .format();
    } else {
      fecha = yearSelected.id + "/01" + "/02";
      fecha = moment
        .utc(fecha)
        .endOf("year")
        .format();
    }

    return fecha;
  };
  const getFechaForQueryMTD = () => {
    let fecha = "";
    if (
      monthSelectedMTD.id === new Date().getMonth() &&
      yearSelectedMTD.id === new Date().getFullYear()
    ) {
      fecha = moment
        .utc(new Date())
        .endOf("day")
        .format();
    } else {
      if (monthSelectedMTD.id + 1 < 10) {
        fecha = yearSelectedMTD.id + "/0" + (monthSelectedMTD.id + 1) + "/02";
      } else {
        fecha = yearSelectedMTD.id + "/" + (monthSelectedMTD.id + 1) + "/02";
      }
      fecha = moment
        .utc(fecha)
        .endOf("month")
        .format();
    }

    return fecha;
  };
  const handleButtonClickBefore = e => {
    e.preventDefault();
    let yearSeleccionado = 0;
    yearSeleccionado = yearSelected.id - 1;
    setYearSelected(years.find(year => year.id === yearSeleccionado));
  };
  const handleButtonClickBeforeMTD = e => {
    e.preventDefault();
    if (monthSelectedMTD.id - 1 >= 0) {
      let mesSeleccionado = 0;
      mesSeleccionado = monthSelectedMTD.id - 1;
      setMonthSelectedMTD(meses.find(mes => mes.id === mesSeleccionado));
    } else {
      if (yearSelectedMTD.id - 1 >= 2019) {
        setYearSelectedMTD(
          years.find(year => year.id === yearSelectedMTD.id - 1)
        );
        setMonthSelectedMTD(meses.find(mes => mes.id === 11));
      }
    }
  };
  const handleButtonClickAfterMTD = e => {
    e.preventDefault();
    if (monthSelectedMTD.id + 1 <= 11) {
      let mesSeleccionado = 0;
      mesSeleccionado = monthSelectedMTD.id + 1;
      setMonthSelectedMTD(meses.find(mes => mes.id === mesSeleccionado));
    } else {
      if (yearSelectedMTD.id + 1 <= 2025) {
        setYearSelectedMTD(
          years.find(year => year.id === yearSelectedMTD.id + 1)
        );
        setMonthSelectedMTD(meses.find(mes => mes.id === 0));
      }
    }
  };

  const handleButtonClickAfter = e => {
    e.preventDefault();
    let yearSeleccionado = 0;
    yearSeleccionado = yearSelected.id + 1;
    setYearSelected(years.find(year => year.id === yearSeleccionado));
  };

  return (
    <>
      <Card>
        <div className="card-header border-0 bg-danger py-5">
          <h3 className="card-title font-weight-bolder text-white">
            Resumen {yearSelected.id} YTD
          </h3>
          {!loadingReq && (
            <div className={"fc-toolbar-chunk"}>
              <ButtonGroup className="mr-2">
                <Button variant="secondary" onClick={handleButtonClickBefore}>
                  <span className={"fa fa-chevron-left"} />
                </Button>
                <Button variant="secondary" onClick={handleButtonClickAfter}>
                  <span className={"fa fa-chevron-right"} />
                </Button>
              </ButtonGroup>
            </div>
          )}
          {loadingReq && (
            <span
              className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
            />
          )}
        </div>
        <CardBody>
          {!loadingReq && (
            <Row>
              <Col>
                <StatusCard
                  total={ventasCristales}
                  variant={
                    (ventasCristalesYTD === 0 && "secondary") ||
                    (ventasCristalesYTD > 0 && "success") ||
                    (ventasCristalesYTD < 0 && "danger")
                  }
                  label={"Ventas Cristales"}
                  percentage={ventasCristalesYTD}
                />
              </Col>
              <Col>
                <StatusCard
                  total={ventasEyewear}
                  variant={
                    (ventasEyewearYTD === 0 && "secondary") ||
                    (ventasEyewearYTD > 0 && "success") ||
                    (ventasEyewearYTD < 0 && "danger")
                  }
                  label={"Venta Eyerwear"}
                  percentage={ventasEyewearYTD}
                />
              </Col>
              <Col>
                <StatusCard
                  total={recargos}
                  variant={
                    (recargosYTD === 0 && "secondary") ||
                    (recargosYTD > 0 && "success") ||
                    (recargosYTD < 0 && "danger")
                  }
                  label={"Others"}
                  percentage={recargosYTD}
                />
              </Col>
            </Row>
          )}
          {loadingReq && (
            <span
              className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
            />
          )}
        </CardBody>
      </Card>
      <Card>
        <div className="card-header border-0 bg-danger py-5">
          <h3 className="card-title font-weight-bolder text-white">
            Resumen {monthSelectedMTD.name} {yearSelectedMTD.id} MTD
          </h3>
          {!loadingReqMTD && (
            <div className={"fc-toolbar-chunk"}>
              <ButtonGroup className="mr-2">
                <Button
                  variant="secondary"
                  onClick={handleButtonClickBeforeMTD}
                >
                  <span className={"fa fa-chevron-left"} />
                </Button>
                <Button variant="secondary" onClick={handleButtonClickAfterMTD}>
                  <span className={"fa fa-chevron-right"} />
                </Button>
              </ButtonGroup>
            </div>
          )}
          {loadingReqMTD && (
            <span
              className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
            />
          )}
        </div>
        <CardBody>
          {!loadingReqMTD && (
            <Row>
              <Col>
                <StatusCard
                  total={ventasCristalesMES}
                  variant={
                    (ventasCristalesMTD === 0 && "secondary") ||
                    (ventasCristalesMTD > 0 && "success") ||
                    (ventasCristalesMTD < 0 && "danger")
                  }
                  label={"Ventas Cristales"}
                  percentage={ventasCristalesMTD}
                />
              </Col>
              <Col>
                <StatusCard
                  total={ventasEyewearMES}
                  variant={
                    (ventasEyewearMTD === 0 && "secondary") ||
                    (ventasEyewearMTD > 0 && "success") ||
                    (ventasEyewearMTD < 0 && "danger")
                  }
                  label={"Venta Eyerwear"}
                  percentage={ventasEyewearMTD}
                />
              </Col>
              <Col>
                <StatusCard
                  total={recargosMES}
                  variant={
                    (recargosMTD === 0 && "secondary") ||
                    (recargosMTD > 0 && "success") ||
                    (recargosMTD < 0 && "danger")
                  }
                  label={"Others"}
                  percentage={recargosMTD}
                />
              </Col>
            </Row>
          )}
          {loadingReqMTD && (
            <span
              className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};
