import { getCarteraTiendas } from "../restFunctios/getCarteraTiendas";

export const getCarteraTiendasAction = queryParams => {
  return async dispatch => {
    dispatch({
      type: "@setCarteraTiendas",
      payload: { cTiendas: [], totalCount: 0 }
    });
    dispatch({
      type: "@setLoading",
      payload: { loading: true }
    });

    const { data, error } = await getCarteraTiendas(queryParams);
    if (data)
      dispatch({
        type: "@setCarteraTiendas",
        payload: { cTiendas: data, totalCount: data.length }
      });

    //if (error) dispatch(actionsLics.setIsError(true))

    dispatch({
      type: "@setLoading",
      payload: { loading: false }
    });
  };
};
