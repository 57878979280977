import { ActionsColumnFormatter } from "./ActionsColumnFormatter";
import { sortCaret } from "../../../../_metronic/_helpers";

export const getColumns = (setShow, setId) => [
  {
    dataField: "fecha_no_visita",
    text: "Fecha",
    footer: "",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "movito_no_visita",
    text: "Motivo",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "fullname",
    text: "Representante",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "accions",
    text: "Acciones",
    formatter: ActionsColumnFormatter,
    formatExtraData: { setShow, setId },
    classes: "text-right pr-3 ",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "70px"
    }
  }
];
