export const optionsHighChart = {
  chart: {
    type: "column"
  },
  title: {
    text: ""
  },
  legend: {
    enabled: false
  },
  accessibility: {
    announceNewData: {
      enabled: true
    }
  },
  xAxis: {
    type: "category",

    label: {
      rotation: -60,
      style: {
        fontSize: "13px",
        fontFamily: "Verdana, sans-serif"
      }
    },
    scrollbar: {
      enabled: true
    }
  },
  yAxis: {
    min: 0,
    max: 100,
    title: {
      text: ""
    }
  },

  plotOptions: {
    series: {
      cropThreshold: 10000000000
    }
  },
  tooltip: {
    pointFormat:
      '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
    shared: true
  },
  series: [
    {
      name: "Porcentaje",
      colorByPoint: true,
      data: [
        {
          name: "Enero",
          y: 20,
          drilldown: "Enero"
        },
        {
          name: "Febrero",
          y: 25,
          drilldown: "Febrero"
        },
        {
          name: "Marzo",
          y: 5,
          drilldown: "Marzo"
        },
        {
          name: "Abril",
          y: 60,
          drilldown: "Abril"
        },
        {
          name: "Mayo",
          y: 20,
          drilldown: "Mayo"
        },
        {
          name: "Junio",
          y: 4,
          drilldown: "Junio"
        },
        {
          name: "Julio",
          y: 30,
          drilldown: "Julio"
        },
        {
          name: "Agosto",
          y: 45,
          drilldown: "Agosto"
        }
      ]
    }
  ]
};
