import moment from "moment";
import date from "yup/lib/date";

var today = new Date();
export const InitialFilter = {
  tecnico: "",
  cliente: "",
  direccion: "",
  estado: "",
  tipo: "",
  responsable: "",
  id: "",
  serieMaquina: "",
  segmento: [],
  fecDesdeAsigna: new Date(today.getFullYear(), today.getMonth(), 1),
  fecHastaAsigna: new Date(today.getFullYear(), today.getMonth() + 1, 0),

  fecDesdeEjecucion: undefined,
  fecHastaEjecucion: undefined,

  fecDesdeCreacion: undefined,
  fecHastaCreacion: undefined,
  eliminado: false
};
