export const InitialStateDireccion = {
  t_direccion: "",
  t_cod_cl: "",
  t_eliminado: "",
  t_latitude: "",
  t_longitude: "",
  t_rut_cliente: "",
  t_nombre_cliente: "",
  t_segmento: "",
  t_email_to: ""
};
