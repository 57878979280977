import React from 'react'
import * as requestFromServer from '../../../helpers/axios'
import {
  exportToExcelPlantillaUsuariosJs,
  exportToExcelJs
} from '../../../helpers/XLSHelpers'
import moment from 'moment'

const preparePlantillaXLS = async usuarios => {
  const data = []
  const dataCarga = []

  for (const d of usuarios)
    dataCarga.push({
      Nombre: d.usu_nombre,
      Apellido: d.usu_pat,
      Login: d.usu_login,
      Correo: d.usu_mail,
      Perfil: d.usu_perfil_nombre,
      Cargo: d.usu_cargo,
      Vigencia: d.eliminado ? 'No Vigente' : 'Vigente',
      'Usa APP IOS': d.usu_usa_apk_tecnica ? 'Si' : 'No'
    })

  const carga = { sheetName: 'Data', data: dataCarga }
  data.push(carga)
  return data
}
const prepareCarteraXLS = async (tiendas, usuario) => {
  const data = []
  const dataCarga = []

  for (const d of tiendas)
    dataCarga.push({
      CL: d.ccli_cod_cl,
      Tienda: d.ccli_nombre_tienda,
      Cliente: d.ccli_nombre_cliente,
      Rut: d.ccli_rut_cliente,
      Representante: `${usuario.usu_nombre}`
    })

  const carga = { sheetName: 'Data', data: dataCarga }
  data.push(carga)
  return data
}

export const generatePlantillaUsuariosXls = async usuarios => {
  const fileName = `Xls Usuarios`
  const csvData = await preparePlantillaXLS(usuarios)
  await exportToExcelPlantillaUsuariosJs(csvData, fileName)
}

export const generateCarteraXls = async (tiendas, usuario) => {
  const fileName = `Cartera ${usuario.usu_nombre}`
  const csvData = await prepareCarteraXLS(tiendas, usuario)
  await exportToExcelJs(csvData, fileName)
}
