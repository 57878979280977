import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import * as requestFromServer from "../../helpers/axios";
import { Button } from "react-bootstrap";
import { FormDireccion } from "./form/FormDireccion";
import { InitialStateDireccion } from "./form/InitialStateDireccion";
import { useDispatch, useSelector } from "react-redux";
import { actionsTiendas } from "../../../redux/tiendas/tiendasRedux";

export const EditDireccion = ({ match }) => {
  const suhbeader = useSubheader();
  const dispatch = useDispatch();
  suhbeader.setTitle("Cuentas");
  const history = useHistory();
  const { params } = match;
  const { id } = params;

  const btnRef = useRef();
  const { tiendas } = useSelector(state => state.tiendas);
  const [direccion, setDireccion] = useState(InitialStateDireccion);
  const [loading, setLoading] = useState(false);

  let cardTitle = "Ingresar Tienda";
  if (id) cardTitle = "Editar Tienda";

  const newEntity = !id;

  const sendDireccionToServer = () => {
    setLoading(true);
    const sendRequest = () => {
      if (!newEntity) {
        return requestFromServer.updateDireccion(id, direccion);
      } else {
        direccion.t_eliminado = false;
        direccion.t_target_x_year = null;
        return requestFromServer.insertDireccion(direccion);
      }
    };
    sendRequest()
      .then(response => {
        updateReduxTiendas();
        history.push("/direcciones/list");
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };
  const updateReduxTiendas = () => {
    if (newEntity) {
      // Agregar la tienda al store
      tiendas.unshift(direccion);
    } else {
      // Encontrar y reemplazar la tienda
      const index = tiendas.findIndex(tienda => tienda.t_cod_cl === id);
      tiendas[index] = direccion;
    }
    dispatch({
      type: "@setTiendas",
      payload: { tiendas: tiendas, totalCount: tiendas.length }
    });
  };
  const onHandleSubmit = () => btnRef.current.click();

  const fetchDireccion = () => {
    setDireccion(tiendas.find(tienda => tienda.t_cod_cl === id));
  };

  useEffect(() => {
    if (id) fetchDireccion();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Card>
        <CardHeader title={cardTitle}>
          <CardHeaderToolbar>
            {!loading && (
              <Button type="button" onClick={onHandleSubmit}>
                {newEntity ? "Ingresar" : "Actualizar"}
              </Button>
            )}
            {loading && (
              <span
                className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
              />
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <FormDireccion
            direccion={direccion}
            setDireccion={setDireccion}
            newEntity={newEntity}
            sendDireccionToServer={sendDireccionToServer}
            setLoading={setLoading}
            loading={loading}
            btnRef={btnRef}
          />
        </CardBody>
      </Card>
    </>
  );
};
