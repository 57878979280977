import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'

export const TableFilters = props => {
  const { queryParams, setQueryParams } = props

  const [nombreSeleccionado, setNombreSeleccionado] = useState('')
  const prepareFilter = (queryParams) => {
    const newQueryParams = { ...queryParams }
    const filter = {}
    filter['nombre'] = nombreSeleccionado !== '' ? nombreSeleccionado : undefined
    newQueryParams.filter = filter
    return newQueryParams
  }

  const handleChangeSku = e => setNombreSeleccionado(e.target.value)

  useEffect(() => {
    const newQueryParams = prepareFilter(queryParams)
    setQueryParams({ ...newQueryParams, pageNumber: 1 })
  }, [nombreSeleccionado])
  return (<div className="form-group row">
    <div className="col-sm-4 col-md-2">
      <Form.Control type={"text"} value={nombreSeleccionado} onChange={handleChangeSku} />
      <small className="form-text text-muted">
        <b>Filtrar</b> por nombre
      </small>
    </div>

  </div>)
}
