import axios from "axios";

export const REQUEST_URL = "api/ventas";

export function getVtsInformeRep({ id_rep, fec_desde, fec_hasta }) {
  return axios.get(
    `${REQUEST_URL}/getVtsInformeRep/${id_rep}/${fec_desde}/${fec_hasta}`
  );
}

export const UploadVentasXlxs = dataVentas =>
  axios.post(`${REQUEST_URL}/UploadVentasXlxs`, { dataVentas: dataVentas });
export const getVentasReporte = queryParams =>
  axios.post(`${REQUEST_URL}/getVentasReporte`, { queryParams });
export const getVentasReportePorCliente = queryParams =>
  axios.post(`${REQUEST_URL}/getVentasReportePorCliente`, { queryParams });

export function putSolicitudXlsX(sol) {
  return axios.post(`${REQUEST_URL}/putSolicitudXlsX`, { sol: sol });
}
export function getSolicitudesXlsX(queryParams) {
  return axios.post(`${REQUEST_URL}/getSolicitudesXlsX`, { queryParams });
}
export const deleteSolicitud = id =>
  axios.post(`${REQUEST_URL}/deleteSolicitud/${id}`);
