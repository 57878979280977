import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
export const FormModalRepositorio = props => {
  const {
    repositorio,
    setRepositorio,
    sendRepositorioToServer,
    btnRef
  } = props;
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = event => {
    setLoading(true);
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      sendRepositorioToServer();
      setLoading(false);
    }
    setValidated(true);
  };
  const onChangeNombre = e =>
    setRepositorio({ ...repositorio, repositorio_nombre: e.target.value });

  const handleInputChange = ev => {
    setRepositorio({ ...repositorio, repositorio_url: ev.target.files[0] });
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Row>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={12}>
            <Form.Label>Nombre:</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeNombre}
              value={repositorio.repositorio_nombre}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={12}>
            <Form.Label>Seleccionar Archivo:</Form.Label>
            <Form.File
              id="file"
              label={
                repositorio.repositorio_url
                  ? repositorio.repositorio_url.name
                  : "Seleccionar Archivo"
              }
              data-browse="Buscar"
              onChange={handleInputChange}
              custom
              name={"file"}
              disabled={loading}
            />
          </Form.Group>
        </Form.Row>
        <Button type="submit" ref={btnRef} style={{ display: "none" }} />
      </Form>
    </>
  );
};
