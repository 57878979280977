import React from "react";
import moment from "moment";
import { exportToExcelPlantillaVisJs } from "../../../helpers/XLSHelpers";
import {
  getCampanasVisitas,
  getCampanasVisitasCli,
  getCampanasVisitaXlsx
} from "../../../helpers/axios";

const prepareXLSVisitas = async visitas => {
  const data = [];
  const dataCarga = [];
  const headersCampanas = await getCampanasVisitasXlsx({
    filter: { eliminado: false }
  });
  const dataCampanas = await getCampanasVisitaCli();

  for (const v of visitas) {
    const campanas = {};
    for (const c of headersCampanas) {
      campanas[c.campana_venta_nombre] = dataCampanas.find(
        dc => dc.id_visita === v.id && dc.id_campana_venta === c.id
      )
        ? "1"
        : "0";
    }
    dataCarga.push({
      ID: v.id,
      "Fecha Programada": v.visitas_fecvisita,
      "Fecha Ejecutada": v.visitas_fecejecuta,
      "Cliente Nombre": v.clientes_nombre,
      "Cliente Rut": `${v.clientes_rut}`,
      "Tienda Nombre": `${v.direcciones_nombre}`,
      "Tienda CL": `${v.cl}`,
      Representante: v.tec_fullname,
      "Tipo de Visita": v.mv_descripcion,
      "Estado visita": v.est_visita_nombre,
      "Cantidad Venta": v.visitas_reporte
        ? v.visitas_reporte.cantidad_venta
        : "",
      "Latitud Cierre": v.visitas_lat_cierre,
      "Longitud Cierre": v.visitas_lon_cierre,
      Asunto: v.visitas_asunto,
      Minuta: v.visitas_reporte ? v.visitas_reporte.motivo : "",
      Participantes: v.evento_participantes,
      ...campanas
    });
  }

  const carga = {
    sheetName: "Data",
    data: dataCarga
  };

  data.push(carga);

  return data;
};

const getCampanasVisitasXlsx = async queryParams => {
  const response = await getCampanasVisitas(queryParams);
  return response?.data?.entities || [];
};

const getCampanasVisitaCli = async () => {
  const response = await getCampanasVisitasCli();
  return response?.data?.entities || [];
};

export const generateVisitasXls = async visitas => {
  const fileName = `Visitas ${moment().format("DD-MM-YYYY HH_ss")}`;
  const csvData = await prepareXLSVisitas(visitas);
  await exportToExcelPlantillaVisJs(csvData, fileName);
  return true;
};
