import React from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../_metronic/_helpers'
import useSync from '../../hooks/useSync'
const SyncBtn = () => {
  const { inSync, triggerSync } = useSync()
  const sync = () => {
    console.log('sync')
    triggerSync()
  }

  return (
    <div
      className="topbar-item"
      data-toggle="tooltip"
      title="Sincronizar"
      data-placement="right"
      onClick={sync}
    >
      <div className="btn btn-icon btn-clean btn-lg mr-1" id="sync_toggle">
        <span className="svg-icon svg-icon-xl svg-icon-primary">
          {inSync && (
            <SVG
              src={toAbsoluteUrl('/media/svg/icons/Navigation/Waiting.svg')}
            />
          )}
          {!inSync && (
            <SVG src={toAbsoluteUrl('/media/svg/icons/General/Update.svg')} />
          )}
        </span>
      </div>
    </div>
  )
}
export default SyncBtn
