import React from "react";
import { ContentRoute } from "../_metronic/layout";
import { ListarVisitas } from "./pages/visitas/ListarVisitas";
import { VerVisita } from "./pages/visitas/VerVisita";
import { MapaTecnicos } from "./pages/mapas/MapaTecnicos";
import { AddVisita } from "./pages/visitas/AddVisita";
import { EditVisita } from "./pages/visitas/EditVisita";
import { RepDetenciones } from "./pages/reportes/RepDetenciones/RepDetenciones";
import { ListarUsuarios } from "./pages/usuarios/ListarUsuarios";
import { EditUsuario } from "./pages/usuarios/EditUsuario";
import { ListarTiposVisitas } from "./pages/tipos_visitas/ListarTiposVisitas";
import { EditTipoVisita } from "./pages/tipos_visitas/EditTipoVisita";
import { ListarClientes } from "./pages/clientes/ListarClientes";
import { EditCliente } from "./pages/clientes/EditCliente";
import { ListarDirecciones } from "./pages/direcciones/ListarDirecciones";
import { EditDireccion } from "./pages/direcciones/EditDireccion";
import { CalendarioVisitas } from "./pages/calendarios/CalendarioVisitas";
import { CargaXls } from "./pages/visitas/CargaXLS";
import { ProtectedRoute } from "./ProtectedRoute";
import { MapaClientes } from "./pages/mapas/MapaClientes";
import { ListarRepositorio } from "./pages/repositorios/ListarRepositorio";
import { RRankingPage } from "./pages/reportes/RRanking/RRankingPage";
import { RepAtecPDF } from "./pages/visitas/RepAtecPDF";
import { ListarVentas } from "./pages/ventas/ListarVentas";
import { MetasPage } from "./pages/metas/MetasPage";
import { CumplimientoRepresentantes } from "./pages/informes/CumplimientoRepresentantes";
import { CantidadVisitas } from "./pages/informes/CantidadVisitas";
import { CantVendidaCliente } from "./pages/informes/CantVendidaCliente";
import { Metas } from "./pages/informes/Metas";
import { InformeRepresentantes } from "./pages/informes/InformeRepresentantes";
import { ListarLicencias } from "./pages/licencias/ListarLicencias";
import { EditLicencia } from "./pages/licencias/EditLicencia";
import { ProMetas } from "./pages/informes/ProMetas";
import { RepSemanal } from "./pages/informes/RepSemanal";
import { DiasNoVisita } from "./pages/diasNoVisita/DiasNoVisita";
import { EditCampanaVisita } from "./pages/campanas_visitas/EditCampanaVisita";
import { ListarCampanasVisitas } from "./pages/campanas_visitas/ListarCampanasVisitas";
import { repCampanasMarketing } from "./pages/informes/repCampanasMarketing";
import { RutaDiariaRep } from "./pages/sug_visitas/RutaDiariaRep";
import { ListarRutasDiarias } from "./pages/sug_visitas/ListarRutasDiarias";

export default function BasePageAdmin() {
  return (
    <>
      <ProtectedRoute path="/calendario" component={CalendarioVisitas} />

      {/*VISITAS*/}
      <ProtectedRoute path="/visitas/listadmin" component={ListarVisitas} />
      <ProtectedRoute path="/visitas/ver/:id" component={VerVisita} />

      <ProtectedRoute path="/visitas/addvis" component={AddVisita} />
      <ProtectedRoute
        path="/visitas/editvis/:id_visita"
        component={EditVisita}
      />
      <ProtectedRoute path="/visitas/cargaxls" component={CargaXls} />

      <ProtectedRoute path="/informes/ranking" component={RRankingPage} />

      {/*MAPAS*/}
      <ProtectedRoute path="/maps/maptec" component={MapaTecnicos} />
      {/*MAPAS*/}
      <ProtectedRoute path="/maps/mapcli" component={MapaClientes} />
      {/*REPORTES*/}
      <ContentRoute
        path="/reportes/atec_pdf/:id_visita"
        component={RepAtecPDF}
      />
      <ProtectedRoute path="/reportes/detenciones" component={RepDetenciones} />
      <ProtectedRoute path="/ventas/list" component={ListarVentas} />
      {/*MAESTROS*/}
      {/*USUARIOS*/}
      <ProtectedRoute path="/usuarios/list" component={ListarUsuarios} />
      <ProtectedRoute path="/usuarios/edit/:id" component={EditUsuario} />
      <ProtectedRoute path="/usuarios/nuevo" component={EditUsuario} />

      {/*TIPOS VISITAS*/}
      <ProtectedRoute
        path="/tiposRequerimientos/list"
        component={ListarTiposVisitas}
      />
      <ProtectedRoute
        path="/tiposRequerimientos/edit/:id"
        component={EditTipoVisita}
      />
      <ProtectedRoute path="/tiposVisitas/nuevo" component={EditTipoVisita} />

      {/*LICENCIAS*/}
      <ProtectedRoute path="/licencias/list" component={ListarLicencias} />
      <ProtectedRoute path="/licencias/edit/:id" component={EditLicencia} />
      {/*CLIENTES*/}
      <ProtectedRoute path="/clientes/list" component={ListarClientes} />
      <ProtectedRoute path="/clientes/edit/:id" component={EditCliente} />
      <ProtectedRoute path="/clientes/nuevo" component={EditCliente} />
      {/*Tipos Visitas*/}
      <ProtectedRoute path="/tiposVisita/list" component={ListarTiposVisitas} />
      <ProtectedRoute path="/tiposVisita/edit/:id" component={EditTipoVisita} />
      <ProtectedRoute path="/tiposVisita/nuevo" component={EditTipoVisita} />

      {/*Campañas*/}
      <ProtectedRoute
        path="/campanaVisitas/list"
        component={ListarCampanasVisitas}
      />
      <ProtectedRoute
        path="/campanaVisitas/edit/:id"
        component={EditCampanaVisita}
      />
      <ProtectedRoute
        path="/campanaVisitas/nuevo"
        component={EditCampanaVisita}
      />

      {/*DIRECCIONES*/}
      <ProtectedRoute path="/direcciones/list" component={ListarDirecciones} />
      <ProtectedRoute path="/direcciones/edit/:id" component={EditDireccion} />
      <ProtectedRoute path="/direcciones/nuevo" component={EditDireccion} />

      <ProtectedRoute path="/repositorio/" component={ListarRepositorio} />
      {/*MARKET SECTOR*/}

      <ProtectedRoute path="/metas" component={MetasPage} />
      <ProtectedRoute
        path="/cumplimiento_representantes"
        component={CumplimientoRepresentantes}
      />
      <ProtectedRoute
        path="/repCampanaVisitas"
        component={repCampanasMarketing}
      />
      <ProtectedRoute
        path="/informe_representantes"
        component={InformeRepresentantes}
      />
      <ProtectedRoute
        path="/cantidad_vendida_cliente"
        component={CantVendidaCliente}
      />
      <ProtectedRoute path="/informe_metas" component={Metas} />
      <ProtectedRoute path="/cantidad_visitas" component={CantidadVisitas} />
      <ProtectedRoute path="/proMetas" component={ProMetas} />
      <ProtectedRoute path="/repSemanal" component={RepSemanal} />
      <ProtectedRoute path="/dias_no_visita" component={DiasNoVisita} />
      <ProtectedRoute path="/rutaDiaria" component={ListarRutasDiarias} />
      <ProtectedRoute path="/verRutaDiaria/:id" component={RutaDiariaRep} />
    </>
  );
}
