import React from "react";
import { OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Link } from "react-router-dom";

export const ActionsColumnFormatter = (cellContent, row, rowIndex, props) => {
  const { setShow, setShowPause, setShowResumir, setIdCampanaVisita } = props;
  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip id="botis-delete-tooltip">Editar Campaña</Tooltip>}
      >
        <Link
          className="btn btn-icon btn-light btn-hover-warning btn-sm mr-1"
          to={"/campanaVisitas/edit/" + row.id}
        >
          <span className="svg-icon svg-icon-md svg-icon-warning">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </Link>
      </OverlayTrigger>
      {row.id_estado_campanas === 1 && (
        <OverlayTrigger
          overlay={<Tooltip id="botis-delete-tooltip">Pausar Campaña</Tooltip>}
        >
          <button
            className="btn btn-icon btn-light btn-hover btn-sm mr-1"
            onClick={e => {
              e.currentTarget.blur();
              setIdCampanaVisita(row.id);
              setShowPause(true);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Pause.svg")} />
            </span>
          </button>
        </OverlayTrigger>
      )}
      {row.id_estado_campanas === 2 && (
        <OverlayTrigger
          overlay={
            <Tooltip id="botis-delete-tooltip">Reanudar Campaña</Tooltip>
          }
        >
          <button
            className="btn btn-icon btn-light btn-hover btn-sm mr-1"
            onClick={e => {
              e.currentTarget.blur();
              setIdCampanaVisita(row.id);
              setShowResumir(true);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Play.svg")} />
            </span>
          </button>
        </OverlayTrigger>
      )}
      <OverlayTrigger
        overlay={<Tooltip id="botis-delete-tooltip">Eliminar Campaña</Tooltip>}
      >
        <button
          className="btn btn-icon btn-light btn-hover-danger btn-sm mr-1"
          onClick={e => {
            e.currentTarget.blur();
            setIdCampanaVisita(row.id);
            setShow(true);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </button>
      </OverlayTrigger>
    </>
  );
};
