import axios from "axios";

export const REQUEST_URL = "api/metas";

export function getMetas(queryParams) {
  return axios.post(`${REQUEST_URL}/`, { queryParams });
}
export const UploadMetas = dataMetas =>
  axios.post(`${REQUEST_URL}/UploadMetas`, { dataMetas: dataMetas });
export function getYearsOfMetas(queryParams) {
  return axios.post(`${REQUEST_URL}/getYearsOfMetas`, { queryParams });
}
export const UploadMetasCargaMasiva = dataMetas =>
  axios.post(`${REQUEST_URL}/UploadMetasCargaMasiva`, { dataMetas: dataMetas });
export const getProMetas = queryParams =>
  axios.post(`${REQUEST_URL}/getProMetas`, { queryParams });
