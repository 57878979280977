import React from "react";

export const ColorColumnFormatter = (cellContent, row, rowIndex) => {
  return (
    <>
      <div
        style={{ backgroundColor: row.mv_color_code, width: 40, height: 40 }}
      >
        &nbsp;
      </div>
    </>
  );
};
