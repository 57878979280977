import React, { useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import { Button, Form } from "react-bootstrap";
import * as requestFromServer from "../../helpers/axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { optionsHighChart } from "./components/optionsHighChart";
import { addAlert } from "../../../redux/alerts/alertsRedux";
import { useDispatch } from "react-redux";
import Highstock from "highcharts/highstock";
import ReactHighcharts from "react-highcharts";
const Charts = ReactHighcharts.withHighcharts(Highstock);
export const CantVendidaCliente = () => {
  const animatedComponents = makeAnimated();

  const suhbeader = useSubheader();
  suhbeader.setTitle("Neto por Cliente");
  const dispatch = useDispatch();
  const [clientes, setClientes] = React.useState([]);
  const [metas, setMetas] = React.useState([]);
  const [ventas, setVentas] = React.useState([]);
  const [areas, setAreas] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [month, setMonth] = React.useState(new Date().getMonth() + 1);
  const [areaSelected, setAreaSelected] = React.useState([]);
  const [clientesSelected, setClientesSelected] = React.useState([]);
  const [segmentoSelected, setSegmentoSelected] = React.useState({});
  const [data, setData] = React.useState({
    name: "Neto",
    colorByPoint: true,
    data: []
  });
  const years = [
    { value: 2020, label: "2020" },
    { value: 2021, label: "2021" },
    { value: 2022, label: "2022" },
    { value: 2023, label: "2023" },
    { value: 2024, label: "2024" },
    { value: 2025, label: "2025" },
    { value: 2026, label: "2026" },
    { value: 2027, label: "2027" },
    { value: 2028, label: "2028" },
    { value: 2029, label: "2029" },
    { value: 2030, label: "2030" }
  ];
  const months = [
    { value: 1, label: "Enero" },
    { value: 2, label: "Febrero" },
    { value: 3, label: "Marzo" },
    { value: 4, label: "Abril" },
    { value: 5, label: "Mayo" },
    { value: 6, label: "Junio" },
    { value: 7, label: "Julio" },
    { value: 8, label: "Agosto" },
    { value: 9, label: "Septiembre" },
    { value: 10, label: "Octubre" },
    { value: 11, label: "Noviembre" },
    { value: 12, label: "Diciembre" }
  ];
  let cardTitle = "Cantidad Vendida por Cliente";
  const segmentos = [
    { label: "Todos", value: null },
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "D", value: "D" },
    { label: "E", value: "E" }
  ];
  const onChangeYear = e => {
    setYear(e.value);
  };
  const onChangeSegmento = e => {
    setSegmentoSelected(e);
  };
  const onChangeMonth = e => {
    setMonth(e.value);
  };
  const onChangeArea = area => {
    setAreaSelected(area);
  };
  const onChangeClientes = clientes => {
    setClientesSelected(clientes);
  };
  const fetchUsuarios = () => {
    setLoading(true);
    requestFromServer
      .getClientes({
        filter: {
          clientes_segmento: segmentoSelected.value
        }
      })
      .then(response => {
        if (response.data) {
          setClientes(response.data.entities);
        } else {
          setClientes([]);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  const fetchAreas = () => {
    setLoading(true);
    requestFromServer
      .getAreas()
      .then(response => {
        if (response.data) {
          setAreas([
            ...response.data.entities.map(x => ({
              value: x.id,
              label: x.area_nombre
            }))
          ]);
        } else {
          setLoadingData(false);
          setAreas([]);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoadingData(false);
        setLoading(false);
      });
  };
  const fetchVentas = () => {
    setLoading(true);
    requestFromServer
      .getVentasReportePorCliente({
        filter: {
          fecha: new Date(year, month - 1, 1),
          ventas_segmento: areaSelected?.map(x => x.label),
          clientes: clientesSelected?.map(x => x.value),
          clientes_segmento: segmentoSelected.value
        }
      })
      .then(response => {
        if (response.data) {
          setVentas(response.data.entities);
        } else {
          setLoadingData(false);
          dispatch(
            addAlert({ msg: "No existen ventas para esta combinación" })
          );
          setVentas([]);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoadingData(false);
        setLoading(false);
      });
  };
  const armarData = () => {
    let datalocal = [];
    clientes.forEach(c => {
      let ventasCliente = ventas.find(
        v => v.ventas_identfis1 === c.clientes_rut
      );
      let total = 0;
      if (ventasCliente) total = parseInt(ventasCliente.total);

      if (ventasCliente)
        datalocal.push({
          name: c.clientes_nombre,
          y: total,
          drilldown: c.clientes_nombre
        });
    });
    datalocal.sort((a, b) => b.y - a.y);
    setData({
      ...data,
      data: datalocal
    });
    setLoadingData(false);
  };
  const fetchData = async () => {
    setClientes([]);

    setVentas([]);
    setData({
      name: "Neto",
      colorByPoint: true,
      data: []
    });
    setLoadingData(true);
    await fetchUsuarios();
    await fetchVentas();
  };

  useEffect(() => {
    fetchAreas();
    fetchUsuarios();
  }, [segmentoSelected.value]);

  useEffect(() => {
    if (clientes.length > 0 && metas.length > 0 && ventas.length > 0)
      armarData();
  }, [clientes, metas, ventas]);

  return (
    <>
      <Card>
        <CardHeader title={cardTitle}>
          <CardHeaderToolbar>
            {!loadingData && (
              <Button type="button" onClick={fetchData}>
                {"Buscar"}
              </Button>
            )}
            {loadingData && (
              <span
                className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
              />
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-sm-12 col-md-2">
              <Select
                options={years}
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={onChangeYear}
                value={years.find(x => x.value === year)}
                placeholder={" "}
              />

              <small className="form-text text-muted">
                <b>Filtrar</b> por año
              </small>
            </div>
            <div className="col-sm-6 col-md-2">
              <Select
                options={months}
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={onChangeMonth}
                value={months.find(x => x.value === month)}
                placeholder={" "}
              />

              <small className="form-text text-muted">
                <b>Filtrar</b> por mes
              </small>
            </div>
            <div className="col-sm-4 col-md">
              <Select
                options={areas}
                closeMenuOnSelect={true}
                isMulti
                components={animatedComponents}
                onChange={onChangeArea}
                value={areaSelected}
                placeholder={" "}
              />

              <small className="form-text text-muted">
                <b>Filtrar</b> por area
              </small>
            </div>

            <div className="col-sm-6 col-md-3">
              <Select
                options={clientes.map(x => ({
                  label: x.clientes_nombre + " " + x.clientes_rut,
                  value: x.clientes_rut
                }))}
                closeMenuOnSelect={true}
                isMulti
                components={animatedComponents}
                onChange={onChangeClientes}
                value={clientesSelected}
                placeholder={"Todos"}
              />

              <small className="form-text text-muted">
                <b>Filtrar</b> por cliente
              </small>
            </div>
          </div>
          {!loadingData && data && data.data && data.data.length > 0 && (
            <Form.Row>
              <div className="col-sm-12 col-md">
                <Charts
                  neverReflow={true}
                  isPureConfig={true}
                  config={{
                    ...optionsHighChart,
                    yAxis: {
                      ...optionsHighChart.yAxis,
                      max: null
                    },
                    xAxis: {
                      ...optionsHighChart.xAxis,
                      max: data.data.length > 10 ? 10 : data.data.length - 1
                    },
                    tooltip: {},
                    series: [data]
                  }}
                />
              </div>
            </Form.Row>
          )}
        </CardBody>
      </Card>
    </>
  );
};
