import React, { useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import { Button, Form } from "react-bootstrap";
import * as requestFromServer from "../../helpers/axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { optionsHighChart } from "./components/optionsHighChart";
import { addAlert } from "../../../redux/alerts/alertsRedux";
import { useDispatch } from "react-redux";
export const Metas = () => {
  const animatedComponents = makeAnimated();

  const suhbeader = useSubheader();
  suhbeader.setTitle("Informe de Metas");
  const dispatch = useDispatch();
  const [usuarios, setUsuarios] = React.useState([]);
  const [metas, setMetas] = React.useState([]);
  const [areas, setAreas] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [month, setMonth] = React.useState(null);
  const [areaSelected, setAreaSelected] = React.useState([]);

  const [usuSelected, setUsuSelected] = React.useState({
    value: null,
    label: "Todos"
  });

  const [data, setData] = React.useState({
    name: "Neto",
    colorByPoint: true,
    data: []
  });
  const [years, setYears] = React.useState([]);
  const months = [
    { value: null, label: "Todos" },
    { value: 1, label: "Enero" },
    { value: 2, label: "Febrero" },
    { value: 3, label: "Marzo" },
    { value: 4, label: "Abril" },
    { value: 5, label: "Mayo" },
    { value: 6, label: "Junio" },
    { value: 7, label: "Julio" },
    { value: 8, label: "Agosto" },
    { value: 9, label: "Septiembre" },
    { value: 10, label: "Octubre" },
    { value: 11, label: "Noviembre" },
    { value: 12, label: "Diciembre" }
  ];
  let cardTitle = "Informe de Metas";
  const onChangeYear = e => {
    setYear(e.value);
  };
  const onChangeMonth = e => {
    setMonth(e.value);
  };
  const onChangeArea = area => {
    setAreaSelected(area);
  };
  const onChangeUsu = usu => {
    setUsuSelected(usu);
  };
  const fetchYears = () => {
    setLoading(true);
    requestFromServer
      .getYearsOfMetas()
      .then(response => {
        if (response.data) {
          setYears(response.data.entities);
        } else {
          setYears([]);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };
  const fetchUsuarios = () => {
    setLoading(true);
    requestFromServer
      .getUsuarios({ filter: { id_perfil: 3 } })
      .then(response => {
        if (response.data) {
          setUsuarios([
            { value: null, label: "Todos" },
            ...response.data.entities.map(x => ({
              value: x.id,
              label: x.fullname
            }))
          ]);
        } else {
          setUsuarios([]);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };
  const fetchMetas = () => {
    setLoading(true);
    requestFromServer
      .getMetas({
        filter: {
          meta_month: month,
          ventas_segmento: areaSelected?.map(x => x.value),
          id_usu: usuSelected.value
        }
      })
      .then(response => {
        if (response.data) {
          console.log(response.data.entities);
          setMetas(response.data.entities);
        } else {
          dispatch(addAlert({ msg: "No existen metas para este mes" }));
          setLoadingData(false);
          setMetas([]);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoadingData(false);
        setLoading(false);
      });
  };
  const fetchAreas = () => {
    setLoading(true);
    requestFromServer
      .getAreas()
      .then(response => {
        if (response.data) {
          setAreas([
            ...response.data.entities.map(x => ({
              value: x.id,
              label: x.area_nombre
            }))
          ]);
        } else {
          setLoadingData(false);
          setAreas([]);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoadingData(false);
        setLoading(false);
      });
  };

  const armarData = () => {
    let datalocal = [];

    years.forEach(y => {
      let total = 0;
      let metasLocal = metas.filter(m => m.meta_year === y.meta_year);
      metasLocal.forEach(m => {
        total += parseInt(m.meta_cantidad);
      });
      datalocal.push({
        name: y.meta_year,
        y: total,
        drilldown: y.meta_year
      });
    });
    setData({
      ...data,
      data: datalocal
    });
    setLoadingData(false);
  };
  const fetchData = async () => {
    setLoadingData(true);
    await fetchYears();
    await fetchMetas();
  };

  useEffect(() => {
    fetchAreas();
    fetchUsuarios();
  }, []);

  useEffect(() => {
    if (years.length > 0 && metas.length > 0) armarData();
  }, [years, metas]);

  return (
    <>
      <Card>
        <CardHeader title={cardTitle}>
          <CardHeaderToolbar>
            {!loadingData && (
              <Button type="button" onClick={fetchData}>
                {"Buscar"}
              </Button>
            )}
            {loadingData && (
              <span
                className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
              />
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-sm-12 col-md">
              <Select
                options={usuarios}
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={onChangeUsu}
                value={usuarios.find(x => x.value === usuSelected.value)}
                placeholder={" "}
              />

              <small className="form-text text-muted">
                <b>Filtrar</b> por representante
              </small>
            </div>
            <div className="col-sm-6 col-md">
              <Select
                options={months}
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={onChangeMonth}
                value={months.find(x => x.value === month)}
                placeholder={" "}
              />

              <small className="form-text text-muted">
                <b>Filtrar</b> por mes
              </small>
            </div>
            <div className="col-sm-6 col-md">
              <Select
                options={areas}
                closeMenuOnSelect={true}
                isMulti
                components={animatedComponents}
                onChange={onChangeArea}
                value={areaSelected}
                placeholder={" "}
              />

              <small className="form-text text-muted">
                <b>Filtrar</b> por area
              </small>
            </div>
          </div>
          {!loadingData && data && data.data && data.data.length > 0 && (
            <Form.Row>
              <div className="col-sm-12 col-md">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    ...optionsHighChart,
                    tooltip: {},
                    series: [data]
                  }}
                />
              </div>
            </Form.Row>
          )}
        </CardBody>
      </Card>
    </>
  );
};
