import React, { useEffect, useRef } from 'react'
import { mbox_accesstoken } from '../MapBoxConstant'
import mapboxgl from 'mapbox-gl'

export const ClientMap = props => {
  const { lat, lng } = props
  const mapContainer = useRef(null)
  mapboxgl.accessToken = mbox_accesstoken
  const initParamsMap = {
    lng: lng ? lng : -70.669265,
    lat: lat ? lat : -33.44889,
    zoom: 13,
  }

  const initializeMap = ({ mapContainer }) => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [initParamsMap.lng, initParamsMap.lat],
      zoom: initParamsMap.zoom,
    })

    map.on('load', () => {
      if (lat && lng) {
        new mapboxgl.Marker()
          .setLngLat([initParamsMap.lng, initParamsMap.lat])
          .addTo(map)
      }
    })
  }

  useEffect(() => {
    initializeMap({ mapContainer })
  }, [lat, lng])
  return (
    <>
      <div
        ref={el => (mapContainer.current = el)}
        style={{ height: 250 }}
      ></div>
    </>
  )
}
