import React from 'react'
import moment from 'moment'

export const FechaSolColumnFormatter = (cellContent, row) => {
  return (
    <span className="text-right pr-3">
      {moment(row.fecha_sol).format('DD-MM-yyyy HH:mm:ss')}
    </span>
  )
}
