import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import * as requestFromServer from "../../helpers/axios";
import { Button } from "react-bootstrap";
import { FormCampanaVisita } from "./form/FormCampanaVisita";
import { InitialStateCampanaVisita } from "./form/InitialStateCampanaVisita";
import { InitalStateRepositorio } from "../repositorios/form/InitialStateRepositorio";
import moment from "moment/moment";
import { ModalCarga } from "./components/ModalCarga";
import { addAlert } from "../../../redux/alerts/alertsRedux";
import { useDispatch } from "react-redux";

export const EditCampanaVisita = ({ match }) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Tipos Requerimientos");
  const history = useHistory();
  const { params } = match;
  const { id } = params;

  const btnRef = useRef();
  const dispatch = useDispatch();
  const [campanaVisita, setCampanaVisita] = useState(InitialStateCampanaVisita);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  let cardTitle = "Ingresar Campaña Visita ";
  if (id) cardTitle = "Editar Campaña Visita ";

  const newEntity = !id;

  const sendCampanaVisitaToServer = () => {
    if (!validateCampanaVisita(campanaVisita)) return;
    setLoading(true);
    const sendRequest = () => {
      if (campanaVisita.campana_venta_all_clientes)
        campanaVisita.campana_clientes = [];
      if (!newEntity)
        return requestFromServer.updateCampanaVisita(id, campanaVisita);
      else
        return requestFromServer.putCampanaVisita(
          campanaVisita,
          campanaVisita.campana_venta_archivo.name.substring(
            campanaVisita.campana_venta_archivo.name.lastIndexOf(".") + 1
          )
        );
    };
    sendRequest()
      .then(response => {
        if (campanaVisita.campana_venta_archivo)
          handleUploadFiles(campanaVisita, response.data).then(r => {
            setLoading(false);
            history.push("/campanaVisitas/list");
          });
        else {
          setLoading(false);
          history.push("/campanaVisitas/list");
        }
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  const validateCampanaVisita = campanaVisita => {
    if (!campanaVisita.campana_venta_nombre) {
      dispatch(
        addAlert({
          msg: "Por favor ingrese un nombre de campaña",
          type: "danger"
        })
      );
      return false;
    }
    if (!campanaVisita.campana_venta_archivo) {
      dispatch(
        addAlert({
          msg: "Por favor ingrese un archivo",
          type: "danger"
        })
      );
      return false;
    }
    if (!campanaVisita.campana_venta_fecha_termino) {
      dispatch(
        addAlert({
          msg: "Por favor ingrese una fecha de termino",
          type: "danger"
        })
      );
      return false;
    }
    if (
      campanaVisita.campana_venta_fecha_inicio >
      campanaVisita.campana_venta_fecha_termino
    ) {
      dispatch(
        addAlert({
          msg: "La fecha de inicio no puede ser mayor a la fecha de termino",
          type: "danger"
        })
      );
      return false;
    }
    return true;
  };

  const generarNombreArchivo = (id, extension) => {
    return `campana_visitas_${id}.${extension}`;
  };
  const handleUploadFiles = async (campanaVisita, id) => {
    await requestFromServer.uploadFileS3(
      generarNombreArchivo(
        id,
        campanaVisita.campana_venta_archivo.name.substring(
          campanaVisita.campana_venta_archivo.name.lastIndexOf(".") + 1
        )
      ),
      campanaVisita.campana_venta_archivo,
      "Campanas_Visitas/"
    );
  };

  const onHandleSubmit = () => btnRef.current.click();

  const fetchCampanaVisita = () => {
    setLoading(true);
    requestFromServer
      .getCampanaVisita(id)
      .then(response => {
        setCampanaVisita({
          ...response.data,
          campana_venta_fecha_inicio: moment(
            response.data.campana_venta_fecha_inicio
          ).toDate(),
          campana_venta_fecha_termino: moment(
            response.data.campana_venta_fecha_termino
          ).toDate(),
          campana_venta_archivo: undefined
        });
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) fetchCampanaVisita();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <ModalCarga
        show={show}
        setShow={setShow}
        fetch={fetchCampanaVisita}
        id={id}
      />
      <Card>
        <CardHeader title={cardTitle}>
          <CardHeaderToolbar>
            {!loading && (
              <Button type="button" onClick={onHandleSubmit}>
                {newEntity ? "Ingresar" : "Actualizar"}
              </Button>
            )}
            &nbsp;
            {!loading && !campanaVisita.campana_venta_all_clientes && (
              <Button
                type="button"
                variant={"success"}
                onClick={() => setShow(true)}
              >
                Carga masiva de clientes
              </Button>
            )}
            {loading && (
              <span
                className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
              />
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <FormCampanaVisita
            campanaVisita={campanaVisita}
            setCampanaVisita={setCampanaVisita}
            newEntity={newEntity}
            sendCampanaVisitaToServer={sendCampanaVisitaToServer}
            setLoading={setLoading}
            btnRef={btnRef}
          />
        </CardBody>
      </Card>
    </>
  );
};
