import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export const ModalPDFGenerated = props => {
  const { onDownload, visita } = props;
  const history = useHistory();

  const onClose = () => {
    history.push("/admin/visitas/ver/" + visita.id);
  };
  return (
    <>
      <Modal show={true}>
        <Modal.Header closeButton onHide={onClose}>
          <Modal.Title>Descarga PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>Desea descargar el PDF generado?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onClose}>
            No
          </Button>
          <Button variant="success" onClick={onDownload}>
            Si
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
