import * as React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import { Button, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { StyleSheet } from "@react-pdf/renderer";
import { ReactComponent as Doc } from "./../../../../assets/svg/Doc.svg";
import { ReactComponent as DoubleArrowRight } from "./../../../../assets/svg/DoubleArrowRight.svg";
import moment from "moment";
import { ClientMap } from "./ClientMap";
import parse from "html-react-parser";

DetalleVerVisitaCard.propTypes = {};
export const CardVisita = props => {
  const { visita, id_visita } = props;
  const history = useHistory();
  const handlePdf = () => history.push(`/reportes/atec_pdf/${id_visita}`);
  const handleEdit = () => history.push(`/visitas/editvis/${id_visita}`);

  return (
    <>
      <Card>
        <CardHeader title={`Visita ID ${id_visita}`}>
          <CardHeaderToolbar>
            <Button type={"button"} variant={"primary"} onClick={handleEdit}>
              Editar
            </Button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div style={{ margin: 15, flex: 1, alignContent: "flex-start" }}>
            <div className={"row"}>
              <div className="col-sm-2">
                <b style={styles.textLabel}>Fecha Visita </b>
              </div>
              <div className="col-sm-4">
                <b style={styles.textInfo}>
                  {visita.visitas_fecvisita
                    ? moment(visita.visitas_fecvisita)
                        .utc()
                        .format("DD/MM/YYYY")
                    : ""}
                </b>
              </div>
              <div className="col-sm-2">
                <b style={styles.textLabel}>Tipo </b>
              </div>
              <div className="col-sm-4">
                <b style={styles.textInfo}>{visita.mv_descripcion}</b>
              </div>
            </div>
            <div className={"row"} style={{ marginTop: 15 }}>
              <div className="col-sm-2">
                <b style={styles.textLabel}>Cliente </b>
              </div>
              <div className="col-sm-4">
                <b style={styles.textInfo}>
                  {visita.clientes_nombre} [{visita.clientes_rut}]
                </b>
              </div>
              <div className="col-sm-2">
                <b style={styles.textLabel}>Tienda </b>
              </div>
              <div className="col-sm-4">
                <b style={styles.textInfo}>
                  {visita.direcciones_nombre} [{visita.dir_coralt}]
                </b>
              </div>
            </div>

            <div className={"row"} style={{ marginTop: 15 }}>
              <div className="col-sm-2">
                <b style={styles.textLabel}>Representante </b>
              </div>
              <div className="col-sm-4">
                <b style={styles.textInfo}>{visita.fullname_tec}</b>
              </div>
              <div className="col-sm-2">
                <b style={styles.textLabel}>Estado </b>
              </div>
              <div className="col-sm-4">
                <b style={styles.textInfo}>{visita.est_visita_nombre}</b>
              </div>
            </div>

            <div className={"row"} style={{ marginTop: 15 }}>
              <div className="col-sm-2">
                <b style={styles.textLabel}>Observación </b>
              </div>
              <div className="col-sm-10">
                {visita.visitas_obs ? parse(visita.visitas_obs) : ""}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      {visita.id_est_visita === 1 && (
        <Card>
          <CardHeader title={`Información de cierre`}></CardHeader>
          <CardBody>
            <div style={{ margin: 15, flex: 1, alignContent: "flex-start" }}>
              <div className={"row"} style={{ marginTop: 15 }}>
                <div className="col-sm-6">
                  <ClientMap
                    lat={visita.visitas_lat_cierre}
                    lng={visita.visitas_lon_cierre}
                  />
                </div>
                <div className="col-sm-6">
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <div
                      style={{
                        backgroundColor: "#C9F7F5",
                        borderRadius: 10,
                        paddingTop: 26,
                        paddingLeft: 23,
                        paddingRight: 23,
                        paddingBottom: 26,
                        display: "flex",
                        flexDirection: "column",
                        marginRight: 5,
                        width: 200
                      }}
                    >
                      <b style={{ fontSize: 30, color: "#12827C" }}>
                        {visita.visitas_fecejecuta
                          ? moment(visita.visitas_fecejecuta).format("DD/MM/YY")
                          : ""}
                      </b>
                      <b style={{ fontSize: 16, color: "#12827C" }}>
                        Fecha de cierre
                      </b>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#C9F7F5",
                        borderRadius: 10,
                        paddingTop: 26,
                        paddingLeft: 23,
                        paddingRight: 23,
                        paddingBottom: 26,
                        display: "flex",
                        flexDirection: "column",
                        width: 200
                      }}
                    >
                      <b style={{ fontSize: 30, color: "#12827C" }}>
                        {visita.visitas_fecejecuta
                          ? moment(visita.visitas_fecejecuta).format("HH:mm")
                          : ""}
                      </b>
                      <b style={{ fontSize: 16, color: "#12827C" }}>
                        Hora cierre
                      </b>
                    </div>
                  </div>
                  <div style={{ marginTop: 5 }}>
                    <div
                      onClick={handlePdf}
                      style={{
                        backgroundColor: "#E2E5FF",
                        borderRadius: 10,
                        paddingTop: 26,
                        paddingLeft: 23,
                        paddingRight: 23,
                        paddingBottom: 26,
                        display: "flex",
                        flexDirection: "column",
                        width: 200
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#F0F4FF",
                          borderRadius: 10,
                          paddingLeft: 15,
                          paddingTop: 10,
                          paddingBottom: 10
                        }}
                      >
                        <DoubleArrowRight />
                        <DoubleArrowRight style={{ marginRight: 18 }} />
                        <Doc />
                      </div>
                      <b style={{ fontSize: 16, color: "#4E7DF6" }}>
                        Ver informe PDF
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
};

function DetalleVerVisitaCard({ reporte }) {
  return (
    <Card>
      <CardBody>
        <div style={{ margin: 15, flex: 1 }}>
          <div
            className={"row"}
            style={{ marginTop: 15, justifyContent: "space-between" }}
          >
            <div
              style={{
                width: "49%",
                backgroundColor: "#E2E5FF",
                borderRadius: 10,
                minHeight: 124,
                paddingTop: 15
              }}
            >
              <div className="col-sm-12">
                <div className={"row"} style={{ paddingLeft: 15 }}>
                  <b style={{ fontSize: 20, color: "#4E7DF6" }}>
                    Problema detectado
                  </b>
                </div>
                <div className={"row"} style={{ paddingLeft: 15 }}>
                  {reporte.problema_detectado}
                </div>
              </div>
            </div>
            <div
              style={{
                width: "49%",
                backgroundColor: "#E2E5FF",
                borderRadius: 10,
                minHeight: 124,
                paddingTop: 15
              }}
            >
              <div className="col-sm-12">
                <div className={"row"} style={{ paddingLeft: 15 }}>
                  <b style={{ fontSize: 20, color: "#4E7DF6" }}>
                    Procedimiento realizado
                  </b>
                </div>
                <div className={"row"} style={{ paddingLeft: 15 }}>
                  {reporte.procedimiento_realizado}
                </div>
              </div>
            </div>
          </div>
          <div
            className={"row"}
            style={{ marginTop: 15, justifyContent: "space-between" }}
          >
            <div
              style={{
                width: "49%",
                backgroundColor: "#E2E5FF",
                borderRadius: 10,
                minHeight: 124,
                paddingTop: 15
              }}
            >
              <div className="col-sm-12">
                <div className={"row"} style={{ paddingLeft: 15 }}>
                  <b style={{ fontSize: 20, color: "#4E7DF6" }}>
                    Capacitaciones entregadas
                  </b>
                </div>
                <div className={"row"} style={{ paddingLeft: 15 }}>
                  {reporte.cap_entregada}
                </div>
              </div>
            </div>
            <div
              style={{
                width: "49%",
                backgroundColor: "#E2E5FF",
                borderRadius: 10,
                minHeight: 124,
                paddingTop: 15
              }}
            >
              <div className="col-sm-12">
                <div className={"row"} style={{ paddingLeft: 15 }}>
                  <b style={{ fontSize: 20, color: "#4E7DF6" }}>
                    Recomendaciones del asesor
                  </b>
                </div>
                <div className={"row"} style={{ paddingLeft: 15 }}>
                  {reporte.recomendacion_asesor}
                </div>
              </div>
            </div>
          </div>
          <div
            className={"row"}
            style={{ marginTop: 15, justifyContent: "space-between" }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#E2E5FF",
                borderRadius: 10,
                minHeight: 124,
                paddingTop: 15
              }}
            >
              <div className="col-sm-12">
                <div className={"row"} style={{ paddingLeft: 15 }}>
                  <b style={{ fontSize: 20, color: "#4E7DF6" }}>
                    Encargado de local
                  </b>
                </div>
                <div className={"row"} style={{ paddingLeft: 15 }}>
                  {reporte.receptor_nombre} - {reporte.receptor_cargo}
                </div>
              </div>
            </div>
          </div>
          <div
            className={"row"}
            style={{ marginTop: 15, justifyContent: "space-between" }}
          >
            <div
              style={{
                width: "49%",
                backgroundColor: "#E2E5FF",
                borderRadius: 10,
                minHeight: 124,
                paddingTop: 15
              }}
            >
              <div className="col-sm-12">
                <div className={"row"} style={{ paddingLeft: 15 }}>
                  <b style={{ fontSize: 20, color: "#4E7DF6" }}>
                    Firma encargado de local
                  </b>
                </div>
                <div className={"row"} style={{ paddingLeft: 15 }}>
                  <Image
                    src={reporte.firma1_filename + "?rnd=" + Math.random()}
                    style={{
                      width: 250,
                      marginHorizontal: "1pt"
                    }}
                    cache={false}
                    crossorigin="anonymous"
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                width: "49%",
                backgroundColor: "#E2E5FF",
                borderRadius: 10,
                minHeight: 124,
                paddingTop: 15
              }}
            >
              <div className="col-sm-12">
                <div className={"row"} style={{ paddingLeft: 15 }}>
                  <b style={{ fontSize: 20, color: "#4E7DF6" }}>
                    Firma Encargado Rodenstock
                  </b>
                </div>
                <div className={"row"} style={{ paddingLeft: 15 }}>
                  <Image
                    src={reporte.firma2_filename + "?rnd=" + Math.random()}
                    style={{
                      width: 250,
                      marginHorizontal: "1pt"
                    }}
                    cache={false}
                    crossorigin="anonymous"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={"row"}
            style={{ marginTop: 15, justifyContent: "flex-start" }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#E2E5FF",
                borderRadius: 10,
                minHeight: 124,
                paddingTop: 15
              }}
            >
              <div className="col-sm-12">
                <div className={"row"} style={{ paddingLeft: 15 }}>
                  <b style={{ fontSize: 20, color: "#4E7DF6" }}>Fotos</b>
                </div>
                <div
                  className={"row"}
                  style={{
                    paddingLeft: 15,
                    marginBottom: 15,
                    paddingRight: 15,
                    justifyContent: "space-evenly"
                  }}
                >
                  {reporte.foto1_filename && (
                    <Image
                      src={reporte.foto1_filename + "?rnd=" + Math.random()}
                      style={{
                        width: "24%",
                        marginHorizontal: "1pt"
                      }}
                      cache={false}
                      crossorigin="anonymous"
                    />
                  )}
                  {reporte.foto2_filename && (
                    <Image
                      src={reporte.foto2_filename + "?rnd=" + Math.random()}
                      style={{
                        width: "24%",
                        marginHorizontal: "1pt"
                      }}
                      cache={false}
                      crossorigin="anonymous"
                    />
                  )}
                  {reporte.foto3_filename && (
                    <Image
                      src={reporte.foto3_filename + "?rnd=" + Math.random()}
                      style={{
                        width: "24%",
                        marginHorizontal: "1pt"
                      }}
                      cache={false}
                      crossorigin="anonymous"
                    />
                  )}
                  {reporte.foto4_filename && (
                    <Image
                      src={reporte.foto4_filename + "?rnd=" + Math.random()}
                      style={{
                        width: "24%",
                        marginHorizontal: "1pt"
                      }}
                      cache={false}
                      crossorigin="anonymous"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

const styles = StyleSheet.create({
  textLabel: { color: "#B5B5C3" },
  textInfo: { color: "#7D7D7D" }
});
