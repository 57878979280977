import React from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import { Button } from "react-bootstrap";
import { getCampanasVisitaXlsx } from "../../helpers/axios";
import { generateRepCampanasMarketingXls } from "./xls/xls_create";

export const repCampanasMarketing = () => {
  let cardTitle = "Reporte Campanas Marketing";

  const exportarXLS = () => {
    console.log("exportarXLS");
    getCampanasVisitaXlsx().then(res => {
      if (res.data) generateRepCampanasMarketingXls(res.data);
    });
  };

  return (
    <>
      <Card>
        <CardHeader title={cardTitle}>
          <CardHeaderToolbar>
            <Button variant={"success"} onClick={exportarXLS}>
              XLS
            </Button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody></CardBody>
      </Card>
    </>
  );
};
