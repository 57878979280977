import React, { useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as requestFromServer from "../../../helpers/axios";
import { addAlert } from "../../../../redux/alerts/alertsRedux";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  putCarteraTiendaUsuario,
  putCarteraUsuario
} from "../../../helpers/axios";
import SelectAsyncPaginationCli from "../../components/AsyncSelects/SelectAsyncPaginationCli";
import SelectAsyncPaginationDir from "../../components/AsyncSelects/SelectAsyncPaginationDir";

export const ModalAddCT = props => {
  const { idUsuario, show, setShow, fetchUsuarios } = props;
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const [dirSelected, setDirSelected] = useState({});

  const handleClose = () => setShow(false);
  const onChangeDir = e => {
    setDirSelected(e);
  };
  const handleInsert = () => {
    setDeleting(true);
    requestFromServer
      .putCarteraTiendaUsuario(idUsuario, dirSelected.cl)
      .then(response => {
        setShow(false);
        dispatch(addAlert({ msg: "Tienda agregada en Cartera" }));
      })
      .catch(err => console.log(err))
      .finally(() => {
        fetchUsuarios();
        setDeleting(false);
      });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        {deleting && <ModalProgressBar variant="query" />}
        <Modal.Header closeButton>
          <Modal.Title>Insertar tienda a cartera</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!deleting && (
            <SelectAsyncPaginationDir
              value={dirSelected}
              onChange={onChangeDir}
              md={12}
              isFilter={false}
              noNullClients={true}
            />
          )}
          {deleting && <span>Ingresando ...</span>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={deleting}>
            Cancelar
          </Button>
          <Button variant="success" onClick={handleInsert} disabled={deleting}>
            Agregar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
