import * as _ from "lodash";

const getMinOrMax = (coords, minOrMax, latOrLng) => {
  if (minOrMax === "max") {
    return _.maxBy(coords, value => {
      return value[latOrLng];
    })[latOrLng];
  } else {
    return _.minBy(coords, value => {
      return value[latOrLng];
    })[latOrLng];
  }
};

export const getBounds = coords => {
  const coordsCalcBounds = coords.map(x => ({ lng: x[0], lat: x[1] }));
  const maxLat = getMinOrMax(coordsCalcBounds, "max", "lat");
  const minLat = getMinOrMax(coordsCalcBounds, "min", "lat");
  const maxLng = getMinOrMax(coordsCalcBounds, "max", "lng");
  const minLng = getMinOrMax(coordsCalcBounds, "min", "lng");

  const southWest = [minLng, minLat];
  const northEast = [maxLng, maxLat];
  return [southWest, northEast];
};
