import React from "react";
import moment from "moment";
import { exportToExcelJs } from "../../../../helpers/XLSHelpers";

const prepareXLS = async reportes => {
  const dataCarga = [];
  for (const v of reportes)
    dataCarga.push({
      "Fecha": moment(v.visitas_fecvisita).format('DD-MM-YYYY'),
      "Cuenta": v.direcciones_nombre,
      "Sap / Codigo": v.codigo,
      "Inventor": v.tec_fullname,
      "Puntuacion": v.reporte_resultado_porcentaje,
    });
  const carga = { sheetName: "Data", data: dataCarga };

  return [carga];
};

export const generateXls = async visitas => {
  const fileName = `Informe Ranking ${moment().format("DD-MM-YYYY HH_ss")}`;
  const csvData = await prepareXLS(visitas);
  await exportToExcelJs(csvData, fileName);
  return true;
};
