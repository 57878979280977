import React from "react";
import { CalendarioVisitas } from "./pages/representantes/calendarios/CalendarioVisitas";
import { ProtectedRoute } from "./ProtectedRoute";
import { ListarVisitas } from "./pages/representantes/visitas/ListarVisitas";
import { VerVisita } from "./pages/representantes/visitas/VerVisita";
import { AddVisita } from "./pages/representantes/visitas/AddVisita";
import { EditVisita } from "./pages/representantes/visitas/EditVisita";
import { CargaXls } from "./pages/representantes/visitas/CargaXLS";
import { ContentRoute } from "../_metronic/layout";
import { RepAtecPDF } from "./pages/visitas/RepAtecPDF";
import { InformeRepresentantes } from "./pages/informes/InformeRepresentantes";
import { ListarRepositorio } from "./pages/representantes/repositorios/ListarRepositorio";
import { ListarVentas } from "./pages/representantes/ventas/ListarVentas";
import { RepSemanal } from "./pages/representantes/informes/RepSemanal";
export default function BasePageRepresentante() {
  return (
    <>
      <ProtectedRoute path="/calendario" component={CalendarioVisitas} />

      {/*VISITAS*/}
      <ProtectedRoute path="/visitas/listadmin" component={ListarVisitas} />
      <ProtectedRoute path="/visitas/ver/:id" component={VerVisita} />

      <ProtectedRoute path="/visitas/addvis" component={AddVisita} />
      <ProtectedRoute
        path="/visitas/editvis/:id_visita"
        component={EditVisita}
      />
      {/*REPORTES*/}
      <ContentRoute
        path="/reportes/atec_pdf/:id_visita"
        component={RepAtecPDF}
      />
      <ProtectedRoute
        path="/informe_representantes"
        component={InformeRepresentantes}
      />
      <ProtectedRoute path="/repositorio/" component={ListarRepositorio} />
      <ProtectedRoute path="/visitas/cargaxls" component={CargaXls} />
      <ProtectedRoute path="/ventas/list" component={ListarVentas} />
      <ProtectedRoute path="/repSemanal" component={RepSemanal} />
    </>
  );
}
