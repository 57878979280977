import React, { useCallback, useEffect, useState } from "react";
import { cloneDeep, isEqual, isFunction } from 'lodash'
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Pagination
} from "../../../_metronic/_partials/controls";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import { columns } from "./table_helpers/LicsTablecols";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from "../../../_metronic/_helpers";
import { TableFilters } from "./table_helpers/TableFilters";
import { initialTableFilter } from "../../helpers/TableHelpers";
import { PaginationTotalCustom } from "../_GlobalComponents/table/pagination/PaginationTotalCustom";
import { useDispatch, useSelector } from "react-redux";
import { actionsLics } from "../../../redux/licencias/licenciasRedux";
import { useHistory } from "react-router-dom";
import { ListDialogsRoutes } from './components/dialogs/ListDialogsRoutes'
import { Button } from "react-bootstrap";
import * as requestFromServer from '../../helpers/axios'
import { generateLicenciasXls } from './xls/xls_create'
import { addAlert } from '../../../redux/alerts/alertsRedux'

export const ListarLicencias = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Licencias");
  let history = useHistory();
  const dispatch = useDispatch();

  const { lics, totalLics, loadingList, isError } = useSelector(
    state => state.lics
  );
  const [queryParams, setQueryParamsBase] = useState(initialTableFilter);
  const [xlsLoading, setXlsLoading] = useState(false);

  const paginationOptions = {
    custom: true,
    totalSize: totalLics,
    sizePerPageList: [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 }
    ],
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
    paginationTotalRenderer: PaginationTotalCustom
  };

  useEffect(() => {
    dispatch(actionsLics.getList(queryParams));
  }, [queryParams]);

  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }
      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const handleXls = () => {
    setXlsLoading(true);
    const queryParameters = cloneDeep(queryParams);
    requestFromServer
    .getLicencias({  })
    .then(response => {
      generateLicenciasXls(response.data.entities).then(r =>
        setXlsLoading(false)
      );
    })
    .catch(err => {
      console.log("err", err);
      dispatch(
        addAlert({
          msg:
            "largo de información demasiado grande, limite los resultados"
        })
      );
      setXlsLoading(false);
    });
  };


  const listEvents = {
    openDeleteDialog: id => history.push(`/licencias/list/delete/${id}`)
  };
  const handleAddLic=()=>{
    history.push(`/licencias/list/add/`)
  }
  return (
    <>
      <ListDialogsRoutes />
      <Card>
        <CardHeader title="Licencias ingresadas">
          <CardHeaderToolbar>
            <Button onClick={handleXls} disabled={xlsLoading}>
              {xlsLoading ? "Cargando xls..." : "Exportar XLS"}
            </Button>
            &nbsp;
            <Button className="btn btn-primary ml-1" onClick={handleAddLic} >
              {" "}
              Agregar
            </Button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <TableFilters
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  isLoading={loadingList}
                  paginationProps={paginationProps}
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    remote
                    keyField={"id"}
                    data={lics === null ? [] : lics}
                    columns={columns({ listEvents })}
                    onTableChange={getHandlerTableChange(setQueryParams)}
                    filter={filterFactory()}
                    striped
                    hover
                    condensed
                    {...paginationTableProps}
                  >
                    <PleaseWaitMessage entities={lics} />
                    <NoRecordsFoundMessage entities={lics} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
    </>
  );
};
