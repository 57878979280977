import React from 'react';
import DatePicker from "react-datepicker";

export const FecRepFilter = (props) => {
    const {onChange, label,value}=props
    return (
        <>
            <div className="col-sm-12 col-md-3">
                <DatePicker dateFormat="dd/MM/yyyy" className={'form-control'} selected={value}
                            onChange={date => onChange(date)}/>
                <small className="form-text text-muted">
                    {label}
                </small>
            </div>
        </>
    );
};
