import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import Select from "react-select";
import * as requestFromServer from "../../helpers/axios";
import makeAnimated from "react-select/animated/dist/react-select.esm";

export const InputSelTipoVisita = props => {
  const animatedComponents = makeAnimated();
  const { valueSelected, onChange, md } = props;
  const { required } = props;
  const [tiposvisita, setTiposVisita] = useState([]);

  useEffect(() => {
    requestFromServer
      .getTiposVisita({ eliminado: false })
      .then(response =>
        setTiposVisita([
          ...response.data.entities.map(x => ({
            value: x.id,
            label: x.mv_descripcion
          }))
        ])
      )
      .catch(err => console.log(err));
  }, []);
  return (
    <>
      <Form.Group controlId="exampleForm.ControlInput1" as={Col} md={md}>
        <Form.Label>
          Tipo visita{" "}
          {required ? <span className={"text-danger"}>* </span> : ""}:
        </Form.Label>
        <Select
          options={tiposvisita}
          closeMenuOnSelect={true}
          components={animatedComponents}
          value={valueSelected}
          onChange={onChange}
          placeholder={""}
          inputProps={{ required: required }}
        />
      </Form.Group>
    </>
  );
};
