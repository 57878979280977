import React, { useState } from "react";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import * as requestFromServer from "../../../helpers/axios";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { xls_to_Arr, xls_to_json } from "../../../helpers/XLSHelpers";
import { addAlert } from "../../../../redux/alerts/alertsRedux";
import { useDispatch } from "react-redux";
import { generatePlantillaXls } from "../xls/xls_create";
import { useHistory } from "react-router-dom";

export const ModalCarga = props => {
  const { fetch, show, setShow, id } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const [filename, setFilename] = useState(null);
  const [dataMasterPlan, setDataMasterPlan] = useState([]);
  const [xlsLoading, setXlsLoading] = useState(false);
  const [progreso, setProgreso] = useState("");

  const handleXlsChange = ev => {
    const filename = ev.target.value.replace(/.*[\/\\]/, "");
    setFilename(filename);
    setXlsLoading(true);
    xls_to_Arr(ev).then(hojas => {
      if (hojas.length > 0) {
        const data = hojas[0].data;
        console.log(data);
        setDataMasterPlan(data);
      }
      setXlsLoading(false);
    });
  };

  const onHandleSaveCsv = () => {
    if (dataMasterPlan.length === 0) {
      dispatch(addAlert({ msg: "Por favor ingrese un documento xls" }));
      setXlsLoading(false);
      return;
    }
    setXlsLoading(true);
    sendDataByParts().then(r => {
      setXlsLoading(false);
    });
  };

  const sendDataByParts = async () => {
    let errores = false;
    const QueryParams = dataMasterPlan;
    setProgreso("0/" + QueryParams.length);
    for (var i = 0; i < QueryParams.length; i += 100) {
      await requestFromServer
        .putCargaMasivaCampanas(QueryParams.slice(i, i + 100), id)
        .catch(e => {
          errores = true;
          setXlsLoading(false);
          dispatch(
            addAlert({
              msg: "Ocurrió un error al momento de cargar el xls"
            })
          );
        });
      setProgreso(i + "/" + QueryParams.length);
      if (errores) break;
    }
    if (!errores) {
      setProgreso(QueryParams.length + "/" + QueryParams.length);
      dispatch(
        addAlert({
          msg: "XLS cargado satisfactoriamente"
        })
      );
      fetch();
      setXlsLoading(false);
      setProgreso("");
      setShow(false);
      history.push("/campanaVisitas/list");
    }
  };

  const generatePlantilla = () => {
    setXlsLoading(true);
    requestFromServer.getCampanasVisitaCargaMasiva(id).then(response => {
      if (response.data) {
        generatePlantillaXls(response.data.campanas);
      }
      setXlsLoading(false);
    });
  };
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        {xlsLoading && <ModalProgressBar variant="query" />}
        <Modal.Header>
          <Modal.Title>Carga Masiva Clientes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!xlsLoading && (
            <>
              <Form.Group controlId="exampleForm.ControlInput2" as={Col}>
                <Form.Label>&nbsp; </Form.Label>
                <Form.File
                  id="file"
                  label={filename ? filename : "Seleccionar XLS"}
                  data-browse="Buscar"
                  onChange={handleXlsChange}
                  custom
                  name={"file"}
                  disabled={xlsLoading}
                />
              </Form.Group>
            </>
          )}
          {xlsLoading && (
            <>
              <div align={"center"}>
                <span>
                  <b>{progreso}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                </span>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-secondary ml-1 mr-auto"
            onClick={handleClose}
            disabled={xlsLoading}
          >
            Cerrar
          </Button>
          <Button
            className="btn btn-success ml-1"
            onClick={generatePlantilla}
            disabled={xlsLoading}
          >
            {" "}
            Descargar Plantilla
          </Button>
          <Button
            className="btn btn-success ml-1"
            onClick={onHandleSaveCsv}
            disabled={!filename || xlsLoading}
          >
            {" "}
            Comenzar Carga XLS
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
