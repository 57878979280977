import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { FormModalRepositorio } from "./FormModalRepositorio";
import { InitalStateRepositorio } from "./InitialStateRepositorio";
import * as requestFromServer from "../../../../helpers/axios";

export const ModalRepositorio = props => {
  const { show, setShow, fetchRepositorios } = props;
  const [loading, setLoading] = useState(false);
  const [repositorio, setRepositorio] = useState(InitalStateRepositorio);
  const handleClose = () => setShow(false);
  const generarNombreArchivo = (id, extension) => {
    return `repositorio_${id}.${extension}`;
  };
  const handleUploadFiles = async (repositorio, id) => {
    await requestFromServer.uploadFileS3(
      generarNombreArchivo(
        id,
        repositorio.repositorio_url.name.substring(
          repositorio.repositorio_url.name.lastIndexOf(".") + 1
        )
      ),
      repositorio.repositorio_url,
      "Repositorios/"
    );
  };

  const sendRepositorioToServer = () => {
    setLoading(true);
    requestFromServer
      .insertRepositorio(
        repositorio,
        repositorio.repositorio_url.name.substring(
          repositorio.repositorio_url.name.lastIndexOf(".") + 1
        )
      )
      .then(response => {
        handleUploadFiles(repositorio, response.data).then(r => {
          setRepositorio(InitalStateRepositorio);
          setLoading(false);
          setShow(false);
          fetchRepositorios();
        });
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  const btnRef = useRef();

  const onHandleIn = () => btnRef.current.click();
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        {loading && <ModalProgressBar variant="query" />}
        <Modal.Header closeButton>
          <Modal.Title>Agregar Repositorio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!loading && (
            <FormModalRepositorio
              repositorio={repositorio}
              setRepositorio={setRepositorio}
              sendRepositorioToServer={sendRepositorioToServer}
              btnRef={btnRef}
            />
          )}
          {loading && <span>Cargando ...</span>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={loading}>
            Cancelar
          </Button>
          <Button variant="success" onClick={onHandleIn} disabled={loading}>
            Cargar Archivo
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
