/* eslint-disable no-restricted-imports */
import React, {useRef, useState} from "react";
import {Modal} from "react-bootstrap";

import {ModalProgressBar} from "../../../../../_metronic/_partials/controls";
import {ChangePassForm} from "../ChangePassForm";
import {changePassInitValues} from "../ChangePassInitValues"

export const CambioPassDialog = props => {

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const errorHandler ={setIsError,setErrorMsg}
    const btnRef = useRef();

    const changePassClick = () => {

        if (btnRef && btnRef.current) {
            btnRef.current.click();
        }
    };

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            {isLoading && <ModalProgressBar variant="query"/>}
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Cambio de contraseña
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!isLoading && (
                    <>
                        <ChangePassForm initValues={changePassInitValues}
                                        btnRef={btnRef}
                                        handleClose={props.handleClose}
                                        errorHandler={errorHandler}
                                        setIsLoading={setIsLoading}
                                        />
                  </>
                )}
                {isLoading && <span>Cambiando contraseña...</span>}
                {isError && <span className={"text-danger"}>{errorMsg}</span>}

            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button
                        type="button"
                        onClick={props.handleClose}
                        className="btn btn-light btn-elevate"
                    >
                        Cancelar
                    </button>
                    <> </>
                    <button
                        type="button"
                        onClick={changePassClick}
                        className="btn btn-delete btn-elevate">
                        Enviar cambio
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
