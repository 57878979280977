import React, { useState } from "react";
import SelectAsyncPaginationUsu from "../../../components/AsyncSelects/SelectAsyncPaginationUsu";

export const TecRepFilter = props => {
  const { onChange } = props;
  const [tecnicoSelected, setTecnicoSelected] = useState({});

  const onChangeTecnico = tec => {
    setTecnicoSelected(tec);
    onChange(tec);
  };

  return (
    <SelectAsyncPaginationUsu
      value={tecnicoSelected}
      onChange={onChangeTecnico}
      md={3}
      isFilter={true}
      label={"Representante"}
      id_perfil={3}
    />
  );
};
