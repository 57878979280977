import React, { useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import { Button } from "react-bootstrap";
import { FormAddEditVisita } from "../components/visitas/FormAddEditVisita";

export const AddVisita = () => {
  const btnRef = useRef();

  let cardTitle = `Ingresar Visita`;
  const onHandleIn = () => btnRef.current.click();

  const [loading, setLoading] = useState(false);

  return (
    <>
      <Card>
        <CardHeader title={cardTitle}>
          <CardHeaderToolbar>
            {!loading && (
              <Button type="button" variant={"success"} onClick={onHandleIn}>
                {"Ingresar Visita"}
              </Button>
            )}
            {loading && (
              <span
                className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
              />
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <FormAddEditVisita
            setLoading={setLoading}
            btnRef={btnRef}
            newEntitie={true}
          />
        </CardBody>
      </Card>
    </>
  );
};
