import React, { useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import { Button, Form } from "react-bootstrap";
import * as requestFromServer from "../../helpers/axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { optionsHighChart } from "./components/optionsHighChart";
import { addAlert } from "../../../redux/alerts/alertsRedux";
import { useDispatch } from "react-redux";
export const CantidadVisitas = () => {
  const animatedComponents = makeAnimated();

  const suhbeader = useSubheader();
  suhbeader.setTitle("Cantidad Visitas");
  const dispatch = useDispatch();
  const [usuarios, setUsuarios] = React.useState([]);
  const [clientes, setClientes] = React.useState([]);

  const [metas, setMetas] = React.useState([]);
  const [visitas, setVisitas] = React.useState([]);
  const [areas, setAreas] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [month, setMonth] = React.useState(new Date().getMonth() + 1);
  const [representanteSelected, setRepresentanteSelected] = React.useState({});
  const [segmentoSelected, setSegmentoSelected] = React.useState({});






  const [data, setData] = React.useState({
    name: "Cantidad Visitas",
    colorByPoint: true,
    data: []
  });
  const years = [
    { value: 2020, label: "2020" },
    { value: 2021, label: "2021" },
    { value: 2022, label: "2022" },
    { value: 2023, label: "2023" },
    { value: 2024, label: "2024" },
    { value: 2025, label: "2025" },
    { value: 2026, label: "2026" },
    { value: 2027, label: "2027" },
    { value: 2028, label: "2028" },
    { value: 2029, label: "2029" },
    { value: 2030, label: "2030" }
  ];
  const segmentos = [
    { label: "Todos", value: null },
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "D", value: "D" },
    { label: "E", value: "E" }
  ];
  const months = [
    { value: 1, label: "Enero" },
    { value: 2, label: "Febrero" },
    { value: 3, label: "Marzo" },
    { value: 4, label: "Abril" },
    { value: 5, label: "Mayo" },
    { value: 6, label: "Junio" },
    { value: 7, label: "Julio" },
    { value: 8, label: "Agosto" },
    { value: 9, label: "Septiembre" },
    { value: 10, label: "Octubre" },
    { value: 11, label: "Noviembre" },
    { value: 12, label: "Diciembre" }
  ];
  let cardTitle = "Cantidad Visitas";
  const onChangeYear = e => {
    setYear(e.value);
  };
  const onChangeSegmento = e => {
    setSegmentoSelected(e);
  };
  const onChangeMonth = e => {
    setMonth(e.value);
  };
  const onChangeRepresentante = area => {
    setRepresentanteSelected(area);
  };
  const fetchUsuarios = () => {
    setLoading(true);
    requestFromServer
      .getUsuarios({ filter: { id_perfil: 3 } })
      .then(response => {
        if (response.data) {
          setUsuarios([
            ...response.data.entities.map(x => ({
              value: x.id,
              label: x.fullname
            }))
          ]);
        } else {
          setUsuarios([]);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };
  const fetchClientes = () => {
    setLoading(true);
    requestFromServer
      .getClientes({})
      .then(response => {
        if (response.data) {
          setClientes(response.data.entities);
        } else {
          setClientes([]);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };
  const fetchAreas = () => {
    setLoading(true);
    requestFromServer
      .getAreas()
      .then(response => {
        if (response.data) {
          setAreas([
            ...response.data.entities.map(x => ({
              value: x.id,
              label: x.area_nombre
            }))
          ]);
        } else {
          setLoadingData(false);
          setAreas([]);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoadingData(false);
        setLoading(false);
      });
  };
  const fetchVisitas = () => {
    setLoading(true);
    requestFromServer
      .getVisitas({
        filter: {
          fAsigMes: new Date(year, month - 1, 1),
          tecnico: representanteSelected.value,
          clientes_segmento: segmentoSelected.value
        }
      })
      .then(response => {
        if (response.data) {
          setVisitas(response.data.entities);
        } else {
          setLoadingData(false);
          dispatch(
            addAlert({ msg: "No existen visitas para esta combinación" })
          );
          setData({
            name: "Cantidad Visitas",
            colorByPoint: true,
            data: []
          });
          setVisitas([]);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoadingData(false);
        setLoading(false);
      });
  };
  const armarData = () => {
    let datalocal = [];
    clientes.forEach(c => {
      let visitasCliente = visitas.filter(v => v.id_clientes === c.id);
      if (visitasCliente.length > 0)
        datalocal.push({
          name: c.clientes_nombre,
          y: visitasCliente.length,
          drilldown: c.clientes_nombre
        });
    });
    setData({
      ...data,
      data: datalocal
    });
    setLoadingData(false);
  };
  const fetchData = async () => {
    setLoadingData(true);
    await fetchVisitas();
    await fetchClientes();
  };

  useEffect(() => {
    fetchAreas();
    fetchUsuarios();
  }, []);

  useEffect(() => {
    if (clientes.length > 0 && visitas.length > 0) armarData();
  }, [clientes, visitas]);

  return (
    <>
      <Card>
        <CardHeader title={cardTitle}>
          <CardHeaderToolbar>
            {!loadingData && (
              <Button type="button" onClick={fetchData}>
                {"Buscar"}
              </Button>
            )}
            {loadingData && (
              <span
                className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
              />
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-sm-12 col-md">
              <Select
                options={years}
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={onChangeYear}
                value={years.find(x => x.value === year)}
                placeholder={" "}
              />

              <small className="form-text text-muted">
                <b>Filtrar</b> por año
              </small>
            </div>
            <div className="col-sm-6 col-md">
              <Select
                options={months}
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={onChangeMonth}
                value={months.find(x => x.value === month)}
                placeholder={" "}
              />

              <small className="form-text text-muted">
                <b>Filtrar</b> por mes
              </small>
            </div>
            <div className="col-sm-6 col-md">
              <Select
                options={usuarios}
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={onChangeRepresentante}
                value={usuarios.find(
                  x => x.value === representanteSelected.value
                )}
                placeholder={" "}
              />

              <small className="form-text text-muted">
                <b>Filtrar</b> por representante
              </small>
            </div>
            <div className="col-sm-2 col-md-2">
              <Select
                options={segmentos}
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={onChangeSegmento}
                value={segmentoSelected}
                placeholder={" "}
              />

              <small className="form-text text-muted">
                <b>Filtrar</b> por segmento
              </small>
            </div>
          </div>
          {!loadingData && data && data.data && data.data.length > 0 && (
            <Form.Row>
              <div className="col-sm-12 col-md">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    ...optionsHighChart,
                    yAxis: {
                      ...optionsHighChart.yAxis,
                      max: null
                    },
                    tooltip: {},
                    series: [data]
                  }}
                />
              </div>
            </Form.Row>
          )}
        </CardBody>
      </Card>
    </>
  );
};
