import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import { Button, Form } from "react-bootstrap";
import { xls_to_json } from "../../helpers/XLSHelpers";
import * as requestFromServer from "../../helpers/axios";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { genTemplateCmInvPer } from "./xls/xls_templata_cm";
import { insertVisitasXls } from "../../helpers/axios";

export const CargaXls = props => {
  const [dataVisitas, setDataVisitas] = useState([]);
  const [loadingXls, setLoadingXls] = useState(false);
  const [filename, setFilename] = useState();
  const [downloadPlant, setDownloadPlant] = useState(false);
  const history = useHistory();
  const onHandleDownTemplate = () => {
    setDownloadPlant(true);
    genTemplateCmInvPer().then(r => {
      setDownloadPlant(false);
    });
  };
  const onHandleSaveCsv = () => {
    setLoadingXls(true);
    setTimeout(() => {
      const queryParams = {};
      queryParams.visitas = dataVisitas.map(x => ({
        //	Hora *	Minuto *
        id_visita: x["Id"],
        id_direcciones: Number(x["Id Sis. Cliente"].split("-")[0]),
        id_usu: Number(x["Id Sis. Usuario Asignado"].split("-")[0]),
        id_mv: Number(x["Tipo Requerimiento"].split("-")[0]),
        year: x["Año asignado"],
        mes: x["Mes asignado"],
        dia: x["Dia asignado"],
        hora: x["Hora"],
        minuto: x["Minuto"],
        obs: x["Observacion"],
        id_est_visita: Number(x["Estado"].split("-")[0])
      }));
      console.log(queryParams.visitas);
      requestFromServer
        .insertVisitasXls(queryParams)
        .then(response => {
          console.log(response);
          setLoadingXls(false);
          history.push("/visitas/listadmin");
        })
        .catch(err => {
          console.log("catch", err);
          setLoadingXls(false);
        });
    }, 1000);
  };

  const handleInputChange = ev => {
    const filename = ev.target.value.replace(/.*[\/\\]/, "");
    setFilename(filename);
    setLoadingXls(true);
    xls_to_json(ev).then(hojas => {
      if (hojas.length > 0) {
        const data = hojas[0].data;
        setDataVisitas(data);
      }
      setLoadingXls(false);
    });
  };
  return (
    <>
      <Card>
        <CardHeader title="Carga masiva de requerimientos">
          <CardHeaderToolbar>
            <Button
              type={"button"}
              variant={"primary"}
              onClick={onHandleDownTemplate}
              disabled={downloadPlant}
            >
              Plantilla
            </Button>
            &nbsp;
            <Button
              type={"button"}
              disabled={loadingXls || !dataVisitas || dataVisitas.length == 0}
              variant={"primary"}
              onClick={onHandleSaveCsv}
            >
              Cargar
            </Button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {loadingXls && (
            <div className={"text-center"}>
              Cargando..
              <CircularProgress size={24} />
            </div>
          )}
          {!loadingXls && (
            <Form.File
              id="file"
              label={filename ? filename : "Seleccionar XLS"}
              data-browse="Buscar"
              onChange={handleInputChange}
              custom
              name={"file"}
              disabled={loadingXls}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};
