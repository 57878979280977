import React, { useEffect } from "react";
import { Col, Form, Table } from "react-bootstrap";
import { RowTablaArea } from "./RowTablaArea";
import { NumericFormat } from "react-number-format";
import { Text } from "@react-pdf/renderer";

export const TablaArea = ({
  area,
  usuarios,
  metas,
  findMetaIndex,
  findMeta,
  setMetas,
  year,
  month
}) => {
  const [total, setTotal] = React.useState(0);
  const calculateTotal = () => {
    let suma = 0;
    metas.forEach(meta => {
      if (
        meta.id_area === area.id &&
        meta.meta_year === year &&
        meta.meta_month === month
      ) {
        suma += meta.meta_cantidad;
      }
    });

    setTotal(suma);
  };
  useEffect(() => {
    calculateTotal();
  }, [year, month, metas]);

  return (
    <>
      <Col xs={4}>
        <Form.Row
          style={{
            alignContent: "center",
            justifyContent: "center"
          }}
        >
          <Form.Label
            column
            style={{
              textLabel: { color: "#B5B5C3", alignSelf: "center" },
              alignSelf: "center",
              justifyContent: "center"
            }}
          >
            {area.area_nombre}
          </Form.Label>
        </Form.Row>
        <Form.Row>
          <Table
            responsive={true}
            borderless
            size="xl"
            style={{ width: "100%" }}
          >
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th>Usuario</th>
                <th>Meta</th>
              </tr>
            </thead>
            <tbody>
              {usuarios.map((usuario, index) => (
                <RowTablaArea
                  area={area}
                  usuario={usuario}
                  metas={metas}
                  findMetaIndex={findMetaIndex}
                  findMeta={findMeta}
                  setMetas={setMetas}
                  index={index}
                  year={year}
                  month={month}
                  calculateTotal={calculateTotal}
                />
              ))}
              <tr style={{ textAlign: "center" }}>
                <td>Total</td>
                <NumericFormat
                  value={total}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix={"$"}
                  renderText={value => <td>{value}</td>}
                />
              </tr>
            </tbody>
          </Table>
        </Form.Row>
      </Col>
    </>
  );
};
