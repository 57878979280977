import React from 'react';
import {Col, Form, InputGroup} from "react-bootstrap";
import DatePicker from "react-datepicker";
export const InputFecVisita = (props) => {
    const {selected,onChange,required}=props
    return (
            <Form.Group controlId="exampleForm.ControlInput1" as={Col} md={2}>
                <Form.Label>Fecha Visita {required?<span className={'text-danger'}>*  </span>:''}:</Form.Label>
                <InputGroup>
                    <DatePicker selected={selected}
                                onChange={date => onChange(date)}
                                isClearable
                                dateFormat="dd/MM/yyyy"
                                className={"form-control"}
                                required={required}
                    />
                </InputGroup>
            </Form.Group>
    );
};
