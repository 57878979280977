import axios from "axios";

export const REQUEST_URL = "api/visitas";
export const insertVisita = visita =>
  axios.post(`${REQUEST_URL}/insert`, { visita: visita });
export const updateVisita = (id_visita, visita) =>
  axios.put(`${REQUEST_URL}/update/${id_visita}`, {
    visita: visita,
    id_visita: id_visita
  });
export const getVisita = id_visita =>
  axios.get(`${REQUEST_URL}/getVisita/${id_visita}`);
export const getReporteFCEQuimicos = id_visita =>
  axios.get(`${REQUEST_URL}/getReporteFCEQuimicos/${id_visita}`);
export const getSugVisitasList = id =>
  axios.get(`${REQUEST_URL}/getSugVisitasList/${id}`);
export const getRutasDiarias = queryParams =>
  axios.get(`${REQUEST_URL}/getRutasDiarias`, { params: queryParams });
export const getVisitas = queryParams =>
  axios.post(`${REQUEST_URL}/getVisitas`, { queryParams });
export const getVisitasListXLS = queryParams =>
  axios.post(`${REQUEST_URL}/getVisitasListXLS`, { queryParams });
export const deleteVisita = id_visita =>
  axios.delete(`${REQUEST_URL}/${id_visita}`);
export const updateVisitaCalendario = (id_visita, visita) =>
  axios.put(`${REQUEST_URL}/updateCalendario/${id_visita}`, {
    visita: visita,
    id_visita: id_visita
  });
export const uploadPhotoRepAreaTec = (id_visita, photo_num, dataform) =>
  axios.post(
    `${REQUEST_URL}/uploadPhotoRepAreaTec/${id_visita}/${photo_num}`,
    dataform
  );
export const uploadSignRepAreaTec = (id_visita, photo_num, dataform) =>
  axios.post(
    `${REQUEST_URL}/uploadSignRepAreaTec/${id_visita}/${photo_num}`,
    dataform
  );
export const uploadPhotoRepFCE = (id_visita, photo_num, dataform) =>
  axios.post(
    `${REQUEST_URL}/uploadPhotoRepFCE/${id_visita}/${photo_num}`,
    dataform
  );
export const uploadSignRepFCE = (id_visita, photo_num, dataform) =>
  axios.post(
    `${REQUEST_URL}/uploadSignRepFCE/${id_visita}/${photo_num}`,
    dataform
  );
export const uploadPhotoRepCLC = (id_visita, photo_num, dataform) =>
  axios.post(
    `${REQUEST_URL}/uploadPhotoRepCLC/${id_visita}/${photo_num}`,
    dataform
  );
export const uploadSignRepCLC = (id_visita, photo_num, dataform) =>
  axios.post(
    `${REQUEST_URL}/uploadSignRepCLC/${id_visita}/${photo_num}`,
    dataform
  );
export const uploadPhotoRepLodging = (id_visita, photo_num, dataform) =>
  axios.post(
    `${REQUEST_URL}/uploadPhotoRepLodging/${id_visita}/${photo_num}`,
    dataform
  );
export const uploadSignRepLodging = (id_visita, photo_num, dataform) =>
  axios.post(
    `${REQUEST_URL}/uploadSignRepLodging/${id_visita}/${photo_num}`,
    dataform
  );
export const uploadPhotoRepRetail = (id_visita, photo_num, dataform) =>
  axios.post(
    `${REQUEST_URL}/uploadPhotoRepRetail/${id_visita}/${photo_num}`,
    dataform
  );
export const uploadSignRepRetail = (id_visita, photo_num, dataform) =>
  axios.post(
    `${REQUEST_URL}/uploadSignRepRetail/${id_visita}/${photo_num}`,
    dataform
  );

export const uploadPhotoRepFSR = (id_visita, photo_num, dataform) =>
  axios.post(
    `${REQUEST_URL}/uploadPhotoRepFSR/${id_visita}/${photo_num}`,
    dataform
  );
export const uploadSignRepFSR = (id_visita, photo_num, dataform) =>
  axios.post(
    `${REQUEST_URL}/uploadSignRepFSR/${id_visita}/${photo_num}`,
    dataform
  );
export const getVisitasRepDet = queryParams =>
  axios.post(`${REQUEST_URL}/getVisitasRepDet`, { queryParams });

export const getVisitasPendRepDet = queryParams =>
  axios.post(`${REQUEST_URL}/getVisitasPendRepDet`, { queryParams });

export const insertVisitasXls = queryParams =>
  axios.post(`${REQUEST_URL}/insertVisitasXls`, { queryParams });
export const DeletePhotoRepAreaTecQuery = (id_visita, photo_num) =>
  axios.post(
    `${REQUEST_URL}/DeletePhotoRepAreaTecQuery/${id_visita}/${photo_num}`
  );
export const DeleteSignRepAreaTecQuery = (id_visita, photo_num) =>
  axios.post(
    `${REQUEST_URL}/DeleteSignRepAreaTecQuery/${id_visita}/${photo_num}`
  );
export const getReporteSemanal = () =>
  axios.post(`${REQUEST_URL}/getReporteSemanal`);
export const DeletePhotoRepFCEQuery = (id_visita, photo_num) =>
  axios.post(`${REQUEST_URL}/DeletePhotoRepFCEQuery/${id_visita}/${photo_num}`);
export const DeleteSignRepFCEQuery = (id_visita, photo_num) =>
  axios.post(`${REQUEST_URL}/DeleteSignRepFCEQuery/${id_visita}/${photo_num}`);
export const getVisitasPromxDia = queryParams =>
  axios.post(`${REQUEST_URL}/getVisitasPromxDia`, { queryParams });
