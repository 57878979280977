import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image
} from "@react-pdf/renderer";
import * as requestFromServer from "../../helpers/axios";
import { TableDatGen } from "../components/pdfvisita/TableDatGen";
import { Subtitle1 } from "../components/pdfvisita/Subtitle1";
import { ModalPDFGenerated } from "./components/ModalPDFGenerated";
import { Header } from "../components/pdfvisita/Header";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { BrowserView, MobileView } from "react-device-detect";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";


export const RepAtecPDF = ({ match }) => {
  const { params } = match;
  const { id_visita } = params;
  const [visita, setVisita] = useState(undefined);
  const [reporte, setReporte] = useState(undefined);

  useEffect(() => {
    requestFromServer
      .getVisita(id_visita)
      .then(response => {
        const { entitie } = response.data;
        setVisita({ ...entitie });
        setReporte({ ...entitie.visitas_reporte });
      })
      .catch(error => console.log(error));
  }, []);
  const download = (filename, url) => {
    const element = document.createElement("a");
    element.setAttribute("href", `${url}`);
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };
  return (
    <>
      {!visita && <> </>}
      {visita && reporte && (
        <>
          <BrowserView style={{ flex: 1, minHeight: `100%` }}>
            <PDFViewer height={window.innerHeight} width="100%">
              <DocumentPdf visita={visita} reporte={reporte} />
            </PDFViewer>
          </BrowserView>
          <MobileView>
            <PDFDownloadLink
              document={<DocumentPdf visita={visita} reporte={reporte} />}
              fileName={`rodenstock_reporte_${visita.id}.pdf`}
            >
              {({ blob, loading }) =>
                loading ? (
                  "Generando..."
                ) : (
                  <ModalPDFGenerated
                    visita={visita}
                    onDownload={() => {
                      download(
                        `rodenstock_reporte_${visita.id}.pdf`,
                        URL.createObjectURL(blob)
                      );
                    }}
                  />
                )
              }
            </PDFDownloadLink>
          </MobileView>
        </>
      )}
    </>
  );
};
const DocumentPdf = ({ visita, reporte }) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <Header visita={visita} />
      <View style={styles.section}>
        
        <TableDatGen visita={visita} reporte={reporte} />
      </View>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <View
          style={{
            ...styles.section,
            textAlign: "left",
            width: "50%",
            padding: 10,
            paddingTop: 20
          }}
        >
          <Text style={styles.textHeader}>MINUTA DETALLADA</Text>
          <Text
            style={{ ...styles.textBody1, marginLeft: 10, marginVertical: 10 }}
          >
            {reporte.motivo}
          </Text>
        </View>
        <View
          style={{
            ...styles.section,
            textAlign: "left",
            width: "50%",
            
          }}
        >
         
         <Image
            style={styles.logo}
            src={toAbsoluteUrl("/images/pdf_image2.jpg")}
          />
        </View>
      </View>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <View
          style={{
            ...styles.section,
            textAlign: "left",
            width: "50%",
            
          }}
        >
          <Image
            style={styles.logo}
            src={toAbsoluteUrl("/images/pdf_image1.jpg")}
          />
        </View>
        <View
          style={{
            ...styles.section,
            textAlign: "left",
            width: "50%",
            padding: 10,
            paddingTop: 20
          }}
        >
          <Text style={styles.textHeader}>PARTICIPANTES</Text>
          <Text
            style={{ ...styles.textBody1, marginLeft: 10, marginVertical: 10 }}
          >
            {reporte.participantes}
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  page: { backgroundColor: "white" },
  sectionTop: {
    color: "white",
    textAlign: "left",
    backgroundColor: "black",
    paddingLeft: 30,
    paddingVertical: 5,
    fontSize: 10,
    position: "absolute",
    top: 0,
    width: "100%"
  },
  logo: { width: "100%" },
  sectionBottom: {
    color: "white",
    textAlign: "left",
    backgroundColor: "white",
    fontSize: 10,
    position: "absolute",
    bottom: 0,
    width: "100%"
  },
  section: { color: "black", textAlign: "center", margin: 10,backgroundColor: '#f7f9fb' },
  textBody1: {
    fontSize: 9
  },
  textHeader: {
    fontSize: 11
  }
});