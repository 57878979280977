// Validation schema
import * as Yup from "yup";

export const ChangePassValidationSchema = Yup.object().shape({
    oldPass: Yup.string()
        .min(2, "Mínimo 2 carácteres")
        .max(50, "Máximo 50 carácteres")
        .required("Antigua contraseña es obligatoria"),

    newPass: Yup.string()
        .min(2, "Mínimo 2 carácteres")
        .max(10, "Máximo 10 carácteres")
        .required("Nueva Contraseña es obligatoria"),

    newPassAgain: Yup.string()
        .min(2, "Mínimo 2 carácteres")
        .max(10, "Máximo 10 carácteres")
        .required("Repetir Contraseña es obligatorio")
        .when("newPass", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("newPass")],
                "Password and Confirm Password didn't match"
            ),
        })
    ,

});


