import React from 'react';
import {Col, Form} from "react-bootstrap";

export const InputObservaciones = (props) => {
    const {value,onChange}=props
    const {required}=props
    return (
                <Form.Group controlId="exampleForm.ControlInput1" as={Col}>
                    <Form.Label>Observaciones {required?<span className={'text-danger'}>*  </span>:''}:</Form.Label>
                    <Form.Control as={"textarea"} required onChange={onChange}
                                  value={value}/>
                </Form.Group>
    );
};
