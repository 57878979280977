import { exportToExcelPlantillaClientesJs } from "../../../helpers/XLSHelpers";

const preparePlantillaXLS = async metas => {
  const data = [];
  const dataCarga = [];

  for (const d of metas)
    dataCarga.push({
      Representante: d.fullname,
      Lenses: "",
      Others: "",
      Eyewear: ""
    });

  const carga = { sheetName: "Data", data: dataCarga };
  data.push(carga);
  return data;
};

export const generatePlantillaMetasXls = async metas => {
  const fileName = `Plantilla carga masiva metas`;
  const csvData = await preparePlantillaXLS(metas);
  await exportToExcelPlantillaClientesJs(csvData, fileName);
};
