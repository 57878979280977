import { ActionsColumnFormatter } from "./ActionsColumnFormatter";
import { sortCaret } from "../../../../_metronic/_helpers";
import { EstVisColFormatter } from "./EstVisColFormatter";
import { RatingColFormatter } from "./RatingColFormatter";

export const getColumns = (setShow, setIdReq) => [
  {
    dataField: "id",
    text: "ID",
    footer: "",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "fec_visita",
    text: "Fec Asignada",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "est_visita_nombre",
    text: "Estado",
    sort: true,
    sortCaret: sortCaret,
    formatter: EstVisColFormatter
  },
  {
    dataField: "clientes_nombre",
    text: "Cliente",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "sap",
    text: "CL",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "direcciones_nombre",
    text: "Tienda",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "tec_fullname",
    text: "Representante",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "mv_descripcion",
    text: "Tipo",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "accions",
    text: "Acciones",
    formatter: ActionsColumnFormatter,
    formatExtraData: { setShow, setIdReq },
    classes: "text-right pr-3 ",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "70px"
    }
  }
];
