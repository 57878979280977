import React from "react";

export function HeaderTablaInformeRepresentanteTipo({ symbol, dateSelected }) {
  return (
    <>
      <div style={styles.row}>
        <div style={{ ...styles.col }}>
          <h3 style={{ ...styles.title2, textAlign: "center" }}>
            TIPO
          </h3>
        </div>
        <div style={{ ...styles.col }}>
          <h3 style={styles.title2}>
            Uni {new Date(dateSelected).getFullYear()}
          </h3>
        </div>
        <div style={{ ...styles.col }}>
          <h3 style={styles.title2}>
            Uni {new Date(dateSelected).getFullYear() - 1}
          </h3>
        </div>
        <div style={{ ...styles.col }}>
          <h3 style={styles.title2}>
            Var vs{"\n"} {new Date(dateSelected).getFullYear() - 1}
          </h3>
        </div>
        <div style={{ ...styles.col }}>
          <h3 style={styles.title2}>
            MTD{"\n"} {new Date(dateSelected).getFullYear()}
          </h3>
        </div>
        <div style={{ ...styles.col }}>
          <h3 style={styles.title2}>
            MTD{"\n"} {new Date(dateSelected).getFullYear() - 1}{" "}
          </h3>
        </div>

        <div style={{ ...styles.col }}>
          <h3 style={styles.title2}>
            Var vs{"\n"} {new Date(dateSelected).getFullYear() - 1}
          </h3>
        </div>
        <div style={{ ...styles.col }}>
          <h3 style={styles.title2}>
            ASP{"\n"} {new Date(dateSelected).getFullYear()}
          </h3>
        </div>
        <div style={{ ...styles.col }}>
          <h3 style={styles.title2}>
            ASP{"\n"} {new Date(dateSelected).getFullYear() - 1}{" "}
          </h3>
        </div>
        <div style={{ ...styles.col }}>
          <h3 style={styles.title2}>Var</h3>
        </div>
      </div>
    </>
  );
}

const styles = {
  col: {
    width: 100
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignSelf: "center",
    backgroundColor: "#ff0000",
    paddingHorizontal: 10,
    paddingBottom: 10
  },
  title: {
    color: "#000000",
    fontSize: 36,
    textAlign: "center"
  },
  title2: {
    justifySelf: "center",
    textAlign: "center",
    color: "white",
    fontSize: 12,
    marginTop: 12,
    fontWeight: "bold"
  }
};
