import { actionsLics } from "../licenciasRedux";
import { deleteItem } from "../restFunctios/deleteItem";

export const deleteItemAction = id => {
  return async dispatch => {
    dispatch(actionsLics.setDeletingItem(true));
    const { response, error } = await deleteItem(id);
    if (!error) dispatch(actionsLics.removeLic(id));
    dispatch(actionsLics.setDeletingItem(false));
  };
};
