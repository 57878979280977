import React from "react";
import { ContentRoute } from "../_metronic/layout";
import { useSelector } from "react-redux";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { Route } from "react-router-dom";

export const ProtectedRoute = ({ path, component, permissions }) => {
  const { user } = useSelector(state => state.auth);
  let isAuth = true;
  if (permissions)
    isAuth =
      user &&
      user.permisos &&
      permissions.reduce(
        (acc, curr) => acc && user.permisos.includes(curr),
        true
      );
  return (
    <>
      {isAuth && <ContentRoute path={path} component={component} />}
      {!isAuth && <Route path="/error" component={ErrorsPage} />}
    </>
  );
};
