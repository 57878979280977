import React, { useEffect, useState } from "react";
import { InitialFilter } from "./InitialFilters";
import { TextFilter } from "./TextFilter";
import { PerfilFilter } from "./PerfilFilter";

export const TableFilters = props => {
  const { queryParams, setQueryParams } = props;

  const [filters, setFilters] = useState(InitialFilter);
  const prepareFilter = queryParams => {
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter["nombre"] = filters.nombre !== "" ? filters.nombre : undefined;
    filter["id_perfil"] = filters.id_perfil ? filters.id_perfil : undefined;
    filter["usu_cargo"] = filters.usu_cargo ? filters.usu_cargo : undefined;
    filter["usu_mail"] = filters.usu_mail ? filters.usu_mail : undefined;

    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const handleChangeNombre = e =>
    setFilters({ ...filters, nombre: e.target.value });
  const handleChangePerfil = e => {
    setFilters({ ...filters, id_perfil: e.value });
  };
  const handleChangeCargo = e => {
    setFilters({ ...filters, usu_cargo: e.target.value });
  };
  const handleChangeMail = e => {
    setFilters({ ...filters, usu_mail: e.target.value });
  };
  useEffect(() => {
    const newQueryParams = prepareFilter(queryParams);
    setQueryParams({ ...newQueryParams, pageNumber: 1 });
  }, [filters]);

  return (
    <div className="form-group row">
      <TextFilter
        onChange={handleChangeNombre}
        label={"nombre"}
        value={filters.nombre}
      />
      <TextFilter
        onChange={handleChangeCargo}
        label={"cargo"}
        value={filters.usu_cargo}
      />
      <PerfilFilter onChange={handleChangePerfil} />
      <TextFilter
        onChange={handleChangeMail}
        label={"correo"}
        value={filters.usu_mail}
      />
    </div>
  );
};
