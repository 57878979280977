import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Link } from "react-router-dom";

export const DeleteCTColumnFormatter = (cellContent, row, rowIndex, props) => {
  const { setShow, setIdUsuario } = props;

  return (
    <>
      <OverlayTrigger
        overlay={
          <Tooltip id="botis-delete-tooltip">Eliminar Cartera Tienda</Tooltip>
        }
      >
        <button
          className="btn btn-icon btn-light btn-hover-danger btn-sm mr-1"
          onClick={e => {
            e.currentTarget.blur();
            setIdUsuario(row.ccli_cod_cl);
            setShow(true);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </button>
      </OverlayTrigger>
    </>
  );
};
