import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Link } from "react-router-dom";
import moment from "moment";

export const FechaColumnFormatter = (cellContent, row) => {
  return (
    <>
      {row.filtros && (
        <span className="text-right pr-3">
          {moment(row.filtros.fechaDesde).format("DD-MM-yyyy")} -{" "}
          {moment(row.filtros.fechaHasta).format("DD-MM-yyyy")}
        </span>
      )}
    </>
  );
};
