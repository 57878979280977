import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from '../../../_metronic/_partials/controls'
import { Button, Form } from 'react-bootstrap'
import * as requestFromServer from '../../helpers/axios'
import { TablaArea } from './components/TablaArea'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { useHistory } from 'react-router-dom'
import { ModalCargaMetas } from './ModalCargaMetas'

export const MetasPage = () => {
  const animatedComponents = makeAnimated()

  const history = useHistory()
  const [usuarios, setUsuarios] = React.useState([])
  const [areas, setAreas] = React.useState([])
  const [metas, setMetas] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [year, setYear] = React.useState(new Date().getFullYear())
  const [month, setMonth] = React.useState(new Date().getMonth() + 1)
  const [showCarga, setShowCarga] = useState(false)

  const years = [
    { value: 2020, label: '2020' },
    { value: 2021, label: '2021' },
    { value: 2022, label: '2022' },
    { value: 2023, label: '2023' },
    { value: 2024, label: '2024' },
    { value: 2025, label: '2025' },
    { value: 2026, label: '2026' },
    { value: 2027, label: '2027' },
    { value: 2028, label: '2028' },
    { value: 2029, label: '2029' },
    { value: 2030, label: '2030' }
  ]
  const months = [
    { value: 1, label: 'Enero' },
    { value: 2, label: 'Febrero' },
    { value: 3, label: 'Marzo' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Mayo' },
    { value: 6, label: 'Junio' },
    { value: 7, label: 'Julio' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Septiembre' },
    { value: 10, label: 'Octubre' },
    { value: 11, label: 'Noviembre' },
    { value: 12, label: 'Diciembre' }
  ]
  let cardTitle = 'Metas '
  const onChangeYear = e => {
    setYear(e.value)
  }
  const onChangeMonth = e => {
    setMonth(e.value)
  }
  const fetchAreas = () => {
    setLoading(true)
    requestFromServer
      .getAreas()
      .then(response => {
        if (response.data) {
          setAreas(response.data.entities)
        } else {
          setAreas([])
        }
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
  }
  const fetchUsuarios = () => {
    setLoading(true)
    requestFromServer
      .getUsuarios({ filter: { id_perfil: 3 }, eliminado: false })
      .then(response => {
        if (response.data) {
          setUsuarios(response.data.entities)
        } else {
          setUsuarios([])
        }
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
  }
  const fetchMetas = () => {
    setLoading(true)
    requestFromServer
      .getMetas({})
      .then(response => {
        if (response.data) {
          setMetas(response.data.entities)
        } else {
          setMetas([])
        }
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
  }
  const sendMetasToServer = () => {
    setLoading(true)
    const sendRequest = () => {
      const m = metas.filter(
        m => m.meta_year === year && m.meta_month === month
      )
      return requestFromServer.UploadMetas(m)
    }
    sendRequest()
      .then(response => {
        history.push('/')
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const findMeta = (id_area, id_usu, meta_year, meta_month) => {
    return metas.find(
      (meta, index) =>
        meta.id_area === id_area &&
        meta.id_usu === id_usu &&
        meta.meta_year === meta_year &&
        meta.meta_month === meta_month
    )?.meta_cantidad
  }
  const findMetaIndex = (id_area, id_usu, meta_year, meta_month) =>
    metas.findIndex(
      (meta, index) =>
        meta.id_area === id_area &&
        meta.id_usu === id_usu &&
        meta.meta_year === meta_year &&
        meta.meta_month === meta_month
    )
  const handleOpenModal = () => setShowCarga(true)
  useEffect(() => {
    fetchAreas()
    fetchUsuarios()
    fetchMetas()
  }, [])

  return (
    <>
      <Card>
        <ModalCargaMetas
          show={showCarga}
          setShow={setShowCarga}
          year={year}
          month={month}
          fetchClientes={() => {
            fetchAreas()
            fetchUsuarios()
            fetchMetas()
          }}
        />
        <CardHeader title={cardTitle}>
          <CardHeaderToolbar>
            {!loading && (
              <Button
                type="button"
                variant={'success'}
                onClick={handleOpenModal}
              >
                {'Carga masiva'}
              </Button>
            )}
            &nbsp;
            {!loading && (
              <Button type="button" onClick={sendMetasToServer}>
                {'Ingresar'}
              </Button>
            )}
            {loading && (
              <span
                className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
              />
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-sm-6 col-md">
              <Select
                options={years}
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={onChangeYear}
                value={years.find(x => x.value === year)}
                placeholder={' '}
              />

              <small className="form-text text-muted">
                <b>Filtrar</b> por año
              </small>
            </div>
            <div className="col-sm-6 col-md">
              <Select
                options={months}
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={onChangeMonth}
                value={months.find(x => x.value === month)}
                placeholder={' '}
              />

              <small className="form-text text-muted">
                <b>Filtrar</b> por mes
              </small>
            </div>
          </div>
          <Form.Row>
            {areas.map((area, indexArea) => (
              <TablaArea
                area={area}
                usuarios={usuarios}
                metas={metas}
                findMetaIndex={findMetaIndex}
                findMeta={findMeta}
                setMetas={setMetas}
                year={year}
                month={month}
              />
            ))}
          </Form.Row>
        </CardBody>
      </Card>
    </>
  )
}
