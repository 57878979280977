import { sortCaret } from "../../../../../_metronic/_helpers";
import { ActionsDownloadColumnFormatter } from "./ActionsDownloadColumnFormatter";
import { FechaColumnFormatter } from "./FechaColumnFormatter";
import { FechaSolColumnFormatter } from "./FechaSolColumnFormatter";

export const getColumns = deleteSolicitud => [
  {
    dataField: "fecha_sol",
    text: "Fecha Solicitud",
    formatter: FechaSolColumnFormatter,
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "porcentajeactual",
    text: "% Completado",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "fullname",
    text: "Solicitador",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "filtros",
    text: "Rango Fechas",
    formatter: FechaColumnFormatter,
    classes: "text-right pr-3 ",
    formatExtraData: {},
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "70px"
    }
  },

  {
    dataField: "accions",
    text: "Descarga",
    formatter: ActionsDownloadColumnFormatter,
    classes: "text-right pr-3 ",
    formatExtraData: { deleteSolicitud },
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "70px"
    }
  }
];
