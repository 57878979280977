import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { addAlert } from "../../../redux/alerts/alertsRedux";
import { useDispatch } from "react-redux";
import { deleteDiasNoVisita } from "../../helpers/axios/DiasNoVisitaCrud";

export const ModalDeleteDias = props => {
  const { id, show, setShow, ListDiasNoVisita } = props;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDelete = () => {
    setLoading(true);
    deleteDiasNoVisita(id)
      .then(response => {
        dispatch(addAlert({ msg: "Dia Eliminado" }));
        ListDiasNoVisita();
        setShow(false);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminación de dia no visita</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Estás seguro que deseas eliminar el dia no visita?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Si
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
