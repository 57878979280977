import React from "react";
import { exportToExcelPlantillaClientesJs } from "../../../helpers/XLSHelpers";
import moment from "moment";

const preparePlantillaXLS = async clientes => {
  const data = [];
  const dataCarga = [];

  for (const d of clientes)
    dataCarga.push({
      RUT: d.clientes_rut,
      Nombre: d.clientes_nombre,
      Segmento: d.clientes_segmento
    });

  const carga = { sheetName: "Data", data: dataCarga };
  data.push(carga);
  return data;
};

export const generatePlantillaClientesXls = async Clientes => {
  const fileName = `Clientes`;
  const csvData = await preparePlantillaXLS(Clientes);
  await exportToExcelPlantillaClientesJs(csvData, fileName);
};
