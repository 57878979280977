import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { Checkbox } from "@material-ui/core";
import { EstVisFilter } from "./EstVisFilter";

export const TableFilters = props => {
  const { queryParams, setQueryParams } = props;

  const [filters, setFilters] = useState({});
  const prepareFilter = queryParams => {
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter["estado"] = filters.estado !== "" ? filters.estado : undefined;
    filter["eliminadoYterminado"] =
      filters.eliminadoYterminado !== ""
        ? filters.eliminadoYterminado
        : undefined;
    newQueryParams.filter = filter;
    return newQueryParams;
  };
  const handleChangeEstVis = e => setFilters({ ...filters, estado: e.value });
  useEffect(() => {
    const newQueryParams = prepareFilter(queryParams);
    setQueryParams({ ...newQueryParams, pageNumber: 1 });
  }, [filters]);
  const onChangeEliminado = e => {
    setFilters({ ...filters, eliminadoYterminado: e.target.checked });
  };

  return (
    <div className="form-group row">
      <EstVisFilter onChange={handleChangeEstVis} />
      <Form.Group controlId="exampleForm.ControlInput1" as={Col}>
        <Form.Label>Eliminados o Terminados</Form.Label>
        <Checkbox
          inputProps={{
            "aria-label": "primary checkbox"
          }}
          onChange={onChangeEliminado}
        />
      </Form.Group>
    </div>
  );
};
