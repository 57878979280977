import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import * as requestFromServer from "../../helpers/axios";
import { useStylesMaterial } from "../../helpers/StylesHelper";
import { CardVisita } from "../components/visitas/CardVisita";
import { CardEventoOutlook } from "../components/visitas/CardEventoOutlook";

export const VerVisita = ({ match }) => {
  const { params } = match;
  const { id } = params;

  const classes = useStylesMaterial();

  const [visita, setVisita] = useState({});
  
  const [loading, setLoading] = useState(false);

  const fetchVisita = () => {
    setLoading(true);
    requestFromServer
      .getVisita(id)
      .then(response => {
        const { entitie } = response.data;
        setVisita({ ...entitie });
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false));
  };

  
  useEffect(() => {
    if (id) fetchVisita();
  }, [id]);
  return (
    <>
      <div className={classes.root}>
        {loading && (
          <div className={"text-center"}>
            <CircularProgress className={classes.progress} />
          </div>
        )}
      </div>

      {!loading && (
        <>
          <CardVisita visita={visita} id_visita={id}  />
          <CardEventoOutlook visita={visita} />
        </>
      )}
    </>
  );
};
