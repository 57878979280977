import React, { useCallback, useEffect, useState } from "react";
import { cloneDeep, isEqual, isFunction } from "lodash";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Pagination
} from "../../../_metronic/_partials/controls";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import { getColumns } from "./table_helpers/DirTablecols";
import * as requestFromServer from "../../helpers/axios";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from "../../../_metronic/_helpers";
import { TableFilters } from "./table_helpers/TableFilters";
import { initialTableFilter } from "../../helpers/TableHelpers";
import { PaginationTotalCustom } from "../_GlobalComponents/table/pagination/PaginationTotalCustom";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { generatePlantillaDireccionesXls } from "./xls/xls_create";
import { useHistory } from "react-router-dom";
import { ModalEliminarDireccion } from "./components/ModalEliminarDireccion";

export const ListarDirecciones = () => {
  const history = useHistory();
  const suhbeader = useSubheader();
  suhbeader.setTitle("Cuentas");
  const { tiendas } = useSelector(state => state.tiendas);
  const [direcciones, setDirecciones] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialTableFilter);
  const [show, setShow] = useState(false);
  const [idDireccion, setIdDireccion] = useState(null);
  const { inSync } = useSelector(state => state.sync);

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 }
    ],
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
    paginationTotalRenderer: PaginationTotalCustom
  };

  useEffect(() => {
    let localTiendas = tiendas;
    if (queryParams.filter.codAlt)
      localTiendas = localTiendas.filter(tienda =>
        tienda.t_cod_cl.includes(queryParams.filter.codAlt)
      );
    if (queryParams.filter.t_rut_cliente)
      localTiendas = localTiendas.filter(tienda =>
        tienda.t_rut_cliente.includes(queryParams.filter.t_rut_cliente)
      );
    if (queryParams.filter.t_nombre_cliente)
      localTiendas = localTiendas.filter(tienda =>
        tienda.t_nombre_cliente
          ?.toUpperCase()
          .includes(queryParams.filter.t_nombre_cliente.toUpperCase())
      );
    setTotalCount(localTiendas.length);
    setDirecciones(
      localTiendas.slice(
        (queryParams.pageNumber - 1) * queryParams.pageSize,
        queryParams.pageSize * queryParams.pageNumber
      )
    );
  }, [
    tiendas,
    queryParams.pageNumber,
    queryParams.filter,
    queryParams.pageSize
  ]);

  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }
      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const handleXls = () => {
    generatePlantillaDireccionesXls(tiendas);
  };
  const handleAdd = () => {
    history.push("/direcciones/nuevo");
  };
  return (
    <>
      <ModalEliminarDireccion
        idDireccion={idDireccion}
        show={show}
        setShow={setShow}
      />
      <Card>
        <CardHeader title="Tiendas">
          <CardHeaderToolbar>
            {!inSync && (
              <Button type={"button"} variant={"success"} onClick={handleXls}>
                XLS
              </Button>
            )}
            &nbsp;
            {!inSync && (
              <Button type={"button"} variant={"primary"} onClick={handleAdd}>
                Agregar
              </Button>
            )}
            {inSync && <div className="spinner spinner-primary mr-10"></div>}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <TableFilters
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  isLoading={inSync}
                  paginationProps={paginationProps}
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    remote
                    keyField={"id"}
                    data={direcciones === null ? [] : direcciones}
                    columns={getColumns(setShow, setIdDireccion)}
                    onTableChange={getHandlerTableChange(setQueryParams)}
                    filter={filterFactory()}
                    striped
                    hover
                    condensed
                    {...paginationTableProps}
                  >
                    <PleaseWaitMessage entities={direcciones} />
                    <NoRecordsFoundMessage entities={direcciones} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
    </>
  );
};
