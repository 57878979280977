import React from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
const styles = StyleSheet.create({
  sectionHeader: {
    color: "black",
    textAlign: "left",
    backgroundColor: "#eee",
    paddingHorizontal: 20,
    paddingTop: 40,
    paddingBottom: 20
  },
  sectionRow: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  sectionCol: {},
  textTitle1: {
    fontSize: 13
  },
  textBody1: {
    fontSize: 10
  },
  textBody2: {
    fontSize: 10,
    color: "#757575"
  },
  logo: {
    width: 100
  }
});

export const Header = ({ visita }) => {
  return (
    <>
      <View style={[styles.sectionHeader, styles.sectionRow]} fixed>
        <View style={styles.sectionCol}>
          <Image
            style={styles.logo}
            src={toAbsoluteUrl("/media/logos/logo_rodenstock2.png")}
          />
        </View>
        <View style={{ ...styles.sectionCol }}>
          <Text style={styles.textTitle1}>INFORME VISITA Nº {visita.id}</Text>
          <Text style={styles.textBody1}>
            Fecha:{" "}
            {moment(visita.visitas_fecvisita)
              .utc()
              .format("DD-MM-YYYY")}
          </Text>
        </View>
      </View>
    </>
  );
};
