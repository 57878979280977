export const InitialVisitaState={
    visitas_fecvisita:undefined,
    id_est_visita:undefined,
    id_usu:undefined,
    id_mv:undefined,
    id_direcciones:undefined,
    id_cliente:undefined,
    id_est_eje:undefined,
    id_vendedor:undefined,
    id_procedencia_req:undefined,
    id_centrocosto_facturacion:undefined,
    visitas_duracion_teorica_hrs:'',
    visitas_obs:'',
    visitas_serie_maquina:'',
    visitas_n_ot:'',
    visitas_ordencompra:'',
    visitas_n_cotizacion:'',
    visitas_val_costo_estimado:'',
    visitas_val_venta_estimada:'',
}
