import React, { useEffect, useState } from "react";
import * as requestFromServer from "../../../helpers/axios";
import { Button, Col, Form } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Checkbox } from "@material-ui/core";

export const FormUsuario = props => {
  const animatedComponents = makeAnimated();
  const { usuario, setUsuario, sendUsuarioToServer, newEntity, btnRef } = props;
  const [validated, setValidated] = useState(false);
  const [perfiles, setPerfiles] = useState([]);
  const areas = [
    { value: "Lenses", label: "Lenses" },
    { value: "Eyewear", label: "Eyewear" },
    { value: "Others", label: "Others" }
  ];
  const [perfilSelected, setPerfilSelected] = useState({});
  const [areaSelected, setAreaSelected] = useState([]);
  const [plataformaSelected, setPlataformaSelected] = useState({});

  const onChangeNombre = e =>
    setUsuario({ ...usuario, usu_nombre: e.target.value });
  const onChangeApep = e => setUsuario({ ...usuario, usu_pat: e.target.value });
  const onChangeApem = e => setUsuario({ ...usuario, usu_mat: e.target.value });
  const onChangeEmail = e =>
    setUsuario({ ...usuario, usu_mail: e.target.value });
  const onChangeLogin = e =>
    setUsuario({ ...usuario, usu_login: e.target.value });

  const onChangeCargo = e =>
    setUsuario({ ...usuario, usu_cargo: e.target.value });
  const onChangeEliminado = e =>
    setUsuario({ ...usuario, usu_eliminado: e.target.checked });
  const onChangeApkTecnica = e =>
    setUsuario({ ...usuario, usu_usa_apk_tecnica: e.target.checked });
  const onChangePerfil = p => {
    setPerfilSelected(p);
    setUsuario({ ...usuario, id_perfil_asignado: p.value });
  };
  const onChangeArea = p => {
    setAreaSelected(p);
    setUsuario({ ...usuario, usu_areas: p.map(x => x.value) });
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      sendUsuarioToServer();
    }
    setValidated(true);
  };

  useEffect(() => {
    //cargamos perfiles de inventario
    let queryPerfiles = { eliminado: false };
    if (usuario.id_plataforma)
      queryPerfiles = {
        eliminado: false,
        filter: { id_plataforma: usuario.id_plataforma }
      };
    requestFromServer
      .getPerfiles(queryPerfiles)
      .then(response => {
        const { entities } = response.data;
        setPerfiles([
          ...entities.map(x => ({ value: x.id, label: x.perfil_nombre }))
        ]);
        if (!newEntity) {
          setPerfilSelected({
            ...entities
              .map(x => ({ value: x.id, label: x.perfil_nombre }))
              .find(x => x.value === usuario.id_perfil_asignado)
          });
          setPlataformaSelected({
            ...entities
              .map(x => ({ value: x.id_plataforma, label: x.pl_nombre }))
              .find(x => x.value === usuario.id_plataforma)
          });
        }
      })
      .catch(error => console.log(error));
  }, [usuario]);

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Row>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Nombre:</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeNombre}
              value={usuario.usu_nombre}
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Apellido paterno:</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeApep}
              value={usuario.usu_pat}
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Apellido Materno:</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeApem}
              value={usuario.usu_mat || ""}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Email:</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeEmail}
              value={usuario.usu_mail}
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Login:</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeLogin}
              value={usuario.usu_login}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Cargo:</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeCargo}
              value={usuario.usu_cargo || ""}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Perfil de usuario:</Form.Label>
            <Select
              options={perfiles}
              closeMenuOnSelect={true}
              components={animatedComponents}
              value={perfilSelected}
              onChange={onChangePerfil}
              placeholder={""}
              inputProps={{ required: true }}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Área:</Form.Label>
            <Select
              options={areas}
              isMulti
              closeMenuOnSelect={true}
              components={animatedComponents}
              value={
                usuario.usu_areas?.map(x => ({ value: x, label: x })) || []
              }
              onChange={onChangeArea}
              placeholder={""}
              inputProps={{ required: true }}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          {!newEntity && (
            <Form.Group controlId="exampleForm.ControlInput2" as={Col}>
              <Form.Label>Eliminado</Form.Label>
              <Checkbox
                checked={Boolean(usuario.usu_eliminado)}
                onChange={onChangeEliminado}
                style={{ verticalAlign: "middle" }}
                inputProps={{
                  "aria-label": "primary checkbox"
                }}
              />
            </Form.Group>
          )}
          <Form.Group controlId="exampleForm.ControlInput2" as={Col}>
            <Form.Label>Usa App IOS</Form.Label>
            <Checkbox
              checked={Boolean(usuario.usu_usa_apk_tecnica)}
              onChange={onChangeApkTecnica}
              style={{ verticalAlign: "middle" }}
              inputProps={{
                "aria-label": "primary checkbox"
              }}
            />
          </Form.Group>
        </Form.Row>

        <Button type="submit" ref={btnRef} style={{ display: "none" }} />
      </Form>
    </>
  );
};
