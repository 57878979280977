import React, {useEffect, useState} from 'react';
import {Col, Form} from "react-bootstrap";
import Select from "react-select";
import * as requestFromServer from "../../helpers/axios";
import makeAnimated from "react-select/animated/dist/react-select.esm";

export const InputSelEstadoVis = (props) => {
    const animatedComponents = makeAnimated();
    const {valueSelected, onChange} = props
    const {required} = props
    const [estados, setEstados] = useState([])

    useEffect(() => {
        requestFromServer.getEstadosVisita().then(response =>
            setEstados([...response.data.entities.map(x => ({
                value: x.id,
                label: x.est_visita_nombre
            }))])).catch(err => console.log(err))
    }, []);
    return (
        <>
            <Form.Group controlId="exampleForm.ControlInput1" as={Col} md={3}>
                <Form.Label>Estado {required?<span className={'text-danger'}>*  </span>:''}:</Form.Label>
                <Select options={estados} closeMenuOnSelect={true}
                        components={animatedComponents}
                        value={valueSelected}
                        onChange={onChange}
                        placeholder={""}
                        inputProps={{required: required}}
                />
            </Form.Group>
        </>
    );
};
