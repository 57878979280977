import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTiendas } from "../../redux/tiendas/restFunctios/getTiendas";
import _ from 'lodash'

const useSync = () => {
  const { inSync } = useSelector(state => state.sync);

  const dispatch = useDispatch();

  const triggerSync = async () => {
    console.log("sincronizando ");

    dispatch({
      type: "@setInSync",
      payload: { inSync: true }
    });

    const { data, error } = await getTiendas({});
    if (data) {
      dispatch({
        type: "@setTiendas",
        payload: { tiendas: data, totalCount: data.length }
      });

      let clientes = data.map(d => ({
        t_nombre_cliente: d.t_nombre_cliente,
        t_rut_cliente: d.t_rut_cliente
      }));
      clientes = _.uniqBy(clientes, 't_rut_cliente');
      //cargamos clientes en redux
      dispatch({
        type: "@setClientes",
        payload: {
          clientes: clientes,
          totalCount: clientes.length
        }
      });
    }

    dispatch({
      type: "@setInSync",
      payload: { inSync: false }
    });
  };

  useEffect(() => {
    if (!inSync) triggerSync();
  }, []);
  return { inSync, triggerSync };
};

export default useSync;
