export const InitialStateUsuario = {
    id: undefined,
    usu_nombre: "",
    usu_login: "",
    usu_pat: "",
    usu_mat: "",
    usu_mail: "",
    id_perfil_asignado: "",

}
