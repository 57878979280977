import React, { useState, useCallback, useEffect } from "react";
import { isEqual, isFunction, cloneDeep } from "lodash";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import { getColumns } from "./table_helpers/Tablecols";
import * as requestFromServer from "../../helpers/axios";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from "../../../_metronic/_helpers";
import { Pagination } from "../../../_metronic/_partials/controls";
import { Filters } from "./table_helpers/filter_comps/Filters";
import { initialTableFilter } from "../../helpers/TableHelpers";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { generateVisitasXls } from "./xls/xls_create";
import { addAlert } from "../../../redux/alerts/alertsRedux";
import { useDispatch, useSelector } from "react-redux";
import { PaginationTotalCustom } from "../_GlobalComponents/table/pagination/PaginationTotalCustom";
import { ModalEliminarReqto } from "./components/ModalEliminarReqto";

export const ListarVisitas = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [visitas, setVisitas] = useState(null);
  const [listLoading, setListLoading] = useState(false);
  const [xlsLoading, setXlsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [show, setShow] = useState(false);
  const [idReqto, setIdReq] = useState(null);

  const [queryParams, setQueryParamsBase] = useState(initialTableFilter);
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 }
    ],
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
    paginationTotalRenderer: PaginationTotalCustom
  };
  const { user } = useSelector(state => state.auth);
  useEffect(() => {
    fetchVisitas();
  }, [queryParams.pageNumber, queryParams.sortField, queryParams.sortOrder]);
  const fetchVisitas = () => {
    const queryParameters = cloneDeep(queryParams);
    if (
      queryParameters &&
      queryParameters.filter &&
      ((queryParameters.filter.fDesdeAsig &&
        queryParameters.filter.fHastaAsig) ||
        queryParameters.filter.id)
    ) {
      if (user.id_perfil && user.id_perfil === 6) {
        queryParameters.filter.userClient = true;
        queryParameters.filter.id_usu = user.id;
      }
      if (user.id_perfil && user.id_perfil === 7) {
        queryParameters.filter.userHolding = true;
        queryParameters.filter.id_usu = user.id;
      }
      if (user?.id_perfil === 3) {
        queryParameters.filter.tecnico = user.id;
      }
      setListLoading(true);
      requestFromServer
        .getVisitas(queryParameters)
        .then(response => {
          if (response.data) {
            setVisitas(response.data.entities);
            setTotalCount(response.data.totalCount);
          } else {
            setVisitas([]);
            setTotalCount(0);
          }
          setListLoading(false);
        })
        .catch(error => {
          console.log(error);
          setListLoading(false);
        });
    }
  };
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }
      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const handleAdd = () => history.push("/visitas/addVis");
  const handleXls = () => {
    setXlsLoading(true);
    const queryParameters = cloneDeep(queryParams);
    requestFromServer
      .getVisitasListXLS(queryParameters)
      .then(response => {
        generateVisitasXls(response.data.entities).then(r =>
          setXlsLoading(false)
        );
      })
      .catch(err => {
        console.log("err", err);
        dispatch(
          addAlert({
            msg:
              "largo de información demasiado grande, debe achicar el rango de fechas"
          })
        );
        setXlsLoading(false);
      });
  };

  return (
    <>
      <Card>
        <CardHeader title="Informes Visitas">
          <CardHeaderToolbar>
            <ModalEliminarReqto
              show={show}
              setShow={setShow}
              idReqto={idReqto}
              fetchVisitas={fetchVisitas}
            />
            <Button disabled={listLoading} type="button" onClick={fetchVisitas}>
              {listLoading ? "Cargando ..." : "Buscar"}
            </Button>
            &nbsp;
            {user.id_perfil && (user.id_perfil === 2 || user.id_perfil === 10) && (
              <Button onClick={handleXls} disabled={xlsLoading}>
                {xlsLoading ? "Cargando xls..." : "Exportar XLS"}
              </Button>
            )}
            &nbsp;
            {!listLoading && user?.permisos?.includes(11) && (
              <Button type={"button"} variant={"warning"} onClick={handleAdd}>
                Agregar
              </Button>
            )}
            &nbsp;
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Filters queryParams={queryParams} setQueryParams={setQueryParams} />
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  isLoading={listLoading}
                  paginationProps={paginationProps}
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    remote
                    keyField={"id"}
                    data={visitas === null ? [] : visitas}
                    columns={getColumns(setShow, setIdReq)}
                    onTableChange={getHandlerTableChange(setQueryParams)}
                    filter={filterFactory()}
                    striped
                    hover
                    condensed
                    {...paginationTableProps}
                  >
                    <PleaseWaitMessage entities={visitas} />
                    <NoRecordsFoundMessage entities={visitas} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
    </>
  );
};
