import React, { useCallback, useEffect, useState } from "react";
import { cloneDeep, isEqual, isFunction } from "lodash";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Pagination
} from "../../../_metronic/_partials/controls";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import { getColumns } from "./table_helpers/UsuTablecols";
import * as requestFromServer from "../../helpers/axios";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from "../../../_metronic/_helpers";
import { TableFilters } from "./table_helpers/TableFilters";
import { initialTableFilter } from "../../helpers/TableHelpers";
import { PaginationTotalCustom } from "../_GlobalComponents/table/pagination/PaginationTotalCustom";
import { Button } from "react-bootstrap";
import { ModalVentasCarga } from "./form/ModalVentasCarga";
import { useDispatch } from "react-redux";
import { addAlert } from "../../../redux/alerts/alertsRedux";
import { ModalSolicitudes } from "./form/ModalSolicitudes";
import { generateVisitasXls } from "../visitas/xls/xls_create";

export const ListarVentas = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Ventas");
  const dispatch = useDispatch();
  const [ventas, setVentas] = useState(null);
  const [listLoading, setListLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialTableFilter);
  const [show, setShow] = useState(false);
  const [idVenta, setIdVenta] = useState(null);
  const [showVerSolicitudes, setShowVerSolicitudes] = useState(false);

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 }
    ],
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
    paginationTotalRenderer: PaginationTotalCustom
  };


  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }
      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);
  const getDaysOfDifference = (date1, date2) => {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };
  const handleXls = () => {
    const queryParameters = cloneDeep(queryParams);
    if (
      !queryParameters.filter.fechaDesde ||
      !queryParameters.filter.fechaHasta
    ) {
      dispatch(addAlert({ msg: "Ingresar fechas para filtrar" }));
      return;
    }
    queryParameters.is_admin_sol = true;
    setListLoading(true);
    requestFromServer
      .putSolicitudXlsX(queryParameters)
      .then(response => {
        dispatch(
          addAlert({
            msg: "Se genero solicitud satisfactoriamente"
          })
        );
        setListLoading(false);
      })
      .catch(err => {
        setListLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <ModalSolicitudes
        show={showVerSolicitudes}
        setShow={setShowVerSolicitudes}
      />
      <Card>
        <CardHeader title="Ventas registrados">
          <CardHeaderToolbar>
            {!listLoading && (
              <>
                &nbsp;
                <Button variant={"success"} onClick={handleXls}>
                  Solicitar XLS
                </Button>
                &nbsp;
                <Button
                  variant={"success"}
                  onClick={() => setShowVerSolicitudes(true)}
                >
                  Ver Solicitudes
                </Button>
              </>
            )}
            {listLoading && (
              <span
                className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
              />
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <TableFilters
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />
        </CardBody>
      </Card>
    </>
  );
};
