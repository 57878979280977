import React from "react";
import ExcelJS from "exceljs";
import * as _ from "lodash";
import * as FileSaver from "file-saver";
import moment from "moment";
import * as requestFromServer from "../../../helpers/axios";

const prepareDataTemplateXLS = () => {
  const data = [];
  const dataDetalle = [];

  dataDetalle.push({
    "Id Sis. Cuenta": "",
    "Id Sis. Usuario Asignado": "",
    "Año asignado": "",
    "Mes asignado": "",
    "Dia asignado": "",
    "Hrs Teoricas": "",
    Observacion: "",
    Estado: "",
    "Tipo Visita": ""
  });

  const detalle = { sheetName: "Carga", data: dataDetalle };

  data.push(detalle);

  return data;
};

const generateNormalSheet = (wb, sheetData, validations) => {
  const ws = wb.addWorksheet(sheetData.sheetName, {
    views: [{ showGridLines: false }]
  });
  let headers = [];
  for (const dataRow of sheetData.data)
    Object.keys(dataRow).forEach(x => {
      headers.push(x);
    });
  headers = _.uniq(headers);
  ws.columns = headers.map(x => ({ header: x, key: x }));

  for (const dataRow of sheetData.data) ws.addRow(dataRow);
  for (const hname of headers)
    ws.getRow(1).getCell(hname).style = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FEFEA5" }
      },
      border: {
        top: { style: "medium", color: { argb: "000000" } },
        left: { style: "medium", color: { argb: "000000" } },
        bottom: { style: "medium", color: { argb: "000000" } },
        right: { style: "medium", color: { argb: "000000" } }
      }
    };

  //generamos validaciones
  const limit_validation = 1000;
  //lista tipos inventario

  for (let i = 2; i <= limit_validation; i++)
    ws.getCell(`A${i}`).dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: ["cli!$A$1:$A$" + validations.clientes.length], //<--------------------------------Right there
      showErrorMessage: true,
      errorStyle: "error",
      errorTitle: "Error",
      error: "Valor debe estar en la lista"
    };
  //lista usuarios
  let ColsLeter = ["B"];
  for (let ColLeter of ColsLeter)
    for (let i = 2; i <= limit_validation; i++)
      ws.getCell(`${ColLeter}${i}`).dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ["users!$A$1:$A$" + validations.users.length], //<--------------------------------Right there
        showErrorMessage: true,
        errorStyle: "error",
        errorTitle: "Error",
        error: "Valor debe estar en la lista"
      };
  for (let ColLeter of ColsLeter)
    for (let i = 2; i <= limit_validation; i++)
      ws.getCell(`H${i}`).dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ["estados!$A$1:$A$" + validations.estados_vis.length], //<--------------------------------Right there
        showErrorMessage: true,
        errorStyle: "error",
        errorTitle: "Error",
        error: "Valor debe estar en la lista"
      };
  for (let ColLeter of ColsLeter)
    for (let i = 2; i <= limit_validation; i++)
      ws.getCell(`I${i}`).dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ["tipos!$A$1:$A$" + validations.tipos.length], //<--------------------------------Right there
        showErrorMessage: true,
        errorStyle: "error",
        errorTitle: "Error",
        error: "Valor debe estar en la lista"
      };

  //creamos sheet de validaciones
  const wsUsers = wb.addWorksheet("users");
  wsUsers.state = "hidden";
  const wsClo = wb.addWorksheet("cli");
  wsClo.state = "hidden";
  const wsEst = wb.addWorksheet("estados");
  wsEst.state = "hidden";
  const wsTip = wb.addWorksheet("tipos");
  wsTip.state = "hidden";
  for (const user of validations.users) wsUsers.addRow([user]);
  for (const cli of validations.clientes) wsClo.addRow([cli]);
  for (const estado of validations.estados_vis) wsEst.addRow([estado]);
  for (const tipo of validations.tipos) wsTip.addRow([tipo]);
};
const exportToExcelJs = async (data, fileName) => {
  const response_usuarios = await requestFromServer.getUsuarios({});
  const response_clientes = await requestFromServer.getDirecciones({});
  const response_estados = await requestFromServer.getEstadosVisita({});
  const response_tipos = await requestFromServer.getTiposVisita({});
  const validations = {
    clientes: response_clientes.data.entities.map(
      x => `${x.id} - ${x.direcciones_nombre}`
    ),
    estados_vis: response_estados.data.entities.map(
      x => `${x.id} - ${x.est_visita_nombre}`
    ),
    users: response_usuarios.data.entities.map(x => `${x.id} - ${x.fullname}`),
    tipos: response_tipos.data.entities.map(
      x => `${x.id} - ${x.mv_descripcion}`
    )
  };

  const wb = new ExcelJS.Workbook();
  for (const sheetData of data) generateNormalSheet(wb, sheetData, validations);
  const buf = await wb.xlsx.writeBuffer();
  const fileExtension = ".xlsx";
  FileSaver.saveAs(new Blob([buf]), fileName + fileExtension);
};

export const genTemplateCmInvPer = async () => {
  const fileName = `Plantilla carga visitas`;
  const csvData = prepareDataTemplateXLS();
  await exportToExcelJs(csvData, fileName);
};
