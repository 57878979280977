import React from 'react'
import { Route } from 'react-router-dom'
import { LicDeleteDialog } from './LicDeleteDialog'
import { LicAddDialog } from './LicAddDialog'

export const ListDialogsRoutes = (props) => {
  return (
    <>
      <Route path="/licencias/list/delete/:id">
        {({ history, match }) => (
          <LicDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/licencias/list");
            }}
          />
        )}
      </Route>
      <Route path="/licencias/list/add">
        {({ history, match }) => (
          <LicAddDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/licencias/list");
            }}
          />
        )}
      </Route>
    </>
  )
}
