import React, { useState } from 'react'
import * as requestFromServer from '../../../../helpers/axios'
import { useDispatch } from 'react-redux'
import { addAlert } from '../../../../../redux/alerts/alertsRedux'
import { generateXls } from '../xls/xls_create'

export const useFetchReps = ({ queryParams }) => {

  const [loading, setLoading] = useState(false)
  const [reporte, setReporte] = useState(undefined)
  const dispatch = useDispatch()

  const sendError = () => {
    dispatch(
      addAlert({
        msg: 'Ha ocurrido un error en la descarga de información',
      }),
    )
  }

  const fetch = () => {
    setLoading(true)

    requestFromServer.getReportes(queryParams).then(response => {
      if (response.data) setReporte([...response.data.entities])
      else setReporte(undefined)
    }).catch(error => {
      sendError()
      console.log(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  const handleXls = () => {
    setLoading(true)
    requestFromServer.getReportes(queryParams).then(response => {
      generateXls(response.data.entities).then(r =>
        setLoading(false),
      )
    }).catch(err => {
      console.log('err', err)
      sendError()
    }).finally(() => {
      setLoading(false)
    })
  }

  return { loading, fetch, handleXls, reporte }
}
