import React, { useEffect, useState } from "react";
import { InitialFilter } from "./InitialFilters";
import { EstVisFilter } from "./EstVisFilter";
import { FecVisFilter } from "./FecVisFilter";
import { IdVisFilter } from "./IdVisFilter";
import { HoldingVisFilter } from "./HoldingVisFilter";
import { EliminadoVisFilter } from "./EliminadoVisFilter";
import { AsignacionVisFilter } from "./AsignacionVisFilter";
import SelectAsyncPaginationCli from "../../../../components/AsyncSelects/SelectAsyncPaginationCli";
import SelectAsyncPaginationDir from "../../../../components/AsyncSelects/SelectAsyncPaginationDir";
import SelectAsyncPaginationUsu from "../../../../components/AsyncSelects/SelectAsyncPaginationUsu";
import Select from "react-select";
import { SegmentoFilter } from "./SegmentoFilter";

export const Filters = props => {
  const { queryParams, setQueryParams } = props;

  const [filters, setFilters] = useState(InitialFilter);
  const [dirSelected, setDirSelected] = useState({});
  const [clienteSelected, setClienteSelected] = useState({});
  const [tecnicoSelected, setTecnicoSelected] = useState({});
  const prepareFilter = queryParams => {
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter["tecnico"] = filters.tecnico !== "" ? filters.tecnico : undefined;
    filter["id_clientes"] =
      filters.cliente !== "" ? filters.cliente : undefined;
    filter["direccion"] =
      filters.direccion !== "" ? filters.direccion : undefined;

    filter["estado"] = filters.estado !== "" ? filters.estado : undefined;
    filter["tipo"] = filters.tipo !== "" ? filters.tipo : undefined;
    filter["responsable"] =
      filters.responsable !== "" ? filters.responsable : undefined;
    filter["id"] = filters.id !== "" ? filters.id : undefined;
    filter["serieMaquina"] =
      filters.serieMaquina !== "" ? filters.serieMaquina : undefined;
    if (!filters.id) {
      filter["fDesdeAsig"] = filters.fecDesdeAsigna
        ? filters.fecDesdeAsigna
        : undefined;
      filter["fHastaAsig"] = filters.fecHastaAsigna
        ? filters.fecHastaAsigna
        : undefined;
    } else {
      filter["fDesdeAsig"] = undefined;
      filter["fHastaAsig"] = undefined;
    }
    filter["fDesdeEj"] = filters.fecDesdeEjecucion
      ? filters.fecDesdeEjecucion
      : undefined;
    filter["fHastaEj"] = filters.fecHastaEjecucion
      ? filters.fecHastaEjecucion
      : undefined;
    filter["fDesdeCrea"] = filters.fecDesdeCreacion
      ? filters.fecDesdeCreacion
      : undefined;
    filter["fHastaCrea"] = filters.fecHastaCreacion
      ? filters.fecHastaCreacion
      : undefined;
    filter["id_grupc"] = filters.id_grupc ? filters.id_grupc : undefined;
    filter["id_grupo_r"] = filters.id_grupo_r ? filters.id_grupo_r : undefined;
    filter["id_frev"] = filters.id_frev ? filters.id_frev : undefined;
    filter["eliminado"] = filters.eliminado ? filters.eliminado : undefined;
    filter["asignado"] = filters.asignado ? filters.asignado : undefined;
    filter["segmento"] = filters.segmento ? filters.segmento : undefined;
    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const handleChangeTecnico = e => {
    setTecnicoSelected(e);
    setFilters({ ...filters, tecnico: e.value });
  };
  const handleChangeCliente = e => {
    setClienteSelected(e);
    setFilters({ ...filters, cliente: e.value });
  };
  const handleChangeDireccion = e => {
    setDirSelected(e);
    setFilters({ ...filters, direccion: e.value });
  };
  const handleChangeEstVis = e => setFilters({ ...filters, estado: e.value });
  const handleChangeIdVis = e => setFilters({ ...filters, id: e.target.value });

  const handleChangeFAsigD = date =>
    setFilters({ ...filters, fecDesdeAsigna: date });
  const handleChangeFAsigH = date =>
    setFilters({ ...filters, fecHastaAsigna: date });

  const handleChangeFEjeD = date =>
    setFilters({ ...filters, fecDesdeEjecucion: date });
  const handleChangeFEjeH = date =>
    setFilters({ ...filters, fecHastaEjecucion: date });

  const handleChangeFEcreaD = date =>
    setFilters({ ...filters, fecDesdeCreacion: date });
  const handleChangeFEcreaH = date =>
    setFilters({ ...filters, fecHastaCreacion: date });
  const handleChangeSegmento = segmento =>
    setFilters({ ...filters, segmento: segmento });
  const handleChangeEliminado = e =>
    setFilters({ ...filters, eliminado: e.target.checked });
  const handleChangeAsignado = e =>
    setFilters({ ...filters, asignado: e.target.checked });
  useEffect(() => {
    const newQueryParams = prepareFilter(queryParams);
    setQueryParams({ ...newQueryParams, pageNumber: 1 });
  }, [filters]);

  return (
    <div className="form-group row">
      <FecVisFilter
        onChange={handleChangeFAsigD}
        label={"F. desde Asignación"}
        value={filters.fecDesdeAsigna}
      />
      <FecVisFilter
        onChange={handleChangeFAsigH}
        label={"F. hasta Asignación"}
        value={filters.fecHastaAsigna}
      />
      <FecVisFilter
        onChange={handleChangeFEjeD}
        label={"F. desde Ejecución"}
        value={filters.fecDesdeEjecucion}
      />
      <FecVisFilter
        onChange={handleChangeFEjeH}
        label={"F. hasta Ejecución"}
        value={filters.fecHastaEjecucion}
      />
      <FecVisFilter
        onChange={handleChangeFEcreaD}
        label={"F. desde Creación"}
        value={filters.fecDesdeCreacion}
      />
      <FecVisFilter
        onChange={handleChangeFEcreaH}
        label={"F. hasta Creación"}
        value={filters.fecHastaCreacion}
      />
      <EstVisFilter onChange={handleChangeEstVis} />
      <IdVisFilter
        onChange={handleChangeIdVis}
        label={"ID"}
        value={filters.id}
      />
      <SelectAsyncPaginationCli
        value={clienteSelected}
        holding={filters.id_grupc}
        onChange={handleChangeCliente}
        md={4}
        isFilter={true}
      />
      <SelectAsyncPaginationDir
        cliente={filters.cliente}
        value={dirSelected}
        onChange={handleChangeDireccion}
        md={4}
        isFilter={true}
      />
      <SegmentoFilter
        onChange={handleChangeSegmento}
        value={filters.segmento}
      />
      <EliminadoVisFilter onChange={handleChangeEliminado} />
    </div>
  );
};
