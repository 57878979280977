import React from "react";
import {Badge} from "react-bootstrap";

export const VigenciaColumnFormatter = (
    cellContent,
    row,
    rowIndex
) => {

    return (
        <>
            <Badge variant={`${row.eliminado ?'danger':'primary'}`}>
                {row.eliminado ?'No Vigente':'Vigente'}</Badge>


        </>
    );

}
