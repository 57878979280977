import React, { useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import { Button, Form } from "react-bootstrap";
import * as requestFromServer from "../../helpers/axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { optionsHighChart } from "./components/optionsHighChart";
import { addAlert } from "../../../redux/alerts/alertsRedux";
import { useDispatch } from "react-redux";
import Highstock from "highcharts/highstock";
import ReactHighcharts from "react-highcharts";
const Charts = ReactHighcharts.withHighcharts(Highstock);
export const CumplimientoRepresentantes = () => {
  const animatedComponents = makeAnimated();

  const suhbeader = useSubheader();
  suhbeader.setTitle("Cumplimiento Representantes");
  const dispatch = useDispatch();
  const [usuarios, setUsuarios] = React.useState([]);
  const [metas, setMetas] = React.useState([]);
  const [ventas, setVentas] = React.useState([]);
  const [areas, setAreas] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [month, setMonth] = React.useState(new Date().getMonth() + 1);
  const [areaSelected, setAreaSelected] = React.useState({
    value: 1,
    label: "Lenses"
  });
  const [data, setData] = React.useState({
    name: "Porcentaje",
    colorByPoint: true,
    data: []
  });
  const years = [
    { value: 2020, label: "2020" },
    { value: 2021, label: "2021" },
    { value: 2022, label: "2022" },
    { value: 2023, label: "2023" },
    { value: 2024, label: "2024" },
    { value: 2025, label: "2025" },
    { value: 2026, label: "2026" },
    { value: 2027, label: "2027" },
    { value: 2028, label: "2028" },
    { value: 2029, label: "2029" },
    { value: 2030, label: "2030" }
  ];
  const months = [
    { value: 1, label: "Enero" },
    { value: 2, label: "Febrero" },
    { value: 3, label: "Marzo" },
    { value: 4, label: "Abril" },
    { value: 5, label: "Mayo" },
    { value: 6, label: "Junio" },
    { value: 7, label: "Julio" },
    { value: 8, label: "Agosto" },
    { value: 9, label: "Septiembre" },
    { value: 10, label: "Octubre" },
    { value: 11, label: "Noviembre" },
    { value: 12, label: "Diciembre" }
  ];
  let cardTitle = "Cumplimiento Representantes";
  const onChangeYear = e => {
    setYear(e.value);
  };
  const onChangeMonth = e => {
    setMonth(e.value);
  };
  const onChangeArea = area => {
    setAreaSelected(area);
  };
  const fetchUsuarios = () => {
    setLoading(true);
    requestFromServer
      .getUsuarios({ filter: { id_perfil: 3 } })
      .then(response => {
        if (response.data) {
          setUsuarios(response.data.entities);
        } else {
          setUsuarios([]);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };
  const fetchMetas = () => {
    setLoading(true);
    requestFromServer
      .getMetas({})
      .then(response => {
        if (response.data) {
          setMetas(response.data.entities);
        } else {
          dispatch(addAlert({ msg: "No existen metas para este mes" }));
          setLoadingData(false);
          setMetas([]);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoadingData(false);
        setLoading(false);
      });
  };
  const fetchAreas = () => {
    setLoading(true);
    requestFromServer
      .getAreas()
      .then(response => {
        if (response.data) {
          setAreas([
            ...response.data.entities.map(x => ({
              value: x.id,
              label: x.area_nombre
            }))
          ]);
        } else {
          setLoadingData(false);
          setAreas([]);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoadingData(false);
        setLoading(false);
      });
  };
  const fetchVentas = () => {
    setLoading(true);
    requestFromServer
      .getVentasReporte({
        filter: {
          fecha: new Date(year, month - 1, 1),
          ventas_segmento_limit: areaSelected.label
        }
      })
      .then(response => {
        if (response.data) {
          setVentas(response.data.entities);
          console.log("Ventas", response.data.entities);
        } else {
          setLoadingData(false);
          dispatch(
            addAlert({ msg: "No existen ventas para esta combinación" })
          );
          setVentas([]);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoadingData(false);
        setLoading(false);
      });
  };
  const armarData = () => {
    let datalocal = [];
    usuarios.forEach(u => {
      let meta = metas.find(
        m =>
          m.id_usu === u.id &&
          m.meta_month === month &&
          m.meta_year === year &&
          m.id_area === areaSelected.value
      );
      let ventasUsuario = ventas.find(v => v.id_rep === parseInt(u.id));
      let total = 0;
      if (ventasUsuario) total = parseInt(ventasUsuario.total);

      let porcentaje = 0;
      if (meta && meta.meta_cantidad) {
        porcentaje = (total * 100) / meta.meta_cantidad;
      }
      datalocal.push({
        name: u.fullname,
        y: porcentaje,
        drilldown: u.fullname
      });
    });
    setData({
      ...data,
      data: datalocal
    });
    setLoadingData(false);
  };
  const fetchData = async () => {
    setLoadingData(true);
    await fetchUsuarios();
    await fetchMetas();
    await fetchVentas();
  };

  useEffect(() => {
    fetchAreas();
  }, []);

  useEffect(() => {
    if (usuarios.length > 0 && metas.length > 0 && ventas.length > 0)
      armarData();
  }, [usuarios, metas, ventas]);

  return (
    <>
      <Card>
        <CardHeader title={cardTitle}>
          <CardHeaderToolbar>
            {!loadingData && (
              <Button type="button" onClick={fetchData}>
                {"Buscar"}
              </Button>
            )}
            {loadingData && (
              <span
                className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
              />
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-sm-12 col-md">
              <Select
                options={years}
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={onChangeYear}
                value={years.find(x => x.value === year)}
                placeholder={" "}
              />

              <small className="form-text text-muted">
                <b>Filtrar</b> por año
              </small>
            </div>
            <div className="col-sm-6 col-md">
              <Select
                options={months}
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={onChangeMonth}
                value={months.find(x => x.value === month)}
                placeholder={" "}
              />

              <small className="form-text text-muted">
                <b>Filtrar</b> por mes
              </small>
            </div>
            <div className="col-sm-6 col-md">
              <Select
                options={areas}
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={onChangeArea}
                value={areaSelected}
                placeholder={" "}
              />

              <small className="form-text text-muted">
                <b>Filtrar</b> por area
              </small>
            </div>
          </div>
          {!loadingData && data && data.data && data.data.length > 0 && (
            <Form.Row>
              <div className="col-sm-12 col-md">
                <Charts
                  isPureConfig={true}
                  neverReflow={true}
                  config={{ ...optionsHighChart, series: [data] }}
                />
              </div>
            </Form.Row>
          )}
        </CardBody>
      </Card>
    </>
  );
};
