/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { AsideMenuItem } from "./AsideMenuItem";
import { AsideMenuDivider } from "./AsideMenuDivider";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

export function AsideMenuListRepresentante({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  const { user } = useSelector(state => state.auth);
  return (
    <>
      {/* begin::Menu Nav */}

      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <AsideMenuItem
          hasSubmenu={false}
          label={"Dashboard"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/dashboard"}
          permissions={[69]}
        />
        {/*end::1 Level*/}

        {/* begin::section */}

        <AsideMenuDivider
          label={"Operación administrativa"}
          permissions={[69, 10, 12, 9]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Visitas"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/visitas/listadmin"}
          urlBase={"/visitas"}
          permissions={[12, 69]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Calendario"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/calendario"}
          permissions={[69, 10]}
        />

        <AsideMenuItem
          hasSubmenu={false}
          label={"Repositorio"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/repositorio/list"}
          urlBase={"/repositorio"}
          permissions={[69, 12]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Ventas"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/ventas/list"}
          urlBase={"/ventas"}
          permissions={[69, 12]}
        />
        <AsideMenuDivider label={"Informes"} permissions={[69]} />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Informe Representantes"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/informe_representantes"}
          urlBase={"/informe_representantes"}
          permissions={[69]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Reporte Diario"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/repSemanal"}
          urlBase={"/repSemanal"}
          permissions={[69]}
        />
        <AsideMenuDivider label={"Aplicacion"} permissions={[69]} />

        {user.permisos && user.permisos.includes(69) && (
          <li
            className={`menu-item ${getMenuItemActive("/dummy", false)}`}
            aria-haspopup="true"
          >
            <a
              className="menu-link"
              href="https://apps.apple.com/cl/app/rodstreet/id1667253294"
              target="_blank"
            >
              {" "}
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                />
              </span>
              <span className="menu-text">App representantes</span>
            </a>
          </li>
        )}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
