import { ActionsColumnFormatter } from "./ActionsColumnFormatter";
import { sortCaret } from "../../../../_metronic/_helpers";
import { VigenciaColumnFormatter } from "./VigenciaColumnFormatter";
export const getColumns = () => [
  {
    dataField: "codAlt",
    text: "SAP",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "clientes_nombre",
    text: "Cliente",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "ccli_rut_cliente",
    text: "Rut Cliente",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "direcciones_nombre",
    text: "Dirección",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "direcciones_segmento",
    text: "Segmento",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "dist",
    text: "Distancia en km",
    sort: true,
    sortCaret: sortCaret
  }
];
