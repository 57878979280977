import React from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  textBody1: {
    fontSize: 9, fontFamily: 'Roboto',
  },
})

export const Resultado = ({ reporte }) => {
  return (
    <View style={{
      width: '100%',
      justifyContent: 'center',
      flexDirection: 'row',
      display: 'flex',
    }}>
      <View style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '240pt',
        display: 'flex',
        backgroundColor: '#000',
        alignItems: 'center',
        color: '#fff',
        padding: '10pt',
      }}>
        <View style={{ width: '33%' }}><Text
          style={styles.textBody1}>{reporte.reporte_resultado_texto}</Text></View>

        <View style={{ width: '33%' }}><Text
          style={styles.textBody1}>{reporte.reporte_resultado_porcentaje}%</Text></View>

        <View style={{
          backgroundColor: `${reporte.reporte_resultado_color}`,
          height: '30pt',
          width: '60pt',
        }}/>


      </View>

    </View>
  )
}
