import React, { useEffect, useState } from "react";
import { InitialFilter } from "./InitialFilters";
import { TextFilter } from "./TextFilter";
import { PerfilFilter } from "./PerfilFilter";

export const CTFilters = props => {
  const { queryParams, setQueryParams } = props;

  const [filters, setFilters] = useState(InitialFilter);
  const prepareFilter = queryParams => {
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter["nombre"] = filters.nombre !== "" ? filters.nombre : undefined;
    filter["cl"] = filters.cl !== "" ? filters.cl : undefined;
    filter["rut"] = filters.rut !== "" ? filters.rut : undefined;
    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const handleChangeNombre = e =>
    setFilters({ ...filters, nombre: e.target.value });
  const handleChangeRut = e => setFilters({ ...filters, rut: e.target.value });
  const handleChangeCL = e => setFilters({ ...filters, cl: e.target.value });
  useEffect(() => {
    const newQueryParams = prepareFilter(queryParams);
    setQueryParams({ ...newQueryParams, pageNumber: 1 });
  }, [filters]);

  return (
    <div className="form-group row">
      <TextFilter
        onChange={handleChangeNombre}
        label={"nombre"}
        value={filters.nombre}
      />
      <TextFilter
        onChange={handleChangeRut}
        label={"rut"}
        value={filters.rut}
      />
      <TextFilter onChange={handleChangeCL} label={"CL"} value={filters.cl} />
    </div>
  );
};
