import React, { useEffect, useState } from "react";
import { InitialFilter } from "./InitialFilters";
import { TextFilter } from "./TextFilter";
import { HoldingFilter } from "./HoldingFilter";
import { CliFilter } from "./CliFilter";
import { useSelector } from "react-redux";
import SelectAsyncPaginationCli from "../../components/AsyncSelects/SelectAsyncPaginationCli";
import { SegmentoFilter } from "../../visitas/table_helpers/filter_comps/SegmentoFilter";

export const TableFilters = props => {
  const { queryParams, setQueryParams } = props;
  const [holdingSelected, setHoldingSelected] = useState({});

  const [filters, setFilters] = useState(InitialFilter);
  const prepareFilter = queryParams => {
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter["nombre"] =
      filters.direcciones_nombre !== ""
        ? filters.direcciones_nombre
        : undefined;
    filter["direcciones_texto"] =
      filters.direcciones_texto !== "" ? filters.direcciones_texto : undefined;
    filter["codAlt"] = filters.codAlt !== "" ? filters.codAlt : undefined;
    filter["id_grupc"] = filters.id_grupc ? filters.id_grupc : undefined;
    filter["id_clientes"] =
      filters.id_clientes !== "" ? filters.id_clientes : undefined;
    filter["segmento"] = filters.segmento ? filters.segmento : undefined;
    filter["t_rut_cliente"] = filters.t_rut_cliente
      ? filters.t_rut_cliente
      : undefined;
    filter["t_nombre_cliente"] = filters.t_nombre_cliente
        ? filters.t_nombre_cliente
        : undefined;
    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const handleChangeSAP = e =>
    setFilters({ ...filters, codAlt: e.target.value });
  const handleChangeRut = e =>
    setFilters({ ...filters, t_rut_cliente: e.target.value });
  const handleChangeNombre = e =>
      setFilters({ ...filters, t_nombre_cliente: e.target.value });

  useEffect(() => {
    const newQueryParams = prepareFilter(queryParams);
    setQueryParams({ ...newQueryParams, pageNumber: 1 });
  }, [filters]);

  return (
    <div className="form-group row">
      <TextFilter
        onChange={handleChangeSAP}
        label={"Sap / Código"}
        value={filters.codAlt}
      />
      <TextFilter
        onChange={handleChangeRut}
        label={"Rut Cliente"}
        value={filters.t_rut_cliente}
      />
      <TextFilter
          onChange={handleChangeNombre}
          label={"Nombre Cliente"}
          value={filters.t_nombre_cliente}
      />
    </div>
  );
};
