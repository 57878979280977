import { getVisitasAction } from './actions/getVisitasAction'
//import { getRutaAction } from './actions/getRutaAction'

const initialRdetState = {
  visitas: [],
  ruta: [],
  loadingVis: false,
  loadingRuta: false
}

export const rdetReducer = (state = initialRdetState, action) => {
  switch (action.type) {
    case '@setVisitas': {
      const { visitas } = action.payload
      return { ...state, visitas: visitas }
    }
    case '@setLoadingVis': {
      const { loading } = action.payload
      return { ...state, loadingVis: loading }
    }
    case '@setLoadingRuta': {
      const { loading } = action.payload
      return { ...state, loadingRuta: loading }
    }

    default:
      return state
  }
}
export const actionsDets = {
  /*setLics: (lics, totalLics) => ({
    type: "@setLics",
    payload: { lics, totalLics }
  }),
  */
  getVisitas: q => getVisitasAction(q)
  //getRuta: getRutaAction
}
