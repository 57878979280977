import axios from "axios";

export const REQUEST_URL = "api/direcciones";
export const getDirecciones = queryParams =>
  axios.post(`${REQUEST_URL}/getDirecciones`, { queryParams });
export const getDireccionesDynamo = queryParams =>
  axios.post(`${REQUEST_URL}/getDireccionesDynamo`, { queryParams });

export const getDireccionesXlsx = queryParams =>
  axios.post(`${REQUEST_URL}/getDireccionesXlxs`, { queryParams });

export const insertDireccion = direccion =>
  axios.put(`${REQUEST_URL}/put`, { dir: direccion });
export const updateDireccion = (id, direccion) =>
  axios.put(`${REQUEST_URL}/update/${id}`, { dir: direccion });
export const getDireccion = id => axios.get(`${REQUEST_URL}/getDir/${id}`);
export const deleteDireccion = id => axios.put(`${REQUEST_URL}/delete/${id}`);
export const getDirsPos = queryParams =>
  axios.post(`${REQUEST_URL}/getdirspos`, { queryParams });
export const UploadDireccionXlxs = dataDirecciones =>
  axios.post(`${REQUEST_URL}/UploadDireccionXlxs`, {
    dataDirecciones: dataDirecciones
  });
