import React, { useEffect, useState } from "react";
import * as requestFromServer from "../../../helpers/axios";
import { Button, Col, Form } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Checkbox } from "@material-ui/core";

export const FormCliente = props => {
  const animatedComponents = makeAnimated();
  const { cliente, setCliente, sendClienteToServer, newEntity, btnRef } = props;
  const [validated, setValidated] = useState(false);
  const [grupos, setGrupos] = useState([]);
  const [grupoSelected, setGrupoSelected] = useState({});

  const onChangeNombre = e =>
    setCliente({ ...cliente, clientes_nombre: e.target.value });
  const onChangeRut = e =>
    setCliente({ ...cliente, clientes_rut: e.target.value });
  const onChangeGrupo = p => {
    setGrupoSelected(p);
    setCliente({ ...cliente, id_grupc: p.value });
  };
  const onChangeEliminado = e =>
    setCliente({ ...cliente, clientes_eliminado: e.target.checked });

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      sendClienteToServer();
    }
    setValidated(true);
  };

  useEffect(() => {
    //cargamos perfiles de inventario
    requestFromServer
      .getGruposCli({})
      .then(response => {
        const { entities } = response.data;
        setGrupos([
          ...entities.map(x => ({ value: x.id, label: x.grupc_nombre }))
        ]);
        if (!newEntity)
          setGrupoSelected({
            ...entities
              .map(x => ({ value: x.id, label: x.grupc_nombre }))
              .find(x => x.value === cliente.id_grupc)
          });
      })
      .catch(error => console.log(error));
  }, [cliente]);

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Row>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={6}>
            <Form.Label>Nombre:</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeNombre}
              value={cliente.clientes_nombre}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={6}>
            <Form.Label>Rut:</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeRut}
              value={cliente.clientes_rut}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          {!newEntity && (
            <Form.Group controlId="exampleForm.ControlInput2" as={Col}>
              <Form.Label>Eliminado</Form.Label>
              <Checkbox
                checked={Boolean(cliente.clientes_eliminado)}
                onChange={onChangeEliminado}
                style={{ verticalAlign: "middle" }}
                inputProps={{
                  "aria-label": "primary checkbox"
                }}
              />
            </Form.Group>
          )}
        </Form.Row>

        <Button type="submit" ref={btnRef} style={{ display: "none" }} />
      </Form>
    </>
  );
};
