import axios from "axios";

export const REQUEST_URL = "api/gruposCli";

export const getGruposCli = queryParams =>
  axios.post(`${REQUEST_URL}/getGruposCli`, { queryParams });
export const insertGrupoCli = grupoCli =>
  axios.put(`${REQUEST_URL}/put`, { grupo_cli: grupoCli });
export const updateGrupoCli = (id, grupoCli) =>
  axios.put(`${REQUEST_URL}/update/${id}`, { grupo_cli: grupoCli });
export const getGrupoCli = id => axios.get(`${REQUEST_URL}/getgrupocli/${id}`);
export const deleteGruposClientes = id =>
  axios.put(`${REQUEST_URL}/delete/${id}`);
export const getGruposClientesXlxs = queryParams =>
    axios.post(`${REQUEST_URL}/getGruposClientesXlxs`, { queryParams });
export const UploadGruposClientesXlxs = (dataGrupoCliente) => axios.post(`${REQUEST_URL}/UploadGruposClientesXlxs`, {dataGrupoCliente: dataGrupoCliente});
