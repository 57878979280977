import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  ModalProgressBar,
  Pagination
} from "../../../../_metronic/_partials/controls";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { getColumns } from "../table_helpers/SolTablecols";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from "../../../../_metronic/_helpers";
import filterFactory from "react-bootstrap-table2-filter";
import { PaginationTotalCustom } from "../../_GlobalComponents/table/pagination/PaginationTotalCustom";
import {
  initialSolTableFilter,
  initialTableFilter
} from "../../../helpers/TableHelpers";
import { cloneDeep, isEqual, isFunction } from "lodash";
import * as requestFromServer from "../../../helpers/axios";

export const ModalSolicitudes = props => {
  const { show, setShow } = props;
  const [loading, setLoading] = useState(false);
  const [solicitudes, setSolicitudes] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialSolTableFilter);
  const handleClose = () => setShow(false);

  const deleteSolicitud = id => {
    setLoading(true);
    requestFromServer
      .deleteSolicitud(id)
      .then(response => {
        if (response.data) {
          fetchSolicitudes();
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };
  const fetchSolicitudes = () => {
    const queryParameters = cloneDeep(queryParams);

    setLoading(true);
    requestFromServer
      .getSolicitudesXlsX(queryParameters)
      .then(response => {
        if (response.data) {
          setSolicitudes(response.data.entities);
          setTotalCount(response.data.totalCount);
        } else {
          setSolicitudes([]);
          setTotalCount(0);
        }

        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (show) fetchSolicitudes();
  }, [queryParams, show]);

  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }
      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 }
    ],
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
    paginationTotalRenderer: PaginationTotalCustom
  };

  return (
    <>
      <Modal show={show} size={"xl"} onHide={handleClose}>
        {loading && <ModalProgressBar variant="query" />}
        <Modal.Header closeButton>
          <Modal.Title>Ver Solicitudes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && (
            <>
              <div align={"center"}>
                <span>
                  <b>{"Cargando..."}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                </span>
              </div>
            </>
          )}
          {!loading && (
            <PaginationProvider
              pagination={paginationFactory(paginationOptions)}
            >
              {({ paginationProps, paginationTableProps }) => {
                return (
                  <Pagination
                    isLoading={loading}
                    paginationProps={paginationProps}
                  >
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4
                      bordered={false}
                      remote
                      keyField={"id"}
                      data={solicitudes === null ? [] : solicitudes}
                      columns={getColumns(deleteSolicitud)}
                      onTableChange={getHandlerTableChange(setQueryParams)}
                      filter={filterFactory()}
                      striped
                      hover
                      condensed
                      {...paginationTableProps}
                    >
                      <PleaseWaitMessage entities={solicitudes} />
                      <NoRecordsFoundMessage entities={solicitudes} />
                    </BootstrapTable>
                  </Pagination>
                );
              }}
            </PaginationProvider>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!loading && (
            <>
              <Button
                variant="secondary"
                onClick={handleClose}
                disabled={loading}
              >
                Cerrar
              </Button>
            </>
          )}
          {loading && (
            <span
              className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
