import React from 'react';
import {Font, StyleSheet, Text} from "@react-pdf/renderer";

Font.register({
  family: "Roboto",
  src:
    "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf"
});
const styles = StyleSheet.create({
  textSubtitle: {
    fontSize: 10,
    color: 'black',
    backgroundColor: '#eee',
    textAlign:'left', padding:4,
    fontFamily: 'Roboto'
  }
});

export const Subtitle2 = (props) => {
  const {label}=props
  return (
      <Text style={styles.textSubtitle}>{label}</Text>
  );
};
