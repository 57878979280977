import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import * as requestFromServer from '../../../helpers/axios'
import makeAnimated from "react-select/animated";

export const FilterUsuario = ({ usuarioSelected,setUsuarioSelected,disabled }) => {
  const animatedComponents = makeAnimated();
  const [loading, setLoading] = useState(false)

  const [usuarios, setUsuarios] = useState([])

  const onChangeUsuario = e => {
    setUsuarioSelected(e);
  };

  const fetchUsuarios = () => {
    setLoading(true)
    requestFromServer.getUsuarios({ filter: { id_perfil: 3 } }).
      then(response => {
        if (response.data) {
          setUsuarios([
            ...response.data.entities.map(x => ({
              value: x.id,
              label: x.fullname,
            })),
          ])

        } else {
          setUsuarios([])
        }
      }).
      catch(error => {
        console.log(error)
      }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
      fetchUsuarios()
  }, [])

  return (
    <>
      <Select
        options={usuarios}
        closeMenuOnSelect={true}
        components={animatedComponents}
        onChange={onChangeUsuario}
        value={usuarios.find(x => x.value === usuarioSelected.value)}
        placeholder={' '}
        isLoading={loading}
        isDisabled={disabled}
      />
      <small className="form-text text-muted">
        <b>Filtrar</b> por Usuario
      </small>

    </>
  )
}
