import React from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import { Col, Form } from "react-bootstrap";

import { useSelector } from "react-redux";

const SelectAsyncPaginationCli = props => {
  const { clientes } = useSelector(state => state.clientes);
  const loadOptions = async (searchQuery, loadedOptions, { pageNumber }) => {
    let dataFiltradas;
    if (searchQuery)
      dataFiltradas = clientes.filter(
        x =>
          x.t_nombre_cliente
            ?.toUpperCase()
            .includes(searchQuery.toUpperCase()) ||
          x.t_rut_cliente?.toUpperCase().includes(searchQuery.toUpperCase())
      );
    else dataFiltradas = clientes;
    const dataPaginada = dataFiltradas
      .slice((pageNumber - 1) * 10, pageNumber * 10)
      .map(x => ({
        value: x.t_rut_cliente,
        t_nombre_cliente: x.t_nombre_cliente,
        label: x.t_nombre_cliente + " - " + x.t_rut_cliente
      }));
    let hasMore = false;
    if (dataPaginada) hasMore = dataFiltradas.length > pageNumber * 10;
    if (dataPaginada && props.isFilter && pageNumber === 1)
      dataPaginada.unshift({ value: undefined, label: "Todos" });
    return {
      options: dataPaginada ? dataPaginada : [],
      hasMore: hasMore,
      additional: {
        pageNumber: searchQuery ? 2 : pageNumber + 1
      }
    };
  };

  const onChange = option => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  return (
    <Form.Group controlId="exampleForm.ControlInput1" as={Col} md={props.md}>
      {!props.isFilter && <Form.Label>Cliente :</Form.Label>}
      <AsyncPaginate
        value={props.value || ""}
        loadOptions={loadOptions}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        onChange={onChange}
        isSearchable={true}
        placeholder=""
        additional={{
          pageNumber: 1
        }}
      />
      {props.isFilter && (
        <small className="form-text text-muted">
          <b>Filtrar</b> por Cliente
        </small>
      )}
    </Form.Group>
  );
};

SelectAsyncPaginationCli.propTypes = {
  md: PropTypes.number.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
  isFilter: PropTypes.bool.isRequired
};
export default SelectAsyncPaginationCli;
