import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../app/modules/Auth/_redux/authRedux'
import { alertsSlice } from './alerts/alertsRedux'
import { licsReducer } from './licencias/licenciasRedux'
import { rdetReducer } from './rdetenciones/rdetenRedux'
import { tiendasReducer } from './tiendas/tiendasRedux'
import { clientesReducer } from './clientes/clientesRedux'
import { ctiendasReducer } from './ctiendas/ctiendasRedux'
import { syncReducer } from './sync/syncRedux'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  alerts: alertsSlice.reducer,
  lics: licsReducer,
  rdet: rdetReducer,
  tiendas: tiendasReducer,
  clientes: clientesReducer,
  ctiendas: ctiendasReducer,
  sync: syncReducer
})

export function* rootSaga() {
  yield all([auth.saga()])
}
