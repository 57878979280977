import { exportToExcelPlantillaClientesJs } from "../../../helpers/XLSHelpers";

const preparePlantillaXLS = async datas => {
  const data = [];
  const dataCarga = [];

  for (const d of datas)
    dataCarga.push({
      CL: d.cod_cl
    });

  const carga = { sheetName: "Data", data: dataCarga };
  data.push(carga);
  return data;
};

export const generatePlantillaXls = async metas => {
  const fileName = `Plantilla carga masiva`;
  const csvData = await preparePlantillaXLS(metas);
  await exportToExcelPlantillaClientesJs(csvData, fileName);
};
