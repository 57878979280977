import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import BasePageAdmin from "./BasePageAdmin";
import { useSelector } from "react-redux";
import BasePageRepresentante from "./BasePageRepresentante";
import { ProtectedRoute } from "./ProtectedRoute";
import { BuilderPage } from "./pages/BuilderPage";
import { DashboardPage } from "./pages/DashboardPage";

export default function BasePage() {
  const { user } = useSelector(state => state.auth);
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ProtectedRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        {user.id_perfil === 2 && <BasePageAdmin />}
        {user.id_perfil === 3 && <BasePageRepresentante />}
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
