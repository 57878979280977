import React, { useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import { Button, Col, Form } from "react-bootstrap";
import makeAnimated from "react-select/animated";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { Checkbox } from "@material-ui/core";
import { InputTextArea } from "../../components/InputTextArea";
import Select from "react-select";
import { GeoSearchControl } from "leaflet-geosearch";
import * as GeoSearch from "leaflet-geosearch";
import "leaflet-geosearch/dist/geosearch.css";
export const FormDireccion = props => {
  const animatedComponents = makeAnimated();

  const {
    direccion,
    setDireccion,
    sendDireccionToServer,
    loading,
    newEntity,
    btnRef
  } = props;
  const [validated, setValidated] = useState(false);
  const [geocoderResult, setGResult] = useState({});
  const [changedLocation, setChangedLocation] = useState(false);
  const [segmentoSelected, setSegmentoSelected] = useState(null);
  const [map, setMap] = useState(null);

  let initParamsMap = {
    center: [-33.44889, -70.669265],
    zoom: [2]
  };

  const [initParmsMap, setInitialParamsMap] = useState(initParamsMap);

  const segmentos = [
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "D", value: "D" },
    { label: "E", value: "E" }
  ];

  const SearchField = () => {
    const provider = new GeoSearch.OpenStreetMapProvider();

    // @ts-ignore
    const searchControl = new GeoSearchControl({
      provider: provider,
      style: "bar",
      params: { "accept-language": "es" }
    });

    const map = useMap();

    useEffect(() => {
      map.addControl(searchControl);
      return () => map.removeControl(searchControl);
    }, []);

    map.on("geosearch/showlocation", onChoseLocation);

    return null;
  };
  const onChoseLocation = e => {
    setDireccion({
      ...direccion,
      t_latitude: e.location.y,
      t_longitude: e.location.x
    });
    setChangedLocation(true);
  };

  const onChangeNombre = e =>
    setDireccion({ ...direccion, t_direccion: e.target.value });
  const onChangeClienteNombre = e =>
    setDireccion({ ...direccion, t_nombre_cliente: e.target.value });
  const onChangeClienteRut = e =>
    setDireccion({ ...direccion, t_rut_cliente: e.target.value });
  const onChangeCodigo = e =>
    setDireccion({ ...direccion, t_cod_cl: e.target.value });
  const onChangeLat = e =>
    setDireccion({ ...direccion, t_latitude: e.target.value });

  const onChangeLon = e =>
    setDireccion({ ...direccion, t_longitude: e.target.value });

  const onChangeDestinatarios = e =>
    setDireccion({ ...direccion, t_email_to: e.target.value });

  const onChangeEliminado = e =>
    setDireccion({ ...direccion, t_eliminado: e.target.checked });

  const onChangeSegmento = p => {
    setSegmentoSelected(p);
    setDireccion({
      ...direccion,
      t_segmento: p.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      sendDireccionToServer();
    }
    setValidated(true);
  };

  useEffect(() => {
    if (!loading) {
      if (
        !newEntity &&
        direccion.t_longitude !== undefined &&
        direccion.t_latitude !== undefined &&
        map
      ) {
        map.setView([direccion.t_latitude, direccion.t_longitude], 14);
      }
    }
  }, [direccion.t_longitude, direccion.t_latitude, map]);

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Row>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Cliente Nombre * :</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeClienteNombre}
              value={direccion.t_nombre_cliente}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Cliente Rut * :</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeClienteRut}
              value={direccion.t_rut_cliente}
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Tienda * :</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeNombre}
              value={direccion.t_direccion}
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>CL * :</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeCodigo}
              value={direccion.t_cod_cl}
              disabled={!newEntity}
            />
          </Form.Group>

          <div className="col-lg-12" style={{ height: 350 }}>
            <MapContainer
              center={initParmsMap.center}
              ref={setMap}
              zoom={initParamsMap.zoom}
              scrollWheelZoom={true}
              style={{ height: 340, width: "100wh" }}
            >
              {!newEntity && !changedLocation && (
                <Marker
                  position={[direccion.t_latitude, direccion.t_longitude]}
                />
              )}
              <SearchField />
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </MapContainer>
          </div>

          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Latitud:</Form.Label>
            <Form.Control
              required
              onChange={onChangeLat}
              disabled
              value={direccion.t_latitude}
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Longitud:</Form.Label>
            <Form.Control
              required
              onChange={onChangeLon}
              disabled
              value={direccion.t_longitude}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Segmento:</Form.Label>
            <Select
              options={segmentos}
              closeMenuOnSelect={true}
              components={animatedComponents}
              value={segmentos.find(
                segmento => segmento.value === direccion.t_segmento
              )}
              onChange={onChangeSegmento}
              placeholder={""}
              inputProps={{ required: true }}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          {!newEntity && (
            <Form.Group controlId="exampleForm.ControlInput2" as={Col}>
              <Form.Label>Eliminado</Form.Label>
              <Checkbox
                checked={Boolean(direccion.t_eliminado)}
                onChange={onChangeEliminado}
                style={{ verticalAlign: "middle" }}
                inputProps={{
                  "aria-label": "primary checkbox"
                }}
              />
            </Form.Group>
          )}
        </Form.Row>
        <Form.Row>
          <InputTextArea
            value={direccion.t_email_to}
            onChange={onChangeDestinatarios}
            label={"Destinatarios reportes (emails)"}
          />
        </Form.Row>
        <Button type="submit" ref={btnRef} style={{ display: "none" }} />
      </Form>
    </>
  );
};
