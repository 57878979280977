import { ActionsColumnFormatter } from "./ActionsColumnFormatter";
import { sortCaret } from "../../../../../_metronic/_helpers";

export const getColumns = () => [
  {
    dataField: "name",
    text: "Nombre",
    footer: "",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "fecha",
    text: "Fecha Creación",
    sort: true,
    sortCaret: sortCaret
  },

  {
    dataField: "accions",
    text: "Acciones",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-3 ",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "70px"
    }
  }
];
