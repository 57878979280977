import React from 'react';
import {Snackbar} from '@material-ui/core';
import {shallowEqual, useSelector,useDispatch} from "react-redux"
import {cleanAllAlerts} from "../../redux/alerts/alertsRedux"

export const AlertMessage = () => {
    const dispatch = useDispatch();
    const {currentState} = useSelector(
        (state) => ({currentState: state.alerts}), shallowEqual
    );

    const {show, alerts} = currentState;
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return
        if (reason === 'timeout') dispatch(cleanAllAlerts())

    }

    return (
        <div>
            {
                alerts && alerts.map((alert,index)=> <Snackbar
                    style={{marginTop:45*index}}
                    key={index}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={show}
                    autoHideDuration={8000}
                    onClose={handleClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{alert.msg}</span>}

                />)
            }

        </div>
    );
}
