import axios from "axios";

export const REQUEST_URL = "api/usus";

export function getUsuarios(queryParams) {
  return axios.post(`${REQUEST_URL}/getusus`, { queryParams });
}
export function getCarteraClientes(queryParams) {
  return axios.post(`${REQUEST_URL}/getCarteraClientes`, { queryParams });
}
export function getCarteraTiendas(queryParams) {
  return axios.post(`${REQUEST_URL}/getCarteraTiendas `, { queryParams });
}
export const getUsersPos = () => axios.get(`${REQUEST_URL}/getuserspos`);
export const insertUsuario = usuario =>
  axios.put(`${REQUEST_URL}/put`, { usu: usuario });
export const updateUsuario = (id, usuario) =>
  axios.put(`${REQUEST_URL}/update/${id}`, { usu: usuario });
export const getDirUsu = id => axios.get(`${REQUEST_URL}/getDirUsu/${id}`);
export const getGruposUsu = id =>
  axios.get(`${REQUEST_URL}/getGruposUsu/${id}`);
export const getUsuario = id => axios.get(`${REQUEST_URL}/getusu/${id}`);
export const putUsuRandPdw = id => axios.put(`${REQUEST_URL}/UsuRandPdw/${id}`);
export const deleteUsuario = id => axios.put(`${REQUEST_URL}/delete/${id}`);
export const deleteCarteraUsuario = (id, rut) =>
  axios.put(`${REQUEST_URL}/deleteCarteraUsuario/${id}/${rut}`);
export const putCarteraUsuario = (id, idCli) =>
  axios.put(`${REQUEST_URL}/putCarteraUsuario/${id}/${idCli}`);
export const putCarteraTiendaUsuario = (id, cl) =>
  axios.put(`${REQUEST_URL}/putCarteraTiendaUsuario/${id}/${cl}`);
export const deleteTiendaUsuario = (id, cl) =>
  axios.put(`${REQUEST_URL}/deleteTiendaUsuario/${id}/${cl}`);
