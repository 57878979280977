import React from "react";
import {Badge} from "react-bootstrap";

export const EstVisColFormatter = (
    cellContent,
    row,
    rowIndex
) => {

    return (
        <>
            {row.id_est_visita===1 && <Badge variant="success">{cellContent}</Badge>}
            {row.id_est_visita===2 && <Badge variant="warning">{cellContent}</Badge>}
            {row.id_est_visita===3 && <Badge variant="danger">{cellContent}</Badge>}
            {row.id_est_visita===4 && <Badge variant="danger">{cellContent}</Badge>}
        </>
    );

}
