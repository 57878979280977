import React, { useState } from "react";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import * as requestFromServer from "../../helpers/axios";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { xls_to_Arr, xls_to_json } from "../../helpers/XLSHelpers";
import { addAlert } from "../../../redux/alerts/alertsRedux";
import { useDispatch } from "react-redux";
import { generatePlantillaMetasXls } from "./xls/xls_create";

export const ModalCargaMetas = props => {
  const { fetchClientes, show, setShow, year, month } = props;
  const dispatch = useDispatch();

  const [filename, setFilename] = useState(null);
  const [dataMasterPlan, setDataMasterPlan] = useState([]);
  const [xlsLoading, setXlsLoading] = useState(false);
  const [progreso, setProgreso] = useState("");

  const handleXlsChange = ev => {
    const filename = ev.target.value.replace(/.*[\/\\]/, "");
    setFilename(filename);
    setXlsLoading(true);
    xls_to_Arr(ev).then(hojas => {
      if (hojas.length > 0) {
        const data = hojas[0].data;
        console.log(data);
        setDataMasterPlan(data);
      }
      setXlsLoading(false);
    });
  };

  const onHandleSaveCsv = () => {
    if (dataMasterPlan.length === 0) {
      dispatch(addAlert({ msg: "Por favor ingrese un documento xls" }));
      setXlsLoading(false);
      return;
    }
    setXlsLoading(true);
    let hasEmptyFields = false;
    if (!hasEmptyFields) {
      checkDataByParts()
        .then(() => sendDataByParts())
        .catch(e => {
          console.log(e);
          setXlsLoading(false);
          if (e === 1062)
            dispatch(
              addAlert({
                msg: "Existen codigos sap repetidos"
              })
            );
        });
    }
  };

  const checkDataByParts = async () => {
    /*const QueryParams = dataMasterPlan.map(x => (
            x["COD SAP *"]
        ));
        let seen = new Set(QueryParams);
        const foundDuplicated = seen.size !== QueryParams.length;

        if (foundDuplicated) {
            throw 1062
        }*/
    return true;
  };

  const sendDataByParts = async () => {
    let errores = false;
    const QueryParams = [];
    dataMasterPlan.forEach((x, index) => {
      if (index !== 0 && x[0] !== undefined) {
        if (x[1])
          QueryParams.push({
            id_area: 1,
            nombre: x[0].trim(),
            meta_cantidad: Math.round(x[1]),
            meta_year: year,
            meta_month: month
          });
        if (x[2])
          QueryParams.push({
            id_area: 2,
            nombre: x[0].trim(),
            meta_cantidad: Math.round(x[2]),
            meta_year: year,
            meta_month: month
          });
        if (x[3])
          QueryParams.push({
            id_area: 3,
            nombre: x[0].trim(),
            meta_cantidad: Math.round(x[3]),
            meta_year: year,
            meta_month: month
          });
      }
    });
    setProgreso("0/" + QueryParams.length);
    for (var i = 0; i < QueryParams.length; i += 100) {
      await requestFromServer
        .UploadMetasCargaMasiva(QueryParams.slice(i, i + 100))
        .catch(e => {
          errores = true;
          setXlsLoading(false);
          dispatch(
            addAlert({
              msg: "Ocurrió un error al momento de cargar el xls"
            })
          );
        });
      setProgreso(i + "/" + QueryParams.length);
      if (errores) break;
    }
    if (!errores) {
      setProgreso(QueryParams.length + "/" + QueryParams.length);
      dispatch(
        addAlert({
          msg: "XLS cargado satisfactoriamente"
        })
      );
      fetchClientes();
      setXlsLoading(false);
      setProgreso("");
      setShow(false);
    }
  };

  const generatePlantilla = () => {
    setXlsLoading(true);
    requestFromServer
      .getUsuarios({ filter: { id_perfil: 3 } })
      .then(response => {
        if (response.data) {
          generatePlantillaMetasXls(response.data.entities);
        }
        setXlsLoading(false);
      });
  };
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        {xlsLoading && <ModalProgressBar variant="query" />}
        <Modal.Header>
          <Modal.Title>Carga Masiva Metas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!xlsLoading && (
            <>
              <Form.Group controlId="exampleForm.ControlInput2" as={Col}>
                <Form.Label>&nbsp; </Form.Label>
                <Form.File
                  id="file"
                  label={filename ? filename : "Seleccionar XLS"}
                  data-browse="Buscar"
                  onChange={handleXlsChange}
                  custom
                  name={"file"}
                  disabled={xlsLoading}
                />
              </Form.Group>
            </>
          )}
          {xlsLoading && (
            <>
              <div align={"center"}>
                <span>
                  <b>{progreso}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                </span>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-secondary ml-1 mr-auto"
            onClick={handleClose}
            disabled={xlsLoading}
          >
            Cerrar
          </Button>
          <Button
            className="btn btn-success ml-1"
            onClick={generatePlantilla}
            disabled={xlsLoading}
          >
            {" "}
            Descargar Plantilla
          </Button>
          <Button
            className="btn btn-success ml-1"
            onClick={onHandleSaveCsv}
            disabled={!filename || xlsLoading}
          >
            {" "}
            Comenzar Carga XLS
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
