/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "react-redux";
import { AdminMenuListAdmin } from "./AsideMenuListAdmin";
import { AsideMenuListRepresentante } from "./AsideMenuListRepresentante";

export function AsideMenuList({ layoutProps }) {
  const { user } = useSelector(state => state.auth);
  return (
    <>
      {/* begin::Menu Nav */}

      {user.id_perfil === 2 && <AdminMenuListAdmin layoutProps={layoutProps} />}
      {user.id_perfil === 3 && (
        <AsideMenuListRepresentante layoutProps={layoutProps} />
      )}
      {/* end::Menu Nav */}
    </>
  );
}
