var today = new Date();
export const InitialFilter = {
  tecnico: "",
  cliente: "",
  direccion: "",
  id: "",
  fecDesdeAsigna: new Date(today.getFullYear(), today.getMonth(), 1),
  fecHastaAsigna: new Date(today.getFullYear(), today.getMonth() + 1, 0),
  eliminado: false
};
