import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { SketchPicker } from "react-color";
import { Checkbox } from "@material-ui/core";

export const FormTipoVisita = props => {
  const {
    tipoVisita,
    setTipoVisita,
    sendTipoVisitaToServer,
    newEntity,
    btnRef
  } = props;
  const [validated, setValidated] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const onChangeNombre = e =>
    setTipoVisita({ ...tipoVisita, mv_descripcion: e.target.value });
  const onChangeColor = color =>
    setTipoVisita({ ...tipoVisita, mv_color_code: color.hex });
  const onChangeEliminado = e =>
    setTipoVisita({ ...tipoVisita, eliminado: e.target.checked });
  const onChangeOcultar = e =>
    setTipoVisita({ ...tipoVisita, mv_ocultar_a_cliente: e.target.checked });
  const onChangePermitirIngresoCli = e =>
    setTipoVisita({ ...tipoVisita, mv_visible_cliente: e.target.checked });

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      sendTipoVisitaToServer();
    }
    setValidated(true);
  };

  const handleColorPicker = () => setShowColorPicker(!showColorPicker);

  useEffect(() => {});

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Row>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col}>
            <Form.Label>Nombre:</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeNombre}
              value={tipoVisita.mv_descripcion}
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput2" as={Col}>
            <Form.Label>Color:</Form.Label>
            <Row>
              <Col xs={"auto"}>
                <Button variant={"secondary"} onClick={handleColorPicker}>
                  Seleccionar Color
                </Button>
              </Col>
              <Col>
                <div
                  style={{
                    backgroundColor: tipoVisita.mv_color_code,
                    width: 40,
                    height: 40
                  }}
                >
                  &nbsp;
                </div>
              </Col>
              <Col xs={12}>
                {showColorPicker && (
                  <SketchPicker
                    color={tipoVisita.mv_color_code}
                    onChange={onChangeColor}
                    disableAlpha={true}
                  />
                )}
              </Col>
            </Row>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          {!newEntity && (
            <Form.Group controlId="exampleForm.ControlInput2" as={Col}>
              <Form.Label>Eliminado</Form.Label>
              <Checkbox
                checked={Boolean(tipoVisita.eliminado)}
                onChange={onChangeEliminado}
                style={{ verticalAlign: "middle" }}
                inputProps={{
                  "aria-label": "primary checkbox"
                }}
              />
            </Form.Group>
          )}
        </Form.Row>

        <Button type="submit" ref={btnRef} style={{ display: "none" }} />
      </Form>
    </>
  );
};
