import React, { useState } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls'
import { TableReporte } from './components/TableReporte'
import { Filters } from './components/filter_comps/Filters'
import { Button } from 'react-bootstrap'
import { useFetchReps } from './hooks/useFetchReps'

export const RRankingPage = () => {

  const [queryParams, setQueryParams] = useState({})

  const { loading, fetch: fetchRep, reporte, handleXls } = useFetchReps(
    { queryParams })

  return (
    <Card>
      <CardHeader title={'Informe Ranking'}>
        <CardHeaderToolbar>
          <Button onClick={handleXls} disabled={loading}>
            {loading ? 'Cargando ...' : 'XLS'}
          </Button>
          &nbsp;
          <Button disabled={loading} type="button"
                  onClick={fetchRep}>
            {loading ? 'Cargando ...' : 'Buscar'}
          </Button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Filters queryParams={queryParams} setQueryParams={setQueryParams}/>
        {reporte && <TableReporte reporte={reporte}/>}
      </CardBody>
    </Card>
  )
}

