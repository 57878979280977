import React, { useRef, useState } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import { Button } from "react-bootstrap";
import { FormAddEditVisita } from "../../components/visitas/FormAddEditVisita";

export const AddVisita = ({ match }) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Agregar Requerimiento");
  const { params } = match;
  const { id_ticket } = params;
  const btnRef = useRef();

  let cardTitle = `Ingresar Requerimiento`;
  const onHandleIn = () => btnRef.current.click();

  const [loading, setLoading] = useState(false);

  return (
    <>
      <Card>
        <CardHeader title={cardTitle}>
          <CardHeaderToolbar>
            {!loading && (
              <Button type="button" variant={"success"} onClick={onHandleIn}>
                {"Ingresar Visita"}
              </Button>
            )}
            {loading && (
              <span
                className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
              />
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <FormAddEditVisita
            id_ticket={id_ticket}
            setLoading={setLoading}
            btnRef={btnRef}
            newEntitie={true}
          />
        </CardBody>
      </Card>
    </>
  );
};
