import { makeStyles } from "@material-ui/core";

export const useStylesMaterial = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginBottom: 5
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

