import React from "react";
import { NumericFormat } from "react-number-format";

export function RowTablaInformeRepresentante({
  prod,
  lastYear,
  thisYear,
  varPercentage,
  prefix,
  clastYear,
  cthisYear,
  cvarPercentage,
  asplastYear,
  aspthisYear,
  aspvarPercentage,
}) {
  const evaluateIfIntinity = value => {
    if (value === Infinity) return 100;
    else return value;
  };
  return (
    <div style={styles.row}>
      <div style={{ ...styles.col }}>
        <h3
          style={{
            ...styles.title2,
            textAlign: "center",
            fontWeight: "bold"
          }}
        >
          {prod}
        </h3>
      </div>


      <div style={{ ...styles.col }}>
        <NumericFormat
          value={isNaN(cthisYear) ? 0 : Math.round(cthisYear)}
          displayType={"text"}
          decimalSeparator={","}
          thousandSeparator={"."}
          prefix={prefix}
          renderText={value => <h3 style={styles.title2}>{value}</h3>}
        />
      </div>
      <div style={{ ...styles.col }}>
        <NumericFormat
          value={isNaN(clastYear) ? 0 : Math.round(clastYear)}
          displayType={"text"}
          decimalSeparator={","}
          thousandSeparator={"."}
          prefix={prefix}
          renderText={value => <h3 style={styles.title2}>{value}</h3>}
        />
      </div>
      <div style={{ ...styles.col }}>
        <h3
          style={{
            ...styles.title2,
            color: cvarPercentage < 0 ? "red" : "blue"
          }}
        >
          {isNaN(cvarPercentage)
            ? `0%`
            : `${evaluateIfIntinity(Math.round(cvarPercentage*100)/100)}%`}
        </h3>
      </div>


      <div style={{ ...styles.col }}>
        <NumericFormat
          value={isNaN(thisYear) ? 0 : Math.round(thisYear)}
          displayType={"text"}
          decimalSeparator={","}
          thousandSeparator={"."}
          prefix={prefix}
          renderText={value => <h3 style={styles.title2}>{value}</h3>}
        />
      </div>
      <div style={{ ...styles.col }}>
        <NumericFormat
          value={isNaN(lastYear) ? 0 : Math.round(lastYear)}
          displayType={"text"}
          decimalSeparator={","}
          thousandSeparator={"."}
          prefix={prefix}
          renderText={value => <h3 style={styles.title2}>{value}</h3>}
        />
      </div>
      <div style={{ ...styles.col }}>
        <h3
          style={{
            ...styles.title2,
            color: varPercentage < 0 ? "red" : "blue"
          }}
        >
          {isNaN(varPercentage)
            ? `0%`
            : `${evaluateIfIntinity(Math.round(varPercentage*100)/100)}%`}
        </h3>
      </div>





      <div style={{ ...styles.col }}>
        <NumericFormat
          value={isNaN(aspthisYear) ? 0 : Math.round(aspthisYear)}
          displayType={"text"}
          decimalSeparator={","}
          thousandSeparator={"."}
          prefix={prefix}
          renderText={value => <h3 style={styles.title2}>{value}</h3>}
        />
      </div>
      <div style={{ ...styles.col }}>
        <NumericFormat
          value={isNaN(asplastYear) ? 0 : Math.round(asplastYear)}
          displayType={"text"}
          decimalSeparator={","}
          thousandSeparator={"."}
          prefix={prefix}
          renderText={value => <h3 style={styles.title2}>{value}</h3>}
        />
      </div>
      <div style={{ ...styles.col }}>
        <h3
          style={{
            ...styles.title2,
            color: aspvarPercentage < 0 ? "red" : "blue"
          }}
        >
          {isNaN(aspvarPercentage)
            ? `0%`
            : `${evaluateIfIntinity(Math.round(aspvarPercentage*100)/100)}%`}
        </h3>
      </div>
    </div>
  );
}
const styles = {
  col: {
    width: "25%"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignSelf: "stretch",
    paddingHorizontal: 10
  },
  title2: {
    color: "#000000",
    fontSize: 12,
    textAlign: "center",
    marginTop: 12,
    fontWeight: "bold"
  }
};
