import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import { Button, Form } from "react-bootstrap";
import * as requestFromServer from "../../helpers/axios";

import { TablaInfRepMarca } from "./components/TablaInfRepMarca";
import { TablaInfRepTipo } from "./components/TablaInfRepTipo";
import { FilterUsuario } from "./components/FilterUsuario";
import { FilterFec } from "./components/FilterFec";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { addAlert } from "../../../redux/alerts/alertsRedux";
export const InformeRepresentantes = () => {
  const { user } = useSelector(state => state.auth);
  const [informe, setInforme] = React.useState({});
  const [loadingData, setLoadingData] = React.useState(false);

  const [usuarioSelected, setUsuarioSelected] = React.useState(
    user.id_perfil === 3 ? { value: user.id, label: user.fullname } : {}
  );
  const dispatch = useDispatch();
  const [fecdesde, setFecdesde] = useState();
  const [fechasta, setFechasta] = useState();

  let cardTitle = "Informe Representantes";

  const fetchInforme = async () => {
    setLoadingData(true);
    if (validarFiltros())
      requestFromServer
        .getVtsInformeRep({
          id_rep: usuarioSelected.value,
          fec_desde: moment(fecdesde).format("YYYY-MM-DD"),
          fec_hasta: moment(fechasta).format("YYYY-MM-DD")
        })
        .then(informe => {
          setInforme({ ...informe.data });
        })
        .finally(() => {
          setLoadingData(false);
        });
    else setLoadingData(false);
  };

  const validarFiltros = () => {
    if (!usuarioSelected.value) {
      dispatch(addAlert({ msg: "Debe seleccionar un representante" }));
      return false;
    }
    if (moment(fecdesde).isAfter(fechasta)) {
      dispatch(
        addAlert({ msg: "La fecha desde debe ser menor a la fecha hasta" })
      );
      return false;
    }
    return true;
  };

  const handleButtonClick = () => {
    fetchInforme();
  };

  useEffect(() => {
    setFechasta(
      moment()
        .endOf("day")
        .toDate()
    );
    setFecdesde(
      moment()
        .startOf("month")
        .toDate()
    );
  }, []);

  return (
    <>
      <Card>
        <CardHeader title={cardTitle}>
          <CardHeaderToolbar>
            {!loadingData && (
              <Button type="button" onClick={handleButtonClick}>
                {"Buscar"}
              </Button>
            )}
            {loadingData && (
              <span
                className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
              />
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-sm-12 col-md">
              <FilterFec
                label="Fec Desde"
                value={fecdesde}
                setFec={setFecdesde}
                disabled={loadingData}
              />
            </div>
            <div className="col-sm-12 col-md">
              <FilterFec
                label="Fec Hasta"
                value={fechasta}
                setFec={setFechasta}
                disabled={loadingData}
              />
            </div>
            <div className="col-sm-6 col-md">
              <FilterUsuario
                usuarioSelected={usuarioSelected}
                setUsuarioSelected={setUsuarioSelected}
                disabled={user.id_perfil === 3 || loadingData}
              />
            </div>
          </div>
          <Form.Row>
            <div className="col-sm-12 col-md">
              {informe.eyewearMarcas && (
                <TablaInfRepMarca
                  informe={informe.eyewearMarcas}
                  dateSelected={fechasta}
                />
              )}
              {informe.lensesMarcas && (
                <TablaInfRepMarca
                  informe={informe.lensesMarcas}
                  dateSelected={fechasta}
                />
              )}
              {informe.tipos && (
                <TablaInfRepTipo
                  informe={informe.tipos}
                  dateSelected={fechasta}
                />
              )}
            </div>
          </Form.Row>
        </CardBody>
      </Card>
    </>
  );
};
