import ExcelJS from "exceljs";
import * as _ from "lodash";
import * as FileSaver from "file-saver";

const generateNormalSheet = (wb, sheetData, validations) => {
  const ws = wb.addWorksheet(sheetData.sheetName, {
    views: [{ showGridLines: false }]
  });
  let headers = [];
  for (const dataRow of sheetData.data)
    Object.keys(dataRow).forEach(x => {
      headers.push(x);
    });
  headers = _.uniq(headers);
  ws.columns = headers.map(x => ({ header: x, key: x }));
  ws.getColumn(3).numFmt = "#,##";
  ws.getColumn(4).numFmt = "#,##0.00";
  ws.getColumn(5).numFmt = "#,##0.00";
  for (const dataRow of sheetData.data) ws.addRow(dataRow);
  for (const hname of headers)
    ws.getRow(1).getCell(hname).style = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FEFEA5" }
      },
      border: {
        top: { style: "medium", color: { argb: "000000" } },
        left: { style: "medium", color: { argb: "000000" } },
        bottom: { style: "medium", color: { argb: "000000" } },
        right: { style: "medium", color: { argb: "000000" } }
      }
    };
};
const generateSheetRepMarketing = (wb, sheetData, headers) => {
  const ws = wb.addWorksheet(sheetData.sheetName, {
    views: [{ showGridLines: false }]
  });
  ws.columns = headers.map(x => ({ header: x, key: x }));

  for (const dataRow of sheetData.data) ws.addRow(dataRow);
  for (const hname of headers)
    ws.getRow(1).getCell(hname).style = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FEFEA5" }
      },
      border: {
        top: { style: "medium", color: { argb: "000000" } },
        left: { style: "medium", color: { argb: "000000" } },
        bottom: { style: "medium", color: { argb: "000000" } },
        right: { style: "medium", color: { argb: "000000" } }
      }
    };
};
const generateSheet = (wb, sheetData, validations) => {
  const ws = wb.addWorksheet(sheetData.sheetName, {
    views: [{ showGridLines: false }]
  });
  let headers = [];
  for (const dataRow of sheetData.data)
    Object.keys(dataRow).forEach(x => {
      headers.push(x);
    });
  headers = _.uniq(headers);
  ws.columns = headers.map(x => ({ header: x, key: x }));
  for (const dataRow of sheetData.data) ws.addRow(dataRow);
  for (const hname of headers)
    ws.getRow(1).getCell(hname).style = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FEFEA5" }
      },
      border: {
        top: { style: "medium", color: { argb: "000000" } },
        left: { style: "medium", color: { argb: "000000" } },
        bottom: { style: "medium", color: { argb: "000000" } },
        right: { style: "medium", color: { argb: "000000" } }
      }
    };
};
export const exportToExcelPlantillaVisJs = async (data, fileName) => {
  const wb = new ExcelJS.Workbook();
  for (const sheetData of data) generateNormalSheet(wb, sheetData);

  const buf = await wb.xlsx.writeBuffer();
  const fileExtension = ".xlsx";
  FileSaver.saveAs(new Blob([buf]), fileName + fileExtension);
};

export const exportToExcelPlantillaMarketingJs = async (
  data,
  fileName,
  headers
) => {
  const wb = new ExcelJS.Workbook();
  for (const sheetData of data)
    generateSheetRepMarketing(wb, sheetData, headers);

  const buf = await wb.xlsx.writeBuffer();
  const fileExtension = ".xlsx";
  FileSaver.saveAs(new Blob([buf]), fileName + fileExtension);
};

export const exportToExcelPlantillaJs = async (data, fileName) => {
  const wb = new ExcelJS.Workbook();
  for (const sheetData of data) generateSheet(wb, sheetData);

  const buf = await wb.xlsx.writeBuffer();
  const fileExtension = ".xlsx";
  FileSaver.saveAs(new Blob([buf]), fileName + fileExtension);
};
