import * as React from "react";
import {
  Card,
  CardBody,
  CardHeader
} from "../../../../_metronic/_partials/controls";
import { StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import parse from "html-react-parser";

export const CardEventoOutlook = props => {
  const { visita } = props;

  return (
    <>
      <Card>
        <CardHeader title={`Info Outlook`} />
        <CardBody>
          <div style={{ margin: 15, flex: 1, alignContent: "flex-start" }}>
            <div className={"row"}>
              <div className="col-sm-2">
                <b style={styles.textLabel}>Fecha </b>
              </div>
              <div className="col-sm-4">
                <b style={styles.textInfo}>
                  {" "}
                  {visita.evento_inicio
                    ? moment(visita.evento_inicio).format("DD/MM/YYYY HH:mm")
                    : ""}
                </b>
              </div>
            </div>
            <div className={"row"} style={{ marginTop: 15 }}>
              <div className="col-sm-2">
                <b style={styles.textLabel}>Asunto</b>
              </div>
              <div className="col-sm-10">
                <b style={styles.textInfo}>{visita.evento_asunto}</b>
              </div>
            </div>

            <div className={"row"} style={{ marginTop: 15 }}>
              <div className="col-sm-2">
                <b style={styles.textLabel}>Notas</b>
              </div>
              <div className="col-sm-10">
                {visita.evento_notas ? parse(visita.evento_notas) : ""}
              </div>
            </div>

            <div className={"row"} style={{ marginTop: 15 }}>
              <div className="col-sm-2">
                <b style={styles.textLabel}>Participantes</b>
              </div>
              <div className="col-sm-10">
                <b style={styles.textInfo}>{visita.evento_participantes}</b>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

const styles = StyleSheet.create({
  textLabel: { color: "#B5B5C3" },
  textInfo: { color: "#7D7D7D" }
});
