import Select from "react-select";
import React from "react";
export const SegmentoFilter = ({ value, onChange }) => {
  return (
    <div className="col-sm-6 col-md-2">
      <Select
        options={[
          { label: "A", value: "A" },
          { label: "B", value: "B" },
          { label: "C", value: "C" },
          { label: "D", value: "D" },
          { label: "E", value: "E" }
        ]}
        closeMenuOnSelect={true}
        isMulti
        onChange={onChange}
        value={value}
        placeholder={""}
      />

      <small className="form-text text-muted">
        <b>Filtrar</b> por segmento
      </small>
    </div>
  );
};
