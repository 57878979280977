import axios from "axios";
import {config} from "./CnxConstants"
const baseURL =config.API_URL


axios.defaults.baseURL = baseURL;
axios.defaults.timeout = 120*1000; // 2 minutes

export default function setupAxios(axios, store) {

    axios.interceptors.request.use(
        config => {
            const {
                auth: {authToken}
            } = store.getState();

            if (authToken) {
                config.headers.Authorization = `Bearer ${authToken}`;
            }

            return config;
        },
        err => Promise.reject(err)
    );
}
