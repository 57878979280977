import React from "react";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {checkIsActive, toAbsoluteUrl} from "../../../../_helpers";
import {useLocation} from "react-router";
import {useSelector} from "react-redux";

export const AsideMenuItem = ({
                                  url,
                                  hasSubmenu,
                                  label,
                                  pathIcon,
                                  urlBase,
                                  permissions
                              }) => {
    const {user} = useSelector(state => state.auth);
    const isAuth =
        user &&
        permissions?.reduce(
            (acc, curr) => acc || (user.permisos && user.permisos.includes(curr)),
            false,
        )
    const location = useLocation();

    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };
    return (
        <>
            {isAuth && (
                <li
                    className={`menu-item ${getMenuItemActive(
                        urlBase ? urlBase : url,
                        hasSubmenu
                    )}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to={url}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(pathIcon)}/>
            </span>
                        <span className="menu-text">{label}</span>
                    </NavLink>
                </li>
            )}
        </>
    );
};
