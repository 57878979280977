import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {mbox_accesstoken} from "../components/MapBoxConstant";
import * as requestFromServer from "../../helpers/axios";
import ReactMapboxGl, {Layer, Feature, Popup} from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {getBounds} from "../components/mapas/BoundsCalc";
import {makeStyles} from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AllOutIcon from "@material-ui/icons/AllOut";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    Pagination
} from "../../../_metronic/_partials/controls";
import {Link} from "react-router-dom";
import {Button, Row} from "react-bootstrap";
import {HoldingFilter} from "../direcciones/table_helpers/HoldingFilter";
import SelectAsyncPaginationCli from "../components/AsyncSelects/SelectAsyncPaginationCli";
import {InitialFilter} from "../direcciones/table_helpers/InitialFilters";
import {initialTableFilter} from "../../helpers/TableHelpers";
import {isEqual, isFunction} from "lodash";
import CloseIcon from '@material-ui/icons/Close';
import {IconButton} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    fab: {
        position: "absolute",
        zIndex: 10,
        display: "flex",
        flexDirection: "column",
        top: "auto",
        right: 10,
        bottom: 40,
        left: "auto"
    },

    extendedIcon: {
        marginRight: theme.spacing(1)
    }
}));

export const MapaClientes = () => {
    const classes = useStyles();
    const btnOpenRef = useRef();
    const [users, setUsers] = useState([]);
    const [userSelected, setUserSelected] = useState(undefined);
    const [filters, setFilters] = useState(InitialFilter);
    const [holdingSelected, setHoldingSelected] = useState({});
    const [queryParams, setQueryParamsBase] = useState(initialTableFilter);

    const initParamsMap = {
        center: [-70.669265, -33.44889],
        zoom: [10],
        fitBounds: undefined
    };
    const [mapProps, setMapProps] = useState(initParamsMap);

    const flyToOptions = {
        speed: 0.6
    };

    const usr_circle_ok = {
        "circle-stroke-width": 4,
        "circle-radius": 10,
        "circle-blur": 0.15,
        "circle-color": "#3770C6",
        "circle-stroke-color": "white"
    };
    const usr_circle_nook = {
        "circle-stroke-width": 4,
        "circle-radius": 10,
        "circle-blur": 0.15,
        "circle-color": "#ca0009",
        "circle-stroke-color": "white"
    };
    const Map = useMemo(() => {
        return ReactMapboxGl({
            accessToken: mbox_accesstoken
        });
    }, []);

    const getUsersPos = async () =>
        requestFromServer
            .getDirsPos(queryParams)
            .then(response => {
                if (response.data) {
                    const ents = response.data.entities.filter(x => x.pos_lat && x.pos_lon);
                    setUsers(ents);
                    const bounds = getBounds(ents.map(x => [x.pos_lon, x.pos_lat]));
                    setMapProps({...mapProps, fitBounds: bounds});
                } else {
                    setUsers([])
                    setMapProps(initParamsMap)
                }
            })
            .catch(err => console.log(err));

    const centerMap = async () => {
        const bounds = getBounds(users.map(x => [x.pos_lon, x.pos_lat]));
        setMapProps({...mapProps, fitBounds: bounds});
    }

    const onClickTec = id_tec => {
        const user = users.find(x => x.id === id_tec);

        setMapProps({
            ...mapProps,
            center: [user.pos_lon, user.pos_lat],
            zoom: [14],
            fitBounds: undefined
        });
        setUserSelected(user);
    };
    const handleChangeHolding = e => {
        setFilters({...filters, id_grupc: e.value});
    };
    const onClickCerrarPopUP = e => {
        setUserSelected(false);
    };
    const handleChangeCliente = e => {
        setHoldingSelected(e);
        setFilters({...filters, id_clientes: e.value});
    };
    useEffect(() => {
        const newQueryParams = prepareFilter(queryParams);
        setQueryParams({...newQueryParams, pageNumber: 1});
    }, [filters]);
    const prepareFilter = queryParams => {
        const newQueryParams = {...queryParams};
        const filter = {};
        filter["id_grupc"] = filters.id_grupc ? filters.id_grupc : undefined;
        filter["id_clientes"] =
            filters.id_clientes !== "" ? filters.id_clientes : undefined;

        newQueryParams.filter = filter;
        return newQueryParams;
    };

    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }
            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }
            return nextQueryParams;
        });
    }, []);

    return (
        <>
            <Card>
                <CardHeader title="Filtros Mapa Cliente">
                    <CardHeaderToolbar>
                        <Button className="btn btn-primary ml-1" onClick={getUsersPos}>
                            {" "}
                            Buscar
                        </Button>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <div className="form-group row">
                        <HoldingFilter onChange={handleChangeHolding}/>
                        <SelectAsyncPaginationCli
                            value={holdingSelected}
                            holding={filters.id_grupc}
                            onChange={handleChangeCliente}
                            md={6}
                            isFilter={true}
                        />
                    </div>
                </CardBody>
            </Card>


            <Map
                style="mapbox://styles/mapbox/streets-v11"
                containerStyle={{
                    height: "100%",
                    width: "100%"
                }}
                {...mapProps}
                fitBoundsOptions={{padding: 20}}
                flyToOptions={flyToOptions}
            >
                <Layer type="circle" id="marker" paint={usr_circle_ok}>
                    {users?.map(x => (
                        <Feature
                            key={`p-${x.id}`}
                            coordinates={[x.pos_lon, x.pos_lat]}
                            onClick={() => onClickTec(x.id)}
                        />
                    ))}
                </Layer>


                {userSelected && (
                    <Popup key={userSelected.id} coordinates={mapProps.center}>
                        <IconButton onClick={onClickCerrarPopUP} style={{float: "right"}} aria-label="Cerrar">
                            <CloseIcon/>
                        </IconButton>

                        <div>{userSelected.fullname}</div>
                        <div>
                            {userSelected.direcciones_nombre + " - " + userSelected.codAlt}
                        </div>
                    </Popup>
                )}


                <Fab
                    color="secondary"
                    size="small"
                    style={{bottom: "10px"}}
                    aria-label="users"
                    className={classes.fab}
                    onClick={centerMap}
                >
                    <AllOutIcon/>
                </Fab>
            </Map>
        </>
    );
};
