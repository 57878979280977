import React, { useCallback, useEffect, useState } from "react";
import { cloneDeep, isEqual, isFunction } from "lodash";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Pagination
} from "../../../_metronic/_partials/controls";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import { getColumns } from "./table_helpers/VisTipoTablecols";
import * as requestFromServer from "../../helpers/axios";
import { Link } from "react-router-dom";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from "../../../_metronic/_helpers";
import { TableFilters } from "./table_helpers/TableFilters";
import { initialTableFilter } from "../../helpers/TableHelpers";
import { PaginationTotalCustom } from "../_GlobalComponents/table/pagination/PaginationTotalCustom";
import { ModalEliminarTipoVisita } from "./components/ModalEliminarTipoVisita";

export const ListarTiposVisitas = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Tipos Visitas");

  const [tipoVisitas, setTipoVisitas] = useState(null);
  const [listLoading, setListLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialTableFilter);
  const [show, setShow] = useState(false);
  const [idTipoVisita, setIdTipoVisita] = useState(null);

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 }
    ],
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
    paginationTotalRenderer: PaginationTotalCustom
  };

  useEffect(() => {
    fetchTipoVisitas();
  }, [queryParams]);

  const fetchTipoVisitas = () => {
    const queryParameters = cloneDeep(queryParams);
    setListLoading(true);
    queryParameters.eliminado = false;
    requestFromServer
      .getTiposVisita(queryParameters)
      .then(response => {
        if (response.data) {
          setTipoVisitas(response.data.entities);
          setTotalCount(response.data.totalCount);
        } else {
          setTipoVisitas([]);
          setTotalCount(0);
        }

        setListLoading(false);
      })
      .catch(error => {
        console.log(error);
        setListLoading(false);
      });
  };

  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }
      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  return (
    <>
      <Card>
        <CardHeader title="Tipos Visitas Registrados">
          <CardHeaderToolbar>
            <ModalEliminarTipoVisita
              idTipoVisita={idTipoVisita}
              show={show}
              setShow={setShow}
              fetchTipoVisitas={fetchTipoVisitas}
            />
            <Link className="btn btn-primary ml-1" to={"/tiposVisitas/nuevo"}>
              {" "}
              Agregar
            </Link>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <TableFilters
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  isLoading={listLoading}
                  paginationProps={paginationProps}
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    remote
                    keyField={"id"}
                    data={tipoVisitas === null ? [] : tipoVisitas}
                    columns={getColumns(setShow, setIdTipoVisita)}
                    onTableChange={getHandlerTableChange(setQueryParams)}
                    filter={filterFactory()}
                    striped
                    hover
                    condensed
                    {...paginationTableProps}
                  >
                    <PleaseWaitMessage entities={tipoVisitas} />
                    <NoRecordsFoundMessage entities={tipoVisitas} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
    </>
  );
};
