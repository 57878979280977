import React from "react";
import { Col, Form } from "react-bootstrap";
export const InputTextVisita = props => {
  const { value, onChange, label } = props;
  const { xs, md, required } = props;
  return (
    <Form.Group controlId="exampleForm.ControlInput1" as={Col} xs={xs} md={md}>
      <Form.Label>
        {label} {required ? <span className={"text-danger"}>* </span> : ""}:
      </Form.Label>
      <Form.Control
        type={"text"}
        onChange={onChange}
        value={value}
        required={required}
      />
    </Form.Group>
  );
};
