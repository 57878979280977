import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import allLocales from '@fullcalendar/core/locales-all'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import { useHistory } from 'react-router-dom'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import './styles.css'
export const Calendario = props => {
  const history = useHistory()

  const {
    visitas,
    setVisitas,
    setQueryParams,
    queryParams,
    OnEventDrop,
    eventStartEditable,
    eventDurationEditable
  } = props

  const OnChangeDate = arg => {
    setVisitas([])
    setQueryParams({
      filter: {
        ...queryParams.filter,
        fecha: arg.view.activeStart
      }
    })
  }

  const handleMouseEnter = arg => {
    tippy(arg.el, {
      allowHTML: true,
      interactive: true,
      theme: 'light',
      title: 'Evento',
      content:
        '<b>Asunto: ' +
        arg.event._def.extendedProps.asunto +
        '</b>' +
        '<br>' +
        arg.event._def.extendedProps.representante +
        '<br>' +
        arg.event._def.extendedProps.tooltipCont +
        "<a href='/visitas/ver/" +
        arg.event._def.extendedProps.id_visita +
        "' target='_blank'>Ver Visita</a>",
      trigger: 'click',
      zIndex: 999999
    })
  }

  return (
    <FullCalendar
      plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
      editable={true}
      initialView="dayGridMonth"
      events={visitas}
      locales={allLocales}
      locale={'es'}
      datesSet={OnChangeDate}
      eventDrop={OnEventDrop}
      eventMouseEnter={handleMouseEnter}
      eventStartEditable={eventStartEditable}
      eventDurationEditable={eventDurationEditable}
      showNonCurrentDates={false}
      eventTimeFormat={
        // like '14:30:00'
        {
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false
        }
      }
    />
  )
}
