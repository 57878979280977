import { getListAction } from "./actions/getListAction";
import { deleteItemAction } from "./actions/deleteItemAction";
import { addItemAction } from "./actions/addItemAction";

const initialLicsState = {
  lics: [],
  loadingList: false,
  deletingItem: false,
  addingItem: false,
  totalLics: 0,
  isError: false
};

export const licsReducer = (state = initialLicsState, action) => {
  switch (action.type) {
    case "@addLic": {
      const { lic } = action.payload;
      return {
        ...state,
        lics: [...state.lics, lic],
        totalLics: state.totalLics + 1
      };
    }
    case "@removeLic": {
      const { id } = action.payload;
      console.log('eliminando ',id)
      return {
        ...state,
        lics: [...state.lics.filter(l=>l.id!==id)],
        totalLics: state.totalLics - 1
      };
    }
    case "@setLics": {
      const { lics, totalLics } = action.payload;
      return { ...state, lics: lics, totalLics: totalLics };
    }
    case "@setLoadingList": {
      const { loading } = action.payload;
      return { ...state, loadingList: loading };
    }
    case "@setDeletingItem": {
      const { loading } = action.payload;
      return { ...state, deletingItem: loading };
    }
    case "@setAddingItem": {
      const { loading } = action.payload;
      return { ...state, addingItem: loading };
    }
    case "@setIsError": {
      const { isError } = action.payload;
      return { ...state, isError: isError };
    }
    default:
      return state;
  }
};
export const actionsLics = {
  setLics: (lics, totalLics) => ({
    type: "@setLics",
    payload: { lics, totalLics }
  }),
  setLoadingList: loading => ({
    type: "@setLoadingList",
    payload: { loading }
  }),
  setDeletingItem: loading => ({
    type: "@setDeletingItem",
    payload: { loading }
  }),
  setAddingItem: loading => ({ type: "@setAddingItem", payload: { loading } }),
  addLic: lic => ({ type: "@addLic", payload: { lic } }),
  removeLic: id => ({ type: "@removeLic", payload: { id } }),
  setIsError: isError => ({ type: "@setIsError", payload: { isError } }),
  getList: getListAction,
  deleteItem: deleteItemAction,
  addItem: addItemAction
};
