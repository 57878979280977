import React from "react";
import { Subtitle2 } from "./Subtitle2";
import { StyleSheet, Text, Font, View, Image } from "@react-pdf/renderer";
import { Subtitle3 } from "./Subtitle3";

const styles = StyleSheet.create({
  textBody1: {
    fontSize: 9,
    textAlign: "left",
    padding: 4,
    fontFamily: "Roboto"
  },
  sectionRow: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between"
  },
  sectionRowImg: {
    flexDirection: "row",
    textAlign: "left",
    marginHorizontal: 5
  },
  sectionCol: {
    paddingHorizontal: 5
  },
  img: {
    width: 100,
    marginHorizontal: "1pt"
  }
});

export const TableGTec = ({ reporte }) => {
  return (
    <>
      <View>
        <View wrap={false}>
          <Subtitle2 label={"Problema detectado"} />
          <Subtitle3 label={reporte?.reporte_contenido?.problema_detectado} />
        </View>

        <View wrap={false}>
          <Subtitle2 label={"Procedimiento realizado"} />
          <Subtitle3
            label={reporte?.reporte_contenido?.procedimiento_realizado}
          />
        </View>

        <View wrap={false}>
          <Subtitle2 label={"Capacitaciones entregadas"} />
          <Subtitle3 label={reporte?.reporte_contenido?.cap_entregada} />
        </View>

        <View wrap={false}>
          <Subtitle2 label={"Recomendaciones asesor"} />
          <Subtitle3 label={reporte?.reporte_contenido?.recomendacion_asesor} />
        </View>
        <View wrap={false}>
          <Subtitle2 label={"Fotos"} />
          <View style={styles.sectionRowImg}>
            <Image
              style={styles.img}
              cache={false}
              src={
                reporte?.reporte_contenido?.foto1_filename +
                "?rnd=" +
                Math.random()
              }
              crossorigin="anonymous"
            />
            <Image
              style={styles.img}
              cache={false}
              src={
                reporte?.reporte_contenido?.foto2_filename +
                "?rnd=" +
                Math.random()
              }
              crossorigin="anonymous"
            />
            <Image
              style={styles.img}
              cache={false}
              src={
                reporte?.reporte_contenido?.foto3_filename +
                "?rnd=" +
                Math.random()
              }
              crossorigin="anonymous"
            />
            <Image
              style={styles.img}
              cache={false}
              src={
                reporte?.reporte_contenido?.foto4_filename +
                "?rnd=" +
                Math.random()
              }
              crossorigin="anonymous"
            />
          </View>
        </View>
        <View wrap={false}>
          <Subtitle2 label={"Encargado local"} />
          <Subtitle3 label={reporte?.reporte_contenido?.receptor_nombre} />
          <Subtitle2 label={"Cargo"} />
          <Subtitle3 label={reporte?.reporte_contenido?.receptor_cargo} />
          <Subtitle2 label={"Firma"} />
          <Image
            style={styles.img}
            cache={false}
            src={
              reporte?.reporte_contenido?.firma1_filename +
              "?rnd=" +
              Math.random()
            }
            crossorigin="anonymous"
          />
        </View>
        <View wrap={false}>
          <Subtitle2 label={"Firma representante Rodenstock"} />
          <Image
            style={styles.img}
            cache={false}
            src={
              reporte?.reporte_contenido?.firma2_filename +
              "?rnd=" +
              Math.random()
            }
            crossorigin="anonymous"
          />
        </View>
      </View>
    </>
  );
};
