import { getVisitas } from "../restFunctios/getVisitas";

export const getVisitasAction = queryParams => {
  return async dispatch => {
    dispatch({
      type: "@setLoadingVis",
      payload: { loading: true }
    });
    const { data, error } = await getVisitas(queryParams);
    console.log("data", data);
    if (data)
      dispatch({
        type: "@setVisitas",
        payload: { visitas: data.entities }
      });

    //if (error) dispatch(actionsLics.setIsError(true))

    dispatch({
      type: "@setLoadingVis",
      payload: { loading: false }
    });
  };
};
