import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { InitialVisitaState } from "./InitialVisitaState";
import * as requestFromServer from "../../../helpers/axios";
import { addAlert } from "../../../../redux/alerts/alertsRedux";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { InputSelTipoVisita } from "../InputSelTipoVisita";
import { InputFecVisita } from "../InputFecVisita";
import { InputObservaciones } from "../InputObservaciones";
import moment from "moment";
import { InputTextVisita } from "../InputTextVisita";
import { InputSelDireccion } from "../InputSelDireccion";
import { InputSelEstadoVis } from "../InputSelEstadoVis";
import SelectAsyncPaginationDir from "../AsyncSelects/SelectAsyncPaginationDir";
import SelectAsyncPaginationCli from "../AsyncSelects/SelectAsyncPaginationCli";
import SelectAsyncPaginationUsu from "../AsyncSelects/SelectAsyncPaginationUsu";
import SelectAsyncPaginationHolding from "../AsyncSelects/SelectAsyncPaginationHolding";

export const FormAddEditVisita = props => {
  const { setLoading, id_visita, btnRef, newEntitie } = props;
  const [visita, setVisita] = useState(InitialVisitaState);
  const [tipoVisitaSelected, setTipoVisitaSelected] = useState({});
  const [tecnicoSelected, setTecnicoSelected] = useState({});
  const [estadoSelected, setEstadoSelected] = useState({});
  const [dirSelected, setDirSelected] = useState({});
  const [subEstSelected, setSubEstSelected] = useState({});
  const [cliSelected, setCliSelected] = useState({});
  const [validated, setValidated] = useState(false);
  const [proSelected, setProSelected] = useState({});
  const [holdingSelected, setHoldingSelected] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(state => state.auth);
  const onChangeFecProgramada = date =>
    setVisita({ ...visita, visitas_fecvisita: date });
  const onChangeObservacion = e =>
    setVisita({ ...visita, visitas_obs: e.target.value });

  const onChangeTecnico = tec => {
    setTecnicoSelected(tec);
    setVisita({ ...visita, id_usu: tec.value });
  };

  const onChangeEstado = est => {
    setEstadoSelected(est);
    setVisita({ ...visita, id_est_visita: est.value });
  };

  const onChangeDir = dir => {
    setDirSelected(dir);
    setVisita({
      ...visita,
      v_cod_cl: dir.value,
      v_dir_nombre: dir.t_direccion,
      v_cli_nombre: dir.t_nombre_cliente,
      v_cli_rut: dir.t_rut_cliente
    });
  };

  const onChangeTipoVis = tv => {
    setTipoVisitaSelected(tv);
    setVisita({ ...visita, id_mv: tv.value });
  };

  const onHandleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (validateForm() && form.checkValidity()) {
      setLoading(true);
      if (visita.visitas_is_repejtipo1 === undefined)
        visita.visitas_is_repejtipo1 = true;
      if (visita.visitas_is_repejtipo2 === undefined)
        visita.visitas_is_repejtipo2 = true;
      if (visita.visitas_is_repejtipo3 === undefined)
        visita.visitas_is_repejtipo3 = true;
      if (visita.visitas_is_repejtipo4 === undefined)
        visita.visitas_is_repejtipo4 = true;
      if (visita.visitas_is_repejtipo5 === undefined)
        visita.visitas_is_repejtipo5 = true;
      if (visita.visitas_is_repejtipo6 === undefined)
        visita.visitas_is_repejtipo6 = true;
      if (visita.visitas_is_repejtipo7 === undefined)
        visita.visitas_is_repejtipo7 = true;
      if (visita.visitas_is_repejtipo8 === undefined)
        visita.visitas_is_repejtipo8 = true;

      const sendToServer = newEntitie
        ? requestFromServer.insertVisita(visita)
        : requestFromServer.updateVisita(id_visita, visita);
      sendToServer
        .then(response => {
          if (newEntitie)
            dispatch(
              addAlert({
                msg: "Visita " + response.data + " ingresada con éxito"
              })
            );
          else dispatch(addAlert({ msg: "Visita modificada con éxito" }));
          history.push("/visitas/listadmin");
        })
        .catch(error => console.log(error))
        .finally(() => setLoading(false));
    } else dispatch(addAlert({ msg: "Error, faltan datos por seleccionar" }));
    setValidated(true);
  };
  const validateForm = () => {
    let valido = true;
    if (!visita.visitas_fecvisita) valido = false;
    if (!visita.v_cod_cl) valido = false;
    if (!visita.id_usu || visita.id_usu.length === 0) valido = false;
    return valido;
  };

  useEffect(() => {
    if (newEntitie) {
      setVisita({
        ...visita,
        visitas_duracion_teorica_hrs: 1,
        id_est_visita: 2
      });
      setEstadoSelected({
        value: 2,
        label: "Pendiente"
      });
    } else
      requestFromServer
        .getVisita(id_visita)
        .then(response => {
          const { entitie } = response.data;
          setVisita({
            ...entitie,
            visitas_fecvisita: moment(entitie.visitas_fecvisita).toDate()
          });
          setTipoVisitaSelected({
            value: entitie.id_mv,
            label: entitie.mv_descripcion
          });
          setTecnicoSelected({
            value: entitie.id_usu,
            label: entitie.fullname_tec
          });
          setDirSelected({
            value: entitie.v_cod_cl,
            label: `${entitie.direcciones_nombre} - ${entitie.dir_coralt}`
          });
          setCliSelected({
            value: entitie.id_clientes,
            label: entitie.clientes_nombre
          });
          setSubEstSelected({
            value: entitie.id_est_eje,
            label: entitie.estado_ejecucion_nombre
          });

          setEstadoSelected({
            value: entitie.id_est_visita,
            label: entitie.est_visita_nombre
          });

          setProSelected({
            value: entitie.id_grupo_r,
            label: entitie.grupo_r_nombre
          });
        })
        .catch(err => console.log(err));
  }, []);

  return (
    <Form noValidate validated={validated} onSubmit={onHandleSubmit}>
      {user.id_perfil && (user.id_perfil === 2 || user.id_perfil === 10) && (
        <>
          <Form.Row>
            <InputFecVisita
              selected={visita.visitas_fecvisita}
              onChange={onChangeFecProgramada}
              required={true}
            />

            <SelectAsyncPaginationUsu
              value={tecnicoSelected}
              onChange={onChangeTecnico}
              md={7}
              isFilter={false}
              label={"Representante"}
              id_perfil={3}
            />
            <SelectAsyncPaginationDir
              cliente={visita.id_cliente}
              value={dirSelected}
              onChange={onChangeDir}
              md={5}
              required={true}
              isFilter={false}
            />
            <InputSelEstadoVis
              valueSelected={estadoSelected}
              onChange={onChangeEstado}
            />
          </Form.Row>
        </>
      )}
      <Form.Row>
        <InputObservaciones
          value={visita.visitas_obs}
          onChange={onChangeObservacion}
          required={true}
        />
      </Form.Row>
      {user.id_perfil && (user.id_perfil === 6 || user.id_perfil === 7) && (
        <>
          <Form.Row>
            <InputTextVisita label={"Nombre"} md={6} required={true} />
            <InputTextVisita label={"Teléfono"} md={6} required={true} />
          </Form.Row>
          <Form.Row>
            <InputTextVisita label={"Email"} md={6} required={true} />
            <InputSelTipoVisita
              valueSelected={tipoVisitaSelected}
              onChange={onChangeTipoVis}
              required={true}
              md={6}
            />{" "}
          </Form.Row>
          <Form.Row>
            <InputSelDireccion
              valueSelected={dirSelected}
              onChange={onChangeDir}
              id_cliente={visita.id_cliente}
              required={true}
              md={12}
            />
          </Form.Row>
        </>
      )}

      <Button type="submit" ref={btnRef} style={{ display: "none" }} />
    </Form>
  );
};
