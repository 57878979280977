import React, { useEffect, useState } from "react";
import moment from "moment";
import { Feature, Layer } from "react-mapbox-gl";

export function ListaUsuariosRBox(props) {
  const { btnOpenRef, users, map } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);

  const onClickTec = u_login => {
    const user = users.find(x => x.u_login === u_login);
    if (!user) return;
    map.flyTo([user.pos_lat, user.pos_lon], 16);
  };

  return (
    <div
      className={` offcanvas offcanvas-right p-5 pb-10 ${show &&
        "offcanvas-on"}`}
    >
      <h3 className={"mb-5"}>Lista Representantes</h3>
      <nav>
        {users.length > 0 && (
          <ul style={{ overflow: "auto", height: "100vh" }}>
            {users.map(x => (
              <li
                key={`li-${x.u_login}`}
                onClick={() => onClickTec(x.u_login)}
                className={`text-${
                  moment(x.pos_fecha).isAfter(moment().startOf("day"))
                    ? "primary"
                    : "danger"
                }`}
              >
                <small>{x.fullname}</small>
              </li>
            ))}
          </ul>
        )}
      </nav>
      <div
        className="offcanvas-close mt-n1 pr-5"
        style={{ position: "absolute", top: "15px", right: "10px" }}
      >
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={handleClose}
        >
          <i className="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <button
        type={"button"}
        className={"d-none"}
        onClick={handleOpen}
        ref={btnOpenRef}
      ></button>
    </div>
  );
}
