import React, { useCallback, useEffect, useState } from 'react'
import { cloneDeep, isEqual, isFunction } from 'lodash'
import {
  Card,
  CardHeader,
  CardBody,
  CardHeaderToolbar,
  Pagination
} from '../../../../_metronic/_partials/controls'
import paginationFactory, {
  PaginationProvider
} from 'react-bootstrap-table2-paginator'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from '../../../../_metronic/_helpers'
import { PaginationTotalCustom } from '../../_GlobalComponents/table/pagination/PaginationTotalCustom'
import { initialTableFilter } from '../../../helpers/TableHelpers'
import { getColumns } from '../table_helpers/CTTablecols'
import { ModalEliminarCT } from './ModalEliminarCT'
import { ModalAddCT } from './ModalAddCT'
import { CTFilters } from '../table_helpers/CTFilters'
import { useDispatch, useSelector } from 'react-redux'
import { actionsCarteraTiendas } from '../../../../redux/ctiendas/ctiendasRedux'
import { Button } from 'react-bootstrap'
import { generateCarteraXls } from '../xls/xls_create'
import { getCarteraTiendas } from '../../../../redux/ctiendas/restFunctios/getCarteraTiendas.js'

export const CarteraTiendas = ({ id, showAdd, setShowAdd, usuario }) => {
  const { loading, cTiendas } = useSelector(state => state.ctiendas)
  const [usuarios, setUsuarios] = useState(null)
  const [listLoading, setListLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [queryParams, setQueryParamsBase] = useState(initialTableFilter)
  const [show, setShow] = useState(false)
  const [rut, setRut] = useState(null)

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: [
      { text: '3', value: 3 },
      { text: '5', value: 5 },
      { text: '10', value: 10 }
    ],
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
    paginationTotalRenderer: PaginationTotalCustom
  }
  const dispatch = useDispatch()

  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams)
      }
      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams
      }
      return nextQueryParams
    })
  }, [])

  const fetchCarteraTiendas = () => {
    const queryParameters = cloneDeep(queryParams)
    queryParameters.filter.id_rep = id
    dispatch(actionsCarteraTiendas.getCarteraTiendas(queryParameters))
  }
  const handleXls = async () => {
    const q = { filter: { id_rep: id } }
    const { data, error } = await getCarteraTiendas(q)

    generateCarteraXls(data, usuario)
      .then()
      .catch(e => {
        console.log(e)
      })
      .finally(() => {})
  }

  useEffect(() => {
    fetchCarteraTiendas()
  }, [id])
  useEffect(() => {
    let localTiendas = cTiendas
    if (queryParams.filter.cl)
      localTiendas = localTiendas.filter(tienda =>
        tienda.ccli_cod_cl.includes(queryParams.filter.cl)
      )
    if (queryParams.filter.nombre)
      localTiendas = localTiendas.filter(tienda =>
        tienda.ccli_nombre_tienda.includes(queryParams.filter.nombre)
      )
    if (queryParams.filter.rut)
      localTiendas = localTiendas.filter(tienda =>
        tienda.ccli_rut_cliente.includes(queryParams.filter.rut)
      )
    setTotalCount(localTiendas.length)
    setUsuarios(
      localTiendas.slice(
        (queryParams.pageNumber - 1) * queryParams.pageSize,
        queryParams.pageSize * queryParams.pageNumber
      )
    )
  }, [cTiendas, queryParams.pageNumber, queryParams.pageSize])

  return (
    <>
      <Card>
        <CardHeader title={'Cartera Tiendas'}>
          <CardHeaderToolbar>
            {loading && <span className="spinner spinner-primary mr-1"></span>}
            {!loading && (
              <>
                <Button className="btn btn-success ml-1" onClick={handleXls}>
                  Exportar cartera
                </Button>
                &nbsp;
                <Button
                  className="btn btn-success ml-1"
                  onClick={() => {
                    setShowAdd(true)
                  }}
                >
                  Agregar Tienda
                </Button>
                &nbsp;
                <Button
                  className="btn btn-primary ml-1"
                  onClick={fetchCarteraTiendas}
                >
                  Buscar
                </Button>
              </>
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ModalEliminarCT
            show={show}
            setShow={setShow}
            idUsuario={id}
            rut={rut}
            fetchUsuarios={fetchCarteraTiendas}
          />
          <ModalAddCT
            show={showAdd}
            setShow={setShowAdd}
            idUsuario={id}
            fetchUsuarios={fetchCarteraTiendas}
          />
          <CTFilters
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  isLoading={listLoading}
                  paginationProps={paginationProps}
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    remote
                    keyField={'id'}
                    data={usuarios === null ? [] : usuarios}
                    columns={getColumns(setShow, setRut)}
                    onTableChange={getHandlerTableChange(setQueryParams)}
                    filter={filterFactory()}
                    striped
                    hover
                    condensed
                    {...paginationTableProps}
                  >
                    <PleaseWaitMessage entities={usuarios} />
                    <NoRecordsFoundMessage entities={usuarios} />
                  </BootstrapTable>
                </Pagination>
              )
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
    </>
  )
}
