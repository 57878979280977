import React, { useEffect, useState } from "react";
import { InitialFilter } from "./InitialFilters";
import { TextFilter } from "./TextFilter";
import { HoldingFilter } from "./HoldingFilter";
import { SegmentoFilter } from "../../visitas/table_helpers/filter_comps/SegmentoFilter";

export const TableFilters = props => {
  const { queryParams, setQueryParams } = props;

  const [filters, setFilters] = useState(InitialFilter);
  const prepareFilter = queryParams => {
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter["nombre"] =
      filters.clientes_nombre !== "" ? filters.clientes_nombre : undefined;
    filter["clientes_rut"] = filters.clientes_rut
      ? filters.clientes_rut
      : undefined;
    filter["segmento"] = filters.segmento ? filters.segmento : undefined;

    newQueryParams.filter = filter;
    return newQueryParams;
  };
  const handleChangeSegmento = segmento =>
    setFilters({ ...filters, segmento: segmento });
  const handleChangeNombre = e =>
    setFilters({ ...filters, clientes_nombre: e.target.value });
  const handleChangeRut = e => {
    setFilters({ ...filters, clientes_rut: e.target.value });
  };
  useEffect(() => {
    const newQueryParams = prepareFilter(queryParams);
    setQueryParams({ ...newQueryParams, pageNumber: 1 });
  }, [filters]);

  return (
    <div className="form-group row">
      <TextFilter
        onChange={handleChangeNombre}
        label={"nombre"}
        value={filters.direcciones_nombre}
      />
      <TextFilter
        onChange={handleChangeRut}
        label={"rut"}
        value={filters.clientes_rut}
      />
      <SegmentoFilter
        onChange={handleChangeSegmento}
        value={filters.segmento}
      />
    </div>
  );
};
