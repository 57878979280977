import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import * as requestFromServer from "../../helpers/axios";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import { useSelector } from "react-redux";
import WindowedSelect from "react-windowed-select";

export const InputSelDireccion = props => {
  const animatedComponents = makeAnimated();
  const { valueSelected, onChange, id_cliente, md } = props;
  const { required } = props;
  const [direcciones, setDirecciones] = useState([]);
  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    const queryParams = {
      filter: { id_clientes: undefined },
      eliminado: false
    };
    if (id_cliente) queryParams.filter.id_clientes = id_cliente;
    if (user.id_perfil && user.id_perfil === 6) {
      queryParams.filter.userClient = true;
      queryParams.filter.id_usu = user.id;
    }
    if (user.id_perfil && user.id_perfil === 7) {
      queryParams.filter.userHolding = true;
      queryParams.filter.id_usu = user.id;
    }
    requestFromServer
      .getDirecciones(queryParams)
      .then(response =>
        setDirecciones([
          ...response.data.entities.map(x => ({
            value: x.id,
            label: `${x.direcciones_nombre} - ${x.direcciones_texto} - ${x.codAlt}`
          }))
        ])
      )
      .catch(err => console.log(err));
  }, [id_cliente]);
  return (
    <>
      <Form.Group controlId="exampleForm.ControlInput1" as={Col} md={md}>
        <Form.Label>
          Dirección {required ? <span className={"text-danger"}>* </span> : ""}:
        </Form.Label>
        <WindowedSelect
          filterOption={createFilter({ ignoreAccents: false })}
          options={direcciones}
          closeMenuOnSelect={true}
          components={animatedComponents}
          value={valueSelected}
          onChange={onChange}
          placeholder={""}
          inputProps={{ required: required }}
        />
      </Form.Group>
    </>
  );
};
