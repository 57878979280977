import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export const ActionsDownloadColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  props
) => {
  const { deleteSolicitud } = props;
  return (
    <>
      {row.terminado && (
        <OverlayTrigger
          overlay={<Tooltip id="botis-delete-tooltip">Descargar</Tooltip>}
        >
          <a
            className="btn btn-icon btn-light btn-hover-warning btn-sm mr-1"
            href={row.url_aws}
            target="_blank"
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")} />
            </span>
          </a>
        </OverlayTrigger>
      )}
      {!row.terminado && (
        <OverlayTrigger
          overlay={<Tooltip id="botis-delete-tooltip">Procesando</Tooltip>}
        >
          <button
            className="btn btn-icon btn-light btn-hover-danger btn-sm mr-1"
            disabled={true}
          >
            <span className="svg-icon svg-icon-md svg-icon-warning">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Home/Alarm-clock.svg")}
              />
            </span>
          </button>
        </OverlayTrigger>
      )}
      <OverlayTrigger
        overlay={
          <Tooltip id="botis-delete-tooltip">Eliminar Solicitud</Tooltip>
        }
      >
        <button
          className="btn btn-icon btn-light btn-hover-danger btn-sm mr-1"
          onClick={e => {
            e.currentTarget.blur();
            deleteSolicitud(row.id);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </button>
      </OverlayTrigger>
    </>
  );
};
