import axios from "axios";

export const REQUEST_URL = "api/licencias";

export function getLicencias(queryParams) {
  return axios.get(`${REQUEST_URL}/`, { params: { queryParams } });
}

export const deleteLicencia = id => axios.delete(`${REQUEST_URL}/${id}`);
export const addLicencia = id => axios.post(`${REQUEST_URL}/${id}`);

