import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import * as requestFromServer from "../../../helpers/axios";
import { FormModalVentasCarga } from "./FormModalVentasCarga";
import { addAlert } from "../../../../redux/alerts/alertsRedux";
import { useDispatch } from "react-redux";
import moment from "moment";

export const ModalVentasCarga = ({
  show,
  setShow,
  fetchVentas,
  newVariable
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingXls, setLoadingXls] = useState(false);
  const [file, setFile] = useState();
  const [data, setData] = useState();
  const [progreso, setProgreso] = useState("");
  const dispatch = useDispatch();
  console.log("newVariable", newVariable);
  const handleClose = () => setShow(false);

  const btnRef = useRef();

  const onHandleIn = () => btnRef.current.click();

  function getJsDateFromExcel(serial) {
    const hours = Math.floor((serial % 1) * 24);
    const minutes = Math.floor(((serial % 1) * 24 - hours) * 60);
    return new Date(Date.UTC(0, 0, serial, hours - 17, minutes));
  }
  const onHandleSaveCsv = () => {
    if (data.length === 0) {
      dispatch(addAlert({ msg: "Por favor ingrese un documento xls" }));
      setLoading(false);
      return;
    }
    setLoading(true);
    let hasEmptyFields = false;
    for (const x of data) {
      // Validamos que no existan campos vacios
    }
    if (!hasEmptyFields) {
      checkDataByParts()
        .then(() => sendDataByParts())
        .catch(e => {
          console.log(e);
          setLoading(false);
          if (e === 1062)
            dispatch(
              addAlert({
                msg: "Existen codigos sap repetidos"
              })
            );
        });
    }
  };

  const checkDataByParts = async () => {
    return true;
  };

  const sendDataByParts = async () => {
    let errores = false;
    const QueryParams = data.map(x => ({
      ventas_clientes: x["Cliente"],
      ventas_nombre: x["Nombre"],
      ventas_identfis1: x["IdentFis1"],
      ventas_identfis2: x["IdentFis2"],
      ventas_fecha: getJsDateFromExcel(x["Fecha"]),
      ventas_ciudad: x["Ciudad"],
      ventas_ofvta: x["OfVTa"],
      ventas_nombre_sector: x["NombreSector"],
      ventas_codigo: x["Codigo"],
      ventas_denominacion: x["Denominacion"],
      ventas_producto: x["Producto"],
      ventas_tipo: x["Tipo"],
      ventas_marca: x["Marca"],
      ventas_material: x["Material"],
      ventas_cant_facturada: x["CtdFacturada"],
      ventas_valor_neto: x["ValorNeto"],
      ventas_nombre_rep_lentes: x["NombreRepLentes"],
      ventas_nombre_armazones: x["NombreRepArmazones"],
      ventas_emisor: x["Emisor"],
      ventas_factura: x["Factura"],
      ventas_cl_factura: x["ClFac"],
      ventas_referencia: x["Referencia"],
      ventas_canal_cliente: x["Canal Clientes"],
      ventas_tipo_producto: x["Tipo de Producto"],
      ventas_region: x["REGION"],
      ventas_segmento: x["Segmento"],
      ventas_rep_lenses: x["Rep lenses"],
      ventas_rep_eyewear: x["Rep Eyewear"],
      ventas_mes: x["Mes"],
      ventas_segmento_2: x["Segmento 2"],
      ventas_grupo_progresivos: x["Grupo Progresivos"],
      ventas_categoria: x["Categoría"]
    }));
    setProgreso("0/" + QueryParams.length);
    for (var i = 0; i < QueryParams.length; i += 100) {
      setProgreso(i + "/" + QueryParams.length);
      await requestFromServer
        .UploadVentasXlxs(QueryParams.slice(i, i + 100))
        .catch(e => {
          errores = true;
          setLoading(false);
          dispatch(
            addAlert({
              msg: "Ocurrió un error al momento de cargar el xls"
            })
          );
        });

      if (errores) break;
    }
    if (!errores) {
      setProgreso(QueryParams.length + "/" + QueryParams.length);
      dispatch(
        addAlert({
          msg: "XLS cargado satisfactoriamente"
        })
      );
      fetchVentas();
      setLoading(false);
      setProgreso("");
      setShow(false);
    }
  };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        {loading && <ModalProgressBar variant="query" />}
        <Modal.Header closeButton>
          <Modal.Title>Carga Masiva Ventas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!loading && (
            <FormModalVentasCarga
              file={file}
              setFile={setFile}
              data={data}
              setData={setData}
              sendVentasToServer={onHandleSaveCsv}
              btnRef={btnRef}
              setLoadingXls={setLoadingXls}
            />
          )}
          {loading && (
            <>
              <div align={"center"}>
                <span>
                  <b>{progreso}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                </span>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!loadingXls && (
            <>
              <Button
                variant="secondary"
                onClick={handleClose}
                disabled={loading}
              >
                Cancelar
              </Button>
              <Button variant="success" onClick={onHandleIn} disabled={loading}>
                Cargar Archivo
              </Button>
            </>
          )}
          {loadingXls && (
            <span
              className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
