import React, { useCallback, useEffect, useState } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Pagination
} from "../../../_metronic/_partials/controls";
import { getReporteSemanal } from "../../helpers/axios";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { getColumns } from "./table_helpers/Tablecols";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from "../../../_metronic/_helpers";
import filterFactory from "react-bootstrap-table2-filter";
import { isEqual, isFunction } from "lodash";
import { initialTableFilter } from "../../helpers/TableHelpers";
import { PaginationTotalCustom } from "../_GlobalComponents/table/pagination/PaginationTotalCustom";
import moment from "moment";

export const RepSemanal = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Reporte Diario");
  const [archivos, setArchivos] = useState(null);
  const [archivosFiltrados, setArchivosFiltrados] = useState(null);
  const [queryParams, setQueryParamsBase] = useState(initialTableFilter);
  const [totalCount, setTotalCount] = useState(0);
  let cardTitle = "Reporte Diario";
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 }
    ],
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
    paginationTotalRenderer: PaginationTotalCustom
  };
  const ListS3Files = async () => {
    getReporteSemanal().then(res => {
      if (res.data.length !== 0) {
        setArchivos(
          res.data.map(item => ({
            name: item.Key.replace("Reportes_Semanales/", ""),
            fecha: moment(item.LastModified).format("DD-MM-YYYY")
          }))
        );
        setArchivosFiltrados(
          res.data.map(item => ({
            name: item.Key.replace("Reportes_Semanales/", ""),
            fecha: moment(item.LastModified).format("DD-MM-YYYY")
          }))
        );
        setTotalCount(res.data.length);
      }
    });
  };
  useEffect(() => {
    ListS3Files();
  }, []);

  useEffect(() => {
    if (archivos)
      setArchivosFiltrados(
        archivos.slice(
          (queryParams.pageNumber - 1) * queryParams.pageSize,
          queryParams.pageSize * queryParams.pageNumber
        )
      );
  }, [archivos, queryParams.pageNumber, queryParams.pageSize]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }
      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  return (
    <>
      <Card>
        <CardHeader title={cardTitle}>
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination paginationProps={paginationProps}>
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    remote
                    keyField={"id"}
                    data={archivosFiltrados === null ? [] : archivosFiltrados}
                    columns={getColumns()}
                    onTableChange={getHandlerTableChange(setQueryParams)}
                    filter={filterFactory()}
                    striped
                    hover
                    condensed
                    {...paginationTableProps}
                  >
                    <PleaseWaitMessage entities={archivosFiltrados} />
                    <NoRecordsFoundMessage entities={archivosFiltrados} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
    </>
  );
};
