import React, { useState } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from '../../../../_metronic/_partials/controls'
import { Button, Form } from 'react-bootstrap'
import { xls_to_json } from '../../../helpers/XLSHelpers'
import * as requestFromServer from '../../../helpers/axios'
import { CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { genTemplateCmInvPer } from './xls/xls_templata_cm'
import ExcelJS from 'exceljs'
import moment from 'moment/moment'
import { useSelector } from 'react-redux'

export const CargaXls = props => {
  const [dataVisitas, setDataVisitas] = useState([])
  const [loadingXls, setLoadingXls] = useState(false)
  const [filename, setFilename] = useState()
  const [downloadPlant, setDownloadPlant] = useState(false)
  const { user } = useSelector(state => state.auth)

  const history = useHistory()
  const onHandleDownTemplate = () => {
    setDownloadPlant(true)
    genTemplateCmInvPer().then(r => {
      setDownloadPlant(false)
    })
  }
  const onHandleSaveCsv = async () => {
    setLoadingXls(true)

    const visitas = dataVisitas.map(x => ({
      id_usu: user.id,
      visitas_asunto: x.nombre,
      visitas_fecvisita: x.fecha,
      visitas_obs: x.motivo,
      visitas_duracion_teorica_hrs: 1,
      id_est_eje: null,
      v_cod_cl: x.cl,
      id_mv: null,
      id_est_visita: 2,
      v_cli_nombre: x.nombre,
      v_dir_nombre: x.pob,
      v_cli_rut: x.rut
    }))
    for (const v of visitas) {
      await requestFromServer
        .insertVisita(v)
        .then(response => {
          console.log(response)

          // history.push('/visitas/listadmin')
        })
        .catch(err => {
          console.log('catch', err)
          // setLoadingXls(false)
        })
    }

    setLoadingXls(false)
    history.push('/visitas/listadmin')
  }

  const handleInputChange = ev => {
    const filename = ev.target.value.replace(/.*[\/\\]/, '')
    setFilename(filename)
    setLoadingXls(true)

    const wb = new ExcelJS.Workbook()
    const reader = new FileReader()

    reader.readAsArrayBuffer(ev.target.files[0])
    reader.onload = () => {
      const buffer = reader.result
      wb.xlsx.load(buffer).then(workbook => {
        const worksheet = workbook.getWorksheet('Visitas')

        let vis = []
        const ranges = [
          { start: 9, end: 18 },
          { start: 21, end: 30 },
          { start: 33, end: 42 },
          { start: 45, end: 54 },
          { start: 57, end: 66 }
        ]

        for (const range of ranges) {
          for (let i = range.start; i <= range.end; i++) {
            const r = worksheet.getRow(i)

            if (r.values[7]) {
              // Ajuste manual de la fecha para GMT-4 (Chile)
              const dVisita = moment
                .utc(getCellValue(r.getCell(9)))
                .format('YYYY-MM-DD')
              const fec_visita = moment(dVisita)
                .add(8, 'hours')
                .toDate()

              const dUltVisita = moment
                .utc(getCellValue(r.getCell(12)))
                .format('YYYY-MM-DD')

              const fec_ult_visita = moment(dUltVisita)
                .add(8, 'hours')
                .toDate()

              vis.push({
                rut: getCellValue(r.getCell(5)),
                nombre: getCellValue(r.getCell(6)),
                cl: getCellValue(r.getCell(7)),
                pob: getCellValue(r.getCell(8)),
                fecha: r.values[9] && fec_visita,
                motivo: getCellValue(r.getCell(10)),
                cat: getCellValue(r.getCell(11)),
                ultv: r.values[12] && fec_ult_visita
              })
            }
          }
        }

        setDataVisitas(vis)
        setLoadingXls(false)
      })
    }
  }
  return (
    <>
      <Card>
        <CardHeader title="Carga masiva de requerimientos">
          <CardHeaderToolbar>
            {/* <Button
              type={'button'}
              variant={'primary'}
              onClick={onHandleDownTemplate}
              disabled={downloadPlant}
            >
              Plantilla
            </Button>
            &nbsp; */}
            <Button
              type={'button'}
              disabled={loadingXls || !dataVisitas || dataVisitas.length == 0}
              variant={'primary'}
              onClick={onHandleSaveCsv}
            >
              Cargar
            </Button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {loadingXls && (
            <div className={'text-center'}>
              Cargando..
              <CircularProgress size={24} />
            </div>
          )}
          {!loadingXls && (
            <Form.File
              id="file"
              label={filename ? filename : 'Seleccionar XLS'}
              data-browse="Buscar"
              onChange={handleInputChange}
              custom
              name={'file'}
              disabled={loadingXls}
            />
          )}
        </CardBody>
        <CardBody>
          <table className="table">
            <thead>
              <tr>
                <th>Rut</th>
                <th>Nombre</th>
                <th>CL</th>
                <th>Población</th>
                <th>Fecha</th>
                <th>Motivo</th>
                <th>Categoría</th>
                <th>Ult Visita</th>
              </tr>
            </thead>
            <tbody>
              {dataVisitas &&
                dataVisitas.map(v => (
                  <tr>
                    <td>{v.rut}</td>
                    <td>{v.nombre}</td>
                    <td>{v.cl}</td>
                    <td>{v.pob}</td>
                    <td>{moment(v.fecha).format('DD-MM-YYYY')}</td>
                    <td>{v.motivo}</td>
                    <td>{v.cat}</td>
                    <td>{moment(v.ultv).format('DD-MM-YYYY')}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </>
  )
}

const getCellValue = cell => {
  let cellValue

  if (cell.type === ExcelJS.ValueType.Formula) {
    // Si la celda es una fórmula, obtener el valor calculado
    cellValue = cell.result
  } else if (cell.type === ExcelJS.ValueType.Null) {
    // Si la celda está vacía
    cellValue = null
  } else {
    // Para otros tipos de celdas, obtener el valor directamente
    cellValue = cell.value
  }

  return cellValue
}
