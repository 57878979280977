import React, { useCallback, useMemo, useEffect, useState } from "react";
import { mbox_accesstoken } from "../../components/MapBoxConstant";
import ReactMapboxGl, {
  Feature,
  Layer,
  Popup,
  Source,
  ZoomControl
} from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as MapboxGL from "mapbox-gl";
import * as requestFromServer from "../../../helpers/axios";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import * as turf from "@turf/turf";
import { getBounds } from "../../components/mapas/BoundsCalc";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import { isEqual, isFunction } from "lodash";
import { InitialRepFilter } from "./components/InitialRepFilters";
import { sLayout, sPaint } from "./components/arrow_points_styles";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  FindStops,
  GetMatchMap,
  GetMinutesDiff,
  InitParamsMap,
  FlyToOptions,
  paint_v,
  GetPointRoute
} from "./components/MapsUtil";
import {
  ioLayout_tx,
  ioPaint_c,
  ioPaint_tx
} from "./components/io_points_styles";
import { PopUpStop } from "./components/PopUpStop";
import { ListaFiltrosRBox } from "./components/ListaFiltrosRBox";

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  markerInit: {
    backgroundColor: "blue",
    //borderRadius: '50px',
    //display: inline-block;

    display: "block",
    border: "none",
    borderRadius: "50%",
    cursor: "pointer",
    padding: 0
  }
}));

export const RepDetenciones = () => {
  toAbsoluteUrl("/media/arrow-map-right.png");
  const { visitas } = useSelector(state => state.rdet);

  const classes = useStyles();

  const nothing = turf.featureCollection([]);
  const [ruta, setRuta] = useState(nothing);
  const [IOPoints, setInOutPoints] = useState(nothing);
  const [visPoints, setVPoints] = useState(nothing);
  const [StopPoints, setStopPoints] = useState([]);
  const [visitaSelected, setVisitaSelected] = useState(undefined);
  const [stopSelected, setStopSelected] = useState(undefined);

  const [queryParams, setQueryParamsBase] = useState(InitialRepFilter);
  const [mapProps, setMapProps] = useState(InitParamsMap);

  const lLayout = {
    "line-join": "round",
    "line-cap": "round"
  };
  const lPaint = {
    "line-color": "#3887be",
    "line-width": ["interpolate", ["linear"], ["zoom"], 12, 3, 22, 12]
  };
  const Map = useMemo(() => {
    return ReactMapboxGl({
      accessToken: mbox_accesstoken
    });
  }, []);

  const getInitRoute = coord => {
    return turf.point([coord[0], coord[1]], {
      //orderTime: Date.now(),
      key: Math.random(),
      type: "init",
      hour: `${moment(coord[2]).format("HH:mm")}`
    });
  };

  const v_circle_pend = {
    "circle-stroke-width": 2,
    "circle-radius": 5,
    "circle-blur": 0.15,
    "circle-color": "#ef5e0c",
    "circle-stroke-color": "white"
  };
  const usr_circle_Stop = {
    "circle-stroke-width": 3,
    "circle-radius": 20,
    "circle-blur": 0.15,
    "circle-color": "rgba(0,0,0,0)",
    "circle-stroke-color": "black"
  };
  // Make a Map Matching request

  const getRuta = async (queryParameters, setLoadingFindButton) => {
    IOPoints.features = [];
    ruta.features = [];
    setStopSelected(undefined);
    setInOutPoints({ ...IOPoints });
    setRuta({ ...ruta });
    if (
      queryParameters.filter !== undefined &&
      queryParameters.filter.fecha !== undefined &&
      queryParameters.filter.tecnico !== undefined
    ) {
      setLoadingFindButton(true);
      requestFromServer
        .getRuta(queryParameters)
        .then(response => {
          if (response.data && response.data.length > 0) {
            GetMatchMap([...response.data])
              .then(matchedCoords => {
                const lineString = turf.lineString(matchedCoords);
                const coords = matchedCoords;
                for (const coord of coords)
                  ruta.features.push(GetPointRoute(coord));
                ruta.features.push(lineString);
                const bounds = getBounds(coords);
                setMapProps({ ...mapProps, fitBounds: bounds });
                setRuta({ ...ruta });
              })
              .finally(() => setLoadingFindButton(false));
            const arrStops = [];
            FindStops([...response.data], 50).forEach(x => {
              // Si la diferencia de tiempo entre el punto de inicio y el punto de fin es mayor a 2 minutos se marca como detenido
              if (
                GetMinutesDiff(new Date(x.end.time), new Date(x.start.time)) > 2
              )
                arrStops.push(x);
            });
            IOPoints.features.push(getInitRoute(response.data[0]));
            IOPoints.features.push(
              getInitRoute(response.data[response.data.length - 1])
            );
            setStopPoints([...arrStops]);
            setInOutPoints({ ...IOPoints });
          } else {
            setLoadingFindButton(false);
          }
        })
        .catch(err => console.log("err", err));
    }
  };

  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }
      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const onClickStop = stop => {
    setStopSelected(null);
    setMapProps({
      ...mapProps,
      center: [stop.start.lat, stop.start.lng],
      zoom: [14],
      fitBounds: undefined
    });
    setStopSelected(stop);
  };
  const onclickVis = ev => {
    const map = ev.target;
    map.getCanvas().style.cursor = "pointer";

    const coordinates = ev.features[0].geometry.coordinates.slice();
    const description = ev.features[0].properties.description;
    const status = ev.features[0].properties.status;

    console.log(coordinates, description);

    popup
      .setLngLat(coordinates)
      .setHTML(description)
      .addTo(map);
  };

  const popup = new MapboxGL.Popup({
    closeButton: true,
    closeOnClick: true
  });

  useEffect(() => {
    if (visitas.length > 0) {
      const features = [];
      for (const v of visitas) {
        const coords = [];
        if (v.id_est_visita === 1) {
          coords[0] = v.visitas_lon_cierre;
          coords[1] = v.visitas_lat_cierre;
        } else {
          coords[0] = v.direcciones_lon;
          coords[1] = v.direcciones_lat;
        }
        const f = GetPointRoute(coords);
        f.properties.status = v.id_est_visita === 1 ? "ejecutada" : "pendiente";
        f.properties.description = `<strong>${
          v.id
        }</strong><p>Fecha Cierre: ${moment(v.visitas_fecejecuta).format(
          "DD-MM-YY HH:mm"
        )} <br>Cliente: ${v.clientes_nombre} <br> Tienda: ${
          v.direcciones_nombre
        } </p>`;

        features.push(f);
      }
      setVPoints({ ...visPoints, features: features });
    } else setVPoints(nothing);
  }, [visitas]);

  return (
    <>
      <ListaFiltrosRBox
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        getRuta={getRuta}
      />

      <Map
        style="mapbox://styles/mapbox/streets-v11"
        containerStyle={{
          minHeight: "70%",
          width: "100%"
        }}
        {...mapProps}
        flyToOptions={FlyToOptions}
        fitBoundsOptions={{ padding: 20 }}
      >
        <ZoomControl />

        {StopPoints.length > 0 && (
          <Layer type="circle" id="marker2" paint={usr_circle_Stop}>
            {StopPoints.map(x => (
              <Feature
                key={`p-${x.id}`}
                coordinates={[x.start.lat, x.start.lng]}
                onClick={() => onClickStop(x)}
              />
            ))}
          </Layer>
        )}
        {stopSelected && (
          <PopUpStop
            stopSelected={stopSelected}
            setStopSelected={setStopSelected}
            mapProps={mapProps}
          />
        )}
        {visitaSelected && (
          <Popup key={visitaSelected.id} coordinates={mapProps.center}>
            <button
              className="mapboxgl-popup-close-button"
              type="button"
              aria-label="Close popup"
              onClick={() => setVisitaSelected(undefined)}
            >
              ×
            </button>
            <div>
              <a href={"/visitas/ver/" + visitaSelected.id}>
                {visitaSelected.id}
              </a>
              <b>{" (" + visitaSelected.mv_descripcion + ") "}</b>

              <Chip
                size="small"
                color="primary"
                label={visitaSelected.est_visita_nombre}
              />
            </div>
            <div>
              {visitaSelected.direcciones_nombre + " "}
              <b>{"[" + visitaSelected.codAlt + "]"}</b>
            </div>
            <div>{visitaSelected.direcciones_calle}</div>
            <div>{visitaSelected.clientes_nombre}</div>
            <div style={{ color: "green" }}>
              {"ATC: " + visitaSelected.tec_fullname}
            </div>
            <div style={{ color: "green" }}>
              {"Fec. Ejecución: " +
                moment(visitaSelected.visitas_fecejecuta).format(
                  "DD-MM-YYYY HH:mm"
                )}
            </div>
            <div style={{ color: "green" }}>
              {"T. En cliente: " +
                moment
                  .duration(
                    moment(visitaSelected.visitas_fecejecuta).diff(
                      moment(visitaSelected.visitas_fecinit_req)
                    )
                  )
                  .humanize()}
            </div>
          </Popup>
        )}

        <Source
          id="route"
          geoJsonSource={{
            type: "geojson",
            data: ruta
          }}
          onSourceLoad={e => {
            console.log("onSourceLoad", e);
          }}
        />
        <Source
          id="initfinish_points"
          geoJsonSource={{
            type: "geojson",
            data: IOPoints
          }}
        />
        <Source
          id="visitas_points"
          geoJsonSource={{
            type: "geojson",
            data: visPoints
          }}
        />
        <Layer
          type="line"
          layout={lLayout}
          paint={lPaint}
          sourceId={"route"}
          id={"routeline-active"}
        />
        <Layer
          type="symbol"
          layout={sLayout}
          paint={sPaint}
          sourceId={"route"}
          id={"routearrows"}
        />

        <Layer
          type="circle"
          paint={ioPaint_c}
          sourceId={"initfinish_points"}
          id={"iop_c"}
        />
        <Layer
          type="symbol"
          layout={ioLayout_tx}
          paint={ioPaint_tx}
          sourceId={"initfinish_points"}
          id={"iop_tx"}
        />

        <Layer
          type="circle"
          paint={paint_v}
          sourceId={"visitas_points"}
          id={"vp_c"}
          onClick={onclickVis}
        />
      </Map>
    </>
  );
};
