import React, { useState } from "react";
import { useSubheader } from "../../../_metronic/layout";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import * as requestFromServer from "../../helpers/axios";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { generatePlantillaClientesXls } from "./xls/xls_create";
import { xls_to_json } from "../../helpers/XLSHelpers";
import { addAlert } from "../../../redux/alerts/alertsRedux";
import { useDispatch } from "react-redux";

export const ModalCargaClientes = props => {
  const { fetchClientes, show, setShow } = props;
  const suhbeader = useSubheader();
  suhbeader.setTitle("Clientes");
  const dispatch = useDispatch();

  const [filename, setFilename] = useState(null);
  const [dataMasterPlan, setDataMasterPlan] = useState([]);
  const [xlsLoading, setXlsLoading] = useState(false);
  const [progreso, setProgreso] = useState("");

  const handleXls = () => {
    setXlsLoading(true);
    requestFromServer
      .getClientesXlxs({})
      .then(response => {
        console.log(response);
        generatePlantillaClientesXls(response.data.entities)
          .then()
          .catch(e => {
            console.log(e);
            setXlsLoading(false);
          })
          .finally(() => setXlsLoading(false));
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleXlsChange = ev => {
    const filename = ev.target.value.replace(/.*[\/\\]/, "");
    setFilename(filename);
    setXlsLoading(true);
    xls_to_json(ev).then(hojas => {
      if (hojas.length > 0) {
        const data = hojas[0].data;
        setDataMasterPlan(data);
      }
      setXlsLoading(false);
    });
  };

  const onHandleSaveCsv = () => {
    if (dataMasterPlan.length === 0) {
      dispatch(addAlert({ msg: "Por favor ingrese un documento xls" }));
      setXlsLoading(false);
      return;
    }
    setXlsLoading(true);
    let hasEmptyFields = false;
    for (const x of dataMasterPlan) {
      // Validamos que no existan campos vacios
      if (!x["Id Sistema Holding *"]) {
        hasEmptyFields = true;
        dispatch(addAlert({ msg: "Existen campos vacios en el xls cargado" }));
        setXlsLoading(false);
        break;
      }
      if (!x["Nombre *"]) {
        hasEmptyFields = true;
        dispatch(addAlert({ msg: "Existen campos vacios en el xls cargado" }));
        setXlsLoading(false);
        break;
      }
    }
    if (!hasEmptyFields) {
      checkDataByParts()
        .then(() => sendDataByParts())
        .catch(e => {
          console.log(e);
          setXlsLoading(false);
          if (e === 1062)
            dispatch(
              addAlert({
                msg: "Existen codigos sap repetidos"
              })
            );
        });
    }
  };

  const checkDataByParts = async () => {
    /*const QueryParams = dataMasterPlan.map(x => (
            x["COD SAP *"]
        ));
        let seen = new Set(QueryParams);
        const foundDuplicated = seen.size !== QueryParams.length;

        if (foundDuplicated) {
            throw 1062
        }*/
    return true;
  };

  const sendDataByParts = async () => {
    let errores = false;
    const QueryParams = dataMasterPlan.map(x => ({
      id: parseInt(x["Id Cliente"]),
      id_grupc: Number(x["Id Sistema Holding *"].split(" ")[0]),
      clientes_nombre: x["Nombre *"],
      codalt: x["Código *"] ? x["Código *"] : null
    }));
    setProgreso("0/" + QueryParams.length);
    for (var i = 0; i < QueryParams.length; i += 100) {
      await requestFromServer
        .UploadClienteXlxs(QueryParams.slice(i, i + 100))
        .catch(e => {
          errores = true;
          setXlsLoading(false);
          dispatch(
            addAlert({
              msg: "Ocurrió un error al momento de cargar el xls"
            })
          );
        });
      setProgreso(i + "/" + QueryParams.length);
      if (errores) break;
    }
    if (!errores) {
      setProgreso(QueryParams.length + "/" + QueryParams.length);
      dispatch(
        addAlert({
          msg: "XLS cargado satisfactoriamente"
        })
      );
      fetchClientes();
      setXlsLoading(false);
      setProgreso("");
      setShow(false);
    }
  };

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        {xlsLoading && <ModalProgressBar variant="query" />}
        <Modal.Header>
          <Modal.Title>Carga Masiva Clientes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!xlsLoading && (
            <>
              <Form.Group controlId="exampleForm.ControlInput2" as={Col}>
                <Form.Label>&nbsp; </Form.Label>
                <Form.File
                  id="file"
                  label={filename ? filename : "Seleccionar XLS"}
                  data-browse="Buscar"
                  onChange={handleXlsChange}
                  custom
                  name={"file"}
                  disabled={xlsLoading}
                />
              </Form.Group>
            </>
          )}
          {xlsLoading && (
            <>
              <div align={"center"}>
                <span>
                  <b>{progreso}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                </span>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-secondary ml-1 mr-auto"
            onClick={handleClose}
            disabled={xlsLoading}
          >
            Cerrar
          </Button>
          <Button
            className="btn btn-success ml-1"
            onClick={handleXls}
            disabled={xlsLoading}
          >
            {" "}
            Descargar Plantilla XLS
          </Button>
          <Button
            className="btn btn-success ml-1"
            onClick={onHandleSaveCsv}
            disabled={!filename || xlsLoading}
          >
            {" "}
            Comenzar Carga XLS
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
