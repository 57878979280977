import { sortCaret } from "../../../../_metronic/_helpers";
import { DeleteCTColumnFormatter } from "./DeleteCTColumnFormatter";

export const getColumns = (setShow, setIdUsuario) => [
  {
    dataField: "ccli_cod_cl",
    text: "CL",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "ccli_nombre_tienda",
    text: "Tienda",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "ccli_nombre_cliente",
    text: "Cliente",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "ccli_rut_cliente",
    text: "Rut",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "accions",
    text: "Acciones",
    formatter: DeleteCTColumnFormatter,
    formatExtraData: { setShow, setIdUsuario },
    classes: "text-right pr-3 ",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "70px"
    }
  }
];
