/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { AsideMenuItem } from "./AsideMenuItem";
import { AsideMenuDivider } from "./AsideMenuDivider";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

export function AdminMenuListAdmin({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  const { user } = useSelector(state => state.auth);
  return (
    <>
      {/* begin::Menu Nav */}

      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <AsideMenuItem
          hasSubmenu={false}
          label={"Dashboard"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/dashboard"}
          permissions={[8]}
        />
        {/*end::1 Level*/}

        {/* begin::section */}

        <AsideMenuDivider
          label={"Operación administrativa"}
          permissions={[10, 12, 9]}
        />

        <AsideMenuItem
          hasSubmenu={false}
          label={"Calendario"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/calendario"}
          permissions={[10]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Mapa Rep."}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/maps/maptec"}
          permissions={[10]}
        />

        <AsideMenuItem
          hasSubmenu={false}
          label={"Visitas"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/visitas/listadmin"}
          urlBase={"/visitas"}
          permissions={[12]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Repositorio"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/repositorio/list"}
          urlBase={"/repositorio"}
          permissions={[12]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Ventas"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/ventas/list"}
          urlBase={"/ventas"}
          permissions={[12]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Metas"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/metas"}
          urlBase={"/metas"}
          permissions={[12]}
        />

        <AsideMenuDivider label={"Informes"} permissions={[12]} />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Reporte Detenciones"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/reportes/detenciones"}
          urlBase={"/reportes/detenciones"}
          permissions={[12]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Informe Representantes"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/informe_representantes"}
          urlBase={"/informe_representantes"}
          permissions={[12]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Cumplimiento Representantes"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/cumplimiento_representantes"}
          urlBase={"/cumplimiento_representantes"}
          permissions={[12]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Neto por Cliente"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/cantidad_vendida_cliente"}
          urlBase={"/cantidad_vendida_cliente"}
          permissions={[12]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Cantidad Visitas"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/cantidad_visitas"}
          urlBase={"/cantidad_visitas"}
          permissions={[12]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Metas"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/informe_metas"}
          urlBase={"/informe_metas"}
          permissions={[12]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Pronostico Metas"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/proMetas"}
          urlBase={"/proMetas"}
          permissions={[12]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Reporte Diario"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/repSemanal"}
          urlBase={"/repSemanal"}
          permissions={[12]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Reporte Campañas de Marketing"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/repCampanaVisitas"}
          urlBase={"/repCampanaVisitas"}
          permissions={[22]}
        />
        <AsideMenuItem
            hasSubmenu={false}
            label={"Ruta Diaria"}
            pathIcon={"/media/svg/icons/General/Settings-2.svg"}
            url={"/rutaDiaria"}
            urlBase={"/rutaDiaria"}
            permissions={[22]}
        />
        <AsideMenuDivider
          label={"Maestros"}
          permissions={[53, 47, 54, 50, 101, 37, 79, 22, 39, 33]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Dias no visita"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/dias_no_visita"}
          urlBase={"/dias_no_visita"}
          permissions={[22]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Campañas de Marketing"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/campanaVisitas/list"}
          urlBase={"/campanaVisitas"}
          permissions={[22]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Usuarios"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/usuarios/list"}
          urlBase={"/usuarios"}
          permissions={[22]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Tiendas"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/direcciones/list"}
          urlBase={"/direcciones"}
          permissions={[22]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Tipos de visita"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/tiposVisita/list"}
          urlBase={"/tiposVisita"}
          permissions={[47]}
        />
        <AsideMenuItem
          hasSubmenu={false}
          label={"Licencias"}
          pathIcon={"/media/svg/icons/General/Settings-2.svg"}
          url={"/licencias/list"}
          urlBase={"/licencias"}
          permissions={[22]}
        />

        <AsideMenuDivider label={"Aplicacion"} permissions={[43]} />

        {user.permisos && user.permisos.includes(43) && (
          <li
            className={`menu-item ${getMenuItemActive("/dummy", false)}`}
            aria-haspopup="true"
          >
            <a
              className="menu-link"
              href="https://apps.apple.com/cl/app/rodstreet/id1667253294"
              target="_blank"
            >
              {" "}
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                />
              </span>
              <span className="menu-text">App representantes</span>
            </a>
          </li>
        )}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
