import React, { useState, useCallback, useEffect } from "react";
import { isEqual, isFunction, cloneDeep } from "lodash";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import { getColumns } from "./table_helpers/Tablecols";
import * as requestFromServer from "../../../helpers/axios";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from "../../../../_metronic/_helpers";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { TableFilters } from "./table_helpers/TableFilters";
import { initialTableFilter } from "../../../helpers/TableHelpers";
import { Button } from "react-bootstrap";
import { ModalRepositorio } from "./form/ModalRepositorio";
import { ModalRepositorioDelete } from "./form/ModalRepositorioDelete";
import { PaginationTotalCustom } from "../../_GlobalComponents/table/pagination/PaginationTotalCustom";

export const ListarRepositorio = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Repositorio");

  const [repositorios, setRepositorios] = useState(null);

  const [listLoading, setListLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialTableFilter);
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 }
    ],
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
    paginationTotalRenderer: PaginationTotalCustom
  };
  const [idRepositorio, setIdRepositorio] = useState(null);

  useEffect(() => {
    fetchRepositorios();
  }, [queryParams]);

  const fetchRepositorios = () => {
    const queryParameters = cloneDeep(queryParams);
    setListLoading(true);
    requestFromServer
      .getRepositorios(queryParameters)
      .then(response => {
        if (response.data) {
          setRepositorios(response.data.entities);
          setTotalCount(response.data.totalCount);
        } else {
          setRepositorios([]);
          setTotalCount(0);
        }

        setListLoading(false);
      })
      .catch(error => {
        console.log(error);
        setListLoading(false);
      });
  };

  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }
      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  return (
    <>
      <ModalRepositorio
        show={show}
        setShow={setShow}
        fetchRepositorios={fetchRepositorios}
      />
      <ModalRepositorioDelete
        id={idRepositorio}
        show={showDelete}
        setShow={setShowDelete}
        fetch={fetchRepositorios}
      />
      <Card>
        <CardHeader title="Repositorios"></CardHeader>
        <CardBody>
          <TableFilters
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  isLoading={listLoading}
                  paginationProps={paginationProps}
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    remote
                    keyField={"id"}
                    data={repositorios === null ? [] : repositorios}
                    columns={getColumns(setShowDelete, setIdRepositorio)}
                    onTableChange={getHandlerTableChange(setQueryParams)}
                    filter={filterFactory()}
                    striped
                    hover
                    condensed
                    {...paginationTableProps}
                  >
                    <PleaseWaitMessage entities={repositorios} />
                    <NoRecordsFoundMessage entities={repositorios} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
    </>
  );
};
