import React from "react";
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";
import axios from "axios";

export const REQUEST_URL = "api/me";

export const SessionHelper = (props) => {
    const history = useHistory();
    const location = useLocation();
    let check_access=undefined
    if(location.pathname=="/invperiodicos/listop") check_access=4
    if(location.pathname=="/invperiodicos/listadmin") check_access=10
    const getMe = () => axios.get(`${REQUEST_URL}`,{params:{check_access:check_access}});
    const checkAxiosError = error => {
        if (error.response && error.response.status === 401) history.push("/logout")
    }

    getMe().then(response => console.log("session ok"))
        .catch(error => checkAxiosError(error));

    return <></>
}
