import React from 'react';
import {Font, StyleSheet, Text} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  textSubtitle: {
    fontSize: 10,
    color: 'white',
    backgroundColor: '#162789',
    
  }
});

export const Subtitle1 = (props) => {
  const {label}=props
  return (
      <Text style={styles.textSubtitle}>{label}</Text>
  );
};
