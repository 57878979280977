import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import * as requestFromServer from "../../../helpers/axios";
import { Col, Form } from "react-bootstrap";

const SelectAsyncPaginationCli = props => {
  const loadOptions = async (searchQuery, loadedOptions, { pageNumber }) => {
    let response = await requestFromServer
      .getUsuarios({
        pageNumber: pageNumber,
        pageSize: 10,
        eliminado: false,
        filter: { nombre: searchQuery, id_perfil: props.id_perfil }
      })
      .then(response => {
        if (response.data)
          return response.data.entities.map(x => ({
            value: x.id,
            usu_login: x.usu_login,
            label: x.fullname
          }));
      })
      .catch(err => console.log(err));
    //const responseJSON = await response.json();
    let hasMore = false;
    if (response) hasMore = response.length >= 10;
    if (response && props.isFilter && props.todosOption && pageNumber === 1)
      response.unshift({ value: undefined, label: "Todos" });
    return {
      options: response ? response : [],
      hasMore: hasMore,
      additional: {
        pageNumber: searchQuery ? 2 : pageNumber + 1
      }
    };
  };

  const onChange = option => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  return (
    <Form.Group controlId="exampleForm.ControlInput1" as={Col} md={props.md}>
      {!props.isFilter && (
        <Form.Label>
          {props.label} <span className={"text-danger"}>* </span> :
        </Form.Label>
      )}
      <AsyncPaginate
        value={props.value || ""}
        loadOptions={loadOptions}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        onChange={onChange}
        isSearchable={true}
        placeholder=""
        additional={{
          pageNumber: 1
        }}
      />
      {props.isFilter && (
        <small className="form-text text-muted">
          <b>Filtrar</b> por {props.label}
        </small>
      )}
    </Form.Group>
  );
};

SelectAsyncPaginationCli.propTypes = {
  md: PropTypes.number.isRequired,
  label: PropTypes.string,
  id_perfil: PropTypes.number,
  value: PropTypes.object,
  onChange: PropTypes.func,
  isFilter: PropTypes.bool.isRequired,
  todosOption: PropTypes.bool.isRequired
};

SelectAsyncPaginationCli.defaultProps = {
  todosOption: true
};

export default SelectAsyncPaginationCli;
