import React, { useCallback, useEffect, useState } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Pagination
} from "../../../_metronic/_partials/controls";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { getColumns } from "./table_helpers/Tablecols";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from "../../../_metronic/_helpers";
import filterFactory from "react-bootstrap-table2-filter";
import { cloneDeep, isEqual, isFunction } from "lodash";
import { initialDias, initialTableFilter } from "../../helpers/TableHelpers";
import { PaginationTotalCustom } from "../_GlobalComponents/table/pagination/PaginationTotalCustom";

import {
  getDiasNoVisita,
  putDiasNoVisita
} from "../../helpers/axios/DiasNoVisitaCrud";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ModalDeleteDias } from "./ModalDeleteDias";
import SelectAsyncPaginationUsu from "../components/AsyncSelects/SelectAsyncPaginationUsu";
export const DiasNoVisita = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Dias de no visita");
  const [diaNoVisita, setDiaNoVisita] = useState(null);
  const [listaFechas, setListaFechas] = useState([]);
  const [queryParams, setQueryParamsBase] = useState(initialDias);
  const [totalCount, setTotalCount] = useState(0);
  const [show, setShow] = useState(false);
  const [tecnicoSelected, setTecnicoSelected] = useState(null);
  const [id, setId] = useState(null);
  let cardTitle = "Dias de no visita";
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 }
    ],
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
    paginationTotalRenderer: PaginationTotalCustom
  };
  const ListDiasNoVisita = () => {
    const queryParameters = cloneDeep(queryParams);
    getDiasNoVisita(queryParameters).then(r => {
      if (r.data) {
        setListaFechas(r.data.entities);
        setTotalCount(r.data.totalCount);
      } else {
        setListaFechas([]);
        setTotalCount(0);
      }
    });
  };
  useEffect(() => {
    ListDiasNoVisita();
  }, [queryParams.pageNumber, queryParams.sortField, queryParams.sortOrder]);

  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }
      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const onChangeDate = date => {
    setDiaNoVisita({
      ...diaNoVisita,
      fecha_no_visita: date
    });
  };

  const onChangeMotivo = e => {
    setDiaNoVisita({
      ...diaNoVisita,
      movito_no_visita: e.target.value
    });
  };
  const onClickAgregar = () => {
    putDiasNoVisita(diaNoVisita).then(r => {
      ListDiasNoVisita();
    });
  };
  const handleChangeTecnico = e => {
    setTecnicoSelected(e);
    setDiaNoVisita({
      ...diaNoVisita,
      id_usu: e.value
    });
  };

  return (
    <>
      <ModalDeleteDias
        show={show}
        setShow={setShow}
        id={id}
        ListDiasNoVisita={ListDiasNoVisita}
      />
      <Card>
        <CardHeader title={"Agregar dia"}>
          <CardHeaderToolbar>
            <Button
              type="button"
              className="btn btn-primary"
              onClick={onClickAgregar}
            >
              Agregar
            </Button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Form>
            <Form.Row>
              <Form.Group controlId="exampleForm.ControlInput1" as={Col} md={2}>
                <Form.Label>Fecha</Form.Label>
                <InputGroup>
                  <DatePicker
                    selected={
                      diaNoVisita === null ? null : diaNoVisita.fecha_no_visita
                    }
                    placeholderText="Fecha"
                    onChange={date => onChangeDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className={"form-control"}
                    required={true}
                  />
                </InputGroup>
              </Form.Group>
              <SelectAsyncPaginationUsu
                value={tecnicoSelected}
                onChange={handleChangeTecnico}
                md={3}
                isFilter={false}
                label={"Representante"}
                id_perfil={3}
              />
            </Form.Row>
            <Form.Row>
              <Form.Group controlId="formBasicPassword" as={Col} md={12}>
                <Form.Label>Motivo</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Motivo"
                  rows={3}
                  onChange={onChangeMotivo}
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </CardBody>
      </Card>
      <Card>
        <CardHeader title={cardTitle}>
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination paginationProps={paginationProps}>
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    remote
                    keyField={"id"}
                    data={listaFechas === null ? [] : listaFechas}
                    columns={getColumns(setShow, setId)}
                    onTableChange={getHandlerTableChange(setQueryParams)}
                    filter={filterFactory()}
                    striped
                    hover
                    condensed
                    {...paginationTableProps}
                  >
                    <PleaseWaitMessage entities={listaFechas} />
                    <NoRecordsFoundMessage entities={listaFechas} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
    </>
  );
};
