import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as requestFromServer from "../../../helpers/axios";
import { addAlert } from "../../../../redux/alerts/alertsRedux";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";

export const ModalEliminarDireccion = props => {
  const { idDireccion, show, setShow } = props;
  const { tiendas } = useSelector(state => state.tiendas);
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);

  const handleClose = () => setShow(false);
  const handleDelete = async () => {
    setDeleting(true);
    await requestFromServer
      .deleteDireccion(idDireccion)
      .then(response => {
        dispatch(addAlert({ msg: "Tienda Eliminada" }));
      })
      .catch(err => {
        console.log(err);
        dispatch(addAlert({ msg: "Existio un error al eliminar la tienda" }));
      })
      .finally(() => {
        setShow(false);
        let tienda = tiendas.find(tienda => tienda.t_cod_cl === idDireccion);
        tienda = { ...tienda, t_eliminado: true };
        const data = tiendas.filter(tienda => tienda.t_cod_cl !== idDireccion);
        data.push(tienda);
        dispatch({
          type: "@setTiendas",
          payload: { tiendas: data, totalCount: data.length }
        });
        setDeleting(false);
      });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        {deleting && <ModalProgressBar variant="query" />}
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Tienda</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!deleting && <span>¿Está seguro que desea eliminar la tienda?</span>}
          {deleting && <span>Eliminando ...</span>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={deleting}>
            No
          </Button>
          <Button variant="danger" onClick={handleDelete} disabled={deleting}>
            Si
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
