import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import * as requestFromServer from "../../helpers/axios";
import { Button } from "react-bootstrap";
import { FormTipoVisita } from "./form/FormTipoVisita";
import { InitialStateTipoVisita } from "./form/InitialStateTipoVisita";
import { addAlert } from "../../../redux/alerts/alertsRedux";
import { useDispatch } from "react-redux";

export const EditTipoVisita = ({ match }) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Tipos Requerimientos");
  const history = useHistory();
  const { params } = match;
  const { id } = params;

  const btnRef = useRef();

  const [tipoVisita, setTipoVisita] = useState(InitialStateTipoVisita);
  const [loading, setLoading] = useState(false);

  let cardTitle = "Ingresar Tipo Visita ";
  if (id) cardTitle = "Editar Tipo Visita ";

  const newEntity = !id;

  const sendTipoVisitaToServer = () => {
    setLoading(true);
    const sendRequest = () => {
      if (!newEntity) return requestFromServer.updateTipoVisita(id, tipoVisita);
      else return requestFromServer.insertTipoVisita(tipoVisita);
    };
    sendRequest()
      .then(response => {
        history.push("/tiposRequerimientos/list");
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  const onHandleSubmit = () => btnRef.current.click();

  const fetchTipoVisita = () => {
    setLoading(true);
    requestFromServer
      .getTipoVisita(id)
      .then(response => {
        console.log(response);
        setTipoVisita({ ...response.data });
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) fetchTipoVisita();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Card>
        <CardHeader title={cardTitle}>
          <CardHeaderToolbar>
            {!loading && (
              <Button type="button" onClick={onHandleSubmit}>
                {newEntity ? "Ingresar" : "Actualizar"}
              </Button>
            )}

            {loading && (
              <span
                className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
              />
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <FormTipoVisita
            tipoVisita={tipoVisita}
            setTipoVisita={setTipoVisita}
            newEntity={newEntity}
            sendTipoVisitaToServer={sendTipoVisitaToServer}
            setLoading={setLoading}
            btnRef={btnRef}
          />
        </CardBody>
      </Card>
    </>
  );
};
