import React, { useEffect, useState } from "react";
import * as requestFromServer from "../../../helpers/axios";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";

export const EstVisFilter = props => {
  const { onChange } = props;
  const [estVisitas, setEstVisitas] = useState([]);
  const animatedComponents = makeAnimated();

  useEffect(() => {
    requestFromServer
      .getEstadosCampanas({})
      .then(response => {
        if (response.data) {
          const result = response.data.entities;
          estVisitas.push({ value: undefined, label: "Todos" });
          setEstVisitas([
            ...estVisitas,
            ...result.map(x => ({
              value: x.id,
              label: `${x.nombre}`
            }))
          ]);
        }
      })
      .catch(error => console.log(error));
  }, []);

  return (
    <div className="col-sm-6 col-md-3">
      <Select
        options={estVisitas}
        closeMenuOnSelect={true}
        components={animatedComponents}
        onChange={onChange}
        placeholder={""}
      />

      <small className="form-text text-muted">
        <b>Filtrar</b> por estado visita
      </small>
    </div>
  );
};
