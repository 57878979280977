import { ActionsColumnFormatter } from "./ActionsColumnFormatter";
import { sortCaret } from "../../../../_metronic/_helpers";
import { VigenciaColumnFormatter } from "./VigenciaColumnFormatter";
export const getColumnsListar = () => [
  {
    dataField: "tec_fullname",
    text: "Representante",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "feccrea",
    text: "Fecha",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "accions",
    text: "Acciones",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-3 ",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "70px"
    }
  }
];
