import { ActionsColumnFormatter } from "./ActionsColumnFormatter";
import { VigenciaColumnFormatter } from "./VigenciaColumnFormatter";
import { sortCaret } from "../../../../_metronic/_helpers";

export const getColumns = (setShow, setIdCliente) => [
  {
    dataField: "clientes_nombre",
    text: "Cliente",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "clientes_rut",
    text: "Rut",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "clientes_segmento",
    text: "Segmento",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "eliminado",
    text: "Vigencia",
    sort: true,
    sortCaret: sortCaret,
    formatter: VigenciaColumnFormatter
  },
  {
    dataField: "accions",
    text: "Acciones",
    formatter: ActionsColumnFormatter,
    formatExtraData: { setShow, setIdCliente },
    classes: "text-right pr-3 ",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "70px"
    }
  }
];
