import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as requestFromServer from "../../../helpers/axios";
import { addAlert } from "../../../../redux/alerts/alertsRedux";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";


export const ModalEliminarUsuario = props => {
  const { idUsuario, show, setShow, fetchUsuarios } = props;
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);

  const handleClose = () => setShow(false);
  const handleDelete = () => {
    setDeleting(true);
    requestFromServer
      .deleteUsuario(idUsuario)
      .then(response => {
        setShow(false);
        dispatch(addAlert({ msg: "Usuario Eliminado" }));
      })
      .catch(err => console.log(err))
      .finally(() => {
        fetchUsuarios();
        setDeleting(false);
      });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        {deleting && <ModalProgressBar variant="query" />}
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!deleting && (
            <span>¿Está seguro que desea eliminar el usuario?</span>
          )}
          {deleting && <span>Eliminando ...</span>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={deleting}>
            No
          </Button>
          <Button variant="danger" onClick={handleDelete} disabled={deleting}>
            Si
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
