import React, { useEffect, useState } from "react";
import * as requestFromServer from "../../../helpers/axios";
import { Button, Col, Form } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Checkbox } from "@material-ui/core";

export const FormLicencia = props => {
  const animatedComponents = makeAnimated();
  const {
    usuario,
    setUsuario,
    sendUsuarioToServer,
    newEntity,
    btnRef,
    direccionesUsu,
    gruposUsu
  } = props;
  const [validated, setValidated] = useState(false);
  const [perfiles, setPerfiles] = useState([]);
  const [perfilSelected, setPerfilSelected] = useState({});
  const [plataformaSelected, setPlataformaSelected] = useState({});
  const [direcciones, setDirecciones] = useState([]);
  const [plataformas, setPlataformas] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [direccionesSelected, setDireccionesSelected] = useState([]);
  const [gruposSelected, setGruposSelected] = useState([]);

  const onChangeNombre = e =>
    setUsuario({ ...usuario, usu_nombre: e.target.value });
  const onChangeApep = e => setUsuario({ ...usuario, usu_pat: e.target.value });
  const onChangeApem = e => setUsuario({ ...usuario, usu_mat: e.target.value });
  const onChangeEmail = e =>
    setUsuario({ ...usuario, usu_mail: e.target.value });
  const onChangeLogin = e =>
    setUsuario({ ...usuario, usu_login: e.target.value });
  const onChangeLoginSecundario = e =>
    setUsuario({ ...usuario, usu_login_alt: e.target.value });
  const onChangeCargo = e =>
    setUsuario({ ...usuario, usu_cargo: e.target.value });
  const onChangeEliminado = e =>
    setUsuario({ ...usuario, usu_eliminado: e.target.checked });
  const onChangeControlado = e =>
    setUsuario({ ...usuario, usu_controlado: e.target.checked });
  const onChangeApkTecnica = e =>
    setUsuario({ ...usuario, usu_usa_apk_tecnica: e.target.checked });
  const onChangePerfil = p => {
    setPerfilSelected(p);
    setUsuario({ ...usuario, id_perfil_asignado: p.value });
  };
  const onChangePlataforma = p => {
    setPlataformaSelected(p);
    setPerfilSelected({});
    setUsuario({
      ...usuario,
      id_plataforma: p.value,
      id_perfil_asignado: null
    });
  };
  const onChangeDir = p => {
    setDireccionesSelected(p);
    if (p) setUsuario({ ...usuario, direcciones: p, grupos: [] });
    else setUsuario({ ...usuario, direcciones: [] });
    setGruposSelected([]);
  };

  const onChangeGrupos = p => {
    setGruposSelected(p);
    if (p) setUsuario({ ...usuario, grupos: p, direcciones: [] });
    else setUsuario({ ...usuario, grupos: [] });
    setDireccionesSelected([]);
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      sendUsuarioToServer();
    }
    setValidated(true);
  };

  useEffect(() => {
    //cargamos perfiles de inventario
    let queryPerfiles = { eliminado: false };
    if (usuario.id_plataforma)
      queryPerfiles = {
        eliminado: false,
        filter: { id_plataforma: usuario.id_plataforma }
      };
    requestFromServer
      .getPerfiles(queryPerfiles)
      .then(response => {
        const { entities } = response.data;
        setPerfiles([
          ...entities.map(x => ({ value: x.id, label: x.perfil_nombre }))
        ]);
        if (!newEntity) {
          setPerfilSelected({
            ...entities
              .map(x => ({ value: x.id, label: x.perfil_nombre }))
              .find(x => x.value === usuario.id_perfil_asignado)
          });
          setPlataformaSelected({
            ...entities
              .map(x => ({ value: x.id_plataforma, label: x.pl_nombre }))
              .find(x => x.value === usuario.id_plataforma)
          });
        }
      })
      .catch(error => console.log(error));
    requestFromServer
      .getDirecciones({ eliminado: false })
      .then(response => {
        const { entities } = response.data;
        setDirecciones([
          ...entities.map(x => ({
            value: x.id,
            label: x.direcciones_nombre + " - " + x.codAlt
          }))
        ]);
      })
      .catch(error => console.log(error));
    requestFromServer
      .getGruposCli({ eliminado: false })
      .then(response => {
        const { entities } = response.data;
        setGrupos([
          ...entities.map(x => ({
            value: x.id,
            label: x.grupc_nombre
          }))
        ]);
      })
      .catch(error => console.log(error));
    requestFromServer
      .getPlataformas({})
      .then(response => {
        const { entities } = response.data;
        setPlataformas([
          ...entities.map(x => ({
            value: x.id,
            label: x.pl_nombre
          }))
        ]);
      })
      .catch(error => console.log(error));
  }, [usuario]);

  useEffect(() => {
    if (direccionesUsu)
      setDireccionesSelected(
        direccionesUsu.map(x => ({
          value: x.id,
          label: x.direcciones_nombre + " - " + x.codAlt
        }))
      );
  }, [direccionesUsu]);
  useEffect(() => {
    if (gruposUsu)
      setGruposSelected(
        gruposUsu.map(x => ({
          value: x.id,
          label: x.grupc_nombre
        }))
      );
  }, [gruposUsu]);

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Row>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Nombre:</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeNombre}
              value={usuario.usu_nombre}
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Apellido paterno:</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeApep}
              value={usuario.usu_pat}
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Apellido Materno:</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeApem}
              value={usuario.usu_mat || ""}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Email:</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeEmail}
              value={usuario.usu_mail}
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Login:</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeLogin}
              value={usuario.usu_login}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Login Secundario:</Form.Label>
            <Form.Control
              type={"text"}
              onChange={onChangeLoginSecundario}
              value={usuario.usu_login_alt || ""}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Cargo:</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeCargo}
              value={usuario.usu_cargo || ""}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Plataforma:</Form.Label>
            <Select
              options={plataformas}
              closeMenuOnSelect={true}
              components={animatedComponents}
              value={plataformaSelected}
              onChange={onChangePlataforma}
              placeholder={""}
              inputProps={{ required: true }}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
            <Form.Label>Perfil de usuario:</Form.Label>
            <Select
              options={perfiles}
              closeMenuOnSelect={true}
              components={animatedComponents}
              value={perfilSelected}
              onChange={onChangePerfil}
              placeholder={""}
              inputProps={{ required: true }}
            />
          </Form.Group>
          {usuario.id_perfil_asignado === 6 && (
            <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
              <Form.Label>Direcciones:</Form.Label>
              <Select
                options={direcciones}
                closeMenuOnSelect={true}
                components={animatedComponents}
                isMulti={true}
                onChange={onChangeDir}
                value={direccionesSelected}
                placeholder={""}
                inputProps={{ required: true }}
              />
            </Form.Group>
          )}
          {usuario.id_perfil_asignado === 7 && (
            <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={4}>
              <Form.Label>Holdings:</Form.Label>
              <Select
                options={grupos}
                closeMenuOnSelect={true}
                components={animatedComponents}
                isMulti={true}
                onChange={onChangeGrupos}
                value={gruposSelected}
                placeholder={""}
                inputProps={{ required: true }}
              />
            </Form.Group>
          )}
        </Form.Row>
        <Form.Row>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col}>
            <Form.Label>Control Cierre Visitas</Form.Label>
            <Checkbox
              checked={Boolean(usuario.usu_controlado)}
              onChange={onChangeControlado}
              style={{ verticalAlign: "middle" }}
              inputProps={{
                "aria-label": "primary checkbox"
              }}
            />
          </Form.Group>
          {!newEntity && (
            <Form.Group controlId="exampleForm.ControlInput2" as={Col}>
              <Form.Label>Eliminado</Form.Label>
              <Checkbox
                checked={Boolean(usuario.usu_eliminado)}
                onChange={onChangeEliminado}
                style={{ verticalAlign: "middle" }}
                inputProps={{
                  "aria-label": "primary checkbox"
                }}
              />
            </Form.Group>
          )}
          <Form.Group controlId="exampleForm.ControlInput2" as={Col}>
            <Form.Label>Usa App IOS</Form.Label>
            <Checkbox
              checked={Boolean(usuario.usu_usa_apk_tecnica)}
              onChange={onChangeApkTecnica}
              style={{ verticalAlign: "middle" }}
              inputProps={{
                "aria-label": "primary checkbox"
              }}
            />
          </Form.Group>
        </Form.Row>

        <Button type="submit" ref={btnRef} style={{ display: "none" }} />
      </Form>
    </>
  );
};
