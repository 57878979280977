import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FecVisFilter } from "../../visitas/table_helpers/filter_comps/FecVisFilter";
import { InitialFilter } from "../../visitas/table_helpers/filter_comps/InitialFilters";
import SelectAsyncPaginationUsu from "../../components/AsyncSelects/SelectAsyncPaginationUsu";

export const TableFilters = props => {
  const { queryParams, setQueryParams } = props;

  const [filters, setFilters] = useState({});
  const [tecnicoSelected, setTecnicoSelected] = useState({});
  const prepareFilter = queryParams => {
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter["fecha"] = filters.fecha ? filters.fecha : undefined;
    filter["id_rep"] = filters.id_rep !== "" ? filters.id_rep : undefined;
    newQueryParams.filter = filter;
    return newQueryParams;
  };
  const handleChangeTecnico = e => {
    setTecnicoSelected(e);
    setFilters({ ...filters, id_rep: e.value });
  };
  const handleChangeFecha = date => setFilters({ ...filters, fecha: date });
  useEffect(() => {
    const newQueryParams = prepareFilter(queryParams);
    setQueryParams({ ...newQueryParams, pageNumber: 1 });
  }, [filters]);
  return (
    <div className="form-group row">
      <SelectAsyncPaginationUsu
        value={tecnicoSelected}
        onChange={handleChangeTecnico}
        md={3}
        isFilter={true}
        label={"Representante"}
        id_perfil={3}
        todosOption={true}
      />
      <FecVisFilter
        onChange={handleChangeFecha}
        label={"Fecha"}
        value={filters.fecha}
      />
    </div>
  );
};
