import React from "react";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { useDispatch, useSelector } from 'react-redux'
import { actionsLics } from "../../../../../redux/licencias/licenciasRedux";

export const LicDeleteDialog = ({ id, show, onHide }) => {
  const dispatch = useDispatch();
  const {deletingItem} = useSelector((state) => state.lics);
  const onDelete = () => {
    dispatch(actionsLics.deleteItem(Number(id))).then(()=>onHide());
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Eliminar Licencia {id}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!deletingItem &&
          <span>
            ¿Estás seguro que deseas eliminar esta licencia?
          </span>
        }
        {deletingItem && <span>Eliminando licencia...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
            disabled={deletingItem}
          >
            Cancelar
          </button>
          <> </>
          <button
            type="button"
            onClick={onDelete}
            className="btn btn-danger btn-elevate"
            disabled={deletingItem}
          >
            Eliminar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
