import React from "react";
import { HeaderTablaInformeRepresentanteTipo } from "./HeaderTablaInformeRepresentanteTipo";
import { RowTablaInformeRepresentanteTipo } from "./RowTablaInformeRepresentanteTipo";

export function TablaInfRepTipo({ informe, dateSelected }) {
  const ValidateNaN = value => {
    if (isNaN(value)) {
      return 0;
    } else {
      return value;
    }
  };
  return (
    <>
      <div style={{ height: "100%" }}>
        <HeaderTablaInformeRepresentanteTipo dateSelected={dateSelected} />
        {informe &&
          informe.map(m => (
            <RowTablaInformeRepresentanteTipo
              prod={m.producto}
              unitsLastYear={m.ventas_cant_facturada2}
              unitsThisYear={m.ventas_cant_facturada}
              varVsUnits={
                ((m.ventas_cant_facturada - m.ventas_cant_facturada2) /
                  m.ventas_cant_facturada2) *
                100
              }
              lastYear={m.ventas_valor_neto2}
              thisYear={m.ventas_valor_neto}
              varVsMTD={
                ((m.ventas_valor_neto - m.ventas_valor_neto2) /
                  m.ventas_valor_neto2) *
                100
              }
              aspLastYear={m.ventas_valor_asp2}
              aspThisYear={m.ventas_valor_asp}
              varPercentage={
                ((ValidateNaN(m.ventas_valor_asp -
                  ValidateNaN(m.ventas_valor_asp2)) /
                  ValidateNaN(m.ventas_valor_asp2)) *
                100)
              }
              bold={true}
            />
          ))}
      </div>
    </>
  );
}
