import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import NumberInput from '../../_GlobalComponents/NumberInput';

export const RowTablaArea = ({
  usuario,
  findMetaIndex,
  findMeta,
  setMetas,
  metas,
  area,
  index,
  year,
  month,
  calculateTotal
}) => {
  const [value, setValue] = React.useState("");
  const onChange = e => {
    const schema = {};
    if(e.target.value)setValue(e.target.value);
    schema.id_usu = usuario.id;
    schema.meta_cantidad = parseInt(e.target.value.replace(/\./g,''));
    schema.id_area = area.id;
    schema.meta_year = year;
    schema.meta_month = month;
    const metaIndex = findMetaIndex(
      area.id,
      usuario.id,
      schema.meta_year,
      schema.meta_month
    );
    if (metaIndex !== -1) metas[metaIndex] = schema;
    else metas.push(schema);
    
    setMetas(metas);
    calculateTotal();
  };
  useEffect(() => {
    if (year && month) setValue(`${findMeta(area.id, usuario.id, year, month)}` || "");
  }, [year, month, metas]);
  return (
    <>
      <tr key={index} style={{ textAlign: "center" }}>
        <td>{usuario.usu_nombre}</td>
        <td>
          <InputGroup>
            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>

            <Form.Control
              as={NumberInput}
              type={"text"}
              onChange={onChange}
              value={value}
              
            />
          </InputGroup>
        </td>
      </tr>
    </>
  );
};
