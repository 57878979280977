import React from "react";
import { Badge } from "react-bootstrap";

export const VigenciaColumnFormatter = (cellContent, row) => {
  const getColor = id_estado => {
    if (id_estado === 1) {
      return "success";
    } else if (id_estado === 2) {
      return "warning";
    } else {
      return "danger";
    }
  };
  return (
    <>
      <Badge variant={getColor(row.id_estado_campanas)}>
        {row.estado_campanas_nombre}
      </Badge>
    </>
  );
};
