import { ActionsColumnFormatter } from "./ActionsColumnFormatter";
import { sortCaret } from "../../../../_metronic/_helpers";
import { VigenciaColumnFormatter } from "./VigenciaColumnFormatter";

export const getColumns = (setShow, setIdDireccion) => [
  {
    dataField: "t_cod_cl",
    text: "CL",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "t_nombre_cliente",
    text: "Cliente",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "t_rut_cliente",
    text: "Cliente RUT",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "t_direccion",
    text: "Tienda",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "t_segmento",
    text: "Segmento",
    sort: true,
    sortCaret: sortCaret
  },

  {
    dataField: "t_eliminado",
    text: "Vigencia",
    sort: true,
    sortCaret: sortCaret,
    formatter: VigenciaColumnFormatter
  },
  {
    dataField: "accions",
    text: "Acciones",
    formatter: ActionsColumnFormatter,
    formatExtraData: { setShow, setIdDireccion },
    classes: "text-right pr-3 ",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "70px"
    }
  }
];
