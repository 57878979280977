import {createSlice} from "@reduxjs/toolkit";

const initialAlertsState = {
    show: false,
    alerts: [],

};

export const alertsSlice = createSlice({
    name: "alerts",
    initialState: initialAlertsState,
    reducers: {
        //add alert
        addAlert: (state, action) => {
            state.alerts.push(action.payload.alert);
        },
        // show all alerts
        showAll: (state, action) => {
            state.show = true;
        },
        cleanAll:(state, action) => {
            state.show = false
            state.alerts=[]
        },
    }
});

const {actions} = alertsSlice;

export const showAlerts = () => dispatch => {
    dispatch(actions.showAll({  }))
}
export const addAlert = (alertProps) => dispatch => {
    dispatch(actions.addAlert({ alert: alertProps }))
    dispatch(actions.showAll());
};
export const cleanAllAlerts = () => dispatch => {
    dispatch(actions.cleanAll());
};
