import React, { useRef, useState } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import { Button } from "react-bootstrap";
import { FormAddEditVisita } from "../../components/visitas/FormAddEditVisita";

export const EditVisita = ({ match }) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Editar Requerimiento");
  const { params } = match;
  const { id_visita } = params;
  const btnRef = useRef();
  let cardTitle = `Editar Requerimiento ${id_visita}`;
  const onHandleIn = () => btnRef.current.click();

  const [loading, setLoading] = useState(false);

  return (
    <>
      <Card>
        <CardHeader title={cardTitle}>
          <CardHeaderToolbar>
            {!loading && (
              <Button type="button" onClick={onHandleIn} variant={"success"}>
                {"Guardar"}
              </Button>
            )}
            {loading && (
              <span
                className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
              />
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <FormAddEditVisita
            id_visita={id_visita}
            setLoading={setLoading}
            btnRef={btnRef}
            newEntitie={false}
          />
        </CardBody>
      </Card>
      {/*<Card>
        <CardBody>
          <FormEditReporte
              id_visita={id_visita}
              setLoading={setLoading}
              btnRef={btnRef}
              newEntitie={false}
          />
        </CardBody>
      </Card>*/}
    </>
  );
};
