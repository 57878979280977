import { actionsLics } from "../licenciasRedux";
import { addItem } from '../restFunctios/addItem'

export const addItemAction = (id) => {
  return async (dispatch) => {
    dispatch(actionsLics.setAddingItem(true));
    const { response, error } = await addItem(id);
    dispatch(actionsLics.addLic(response.data.lic))
    dispatch(actionsLics.setAddingItem(false));

  };
};
