import React, { useEffect, useState } from "react";

import { Col, Form, InputGroup } from "react-bootstrap";
import { Checkbox } from "@material-ui/core";
import makeAnimated from "react-select/animated/dist/react-select.esm";

export const EliminadoVisFilter = props => {
  const { onChange } = props;
  const animatedComponents = makeAnimated();

  return (
    <Form.Group controlId="exampleForm.ControlInput1" as={Col}>
      <Form.Label>Eliminados</Form.Label>
      <Checkbox
        inputProps={{
          "aria-label": "primary checkbox"
        }}
        onChange={onChange}
      />
    </Form.Group>
  );
};
