const initialState = {
  inSync: false,
  last_sync: undefined
}

export const syncReducer = (state = initialState, action) => {
  switch (action.type) {
    case '@setInSync': {
      const { inSync } = action.payload
      return { ...state, inSync: inSync }
    }

    default:
      return state
  }
}
