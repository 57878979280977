import React from "react";
import { FeatureGroup, Marker, Popup } from "react-leaflet";
import moment from "moment";
import L from "leaflet";

export const MarkersUsus = ({ users }) => {
  const redIcon = new L.Icon({
    iconUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
    shadowUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  function UsusMarkersBlue() {
    return users
      .filter(x => moment(x.pos_fecha).isSameOrAfter(moment().startOf("day")))
      .map(x => (
        <Marker position={[x.pos_lat, x.pos_lon]} key={x.fullname}>
          <Popup>
            {x.fullname} <br /> {moment(x.pos_fecha).format("DD/MM/YYYY HH:mm")}
          </Popup>
        </Marker>
      ));
  }
  function UsusMarkersRed() {
    return users
      .filter(x => moment(x.pos_fecha).isBefore(moment().startOf("day")))
      .map(x => (
        <Marker
          position={[x.pos_lat, x.pos_lon]}
          key={x.fullname}
          icon={redIcon}
        >
          <Popup>
            {x.fullname} <br /> {moment(x.pos_fecha).format("DD/MM/YYYY HH:mm")}
          </Popup>
        </Marker>
      ));
  }

  return (
    <FeatureGroup>
      <UsusMarkersBlue />
      <UsusMarkersRed />
    </FeatureGroup>
  );
};
