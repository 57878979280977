const ioLayout_tx = {
  "text-field": "{hour}",
  "text-size": 9,
  "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
  //'text-offset':[0,1.1],
  //'text-anchor':'bottom',
  "text-keep-upright": false
  //'icon-image':'ioMarker-1',
  //'icon-size':0.8,
  //'icon-anchor':'bottom',
};
const ioPaint_tx = {
  "text-color": "white"
  //'text-halo-color': 'hsl(55, 11%, 96%)',
  //'text-halo-width': 1,
  //'icon-color':'#3887be',
  //'icon-halo-width': 5,
  //'icon-halo-color': 'hsl(55, 11%, 96%)',
};

const ioPaint_c = {
  "circle-stroke-width": 4,
  "circle-radius": 15,
  "circle-blur": 0.15,
  "circle-color": "#3770C6",
  "circle-stroke-color": "white"
};

const ioPaint_d = {
  "circle-stroke-width": 4,
  "circle-radius": 15,
  "circle-blur": 0.15,
  "circle-color": "#6bd707",
  "circle-stroke-color": "white"
};

export { ioPaint_tx, ioLayout_tx, ioPaint_c, ioPaint_d };
