import React from "react";

export function HeaderTablaInformeRepresentante({ symbol, dateSelected }) {
  return (
    <>
      <div style={styles.row}>
        <div style={{ ...styles.col }}>
          <h1 style={{ ...styles.title2, textAlign: "center" }}>
            MARCA
          </h1>
        </div>
        <div style={{ ...styles.col }}>
          <h1 style={styles.title2}>
            MTD {new Date(dateSelected).getFullYear()}
          </h1>
        </div>
        <div style={{ ...styles.col }}>
          <h1 style={styles.title2}>
            MTD {new Date(dateSelected).getFullYear() - 1}{" "}
          </h1>
        </div>

        <div style={{ ...styles.col }}>
          <h1 style={styles.title2}>
            Var vs {new Date(dateSelected).getFullYear() - 1}
          </h1>
        </div>
        <div style={{ ...styles.col }}>
          <h1 style={styles.title2}>
            MTD {new Date(dateSelected).getFullYear()}
          </h1>
        </div>
        <div style={{ ...styles.col }}>
          <h1 style={styles.title2}>
            MTD {new Date(dateSelected).getFullYear() - 1}{" "}
          </h1>
        </div>

        <div style={{ ...styles.col }}>
          <h1 style={styles.title2}>
            Var vs {new Date(dateSelected).getFullYear() - 1}
          </h1>
        </div>


        <div style={{ ...styles.col }}>
          <h1 style={styles.title2}>
            ASP {new Date(dateSelected).getFullYear()}
          </h1>
        </div>
        <div style={{ ...styles.col }}>
          <h1 style={styles.title2}>
            ASP {new Date(dateSelected).getFullYear() - 1}{" "}
          </h1>
        </div>

        <div style={{ ...styles.col }}>
          <h1 style={styles.title2}>
            Var vs {new Date(dateSelected).getFullYear() - 1}
          </h1>
        </div>
      </div>
    </>
  );
}

const styles = {
  redLine: {
    backgroundColor: "#ff0000",
    width: "100%",
    borderRadius: 10
  },
  col: {
    width: "25%"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignSelf: "center",
    backgroundColor: "#ff0000",
    paddingHorizontal: 10,
    paddingBottom: 10
  },
  title: {
    color: "#000000",
    fontSize: 36,
    textAlign: "center"
  },
  title2: {
    justifySelf: "center",
    textAlign: "center",
    color: "white",
    fontSize: 13,
    marginTop: 12,
    fontWeight: "bold"
  }
};
