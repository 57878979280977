import React from "react";
import { exportToExcelPlantillaMasterPlanJs } from "../../../helpers/XLSHelpers";
import moment from "moment";

const preparePlantillaXLS = async direcciones => {
  const data = [];
  const dataCarga = [];
  for (const d of direcciones)
    dataCarga.push({
      CL: d.t_cod_cl,
      Tienda: d.t_direccion,
      Cliente: d.t_nombre_cliente,
      "Cliente RUT": d.t_rut_cliente,
      Latitud: d.t_latitude,
      Longitud: d.t_longitude,
      Segmento: d.t_segmento
    });

  const carga = { sheetName: "Data", data: dataCarga };

  data.push(carga);
  return data;
};

export const generatePlantillaDireccionesXls = async Direcciones => {
  const fileName = `Tiendas`;
  const csvData = await preparePlantillaXLS(Direcciones);
  await exportToExcelPlantillaMasterPlanJs(csvData, fileName);
};
