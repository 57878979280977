import React from 'react'
import moment from 'moment'

export const TableReporte = ({ reporte }) => {
  return (
    <table className="table">
      <thead>
      <tr>
        <th scope="col">Fecha</th>
        <th scope="col">Cuenta</th>
        <th scope="col">Sap / Código</th>
        <th scope="col">Inventor</th>
        <th scope="col">Puntuación</th>
      </tr>
      </thead>
      <tbody>
      {reporte && reporte.map(v => (
        <tr>
          <td>{moment(v.visitas_fecvisita).format('DD-MM-YYYY')}</td>
          <td>{v.direcciones_nombre}</td>
          <td>{v.codigo}</td>
          <td>{v.tec_fullname}</td>
          <td>{v.reporte_resultado_porcentaje}</td>
        </tr>
      ))}

      </tbody>
    </table>
  )
}
