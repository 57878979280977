import React, { useEffect, useState } from "react";

import { InitialRepFilter } from "./InitialRepFilters";
import { FecRepFilter } from "./FecRepFilter";
import { TecRepFilter } from "./TecRepFilter";

export const RepFilters = props => {
  const { queryParams, setQueryParams } = props;

  const [filters, setFilters] = useState(InitialRepFilter);
  const prepareFilter = queryParams => {
    const newQueryParams = { ...queryParams };
    const filter = {};

    filter["tecnicologin"] =
      filters.tecnicologin !== "" ? filters.tecnicologin : undefined;

    filter["tecnico"] = filters.tecnico !== "" ? filters.tecnico : undefined;
    filter["fecha"] = filters.fecha ? filters.fecha : undefined;

    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const handleChangeTecnicoLogin = tec =>
    setFilters({ ...filters, tecnicologin: tec.usu_login, tecnico: tec.value });

  const handleChangeFecha = date => setFilters({ ...filters, fecha: date });

  useEffect(() => {
    const newQueryParams = prepareFilter(queryParams);
    setQueryParams({ ...newQueryParams });
  }, [filters]);

  return (
    <div className="form-group row m-0">
      <FecRepFilter
        onChange={handleChangeFecha}
        label={"Fecha"}
        value={filters.fecha}
      />
      <TecRepFilter onChange={handleChangeTecnicoLogin} />
    </div>
  );
};
