import { ActionsColumnFormatter } from "./ActionsColumnFormatter";
import { sortCaret } from "../../../../_metronic/_helpers";
import { ColorColumnFormatter } from "./ColorColumnFormatter";
import { VigenciaColumnFormatter } from "./VigenciaColumnFormatter";

export const getColumns = (setShow, setIdTipoVisita) => [
  {
    dataField: "mv_descripcion",
    text: "Nombre",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "mv_color_code",
    text: "Color",
    sort: true,
    sortCaret: sortCaret,
    formatter: ColorColumnFormatter
  },
  {
    dataField: "eliminado",
    text: "Vigencia",
    sort: true,
    sortCaret: sortCaret,
    formatter: VigenciaColumnFormatter
  },
  {
    dataField: "accions",
    text: "Acciones",
    formatter: ActionsColumnFormatter,
    formatExtraData: { setShow, setIdTipoVisita },
    classes: "text-right pr-3 ",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "70px"
    }
  }
];
