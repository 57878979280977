import React, { useEffect, useRef, useState } from "react";
import * as requestFromServer from "../../helpers/axios";
import { ListaUsuariosRBox } from "../components/mapas/ListaUsuariosRBox";
import { MapContainer, TileLayer } from "react-leaflet";
import { BotonesMapas } from "./components/BotonesMapas";
import { MarkersUsus } from "./components/MarkersUsus";
import { getBounds } from "../components/mapas/BoundsCalc";

export const MapaTecnicos = () => {
  const btnOpenRef = useRef();
  const [users, setUsers] = useState([]);
  const [map, setMap] = useState(null);

  const getUsersPos = () =>
    requestFromServer
      .getUsersPos()
      .then(response => {
        const ents = response.data.entities.filter(x => x.pos_lat && x.pos_lon);
        setUsers(ents);
      })
      .catch(err => console.log(err));

  useEffect(() => {
    setTimeout(() => {
      getUsersPos();
    }, 1000);
  }, []);
  useEffect(() => {
    // Centrar el mapa al ingresar a la página
    if (!map) return;
    if (users.length === 0) return;
    const bounds = getBounds(users.map(x => [x.pos_lat, x.pos_lon]));
    map.fitBounds(bounds, {
      padding: [10, 10]
    });
  }, [users]);

  return (
    <>
      <ListaUsuariosRBox btnOpenRef={btnOpenRef} users={users} map={map} />
      <MapContainer
        center={[-33.44889, -70.669265]}
        ref={setMap}
        zoom={10}
        scrollWheelZoom={true}
        style={{ height: "85vh", width: "100wh" }}
      >
        <MarkersUsus users={users} />
        <BotonesMapas users={users} map={map} btnOpenRef={btnOpenRef} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    </>
  );
};
