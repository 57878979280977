import React, { useEffect, useState } from "react";
import { InitialFilter } from "./InitialFilters";
import { TipoVisFilter } from "./TipoVisFilter";
import { HolVisFilter } from "./HolVisFilter";
import { useSelector } from "react-redux";
import SelectAsyncPaginationCli from "../../../components/AsyncSelects/SelectAsyncPaginationCli";
import SelectAsyncPaginationUsu from "../../../components/AsyncSelects/SelectAsyncPaginationUsu";
import { SegmentoFilter } from "../../visitas/table_helpers/filter_comps/SegmentoFilter";
import SelectAsyncPaginationDir from "../../../components/AsyncSelects/SelectAsyncPaginationDir";

export const FiltersCalendario = props => {
  const { queryParams, setQueryParams } = props;
  const [clienteSelected, setClienteSelected] = useState({
    label: "Todos",
    value: null
  });
  const [tecnicoSelected, setTecnicoSelected] = useState({
    label: "Todos",
    value: null
  });
  const [dirSelected, setDirSelected] = useState({
    label: "Todos",
    value: null
  });
  const [filters, setFilters] = useState(InitialFilter);
  const prepareFilter = queryParams => {
    const newQueryParams = { ...queryParams };
    const filter = { ...queryParams.filter };
    filter["tecnico"] = filters.tecnico !== "" ? filters.tecnico : undefined;
    filter["tipo"] = filters.tipo !== "" ? filters.tipo : undefined;
    filter["id_grupc"] = filters.id_grupc !== "" ? filters.id_grupc : undefined;
    filter["id_clientes"] =
      filters.id_clientes !== "" ? filters.id_clientes : undefined;
    filter["segmento"] = filters.segmento ? filters.segmento : undefined;
    filter["direccion"] =
      filters.direccion !== "" ? filters.direccion : undefined;
    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const handleChangeTecnico = e => {
    setTecnicoSelected(e);
    setFilters({ ...filters, tecnico: e.value });
  };
  const handleChangeCliVis = e => {
    setClienteSelected(e);
    setFilters({ ...filters, id_clientes: e.value });
  };
  const handleChangeSegmento = segmento =>
    setFilters({ ...filters, segmento: segmento });
  const handleChangeDireccion = e => {
    setDirSelected(e);
    setFilters({ ...filters, direccion: e.value });
  };
  useEffect(() => {
    const newQueryParams = prepareFilter(queryParams);
    setQueryParams({ ...newQueryParams });
  }, [filters]);

  const { user } = useSelector(state => state.auth);

  return (
    <div className="form-group row mb-0">
      {user.id_perfil && user.id_perfil !== 6 && user.id_perfil !== 7 && (
        <>
          {user?.id_perfil !== 3 && (
            <SelectAsyncPaginationUsu
              value={tecnicoSelected}
              onChange={handleChangeTecnico}
              md={3}
              isFilter={true}
              label={"Representante"}
              id_perfil={3}
            />
          )}
          <SelectAsyncPaginationCli
            value={clienteSelected}
            holding={filters.id_grupc}
            onChange={handleChangeCliVis}
            md={3}
            isFilter={true}
          />
          <SelectAsyncPaginationDir
            cliente={filters.id_clientes}
            value={dirSelected}
            onChange={handleChangeDireccion}
            md={4}
            isFilter={true}
          />
          <SegmentoFilter
            onChange={handleChangeSegmento}
            value={filters.segmento}
          />
        </>
      )}
    </div>
  );
};
