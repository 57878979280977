import { ActionsColumnFormatter } from "./ActionsColumnFormatter";
import { sortCaret } from "../../../../../_metronic/_helpers";
import { VigenciaColumnFormatter } from "./VigenciaColumnFormatter";
export const getColumns = (setShowDelete, setIdRepositorio) => [
  {
    dataField: "repositorio_nombre",
    text: "Nombre",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "eliminado",
    text: "Vigencia",
    sort: true,
    sortCaret: sortCaret,
    formatter: VigenciaColumnFormatter
  },
  {
    dataField: "accions",
    text: "Acciones",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-3 ",
    formatExtraData: { setShowDelete, setIdRepositorio },
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "70px"
    }
  }
];
