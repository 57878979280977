import React from "react";
import {Formik, Form, Field} from "formik";
import {Input} from "../../../../_metronic/_partials/controls";
import {ChangePassValidationSchema} from "./ChangePassFormValidatorSchema";
import * as requestFromServer from "../../../../app/modules/Auth/_redux/authCrud";
import {useDispatch} from "react-redux";
import {addAlert} from "../../../../redux/alerts/alertsRedux"

export const ChangePassForm= ({initValues,btnRef,handleClose,errorHandler,setIsLoading})=> {

    const dispatch = useDispatch();

    const changePass=values=>{
        setIsLoading(true)
        errorHandler.setIsError(false)

        requestFromServer
            .requestChangePass(values)
            .then(response => {
                //cambiado con exito
                setIsLoading(false)

                dispatch(addAlert({msg:"Contraseña cambiada con éxito"}))

                handleClose()
            })
            .catch(error => {
                setIsLoading(false)
                errorHandler.setIsError(true)

                if (error.response) errorHandler.setErrorMsg(error.response.data)
                else {
                    errorHandler.setErrorMsg("Ha ocurrido un error, comuníquese con el administrador del sistema ")
                    console.log(error)
                }
            });
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initValues}
                validationSchema={ChangePassValidationSchema}
                onSubmit={(values) => {
                    changePass(values);
                }}

            >
                {({handleSubmit}) => (
                    <>
                        <Form className="form form-label-right">
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Field
                                        name="oldPass"
                                        component={Input}
                                        label="Contraseña Actual"
                                        withFeedbackLabel={false}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <Field
                                        name="newPass"
                                        component={Input}
                                        label={"Nueva contraseña"}
                                        withFeedbackLabel={false}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <Field
                                        name="newPassAgain"
                                        component={Input}
                                        label="Repite la nueva contraseña"
                                        withFeedbackLabel={false}

                                    />
                                </div>
                            </div>

                            <button
                                type="submit"
                                style={{display: 'none'}}
                                ref={btnRef}
                                onSubmit={() => handleSubmit()}
                            ></button>
                        </Form>
                    </>
                )}
            </Formik>

        </>
    );
}
