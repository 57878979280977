import { actionsLics } from "../licenciasRedux";
import { getList } from "../restFunctios/getList";

export const getListAction = (queryParams) => {
  return async (dispatch) => {
    dispatch(actionsLics.setLoadingList(true));
    const { data, error } = await getList(queryParams);
    if (data) dispatch(actionsLics.setLics(data.entities,data.totalCount));
    if (error) dispatch(actionsLics.setIsError(true));
    else dispatch(actionsLics.setIsError(false));
    dispatch(actionsLics.setLoadingList(false));
  };
};
