import React from 'react'
import { useSelector } from 'react-redux'

export const AsideMenuDivider = ({ label, permissions }) => {
  const { user } = useSelector(state => state.auth)
  const isAuth =
    user &&
    permissions.reduce(
      (acc, curr) => acc || (user.permisos && user.permisos.includes(curr)),
      false,
    )
  return (
    <>
      {isAuth && (
        <li className="menu-section ">
          <h4 className="menu-text">{label}</h4>
        </li>
      )}
    </>
  )
}
