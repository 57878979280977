import axios from "axios";

export const REQUEST_URL = "api/tiposvis";
export const getTiposVisita = queryParams =>
  axios.post(`${REQUEST_URL}/getTiposVisita`, { queryParams });
export const insertTipoVisita = tipoVisita =>
  axios.put(`${REQUEST_URL}/put`, { tipoVis: tipoVisita });
export const updateTipoVisita = (id, tipoVisita) =>
  axios.put(`${REQUEST_URL}/update/${id}`, { tipoVis: tipoVisita });
export const getTipoVisita = id =>
  axios.get(`${REQUEST_URL}/getTipoVisita/${id}`);
export const deleteTipoVisita = id => axios.put(`${REQUEST_URL}/delete/${id}`);
