import React from "react";
import { HeaderTablaInformeCliente } from "./HeaderTablaInformeCliente";
import { RowTablaInformeCliente } from "./RowTablaInformeCliente";
import { HeaderTablaInformeRepresentante } from "./HeaderTablaInformeRepresentante";
import { RowTablaInformeRepresentante } from "./RowTablaInformeRepresentante";

export function TablaInfRepMarca({ informe, dateSelected }) {
  return (
    <>
      <HeaderTablaInformeRepresentante dateSelected={dateSelected} />
      {informe &&
        informe.map(m => (
          <RowTablaInformeRepresentante
            prod={m.ventas_marca}
            lastYear={`${m.ventas_valor_neto2}`}
            thisYear={`${m.ventas_valor_neto}`}
            varPercentage={
              ((m.ventas_valor_neto - m.ventas_valor_neto2) /
                m.ventas_valor_neto2) *
              100
            }
            clastYear={`${m.ventas_cant_facturada2}`}
            cthisYear={`${m.ventas_cant_facturada}`}
            cvarPercentage={
              ((m.ventas_cant_facturada - m.ventas_cant_facturada2) /
                m.ventas_cant_facturada2) *
              100
            }
            asplastYear={`${m.ventas_valor_asp2}`}
            aspthisYear={`${m.ventas_valor_asp}`}
            aspvarPercentage={
              ((m.ventas_valor_asp - m.ventas_valor_asp2) /
                m.ventas_valor_asp2) *
              100
            }
            bold={true}
          />
        ))}
    </>
  );
}
