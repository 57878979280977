import React from "react";
import { Badge } from "react-bootstrap";

export const VigenciaColumnFormatter = (cellContent, row, rowIndex) => {
  return (
    <>
      <Badge variant={`${row.t_eliminado ? "danger" : "primary"}`}>
        {row.t_eliminado ? "No Vigente" : "Vigente"}
      </Badge>
    </>
  );
};
