import React, { useEffect, useState } from "react";
import { Calendario } from "../../components/calendarios/Calendario";
import * as requestFromServer from "../../../helpers/axios";
import * as moment from "moment";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import { FiltersCalendario } from "./filter_comps/FiltersCalendario";
import { Button, Form } from "react-bootstrap";
import { ModalChangeVisita } from "../../components/calendarios/ModalChangeVisita";
import { useDispatch, useSelector } from "react-redux";
import { CalendarioBadge } from "../../components/CalendarioBadge";
import { ModalGetVisitas } from "../../components/calendarios/ModalGetVisitas";

export const CalendarioVisitas = () => {
  const [visitas, setVisitas] = useState(null);
  const [motivosVisitas, setMotivosVisitas] = useState([]);
  const [visita, setVisita] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showGetVisita, setShowGetVisita] = useState(false);
  const [cantVisitas, setCantVisitas] = useState(0);

  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = useState({
    filter: {
      fecha: moment().startOf("month")
    }
  });
  const { user } = useSelector(state => state.auth);

  const OnClickBuscar = async () => {
    setShow(false);
    setShowGetVisita(false);
    setLoading(true);
    queryParams.filter.estado = 2;
    if (user.id_perfil === 3) {
      queryParams.filter.tecnico = user.id;
    }

    const eventos = await requestFromServer.getEventos(queryParams).then(r => {
      if (r.data) {
        return r.data.entities.map(x => ({
          id_visita: x.id_visitas,
          title: x.evento_asunto,
          start: moment(x.evento_inicio).format("YYYY-MM-DD HH:mm:ss"),
          end: moment(x.evento_termino).format("YYYY-MM-DD HH:mm:ss"),
          evento: true,
          tooltipCont: x.evento_notas,
          representante: x.fullname,
          asunto: x.evento_asunto,
          backgroundColor: x.mv_color_code ? x.mv_color_code : "#000000",
          borderColor: x.mv_color_code ? x.mv_color_code : "#000000",
          id_mv: x.id_mv
        }));
      } else {
        return [];
      }
    });
    setVisitas([...eventos]);
    setLoading(false);
  };

  const OnClickBuscarCantidad = async () => {
    setShow(false);
    setLoading(true);
    queryParams.filter.estado = 2;
    if (user.id_perfil === 3) {
      queryParams.filter.tecnico = user.id;
    }

    const cantEventos = await requestFromServer
      .getEventos(queryParams)
      .then(r => {
        if (r.data) {
          return r.data.totalCount;
        } else {
          return 0;
        }
      });
    setCantVisitas(cantEventos);
    setShowGetVisita(true);
    setLoading(false);
  };

  const handleChangeDate = () => {
    /* setLoading(true);
    requestFromServer
      .updateVisitaCalendario(visita.id_visita, visita)
      .then(response => {
        dispatch(addAlert({ msg: "Visita Reasignada" }));
        setShow(false);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));*/
  };

  const OnEventDrop = info => {
    /*
    setShow(true);
    const id_visita = info.event._def.extendedProps.id_visita;
    const { end: newEnd } = info.event._instance.range;
    setVisita({
      ...visita,
      visitas_fecvisita: moment(newEnd).format("YYYY-MM-DD"),
      id_visita: id_visita
    });
     */
  };

  useEffect(() => {
    setLoading(true);
    requestFromServer
      .getTiposVisita({ eliminado: false })
      .then(response => {
        if (response.data) {
          setMotivosVisitas(response.data.entities);
          setLoading(false);
        } else {
          setMotivosVisitas([]);
          setLoading(false);
        }
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, []);
  return (
    <>
      <Card>
        <CardHeader title="Filtros">
          <CardHeaderToolbar>
            {!loading && (
              <Button type="button" onClick={OnClickBuscarCantidad}>
                {"Buscar"}
              </Button>
            )}

            {loading && (
              <span
                className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
              />
            )}
            <ModalChangeVisita
              show={show}
              setShow={setShow}
              handleChangeDate={handleChangeDate}
              OnClickBuscar={OnClickBuscar}
            />
            <ModalGetVisitas
              show={showGetVisita}
              setShow={setShowGetVisita}
              OnClickBuscar={OnClickBuscar}
              cantRequerimientos={cantVisitas}
            />
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <FiltersCalendario
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />
        </CardBody>
        <CardFooter>
          <Form.Row>
            {motivosVisitas.map((x, index) => (
              <CalendarioBadge
                key={"badge-" + index}
                label={x.mv_descripcion}
                quantity={
                  visitas.filter(visita => visita.id_mv === x.id).length
                }
                color={x.mv_color_code}
              />
            ))}
          </Form.Row>
        </CardFooter>
      </Card>
      <Card>
        <CardBody>
          <Calendario
            visitas={visitas === null ? [] : visitas}
            setQueryParams={setQueryParams}
            queryParams={queryParams}
            setVisitas={setVisitas}
            eventDurationEditable={false}
            eventStartEditable={false}
          />
        </CardBody>
      </Card>
    </>
  );
};
