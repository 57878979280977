import React, { useEffect, useState } from 'react'
import { InitialFilter } from './InitialFilters'
import { FecVisFilter } from './FecVisFilter'
import { HoldingVisFilter } from './HoldingVisFilter'
import SelectAsyncPaginationCli
  from '../../../../components/AsyncSelects/SelectAsyncPaginationCli'
import SelectAsyncPaginationDir
  from '../../../../components/AsyncSelects/SelectAsyncPaginationDir'
import SelectAsyncPaginationUsu
  from '../../../../components/AsyncSelects/SelectAsyncPaginationUsu'

export const Filters = props => {
  const { queryParams, setQueryParams } = props

  const [filters, setFilters] = useState(InitialFilter)
  const [dirSelected, setDirSelected] = useState({})
  const [clienteSelected, setClienteSelected] = useState({})
  const [tecnicoSelected, setTecnicoSelected] = useState({})
  const prepareFilter = queryParams => {
    const newQueryParams = { ...queryParams }
    const filter = {}
    filter['tecnico'] = filters.tecnico !== '' ? filters.tecnico : undefined
    filter['id_clientes'] =
      filters.cliente !== '' ? filters.cliente : undefined
    filter['direccion'] =
      filters.direccion !== '' ? filters.direccion : undefined

    filter["fDesdeAsig"] = filters.fecDesdeAsigna
      ? filters.fecDesdeAsigna
      : undefined;
    filter["fHastaAsig"] = filters.fecHastaAsigna
      ? filters.fecHastaAsigna
      : undefined;

    filter['id_grupc'] = filters.id_grupc ? filters.id_grupc : undefined
    newQueryParams.filter = filter
    return newQueryParams
  }

  const handleChangeTecnico = e => {
    setTecnicoSelected(e)
    setFilters({ ...filters, tecnico: e.value })
  }
  const handleChangeCliente = e => {
    setClienteSelected(e)
    setFilters({ ...filters, cliente: e.value })
  }
  const handleChangeDireccion = e => {
    setDirSelected(e)
    setFilters({ ...filters, direccion: e.value })
  }
  const handleChangeHolding = e => {
    setFilters({ ...filters, id_grupc: e.value })
  }

  const handleChangeFAsigD = date =>
    setFilters({ ...filters, fecDesdeAsigna: date })
  const handleChangeFAsigH = date =>
    setFilters({ ...filters, fecHastaAsigna: date })

  useEffect(() => {
    const newQueryParams = prepareFilter(queryParams)
    setQueryParams({ ...newQueryParams, pageNumber: 1 })
  }, [filters])

  return (
    <div className="form-group row">
      <FecVisFilter
        onChange={handleChangeFAsigD}
        label={'F. desde Asignación'}
        value={filters.fecDesdeAsigna}
      />
      <FecVisFilter
        onChange={handleChangeFAsigH}
        label={'F. hasta Asignación'}
        value={filters.fecHastaAsigna}
      />
      <SelectAsyncPaginationUsu
        value={tecnicoSelected}
        onChange={handleChangeTecnico}
        md={3}
        isFilter={true}
        label={'Técnico'}
        id_perfil={3}
      />
      <HoldingVisFilter onChange={handleChangeHolding}/>
      <SelectAsyncPaginationCli
        value={clienteSelected}
        holding={filters.id_grupc}
        onChange={handleChangeCliente}
        md={4}
        isFilter={true}
      />
      <SelectAsyncPaginationDir
        cliente={filters.cliente}
        value={dirSelected}
        onChange={handleChangeDireccion}
        md={4}
        isFilter={true}
      />

    </div>
  )
}
