import axios from "axios";

export const REQUEST_URL = "api/campanasvisitas";
export const getCampanasVisitas = queryParams =>
  axios.post(`${REQUEST_URL}/getCampanasVisitas`, { queryParams });
export const getCampanasVisitasCli = queryParams =>
  axios.post(`${REQUEST_URL}/getCampanasVisitasCli`);
export const getEstadosCampanas = queryParams =>
  axios.post(`${REQUEST_URL}/getEstadosCampanas`, { queryParams });
export const getCampanasVisitaXlsx = queryParams =>
  axios.post(`${REQUEST_URL}/getCampanasVisitaXlsx`, { queryParams });
export const getCampanasVisitaCargaMasiva = id =>
  axios.get(`${REQUEST_URL}/getCampanasVisitaCargaMasiva/${id}`);
export const putCampanaVisita = (campanaVisita, extension) =>
  axios.put(`${REQUEST_URL}/put`, {
    campanaVisita: campanaVisita,
    extension: extension
  });
export const putCargaMasivaCampanas = (data, id) =>
  axios.post(`${REQUEST_URL}/putCargaMasivaCampanas/${id}`, { data: data });

export const updateCampanaVisita = (id, campanaVisita) =>
  axios.put(`${REQUEST_URL}/update/${id}`, { campanaVisita: campanaVisita });
export const getCampanaVisita = id =>
  axios.get(`${REQUEST_URL}/getCampanaVisita/${id}`);
export const deleteCampanaVenta = id =>
  axios.put(`${REQUEST_URL}/delete/${id}`);
export const pauseCampanaVenta = id => axios.put(`${REQUEST_URL}/pause/${id}`);
export const resumeCampanaVenta = id =>
  axios.put(`${REQUEST_URL}/resume/${id}`);
