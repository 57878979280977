import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import { Button, Form } from "react-bootstrap";
import * as requestFromServer from "../../helpers/axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { addAlert } from "../../../redux/alerts/alertsRedux";
import { useDispatch } from "react-redux";
import moment from "moment";
import { generateProMetasXls } from "./xls/xls_create";
import { useSubheader } from "../../../_metronic/layout";

export const ProMetas = () => {
  const animatedComponents = makeAnimated();

  const suhbeader = useSubheader();
  suhbeader.setTitle("Pronostico Metas");
  const dispatch = useDispatch();
  const [usuarios, setUsuarios] = React.useState([]);
  const [metas, setMetas] = React.useState([]);
  const [metasAcumulado, setMetasAcumulado] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [ventas, setVentas] = React.useState([]);
  const [ventasAcumulado, setVentasAcumulado] = React.useState([]);
  const [loadingData, setLoadingData] = React.useState(false);
  const [month, setMonth] = React.useState(null);
  const [year, setYear] = React.useState(null);
  const [usuSelected, setUsuSelected] = React.useState({});

  const getDaysInMonth = (month, year) => {
    // Since no month has fewer than 28 days
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      days.push(moment(date).format("DD"));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const getDataByDays = (month, year, data) => {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      let ventas = 0;
      if (data.find(x => x.day === moment(date).format("DD")))
        ventas = parseInt(
          data.find(x => x.day === moment(date).format("DD")).ventas
        );
      days.push(ventas);
      date.setDate(date.getDate() + 1);
    }
    return days;
  };
  const getDataAcumuladoByDays = (month, year, data) => {
    const date = new Date(year, month, 1);
    const days = [];
    let ventas = 0;
    while (date.getMonth() === month) {
      if (data.find(x => x.day === moment(date).format("DD")))
        ventas += parseInt(
          data.find(x => x.day === moment(date).format("DD")).ventas
        );
      days.push(ventas);
      date.setDate(date.getDate() + 1);
    }
    return days;
  };
  const getMetaByDays = (metas, month, year, data) => {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      let factor = 0;
      if (data.find(x => x.day === moment(date).format("DD")))
        factor = parseFloat(
          data.find(x => x.day === moment(date).format("DD")).factor
        );
      days.push(Math.round(metas * factor));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };
  const getMetaAcumuladoByDays = (metas, month, year, data) => {
    const date = new Date(year, month, 1);
    const days = [];
    let factor = 0;
    while (date.getMonth() === month) {
      if (data.find(x => x.day === moment(date).format("DD")))
        factor += parseFloat(
          data.find(x => x.day === moment(date).format("DD")).factor
        );
      days.push(Math.round(metas * factor));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const baseHighChart = {
    chart: {
      type: "spline"
    },
    title: {
      text: ""
    },
    xAxis: {
      categories: getDaysInMonth(month, year)
    },
    yAxis: {
      title: {
        text: "Ventas"
      },
      labels: {
        format: "{value}$"
      },
      min: 0
    },
    tooltip: {
      crosshairs: true,
      shared: true
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          lineColor: "#666666",
          lineWidth: 1
        }
      }
    },
    series: [
      {
        name: "Ventas",
        marker: {
          symbol: "square"
        },
        data: ventas
      },
      {
        name: "Meta",
        marker: {
          symbol: "triangle"
        },
        data: metas
      }
    ]
  };
  const baseHighChartAcumulado = {
    chart: {
      type: "spline"
    },
    title: {
      text: "Pronostico Metas Acumulado"
    },
    xAxis: {
      categories: getDaysInMonth(month, year)
    },
    yAxis: {
      title: {
        text: "Ventas"
      },
      min: 0,
      labels: {
        format: "{value}$"
      }
    },
    tooltip: {
      crosshairs: true,
      shared: true
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          lineColor: "#666666",
          lineWidth: 1
        }
      }
    },
    series: [
      {
        name: "Ventas",
        marker: {
          symbol: "square"
        },
        data: ventasAcumulado
      },
      {
        name: "Meta",
        marker: {
          symbol: "triangle"
        },
        data: metasAcumulado
      }
    ]
  };

  const months = [
    { value: 0, label: "Enero" },
    { value: 1, label: "Febrero" },
    { value: 2, label: "Marzo" },
    { value: 3, label: "Abril" },
    { value: 4, label: "Mayo" },
    { value: 5, label: "Junio" },
    { value: 6, label: "Julio" },
    { value: 7, label: "Agosto" },
    { value: 8, label: "Septiembre" },
    { value: 9, label: "Octubre" },
    { value: 10, label: "Noviembre" },
    { value: 11, label: "Diciembre" }
  ];
  const years = [
    { value: 2023, label: 2023 },
    { value: 2024, label: 2024 },
    { value: 2025, label: 2025 }
  ];
  let cardTitle = "Pronostico Metas";

  const onChangeMonth = e => {
    setMonth(e.value);
  };
  const onChangeYear = e => {
    setYear(e.value);
  };

  const onChangeUsu = usu => {
    setUsuSelected(usu);
  };

  const fetchUsuarios = () => {
    setLoading(true);
    requestFromServer
      .getUsuarios({ filter: { id_perfil: 3 } })
      .then(response => {
        if (response.data) {
          setUsuarios([
            ...response.data.entities.map(x => ({
              value: x.id,
              label: x.fullname
            }))
          ]);
        } else {
          setUsuarios([]);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  const fetchProMetas = async () => {
    setLoading(true);
    const metasResponse = await requestFromServer.getMetas({
      filter: {
        meta_month: month,
        meta_year: year,
        id_usu: usuSelected.value
      }
    });
    let metas = [0];
    if (metasResponse.data) {
      metas = metasResponse.data?.entities.map(x => x.meta_cantidad);
    }
    requestFromServer
      .getProMetas({
        filter: {
          month: month,
          year: year,
          id_usu: usuSelected.value
        }
      })
      .then(response => {
        if (response.data) {
          setMetas(
            getMetaByDays(
              metas.reduce((partialSum, a) => partialSum + a, 0),
              month,
              year,
              response.data.map(x => ({
                factor: x.factor,
                day: moment(x.fecha).format("DD")
              }))
            )
          );
          setMetasAcumulado(
            getMetaAcumuladoByDays(
              metas.reduce((partialSum, a) => partialSum + a, 0),
              month,
              year,
              response.data.map(x => ({
                factor: x.factor,
                day: moment(x.fecha).format("DD")
              }))
            )
          );
          setVentas(
            getDataByDays(
              month,
              2023,
              response.data.map(x => ({
                ventas: x.ventas,
                day: moment(x.fecha).format("DD")
              }))
            )
          );

          setVentasAcumulado(
            getDataAcumuladoByDays(
              month,
              2023,
              response.data.map(x => ({
                ventas: x.ventas,
                day: moment(x.fecha).format("DD")
              }))
            )
          );
        } else {
          dispatch(addAlert({ msg: "No existen metas para este mes" }));
          setLoadingData(false);
          setMetas([]);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoadingData(false);
        setLoading(false);
      });
  };

  const fetchData = async () => {
    if (month === null) return dispatch(addAlert({ msg: "Debe seleccionar un mes" }));
    if (!year) return dispatch(addAlert({ msg: "Debe seleccionar un año" }));
    if (!usuSelected.value)
      return dispatch(addAlert({ msg: "Debe seleccionar un representante" }));
    setLoadingData(true);

    await fetchProMetas();
    setLoadingData(false);
  };

  useEffect(() => {
    fetchUsuarios();
  }, []);

  const handleXls = async () => {
    if (!month) return dispatch(addAlert({ msg: "Debe seleccionar un mes" }));
    const metasResponse = await requestFromServer.getMetas({
      filter: {
        meta_month: month,
        meta_year: year
      }
    });
    requestFromServer
      .getProMetas({
        filter: {
          month: month,
          year: year
        }
      })
      .then(response => {
        if (response.data) {
          generateProMetasXls(response.data, metasResponse.data.entities);
        }
      })
      .catch(error => {
        console.log(error);
        setLoadingData(false);
        setLoading(false);
      });
  };

  return (
    <>
      <Card>
        <CardHeader title={cardTitle}>
          <CardHeaderToolbar>
            {!loadingData && (
              <Button type="button" onClick={fetchData}>
                {"Buscar"}
              </Button>
            )}
            &nbsp;
            {!loadingData && (
              <Button type="button" variant={"success"} onClick={handleXls}>
                {"XLS"}
              </Button>
            )}
            {loadingData && (
              <span
                className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
              />
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-sm-12 col-md">
              <Select
                options={usuarios}
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={onChangeUsu}
                value={usuarios.find(x => x.value === usuSelected.value)}
                placeholder={" "}
              />

              <small className="form-text text-muted">
                <b>Filtrar</b> por representante
              </small>
            </div>
            <div className="col-sm-6 col-md">
              <Select
                options={years}
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={onChangeYear}
                value={years.find(x => x.value === year)}
                placeholder={" "}
              />

              <small className="form-text text-muted">
                <b>Filtrar</b> por año
              </small>
            </div>
            <div className="col-sm-6 col-md">
              <Select
                options={months}
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={onChangeMonth}
                value={months.find(x => x.value === month)}
                placeholder={" "}
              />

              <small className="form-text text-muted">
                <b>Filtrar</b> por mes
              </small>
            </div>
          </div>

          <Form.Row>
            <div className="col-sm-12 col-md">
              {!loadingData && ventas.length > 0 && metas.length > 0 && (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{ ...baseHighChart }}
                />
              )}
              {!loadingData && ventas.length > 0 && metas.length > 0 && (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{ ...baseHighChartAcumulado }}
                />
              )}
            </div>
          </Form.Row>
        </CardBody>
      </Card>
    </>
  );
};
