import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "./styles.css";
import { Checkbox } from "@material-ui/core";
import SelectAsyncPaginationDirMulti from "../../components/AsyncSelects/SelectAsyncPaginationDirMulti";
import { useSelector } from "react-redux";

export const FormCampanaVisita = props => {
  const {
    campanaVisita,
    setCampanaVisita,
    sendCampanaVisitaToServer,
    newEntity,
    btnRef
  } = props;
  const [validated, setValidated] = useState(false);
  const [dirSelected, setDirSelected] = useState([]);
  const { tiendas } = useSelector(state => state.tiendas);
  const onChangeNombre = e =>
    setCampanaVisita({
      ...campanaVisita,
      campana_venta_nombre: e.target.value
    });

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      sendCampanaVisitaToServer();
    }
    setValidated(true);
  };
  const onChangeFecInicio = date =>
    setCampanaVisita({ ...campanaVisita, campana_venta_fecha_inicio: date });
  const onChangeFecTermino = date =>
    setCampanaVisita({ ...campanaVisita, campana_venta_fecha_termino: date });
  const handleInputChange = ev => {
    setCampanaVisita({
      ...campanaVisita,
      campana_venta_archivo: ev.target.files[0]
    });
  };
  const onChangeCheckCliente = e =>
    setCampanaVisita({
      ...campanaVisita,
      campana_venta_all_clientes: e.target.checked
    });
  const onChangeClientes = p => {
    setDirSelected(p);
    setCampanaVisita({ ...campanaVisita, campana_clientes: p });
  };
  useEffect(() => {
    if (
      !newEntity &&
      campanaVisita &&
      campanaVisita.campana_clientes &&
      campanaVisita.campana_clientes.length > 0
    ) {
      setDirSelected(
        campanaVisita.campana_clientes.map(x => ({
          value: x.value,
          label: getLabelTiendas(x.value)
        }))
      );
    }
  }, [campanaVisita.campana_clientes]);

  const getLabelTiendas = value => {
    const x = tiendas.find(x => x.t_cod_cl === value);
    return `${x.t_direccion} - ${x.t_cod_cl}`;
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Row>
          <Form.Group controlId="exampleForm.ControlInput1" as={Col} md={2}>
            <Form.Label>Fecha Inicio:</Form.Label>
            <InputGroup>
              <DatePicker
                selected={campanaVisita.campana_venta_fecha_inicio}
                onChange={date => onChangeFecInicio(date)}
                style={{ zIndex: 9999 }}
                isClearable
                dateFormat="dd/MM/yyyy"
                className={"form-control"}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1" as={Col} md={2}>
            <Form.Label>Fecha Termino:</Form.Label>
            <InputGroup>
              <DatePicker
                selected={campanaVisita.campana_venta_fecha_termino}
                onChange={date => onChangeFecTermino(date)}
                style={{ zIndex: 9999 }}
                isClearable
                dateFormat="dd/MM/yyyy"
                className={"form-control"}
              />
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} md={12}>
            <Form.Label>Nombre:</Form.Label>
            <Form.Control
              type={"text"}
              required
              onChange={onChangeNombre}
              value={campanaVisita.campana_venta_nombre}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col} xs={12}>
            <Form.Label>Seleccionar Archivo:</Form.Label>
            <Form.File
              id="file"
              label={
                campanaVisita.campana_venta_archivo
                  ? campanaVisita.campana_venta_archivo.name
                  : "Seleccionar Archivo"
              }
              style={{ zIndex: 0 }}
              data-browse="Buscar"
              onChange={handleInputChange}
              custom
              name={"file"}
            />
          </Form.Group>
        </Form.Row>
        {!campanaVisita.campana_venta_all_clientes && (
          <Form.Row>
            <SelectAsyncPaginationDirMulti
              value={dirSelected}
              onChange={onChangeClientes}
              md={12}
              isFilter={false}
              noNullClients={true}
            />
          </Form.Row>
        )}
        <Form.Row>
          <Form.Group controlId="exampleForm.ControlInput2" as={Col}>
            <Form.Label>Para todos los clientes</Form.Label>
            <Checkbox
              checked={Boolean(campanaVisita.campana_venta_all_clientes)}
              onChange={onChangeCheckCliente}
              style={{ verticalAlign: "middle" }}
              inputProps={{
                "aria-label": "primary checkbox"
              }}
            />
          </Form.Group>
        </Form.Row>

        <Button type="submit" ref={btnRef} style={{ display: "none" }} />
      </Form>
    </>
  );
};
