import React from "react";
import { Col, Row } from "react-bootstrap";
import { NumericFormat } from "react-number-format";

export const StatusCard = props => {
  const { total, variant, label, percentage } = props;
  return (
    <>
      <div className={`col bg-light-${variant} px-6 py-8 rounded-xl mr-7 mb-0`}>
        <Row>
          <Col md="8">
            <a
              href="#"
              className={`text-${variant} font-weight-bolder font-size-h4`}
            >
              $
              <NumericFormat
                value={total}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
              />
            </a>
          </Col>

          <Col className={"text-right"} md="4">
            <a
              href="#"
              className={`text-${variant} font-weight-bolder font-size-h4`}
              style={{ marginTop: 5 }}
            >
              {Math.round(percentage)}%
            </a>
          </Col>
        </Row>
        <Row>
          <Col>
            <a
              href="#"
              className={`text-${variant} font-weight-bolder font-size-h4`}
            >
              {label}
            </a>
          </Col>
        </Row>
      </div>
    </>
  );
};
