import React from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import { NumericFormat } from "react-number-format";

const styles = StyleSheet.create({
  sectionRow: {
    flexDirection: "row",
    textAlign: "left",
    padding: 10,
    borderBottom: 1,
    borderBottomColor: "#162789",
    borderTop: 8,
    borderTopColor: "#162789"
  },
  sectionCol: {
    flexGrow: 1
  },
  textBody1: {
    fontSize: 9,
    fontWeight: "normal"
  },
  textHeader: {
    fontSize: 9,
    fontWeight: "bold"
  }
});

export const TableDatGen = ({ visita, reporte }) => {
  return (
    <>
      <View style={styles.sectionRow}>
        <View style={styles.sectionCol}>
          <Text style={styles.textHeader}>Cliente</Text>
          <Text style={styles.textHeader}>Dirección</Text>
          <Text style={styles.textHeader}>Fecha Cierre</Text>
          <Text style={styles.textHeader}>Representante</Text>
        </View>
        <View style={styles.sectionCol}>
          <Text style={styles.textBody1}>
            :&nbsp;&nbsp;{visita.clientes_nombre}&nbsp;
          </Text>
          <Text style={styles.textBody1}>
            :&nbsp;&nbsp;
            {visita.direcciones_nombre} - {visita.direcciones_texto}
          </Text>
          <Text style={styles.textBody1}>
            :&nbsp;&nbsp;
            {moment(visita.visitas_fecejecuta)
              .utc()
              .format("DD-MM-YYYY")}
            &nbsp;
          </Text>
          <Text style={styles.textBody1}>
            :&nbsp;&nbsp;{visita.fullname_tec}
          </Text>
        </View>
        <View style={styles.sectionCol}>
          <Text style={styles.textHeader}>Tipo</Text>
          <Text style={styles.textHeader}>Estado</Text>
          <Text style={styles.textHeader}>Cantidad Venta</Text>
        </View>
        <View style={styles.sectionCol}>
          <Text style={styles.textBody1}>
            :&nbsp;&nbsp;{visita.mv_descripcion}&nbsp;
          </Text>
          <Text style={styles.textBody1}>
            :&nbsp;&nbsp;{visita.est_visita_nombre}&nbsp;
          </Text>
          {reporte.cant_venta && (
            <NumericFormat
              value={reporte.cant_venta}
              displayType={"text"}
              decimalSeparator={","}
              thousandSeparator={"."}
              prefix={"$"}
              renderText={value => (
                <>
                  :&nbsp;&nbsp;{" "}
                  <Text style={{ ...styles.textBody1 }}>{value}</Text>
                </>
              )}
            />
          )}
          {!reporte.cant_venta && (
            <Text style={styles.textBody1}>:&nbsp;&nbsp;No especificado</Text>
          )}
        </View>
      </View>
    </>
  );
};
