import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as requestFromServer from "../../../helpers/axios";
import { addAlert } from "../../../../redux/alerts/alertsRedux";
import { useDispatch } from "react-redux";

export const ModalGetVisitas = props => {
  const { id_visita, show, setShow, OnClickBuscar, cantRequerimientos } = props;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleDelete = () => {
    setLoading(true);
    requestFromServer
      .deleteVisita(id_visita)
      .then(response => {
        dispatch(addAlert({ msg: "Visita Eliminada" }));
        setShow(false);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Carga de requerimientos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Se cargaran en total {cantRequerimientos} eventos, ¿seguro que desea
          continuar?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={OnClickBuscar}>
            Si
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
