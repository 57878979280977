import React from 'react';
import {Font, StyleSheet, Text} from "@react-pdf/renderer";


const styles = StyleSheet.create({
  textSubtitle: {
    fontSize: 9,
    color: 'black',
    backgroundColor: '#fafafa',
    textAlign:'left', padding:4,
    fontFamily: 'Roboto'
  }
});

export const Subtitle3 = (props) => {
  const {label}=props
  return (
      <Text style={styles.textSubtitle}>{label}</Text>
  );
};
