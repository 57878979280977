import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../_metronic/_partials/controls";
import * as requestFromServer from "../../helpers/axios";
import { Button } from "react-bootstrap";
import { FormUsuario } from "./form/FormUsuario";
import { InitialStateUsuario } from "./form/InitialStateUsuario";
import { addAlert } from "../../../redux/alerts/alertsRedux";
import { useDispatch } from "react-redux";
import { CarteraClientes } from "./components/CarteraClientes";
import { CarteraTiendas } from "./components/CarteraTiendas";
import { CCFilters } from "./table_helpers/CCFilters";
import { initialTableFilter } from "../../helpers/TableHelpers";
import { isEqual, isFunction } from "lodash";

export const EditUsuario = ({ match }) => {
  const dispatch = useDispatch();
  const suhbeader = useSubheader();
  suhbeader.setTitle("Usuarios");
  const history = useHistory();
  const { params } = match;
  const { id } = params;

  const btnRef = useRef();
  const [queryParams, setQueryParamsBase] = useState(initialTableFilter);
  const [usuario, setUsuario] = useState(InitialStateUsuario);
  const [loading, setLoading] = useState(false);
  const [direccionesUsu, setDireccionesUsu] = useState([]);
  const [gruposUsu, setGruposUsu] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showAddTienda, setShowAddTienda] = useState(false);
  let cardTitle = "Ingresar Usuario ";
  if (id) cardTitle = "Editar Usuario ";

  const newEntity = !id;

  const sendUsuarioToServer = () => {
    setLoading(true);
    const sendRequest = () => {
      if (!usuario.usu_controlado) usuario.usu_controlado = false;
      if (!usuario.usu_usa_apk_tecnica) usuario.usu_usa_apk_tecnica = false;
      if (!newEntity) return requestFromServer.updateUsuario(id, usuario);
      else return requestFromServer.insertUsuario(usuario);
    };
    sendRequest()
      .then(response => {
        history.push("/usuarios/list");
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };
  const changePWD = () => {
    setLoading(true);
    const sendRequest = () => {
      if (!newEntity) {
        return requestFromServer.putUsuRandPdw(id);
      }
    };
    sendRequest()
      .then(response => {
        console.log(response.data);
        dispatch(addAlert({ msg: "Nueva contraseña: " + response.data.pass }));
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  const onHandleSubmit = () => btnRef.current.click();

  const fetchUsuario = () => {
    setLoading(true);
    requestFromServer
      .getUsuario(id)
      .then(response => {
        setUsuario({ ...response.data });
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
    requestFromServer
      .getDirUsu(id)
      .then(response => {
        setDireccionesUsu([...response.data.entities]);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
    requestFromServer
      .getGruposUsu(id)
      .then(response => {
        setGruposUsu([...response.data.entities]);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) fetchUsuario();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Card>
        <CardHeader title={cardTitle}>
          <CardHeaderToolbar>
            {!newEntity && !loading && (
              <Button type="button" onClick={changePWD}>
                {"Cambiar Contraseña"}
              </Button>
            )}
            &nbsp;
            {!loading && (
              <Button type="button" onClick={onHandleSubmit}>
                {newEntity ? "Ingresar" : "Actualizar"}
              </Button>
            )}
            {loading && (
              <span
                className={`input-group-append 
                             spinner spinner-sm spinner-primary`}
              />
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <FormUsuario
            usuario={usuario}
            setUsuario={setUsuario}
            newEntity={newEntity}
            sendUsuarioToServer={sendUsuarioToServer}
            setLoading={setLoading}
            btnRef={btnRef}
            direccionesUsu={direccionesUsu}
            gruposUsu={gruposUsu}
          />
        </CardBody>
      </Card>

          <CarteraTiendas
            id={id}
            setShowAdd={setShowAddTienda}
            showAdd={showAddTienda}
            usuario={usuario}
          />
    </>
  );
};
