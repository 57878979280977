import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AllOutIcon from "@material-ui/icons/AllOut";
import GroupIcon from "@material-ui/icons/Group";
import { getBounds } from "../../components/mapas/BoundsCalc";

const useStyles = makeStyles(theme => ({
  fab: {
    position: "absolute",
    zIndex: 10,
    display: "flex",
    flexDirection: "column",
    top: "auto",
    right: 10,
    bottom: 40,
    left: "auto"
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

export const BotonesMapas = ({ map, users, btnOpenRef }) => {
  const classes = useStyles();

  const centerBounds = () => {
    const bounds = getBounds(users.map(x => [x.pos_lat, x.pos_lon]));
    map.fitBounds(bounds, {
      padding: [10, 10]
    });
  };

  const handleOpenListaUsuarios = () => btnOpenRef.current.click();

  return (
    <>
      <Fab
        color="secondary"
        size="small"
        aria-label="users"
        style={{ zIndex: 10000 }}
        className={classes.fab}
        onClick={handleOpenListaUsuarios}
      >
        <GroupIcon />
      </Fab>

      <Fab
        color="secondary"
        size="small"
        style={{ bottom: "100px", zIndex: 10000 }}
        aria-label="users"
        className={classes.fab}
        onClick={centerBounds}
      >
        <AllOutIcon />
      </Fab>
    </>
  );
};
