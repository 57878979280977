import { getCarteraTiendasAction } from "./actions/getCarteraTiendasAction";

const initialRdetState = {
  cTiendas: [],
  totalCount: 0,
  loading: false
};

export const ctiendasReducer = (state = initialRdetState, action) => {
  switch (action.type) {
    case "@setCarteraTiendas": {
      const { cTiendas, totalCount } = action.payload;
      return { ...state, cTiendas: cTiendas, totalCount: totalCount };
    }
    case "@setLoading": {
      const { loading } = action.payload;
      return { ...state, loading: loading };
    }
    default:
      return state;
  }
};
export const actionsCarteraTiendas = {
  getCarteraTiendas: q => getCarteraTiendasAction(q)
};
