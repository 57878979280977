import React, { useState } from 'react'
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { useDispatch, useSelector } from "react-redux";
import { actionsLics } from "../../../../../redux/licencias/licenciasRedux";
import SelectAsyncPaginationUsu
  from '../../../components/AsyncSelects/SelectAsyncPaginationUsu'

export const LicAddDialog = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { addingItem } = useSelector(state => state.lics);

  const [tecnicoSelected, setTecnicoSelected] = useState({});

  const handleChangeTecnico = e => {
    setTecnicoSelected(e);
  };


  const onAdd = () => {
    if(tecnicoSelected.value)dispatch(actionsLics.addItem(tecnicoSelected.value)).then(() => onHide());
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Agregar Licencia
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!addingItem &&
          <SelectAsyncPaginationUsu
            value={tecnicoSelected}
            onChange={handleChangeTecnico}
            md={12}
            isFilter={false}
            label={"Usuario"}
            id_perfil={3}
          />
        }

        {addingItem && <span>Agregando licencia...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
            disabled={addingItem}
          >
            Cancelar
          </button>
          <> </>
          <button
            type="button"
            onClick={onAdd}
            className="btn btn-primary btn-elevate"
            disabled={addingItem}
          >
            Agregar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
