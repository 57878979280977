import React from "react";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import ExcelJS from "exceljs";
import * as _ from "lodash";
import * as requestFromServer from "./axios";

export const xls_to_json = ev => {
  const target = ev.target;
  const value = target.type === "checkbox" ? target.checked : target.value;
  const name = target.name;
  const hojas = [];

  return new Promise((resolve, reject) => {
    if (name === "file") {
      let reader = new FileReader();
      reader.readAsArrayBuffer(target.files[0]);
      reader.onload = e => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        workbook.SheetNames.forEach(sheetName => {
          const XL_row_object = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName]
          );
          hojas.push({
            data: XL_row_object,
            sheetName
          });
        });
        resolve(hojas);
      };
    }
  });
};

export const xls_to_Arr = ev => {
  const target = ev.target;
  const name = target.name;
  const hojas = [];

  return new Promise((resolve, reject) => {
    if (name === "file") {
      let reader = new FileReader();
      reader.readAsArrayBuffer(target.files[0]);
      reader.onload = e => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        workbook.SheetNames.forEach(sheetName => {
          const XL_row_object = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName],
            { header: 1 }
          );
          hojas.push({
            data: XL_row_object,
            sheetName
          });
        });
        resolve(hojas);
      };
    }
  });
};
const generateNormalSheetUsuarios = (wb, sheetData) => {
  const ws = wb.addWorksheet(sheetData.sheetName, {
    views: [{ showGridLines: false }]
  });
  let headers = [];
  for (const dataRow of sheetData.data)
    Object.keys(dataRow).forEach(x => {
      headers.push(x);
    });
  headers = _.uniq(headers);
  ws.columns = headers.map(x => ({ header: x, key: x }));

  for (const dataRow of sheetData.data) ws.addRow(dataRow);

  ws.columns.forEach(column => {
    let maxColumnLength = 0;
    if (column.number !== 2) {
      column.eachCell({ includeEmpty: true }, cell => {
        maxColumnLength = Math.max(
          maxColumnLength,
          5,
          cell.value ? cell.value.toString().length : 0
        );
      });
      column.width = maxColumnLength + 2;
    }
  });

  ws.columns.forEach(column => {
    column.eachCell({ includeEmpty: true }, cell => {
      cell.border = {
        left: { style: "medium", color: { argb: "000000" } },
        right: { style: "medium", color: { argb: "000000" } }
      };
    });
  });

  for (const hname of headers)
    ws.getRow(1).getCell(hname).style = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FEFEA5" }
      },
      border: {
        top: { style: "medium", color: { argb: "000000" } },
        left: { style: "medium", color: { argb: "000000" } },
        bottom: { style: "medium", color: { argb: "000000" } },
        right: { style: "medium", color: { argb: "000000" } }
      }
    };
};
const generateNormalSheet = (wb, sheetData, validations) => {
  const ws = wb.addWorksheet(sheetData.sheetName, {
    views: [{ showGridLines: false }]
  });
  let headers = [];
  for (const dataRow of sheetData.data)
    Object.keys(dataRow).forEach(x => {
      headers.push(x);
    });
  headers = _.uniq(headers);
  ws.columns = headers.map(x => ({ header: x, key: x }));

  for (const dataRow of sheetData.data) ws.addRow(dataRow);

  ws.columns.forEach(column => {
    let maxColumnLength = 0;
    if (column.number !== 2) {
      column.eachCell({ includeEmpty: true }, cell => {
        maxColumnLength = Math.max(
          maxColumnLength,
          5,
          cell.value ? cell.value.toString().length : 0
        );
      });
      column.width = maxColumnLength + 2;
    }
  });

  ws.columns.forEach(column => {
    column.eachCell({ includeEmpty: true }, cell => {
      cell.border = {
        left: { style: "medium", color: { argb: "000000" } },
        right: { style: "medium", color: { argb: "000000" } }
      };
    });
  });

  for (const hname of headers)
    ws.getRow(1).getCell(hname).style = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FEFEA5" }
      },
      border: {
        top: { style: "medium", color: { argb: "000000" } },
        left: { style: "medium", color: { argb: "000000" } },
        bottom: { style: "medium", color: { argb: "000000" } },
        right: { style: "medium", color: { argb: "000000" } }
      }
    };
};
export const exportToExcelJs = async (data, fileName) => {
  const wb = new ExcelJS.Workbook();
  for (const sheetData of data) generateNormalSheet(wb, sheetData);
  const buf = await wb.xlsx.writeBuffer();
  const fileExtension = ".xlsx";
  FileSaver.saveAs(new Blob([buf]), fileName + fileExtension);
};
export const exportToExcelPlantillaVisJs = async (data, fileName) => {
  const wb = new ExcelJS.Workbook();
  for (const sheetData of data) generateNormalSheet(wb, sheetData);

  const buf = await wb.xlsx.writeBuffer();
  const fileExtension = ".xlsx";
  FileSaver.saveAs(new Blob([buf]), fileName + fileExtension);
};

export const exportToExcelPlantillaMasterPlanJs = async (data, fileName) => {
  const wb = new ExcelJS.Workbook();
  for (const sheetData of data) generateNormalSheet(wb, sheetData);

  const buf = await wb.xlsx.writeBuffer();
  const fileExtension = ".xlsx";
  FileSaver.saveAs(new Blob([buf]), fileName + fileExtension);
};
export const exportToExcelPlantillaClientesJs = async (data, fileName) => {
  const wb = new ExcelJS.Workbook();
  for (const sheetData of data) generateNormalSheet(wb, sheetData);

  const buf = await wb.xlsx.writeBuffer();
  const fileExtension = ".xlsx";
  FileSaver.saveAs(new Blob([buf]), fileName + fileExtension);
};

export const exportToExcelPlantillaUsuariosJs = async (data, fileName) => {
  const wb = new ExcelJS.Workbook();
  for (const sheetData of data) generateNormalSheetUsuarios(wb, sheetData);

  const buf = await wb.xlsx.writeBuffer();
  const fileExtension = ".xlsx";
  FileSaver.saveAs(new Blob([buf]), fileName + fileExtension);
};
export const exportToExcelPlantillaGruposClientesJs = async (
  data,
  fileName
) => {
  const wb = new ExcelJS.Workbook();
  for (const sheetData of data) generateNormalSheet(wb, sheetData);

  const buf = await wb.xlsx.writeBuffer();
  const fileExtension = ".xlsx";
  FileSaver.saveAs(new Blob([buf]), fileName + fileExtension);
};