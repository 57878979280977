import { ActionsColumnFormatter } from "./ActionsColumnFormatter";
import { VigenciaColumnFormatter } from "./VigenciaColumnFormatter";
import { sortCaret } from "../../../../_metronic/_helpers";

export const getColumns = (setShow, setIdUsuario) => [
  {
    dataField: "usu_login",
    text: "Login",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "fullname",
    text: "Nombre",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "usu_pat",
    text: "Apellido",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "usu_cargo",
    text: "Cargo",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "usu_perfil_nombre",
    text: "Perfil",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "usu_mail",
    text: "Correo",
    sort: true,
    sortCaret: sortCaret
  },
  {
    dataField: "eliminado",
    text: "Vigencia",
    sort: true,
    sortCaret: sortCaret,
    formatter: VigenciaColumnFormatter
  },
  {
    dataField: "accions",
    text: "Acciones",
    formatter: ActionsColumnFormatter,
    formatExtraData: { setShow, setIdUsuario },
    classes: "text-right pr-3 ",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "70px"
    }
  }
];
