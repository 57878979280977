import React, { useState } from "react";
import { RepFilters } from "./RepFilters";
import { Button } from "react-bootstrap";
import { cloneDeep } from "lodash";
import { actionsDets } from "../../../../../redux/rdetenciones/rdetenRedux";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../../_metronic/_partials/controls";

export function ListaFiltrosRBox(props) {
  const { queryParams, setQueryParams, getRuta } = props;
  const dispatch = useDispatch();
  const { loadingVis } = useSelector(state => state.rdet);
  const [isLoading, setLoading] = useState(false);
  const handleBuscar = () => {
    setLoading(true);
    const queryParameters = cloneDeep(queryParams);
    getRuta(queryParameters, setLoading);

    if (
      queryParameters.filter !== undefined &&
      queryParameters.filter.fecha !== undefined &&
      queryParameters.filter.tecnico !== undefined
    )
      dispatch(actionsDets.getVisitas(queryParameters));
    setLoading(false);
  };
  return (
    <Card>
      <CardHeader title="Mapa Detenciones">
        <CardHeaderToolbar>
          <Button
            variant={"primary"}
            disabled={isLoading}
            onClick={!isLoading ? handleBuscar : null}
          >
            {isLoading || loadingVis ? "Buscando.." : "Buscar"}
          </Button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <RepFilters queryParams={queryParams} setQueryParams={setQueryParams} />
      </CardBody>
    </Card>
  );
}
